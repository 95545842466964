import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { ButtonGhost2 } from 'components/button';
import { CollectibleList } from 'components/collectible';
import { PageArea, PageHeader } from 'components/page';

import { GetCollectiblesReq } from 'protobuf/lib/collectibleService';

import { getCollectibles } from 'services/collectible';

import { AppState } from 'state';

import css from './index.module.css';

export const CollectibleListRoute = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<'created' | 'shared'>('created');
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  const request = useMemo<GetCollectiblesReq>(() => {
    switch (filter) {
      case 'created':
        return { creator: walletConnectV2.address! };
      case 'shared':
        return { contributor: walletConnectV2.address! };
    }
  }, [filter]);

  const query = useQuery(['getCollectibles', request], () => getCollectibles(request));

  return (
    <PageArea>
      <PageHeader title="NFTs Gallery">
        <div className={css.filter}>
          <ButtonGhost2 className={clsx({ [css.active]: filter === 'created' })} onClick={() => setFilter('created')}>
            {t('collection.createdByMe')}
          </ButtonGhost2>
          <ButtonGhost2 className={clsx({ [css.active]: filter === 'shared' })} onClick={() => setFilter('shared')}>
            {t('collection.sharedWithMe')}
          </ButtonGhost2>
        </div>
      </PageHeader>
      <CollectibleList
        query={query}
        emptyMessage={
          filter === 'created'
            ? 'You have not contributed to any NFTs yet.'
            : 'You do not have any NFTs shared with you.'
        }
      />
    </PageArea>
  );
};
