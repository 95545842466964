import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useBoolean } from 'use-boolean';

import { HelpIcon } from 'assets/icons';
import logo from 'assets/images/logo.png';
import comingSoon from 'assets/images/ndau/comingSoon.png';

import { Tooltip } from 'components/Tooltip';
import { ButtonPrimary2 } from 'components/button';
import { ClipboardCopy } from 'components/clipboard';
import { ClaimRoyaltyList } from 'components/collection/ClaimRoyaltyList';
import { DialogArea, DialogClose, DialogHeader, DialogOverlay } from 'components/dialog';

import { shortenAddress } from 'constants/helper';

import { getUserCollectionBalance } from 'services/royalties';

import { AppState } from 'state';

import css from './index.module.css';

export type CollectionRoyaltyInfo = {
  metadataCid: string | undefined;
  name: string | undefined;
  balance: string;
  balanceToken: string;
};

const hasBalance = (item: { balanceToken: string; balance: string }) =>
  item.balanceToken !== '0' || item.balance !== '0';

const Header = () => {
  const { t } = useTranslation();
  const [logo2x, setLogo2x] = useState(null);
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  useEffect(() => {
    const loadLogo = async () => {
      const logoModule = await import(`assets/images/${process.env.REACT_APP_DOMAIN}/logo-2x.png`);
      setLogo2x(logoModule.default);
    };
    loadLogo();
  }, []);
  const [isClaimRoyaltiesOpen, showClaimRoyalties, closeClaimRoyalties] = useBoolean(false);

  const { data, refetch } = useQuery('getUserCollectionBalance', getUserCollectionBalance);

  const isEnabled = Boolean(data?.collections.find(hasBalance));
  useEffect(() => {
    if (data !== undefined) {
      if (!isEnabled && isClaimRoyaltiesOpen) {
        closeClaimRoyalties();
      }
    } else if (data === undefined && isClaimRoyaltiesOpen) {
      closeClaimRoyalties();
    }
  }, [closeClaimRoyalties, data, isClaimRoyaltiesOpen, isEnabled]);

  const onSuccess = useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <div className={css.header}>
      <img
        className={css.logo}
        src={logo}
        alt="logo"
        srcSet={
          process.env.REACT_APP_DOMAIN === 'rag'
            ? require(`assets/images/rag/logo-rag.png`).default
            : `${logo} 1x, ${logo2x} 2x`
        }
        style={{
          width: {
            beasy: 136,
            ndau: 210,
            rag: 136,
            obsesh: 136,
          }[process.env.REACT_APP_DOMAIN],
        }}
      />
      {process.env.REACT_APP_DOMAIN === 'ndau' && (
        <a target="_blank" href="https://ndau.io/nft" rel="noreferrer">
          {comingSoon && (
            <img
              className={css.logo}
              src={comingSoon}
              alt="logo"
              style={{
                width: {
                  beasy: 208,
                  ndau: 350,
                  rag: 208,
                  obsesh: 208,
                }[process.env.REACT_APP_DOMAIN],
              }}
            />
          )}
        </a>
      )}

      <div className={css.claimButton}>
        <ButtonPrimary2 disabled={!isEnabled} onClick={showClaimRoyalties}>
          {t('home.claimRoyalties')}
        </ButtonPrimary2>
      </div>

      <div className={css.info}>
        <div>
          <span>{t('home.address')}:</span>
          <span>
            <ClipboardCopy text={walletConnectV2.address}>{shortenAddress(walletConnectV2.address)}</ClipboardCopy>
          </span>
        </div>
        <div>
          <span>{t('home.balance')}:</span>
          <span>{walletConnectV2.balance}</span>
        </div>
        <div>
          <Tooltip content="Active network synchronized with your wallet. You can switch it within BEASY Wallet and re-login.">
            <HelpIcon />
          </Tooltip>
          <span>{t('home.activeNetwork')}:</span>
          <span>{walletConnectV2.chainId}</span>
        </div>
      </div>

      {isClaimRoyaltiesOpen && (
        <DialogOverlay>
          <DialogArea>
            <DialogHeader className={css.dialogHeader}>
              <h3>{t('home.existingCollections')}</h3>
              <p>{t('home.royaltyBalance')}: </p>
              <DialogClose onClick={closeClaimRoyalties} />
            </DialogHeader>

            <div className={css.content}>
              {data?.collections.map(i => (
                <ClaimRoyaltyList key={i.collectionId} collectionRoyaltyInfo={i} handleSuccess={() => onSuccess()} />
              ))}
            </div>
          </DialogArea>
        </DialogOverlay>
      )}
    </div>
  );
};

export default Header;
