import * as yup from 'yup';

const findDuplicates = (arr: string[]) => arr.filter((item, index) => arr.indexOf(item) !== index);

export const validationSchema = yup
  .object()
  .shape({
    attributes: yup.array().of(
      yup.object().shape({
        trait_type: yup.string().required('You must provide a name'),
        display_type: yup.string().required('You must provide a type'),
        value: yup.string().required('You must provide a value'),
      }),
    ),
  })
  .test('youAttributesName', function ({ attributes }) {
    var nilValue: boolean = false;
    var duplicates: boolean = false;
    var nameArray: string[] = [];
    if (attributes) {
      attributes.forEach(function (a) {
        if (!nilValue) {
          nilValue = !a.trait_type || a.trait_type === '';
          nameArray.push(a.trait_type!);
        }
      });
      duplicates = findDuplicates(nameArray).length > 0;
    }
    if (nilValue) {
      return this.createError({
        path: 'youAttributesName',
        message: `You must provide a name`,
      });
    }
    if (duplicates) {
      return this.createError({
        path: 'youAttributesName',
        message: `Attribute's name has to be unique`,
      });
    }
    return true;
  })
  .test('youAttributesValueText', function ({ attributes }) {
    var nilValue: boolean = false;
    if (attributes) {
      attributes.forEach(function (a) {
        if (!nilValue) {
          nilValue = a.display_type === 'Text' && (!a.value || a.value === '');
        }
      });
    }
    return nilValue
      ? this.createError({
          path: 'youAttributesValueText',
          message: `You must provide a value`,
        })
      : true;
  })
  .test('youAttributesValueNumber', function ({ attributes }) {
    var nilValue: boolean = false;
    if (attributes) {
      attributes.forEach(function (a) {
        if (!nilValue) {
          nilValue = a.display_type === 'Number' && (!a.value || a.value === '');
        }
      });
    }
    return nilValue
      ? this.createError({
          path: 'youAttributesValueNumber',
          message: `You must provide a value`,
        })
      : true;
  })
  .test('youAttributesValuePercentage', function ({ attributes }) {
    var nilValue: boolean = false;
    var limitsExceded: boolean = false;
    if (attributes) {
      attributes.forEach(function (a) {
        if (a.display_type === 'Percentage') {
          if (!nilValue) {
            nilValue = !a.value || a.value === '';
          } 
          if (!nilValue) {
            const num = Number(a.value);
            if (!limitsExceded) {
              limitsExceded = num < 0 || num > 100;
            }
          }
        }
      });
    }
    if (nilValue) {
      return this.createError({
        path: 'youAttributesValuePercentage',
        message: `You must provide a value`,
      });
    }
    if (limitsExceded) {
      return this.createError({
        path: 'youAttributesValuePercentage',
        message: `The percentage value has to be a number between 0 and 100`,
      });
    }
    return true;
  })
  .test('youAttributesValueDate', function ({ attributes }) {
    var nilValue: boolean = false;
    if (attributes) {
      attributes.forEach(function (a) {
        if (!nilValue && a.display_type === 'Date') {
          nilValue = (!a.value || a.value === '');
        }
      });
    }
    if (nilValue) {
      return this.createError({
        path: `youAttributesValueDate`,
        message: `You must provide a value`,
      });
    }
    return true;
  });
