import { EthereumAddress } from 'beasy-fe-commons';
import { FieldArray, FormikConfig, FormikProps, FormikProvider } from 'formik';
import { useForm } from 'hooks';
//import logger from 'logger';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useBoolean } from 'use-boolean';
import * as yup from 'yup';

import { HelpIcon, ImagePlaceholderIcon, PlusIcon } from 'assets/icons';

import Alert, { AlertProps } from 'components/Alert';
import ProgressBar from 'components/ProgressBar';
import { Spacer } from 'components/Spacer';
import { Tooltip } from 'components/Tooltip';
import { ButtonPrimary, ButtonPrimary2 } from 'components/button';
import { FieldContainer, FieldError, FieldTitle } from 'components/field';
import { ImageFile } from 'components/image';
import { InputFile, InputSlider, NumberInput, TextInput } from 'components/input';
import { LayoutCenter, VR } from 'components/layout';
import { LoadingScreen, LoadingScreenProps } from 'components/loading';
import { ShareHolder, UserPickDialog } from 'components/user';
import { CreatorForm, creatorFormikConfig } from 'components/user/CreatorForm';

import { encodeParam, priceRegexp } from 'constants/helper';
import { acceptedImageTypes, supportedImageTypes } from 'constants/mimeTypes';

import { useCreators } from 'hooks/friends';
import { useProgress } from 'hooks/useProgress';

import { CreateCollectionReq_RoyaltyHolder } from 'protobuf/lib/collectionService';
import { UserProfile_AdditionalData } from 'protobuf/lib/userProfileService';

import { setAccount } from 'services/account';
import { Activity, log } from 'services/activityReport';
import { axios } from 'services/axios';
import { registerUser } from 'services/chargeMaster';
import { createFriend } from 'services/friend';
import {
  createCreatorProfile,
  useGetCurrentUserProfile,
  UsernameAlreadyExistsError,
  UserProfileQueryKey,
} from 'services/userProfile';

import { AppState } from 'state';

//import { registerContributorHubspot } from 'services/zapier';
import { environment } from 'environment';

import css from './index.module.css';

interface Props {
  form: FormikProps<FormValue>;
}

export const CollectionEdit = ({ form }: Props) => {
  const { values, touched, errors, setFieldValue, handleChange, handleBlur } = form;
  const getCurrentUserProfile = useGetCurrentUserProfile();
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  const getUserProfileQuery = useQuery(UserProfileQueryKey.getUserProfile, () => {
    return getCurrentUserProfile();
  });
  const message = (
    <>
      <p>
        The Royalty holders allows you to share royalties from secondary sales of your NFT with the Friends from your
        Beasy Wallet. You will be able share and track royalties together with your co-creators, business partners, and
        stakeholders.
      </p>
      <br />
      <p>
        Press “<b>+ Existing Royalty Holder</b>” if your Royalty Holder is already a Friend in your Beasy Digital Wallet
        and a registered creator on the platform.
      </p>
      <br />
      <p>
        Press “<b>+ Create New Royalty Holder</b>” if your Royalty Holder does not have a Beasy Wallet user or is not a
        registered creator on the platform.
      </p>
    </>
  );

  const creators = useCreators();
  const allCreators = useMemo(() => [walletConnectV2.address!, ...creators], [creators]);

  const setFriends = useCallback(
    (friends: EthereumAddress[]) => {
      setFieldValue(
        'royaltyHolders',
        friends.map<CreateCollectionReq_RoyaltyHolder>(
          address =>
            values.royaltyHolders.find(i => i.userProfileId === address) || {
              userProfileId: address,
              royalty: '0',
            },
        ),
      );
    },
    [setFieldValue, values.royaltyHolders],
  );

  const [isFriendsPickerOpen, showFriendsPicker, closeFriendsPicker] = useBoolean(false);
  const [isCreatorFormOpen, showCreatorForm, closeCreatorForm] = useBoolean(false);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [currentUsername, setUsername] = useState('');

  const { progress, setProgress } = useProgress();
  const [alertProps, setAlertProps] = useState<AlertProps | undefined>();
  const [loadingScreenProps, setLoadingScreenProps] = useState<LoadingScreenProps | undefined>();
  const { form: creatorForm, getFormValue } = useForm(creatorFormikConfig);
  const progressMessage = useMemo(() => {
    return progress === 1 ? 'Almost done...' : `Processing: ${progress != null ? Math.round(100 * progress) : null}%`;
  }, [progress]);

  const showErrorAlert = useCallback(() => {
    setLoadingScreenProps(undefined);
    setAlertProps({
      isSuccess: false,
      message: 'There was an issue processing your registration. Please try again.',
      onClose: () => setAlertProps(undefined),
    });
  }, []);

  //TODO REFACTOR WITH REGISTERCONTRIBUTOR
  const registerCreator = async () => {
    setLoading(true);
    setError(false);
    const account_id = encodeParam((+new Date()).toString(36).slice(-5) + Math.random().toString(36).substring(2, 5));
    let formValue;
    let address;
    try {
      //Validate username and address on database
      formValue = await getFormValue();
      const { username } = formValue;
      setUsername(username);
      //Set Account
      const result = await setAccount(account_id);
      address = result.address;
      const additionalData: UserProfile_AdditionalData = {
        firstName: formValue.name,
        lastName: formValue.lastname,
        businessName: formValue.business,
        source: process.env.REACT_APP_DOMAIN,
      };
      await createCreatorProfile(address, username, undefined, additionalData);
      closeCreatorForm();
    } catch (error) {
      setLoading(false);
      error instanceof UsernameAlreadyExistsError ? setError(true) : setError(false);
      //logger.error(error);
      return;
    }
    setLoading(false);
    //Open alert modal
    setLoadingScreenProps({
      message: 'Please give us a moment while we finish creating your Royalty Holder account.',
      isNotification: false,
    });

    setProgress(0);

    //Register user as creator
    try {
      await axios.get(environment.apiUrl + 'creator/add/' + formValue?.role + '/' + address);
    } catch (error) {
      //logger.error(error);
      showErrorAlert();
      return;
    }

    //Register user on hubspot
    /*
    setProgress(0.25);
    try {
      await registerContributorHubspot(account_id, {
        name: formValue?.name,
        lastname: formValue?.lastname,
        business: formValue?.business,
        industry: formValue?.industry,
        role: formValue?.role,
        eth_address: address,
        email: formValue?.email,
        phone: formValue?.phone,
        existingUser: getUserProfileQuery?.data?.nickname,
      });
    } catch (error) {
      logger.error(error);
      showErrorAlert();
      return;
    }
    */
    //Register user on chargeMaster and setAccount
    setProgress(0.5);
    try {
      const roleEncoded = encodeParam(formValue?.role!.toLowerCase());
      await registerUser(address, roleEncoded, account_id);
    } catch (error) {
      //logger.error(error);
      showErrorAlert();
      return;
    }

    //Create a friend connection
    setProgress(0.75);
    try {
      await createFriend(address);
      const currentRoyalties = values.royaltyHolders;
      currentRoyalties.push({
        userProfileId: address,
        royalty: '0',
      });
      let royaltyHolders: string[] = [];
      currentRoyalties.forEach(currentRoyalty => {
        royaltyHolders.push(currentRoyalty.userProfileId);
      });
      setFriends(royaltyHolders);
    } catch (error) {
      //logger.error(error);
      showErrorAlert();
      return;
    }

    //Finish
    setProgress(1);
    log(address, Activity.AUTO_REGISTRATION, process.env.REACT_APP_DOMAIN);
    setLoadingScreenProps(undefined);
    setAlertProps({
      isSuccess: true,
      message: `Royalty Holder successfully added!`,
      onClose: () => {
        setAlertProps(undefined);
      },
    });
  };

  return (
    <section className={css.wrapper}>
      <section className={css.left}>
        <FieldContainer>
          <figure className={css.image}>
            {values.image ? (
              <ImageFile file={values.image} alt={values.image.name} />
            ) : (
              <LayoutCenter>
                <ImagePlaceholderIcon />
              </LayoutCenter>
            )}
          </figure>
          <InputFile name="image" setFieldValue={setFieldValue} onBlur={handleBlur} accept={acceptedImageTypes} />
          <FieldError>{touched.image && errors.image}</FieldError>
        </FieldContainer>

        <FieldContainer>
          <FieldTitle>Name</FieldTitle>
          <TextInput name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} />
          <FieldError>{touched.name && errors.name}</FieldError>
        </FieldContainer>

        <FieldContainer>
          <FieldTitle>Description</FieldTitle>
          <TextInput name="description" value={values.description} onChange={handleChange} onBlur={handleBlur} />
          <FieldError>{touched.description && errors.description}</FieldError>
        </FieldContainer>

        <FieldContainer>
          <FieldTitle>
            External link <Spacer width="5px" />
            <i>Optional</i>
          </FieldTitle>
          <TextInput name="externalLink" value={values.externalLink} onChange={handleChange} onBlur={handleBlur} />
        </FieldContainer>
      </section>

      <VR />

      <section className={css.right}>
        <header>
          <div className={css.tooltip}>
            <h3>Royalty Holders</h3>
            <Tooltip isBig={true} content={message}>
              <HelpIcon />
            </Tooltip>
          </div>
          <Spacer height="8px" />
          <p>When the NFT is resold, these royalty holders will receive a percent of the transactional value.</p>
        </header>

        <FormikProvider value={form}>
          <FieldArray name="royaltyHolders">
            {({ remove, push }) =>
              values.royaltyHolders.map(({ userProfileId, royalty }, index) => (
                <div key={userProfileId} className={css.field}>
                  <ShareHolder address={userProfileId} />
                  <InputSlider
                    value={Number(royalty)}
                    step={0.1}
                    max={availableTotalRoyalty}
                    onChange={v => setFieldValue(`royaltyHolders.${index}.royalty`, String(v))}
                  />
                  <NumberInput
                    name={`royaltyHolders.${index}.royalty`}
                    min={0}
                    max={availableTotalRoyalty}
                    step={0.01}
                    value={Number(royalty)}
                    onChange={handleChange}
                  />
                </div>
              ))
            }
          </FieldArray>
        </FormikProvider>

        {errors['allRoyaltyHolders' as 'royaltyHolders'] && (
          <FieldError>{String(errors['allRoyaltyHolders' as 'royaltyHolders'])}</FieldError>
        )}

        <ButtonPrimary2 className={css.button} onClick={showFriendsPicker}>
          <PlusIcon size={12} />
          <Spacer width="6px" />
          Existing Royalty Holder
        </ButtonPrimary2>
        {/*
        <ButtonPrimary className={css.button} onClick={showCreatorForm}>
          <PlusIcon size={12} />
          <Spacer width="6px" />
          Create New Royalty Holder
        </ButtonPrimary> */}

        {isFriendsPickerOpen && (
          <UserPickDialog
            options={allCreators}
            title="Add/Remove Royalty Holders"
            value={values.royaltyHolders.map(i => i.userProfileId.toLowerCase())}
            setValue={setFriends}
            onClose={closeFriendsPicker}
          />
        )}
        {isCreatorFormOpen && (
          <CreatorForm
            form={creatorForm}
            username={currentUsername}
            isLoading={isLoading}
            isError={isError}
            onClick={registerCreator}
            onClose={closeCreatorForm}
          />
        )}
        {loadingScreenProps && (
          <LoadingScreen {...loadingScreenProps}>
            {progress != null && !loadingScreenProps.isNotification && (
              <ProgressBar message={progressMessage} progress={progress} />
            )}
          </LoadingScreen>
        )}
        {alertProps && <Alert {...alertProps} />}
      </section>
    </section>
  );
};

interface FormValue {
  name: string;
  description: string;
  externalLink: string;
  image?: File;
  royaltyHolders: CreateCollectionReq_RoyaltyHolder[];
}

const availableTotalRoyalty = 10;

export const collectionFormikConfig: FormikConfig<FormValue> = {
  initialValues: {
    name: '',
    description: '',
    externalLink: '',
    image: undefined,
    royaltyHolders: [],
  },
  onSubmit: () => {},
  validateOnBlur: true,
  validateOnChange: true,
  validationSchema: yup
    .object()
    .shape({
      name: yup
        .string()
        .required('You must provide a name')
        .min(1, 'Minimum length is 1 character')
        .max(100, 'Maximum length is 100 characters'),
      description: yup
        .string()
        .required('You must provide a description')
        .max(1500, 'Maximum length is 1,500 characters'),
      image: yup
        .mixed()
        .required('You must provide an image')
        .test('size', 'The file size must not exceed 50MB', (f?: File) => (f ? f.size <= 52428800 : true))
        .test('type', 'Supported file types are: jpeg, png, jpg', (f?: File) =>
          f ? supportedImageTypes.includes(f.type) : true,
        ),
      royaltyHolders: yup.array().of(
        yup.object().shape({
          userProfileId: yup.string().required('Value is required'),
          royalty: yup
            .number()
            .required('Value is required')
            .test('Royalty', 'Must contain only two numbers after decimal point', n =>
              n ? priceRegexp.test(String(n)) : true,
            ),
        }),
      ),
    })
    .test('youRoyaltyHolder', function ({ royaltyHolders }) {
      const othersRoyalty = royaltyHolders!.reduce((acc, { royalty }) => acc + royalty!, 0);

      return othersRoyalty > availableTotalRoyalty
        ? this.createError({
            path: 'allRoyaltyHolders', // must have path otherwise formik will ignore this error
            message: `The total royalty of all royalty holders must not exceed ${availableTotalRoyalty}%.`,
          })
        : true;
    }),
};
