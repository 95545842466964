import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

import { UserRole } from 'protobuf/lib/userProfileService';

import { getRoleNameMap } from 'services/userProfile';
import { useSelector } from 'react-redux';
import { AppState } from 'state';



export const useRole = () => {
  const rolesQuery = useQuery('getRoleNameMap', () => getRoleNameMap());
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  const byNetwork = ({ network }: UserRole) => network === walletConnectV2.chainId;
  const getRoleNameByNetwork = useCallback(
    (userRoles: UserRole[]) => {
      if (rolesQuery.data) {
        const found = userRoles.find(byNetwork);

        if (found) {
          return rolesQuery.data[found.role];
        }
      }
    },
    [rolesQuery.data],
  );

  return useMemo(
    () => ({
      getRoleNameByNetwork,
    }),
    [getRoleNameByNetwork],
  );
};
