// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sort_icon__dIPpx {
  fill: none;
}

.Sort_icon__dIPpx rect {
  fill: var(--alt2-color);
}
`, "",{"version":3,"sources":["webpack://./src/assets/icons/Sort/index.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".icon {\n  fill: none;\n}\n\n.icon rect {\n  fill: var(--alt2-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `Sort_icon__dIPpx`
};
export default ___CSS_LOADER_EXPORT___;
