import Web3 from 'web3';

import { Spacer } from 'components/Spacer';
import { ButtonPrimary } from 'components/button';

import css from './index.module.css';

interface Props {
  priceAsWei: string;
  cryptocurrency: string;
  isLoading: boolean;
  onSubmit: () => void;
}

export const ClaimRoyaltyItem = ({ priceAsWei, cryptocurrency, isLoading, onSubmit }: Props) => {
  const price = Web3.utils.fromWei(priceAsWei, "wei");
  return (
    <div className={css.item}>
      <p>{price}</p>
      <Spacer width="10px"></Spacer>
      <p>{cryptocurrency}</p>
      <Spacer width="20px"></Spacer>
      <ButtonPrimary disabled={price === '0' || isLoading} onClick={onSubmit}>
        {isLoading ? 'Processing...' : 'Claim'}
      </ButtonPrimary>
    </div>
  );
};
