interface Props {
  width?: string;
  height?: string;

  /** use `grow` without `width/height` */
  grow?: true;
}

export const Spacer = ({ width, height, grow }: Props) => (
  <div style={{ width, height, display: width ? 'inline-block' : undefined, flexGrow: grow && 1 }}></div>
);
