import { EthereumAddress } from 'beasy-fe-commons';
import { useCallback, useState } from 'react';

import { PlusIcon } from 'assets/icons';

import { Spacer } from 'components/Spacer';
import { ButtonPrimary, ButtonPrimary2, ButtonSecondary } from 'components/button';
import { DialogFooter, DialogPicker1 } from 'components/dialog';
import { InputValueSelector, TextInput } from 'components/input';
import { EmptyList, VH } from 'components/layout';

import { Collection } from 'protobuf/lib/collectionService';

import { history } from 'services/history';

import { CollectionPickItem } from '..';
import css from './index.module.css';

interface Props {
  options?: Collection[];
  value?: EthereumAddress;
  setValue: (value?: EthereumAddress) => void;
  onClose: () => void;
}

export const CollectionPickDialog = ({ options, value, setValue, onClose }: Props) => {
  const [selected, setSelected] = useState(value);
  const [filter, setFilter] = useState('');
  const submit = useCallback(() => {
    setValue(selected);
    onClose();
  }, [onClose, selected, setValue]);
  const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilter(value);
  };
  return (
    <DialogPicker1 title="Pick collection" onClose={onClose} className={css.wrapper}>
      {/* TODO: Add lens icon */}
      <TextInput placeholder="Search" onChange={handleFilterInputChange} />
      <VH />
      <ButtonPrimary2
        className={css.createButton}
        onClick={() => {
          history.push('/home/collection/create');
        }}
      >
        <PlusIcon size={12} />
        <Spacer width="6px" />
        Create collection
      </ButtonPrimary2>

      <section>
        {!options?.length && (
          <EmptyList
            message={`
              You have not created any collections yet.
              Press "Create" to begin adding to your gallery.
            `}
          />
        )}
        <div className={css.list}>
          {options
            ?.filter(option => option.name.includes(filter))
            .map(i => (
              <InputValueSelector
                key={i.address}
                value={i.address}
                select={setSelected}
                selected={selected === i.address}
              >
                <CollectionPickItem collection={i} />
              </InputValueSelector>
            ))}
        </div>
      </section>

      <DialogFooter>
        <ButtonSecondary onClick={onClose}>Cancel</ButtonSecondary>
        <ButtonPrimary disabled={!selected} onClick={submit}>
          Continue
        </ButtonPrimary>
      </DialogFooter>
    </DialogPicker1>
  );
};
