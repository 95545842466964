// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_container__gc\\+Y2 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.home_header__1dISi {
  flex-basis: 80px;
}

.home_content__2EyLi {
  display: flex;
  flex: 1 1;
}

.home_routes__wVTrE {
  flex: 1 1;
  margin-bottom: 24px;
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/home/home.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAO;AACT;;AAEA;EACE,SAAO;EACP,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.header {\n  flex-basis: 80px;\n}\n\n.content {\n  display: flex;\n  flex: 1;\n}\n\n.routes {\n  flex: 1;\n  margin-bottom: 24px;\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `home_container__gc+Y2`,
	"header": `home_header__1dISi`,
	"content": `home_content__2EyLi`,
	"routes": `home_routes__wVTrE`
};
export default ___CSS_LOADER_EXPORT___;
