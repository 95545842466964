import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { LogoIcon, TickIcon, WarningIcon } from 'assets/icons';

import { Spacer } from 'components/Spacer';
import { ButtonPrimary } from 'components/button';
import { DialogArea, DialogClose, DialogHeader, DialogOverlay } from 'components/dialog';



import css from './index.module.css';

export interface AlertProps {
  message: string;
  cryptoRequired?: string;
  hash?: string;
  isSuccess: boolean;
  onClose: () => void;
}

const Alert = ({ message, hash, isSuccess, cryptoRequired, onClose }: AlertProps) => {
  const { t } = useTranslation();

  return (
    <DialogOverlay>
      <DialogArea className={css.wrapper}>
        <DialogHeader className={css.header}>
          <DialogClose onClick={onClose} />
        </DialogHeader>
        <div>
          <LogoIcon />
          {isSuccess ? (
            <TickIcon className={clsx(css.icon, css.tick)} />
          ) : (
            <WarningIcon className={clsx(css.icon, css.warning)} />
          )}
          <Spacer height="15px" />
          <h3>{message}</h3>
          <Spacer height="15px" />
          {cryptoRequired && <h3>{cryptoRequired}</h3>}
        </div>
            
        {hash && (
          <>
            <Spacer height="25px" />
            <ButtonPrimary
              className={css.button}
              onClick={() => {
                const url = `${hash}`;
                window.open(url, '_blank')?.focus();
              }}
            >
              {t('components.alert.navPolygon')}
            </ButtonPrimary>
          </>
        )}
      </DialogArea>
    </DialogOverlay>
  );
};

export default Alert;
