import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { ChevronIcon } from 'assets/icons';

import { ButtonGhost, ButtonGhost2 } from 'components/button';

import css from './index.module.css';

interface Props {
  collectionName?: string;
  collectibleName?: string;
  handleNavigateCollectionView?: () => void;
}

export const Navigator = ({ collectionName, collectibleName, handleNavigateCollectionView }: Props) => {
  const history = useHistory();
  const handleNavigateCollectionList = useCallback(() => history.push('/home/collection/list'), [history]);
  return (
    <div className={css.wrapper}>
      <ButtonGhost2 onClick={handleNavigateCollectionList}>Collections</ButtonGhost2>
      <ChevronIcon className={css.chevronIcon} />
      {collectibleName ? (
        <>
          <ButtonGhost2 onClick={handleNavigateCollectionView}>{collectionName}</ButtonGhost2>
          <ChevronIcon className={css.chevronIcon} />
          <ButtonGhost>{collectibleName}</ButtonGhost>
        </>
      ) : (
        <ButtonGhost>{collectionName}</ButtonGhost>
      )}
    </div>
  );
};

export default Navigator;
