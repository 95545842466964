// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileWithSelect_wrapper__yFeuS {
  display: flex;
  color: var(--text-color-accent);
  border-radius: 6px 0px 0px 6px;
}

.FileWithSelect_selector__LjfWH {
  flex: 1 1;
  border-radius: 6px 0px 0px 6px;
}

.FileWithSelect_wrapper__yFeuS > label {
  height: var(--input-min-height);
  line-height: var(--input-min-height);
  background: var(--alt2-color);
  color: var(--alt3-color);
  font-weight: 500;
  font-size: 14px;
  padding: 0 15px;
  cursor: pointer;
  border-radius: 0px 6px 6px 0px;
}

.FileWithSelect_wrapper__yFeuS > label:hover {
  background: var(--primary-color);
  color: var(--body-color);
}

.FileWithSelect_wrapper__yFeuS > label > input {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/input/FileWithSelect/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+BAA+B;EAC/B,8BAA8B;AAChC;;AAEA;EACE,SAAO;EACP,8BAA8B;AAChC;;AAEA;EACE,+BAA+B;EAC/B,oCAAoC;EACpC,6BAA6B;EAC7B,wBAAwB;EACxB,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,gCAAgC;EAChC,wBAAwB;AAC1B;;AAEA;EACE,aAAa;AACf","sourcesContent":[".wrapper {\n  display: flex;\n  color: var(--text-color-accent);\n  border-radius: 6px 0px 0px 6px;\n}\n\n.selector {\n  flex: 1;\n  border-radius: 6px 0px 0px 6px;\n}\n\n.wrapper > label {\n  height: var(--input-min-height);\n  line-height: var(--input-min-height);\n  background: var(--alt2-color);\n  color: var(--alt3-color);\n  font-weight: 500;\n  font-size: 14px;\n  padding: 0 15px;\n  cursor: pointer;\n  border-radius: 0px 6px 6px 0px;\n}\n\n.wrapper > label:hover {\n  background: var(--primary-color);\n  color: var(--body-color);\n}\n\n.wrapper > label > input {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `FileWithSelect_wrapper__yFeuS`,
	"selector": `FileWithSelect_selector__LjfWH`
};
export default ___CSS_LOADER_EXPORT___;
