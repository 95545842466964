// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TourStep_wrapper__UJKff {
  width: 420px;
  padding: 24px;
  border-radius: 6px;
  background-color: var(--dialog-bg-color);
}

.TourStep_wrapper__UJKff > p {
  margin-top: 24px;
  margin-bottom: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Onboarding/TourStep/index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".wrapper {\n  width: 420px;\n  padding: 24px;\n  border-radius: 6px;\n  background-color: var(--dialog-bg-color);\n}\n\n.wrapper > p {\n  margin-top: 24px;\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `TourStep_wrapper__UJKff`
};
export default ___CSS_LOADER_EXPORT___;
