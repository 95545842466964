import clsx from 'clsx';

import { ArrowIcon } from 'assets/icons';

import css from './index.module.css';

type Props = {
  onClickPrevious?: () => void;
  onClickContinue?: () => void;
  isLast?: boolean;
  isDisabled?: boolean;
};

const NavButtons = ({ onClickPrevious, onClickContinue, isLast, isDisabled }: Props) => {
  return (
    <div className={css.container}>
      <div className={css.wrapper}>
        {onClickPrevious && (
          <button
            className={clsx(css.button, css.previousButton)}
            onClick={onClickPrevious}
            type="submit"
            disabled={isDisabled}
          >
            <ArrowIcon /> Previous Step
          </button>
        )}
        {onClickContinue && (
          <button
            className={clsx(css.button, css.continueButton, isLast && css.last)}
            onClick={onClickContinue}
            type="submit"
            disabled={isDisabled}
          >
            {isLast ? 'Subscribe' : 'Continue'}
          </button>
        )}
      </div>
    </div>
  );
};

export default NavButtons;
