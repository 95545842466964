// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PickItem_wrapper__KqfUS {
  display: flex;
  align-items: center;
  gap: 8px;
}

.PickItem_wrapper__KqfUS > figure {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 3px;
}

.PickItem_wrapper__KqfUS > h4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/collection/PickItem/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.wrapper > figure {\n  width: 32px;\n  height: 32px;\n  min-width: 32px;\n  min-height: 32px;\n  border-radius: 3px;\n}\n\n.wrapper > h4 {\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `PickItem_wrapper__KqfUS`
};
export default ___CSS_LOADER_EXPORT___;
