// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Terms_modWrapper__4HpqR {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.Terms_modWrapper__4HpqR fieldset {
  border: 0px;
  margin-top: 20px;
}

.Terms_modWrapper__4HpqR fieldset span {
  font-weight: 300;
  font-size: 14px;
}

.Terms_modWrapper__4HpqR fieldset > label {
  margin-bottom: 10px;
  display: block;
  font-size: 16px;
  line-height: 20px;
  color: var(--body-color);
}

.Terms_content__VgHdL {
  margin-top: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/components/collectible/steps/Terms/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".modWrapper {\n  display: flex;\n  flex-direction: column;\n  margin-right: 20px;\n}\n\n.modWrapper fieldset {\n  border: 0px;\n  margin-top: 20px;\n}\n\n.modWrapper fieldset span {\n  font-weight: 300;\n  font-size: 14px;\n}\n\n.modWrapper fieldset > label {\n  margin-bottom: 10px;\n  display: block;\n  font-size: 16px;\n  line-height: 20px;\n  color: var(--body-color);\n}\n\n.content {\n  margin-top: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modWrapper": `Terms_modWrapper__4HpqR`,
	"content": `Terms_content__VgHdL`
};
export default ___CSS_LOADER_EXPORT___;
