import { IpfsCollectionInfo } from 'beasy-fe-commons';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { ButtonSecondary } from 'components/button';
import { CollectibleThumbnail } from 'components/collectible';
import { ImageView } from 'components/image';
import { EmptyList } from 'components/layout';

import { useIpfsInfo } from 'hooks/useIpfs';

import { Collectible } from 'protobuf/lib/collectibleService';
import { Collection } from 'protobuf/lib/collectionService';

import { getCollectibles } from 'services/collectible';

import css from './index.module.css';

interface Props {
  collection?: Collection;
}

export const CollectionListItem = ({ collection }: Props) => {
  const history = useHistory();

  const info = useIpfsInfo<IpfsCollectionInfo>(collection?.metadataCid);

  const collectibleReq = {
    collection: collection?.address,
  };

  const { data: collectibles } = useQuery(['getCollectibles', collectibleReq], () => getCollectibles(collectibleReq), {
    enabled: Boolean(collection),
  });

  // const collectibles = _collectibles ? [..._collectibles, ..._collectibles, ..._collectibles] : _collectibles;

  return (
    <article className={css.wrapper}>
      <h4>{collection?.name ?? <Skeleton width={50} />}</h4>
      <section className={css.collection}>
        <figure>
          <ImageView src={info?.image} />
        </figure>
        <p>{info?.description ?? <Skeleton width={50} />}</p>
      </section>
      <div className={css.collectibles}>
        {(collectibles?.slice(0, collectiblesLimit) || loadingCollectibles).map(({ CID }, ix) => (
          <figure key={ix}>
            <CollectibleThumbnail metadataCid={CID} />
          </figure>
        ))}
        {collectibles && collectibles.length > collectiblesLimit && (
          <figure className={css.counter}>+{collectibles.length - collectiblesLimit}</figure>
        )}
        {collectibles?.length === 0 && <EmptyList message="No NFTs in this collection yet." border />}
      </div>
      <ButtonSecondary
        className={css.button}
        onClick={() => {
          history.push(`/home/collection/view/${collection?.address}`);
        }}
      >
        See collection
      </ButtonSecondary>
    </article>
  );
};

const collectiblesLimit = 5;
const loadingCollectibles: Partial<Collectible>[] = [{}, {}, {}, {}, {}, {}];
