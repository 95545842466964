import clsx from 'clsx';
import React from 'react';

import { CrossIcon } from 'assets/icons';

import { removeArrayIndex } from 'constants/helper';

import css from './index.module.css';

interface Props<T, V extends T | T[]> {
  value?: V;
  multiple?: V extends T ? false : true;
  onChange?: (value?: V) => void;
  renderValue?: (value: T) => React.ReactNode;
  className?: string;
  placeholder?: React.ReactNode;
  isNoRemovable?: boolean;
}

interface ItemProps {
  index?: number;
  remove: React.MouseEventHandler<HTMLButtonElement>;
  content: React.ReactNode;
  isNoRemovable?: boolean;
}

const Item = ({ index, remove, content, isNoRemovable }: ItemProps) => (
  <li>
    {!isNoRemovable && (
      <button type="button" onClick={remove} title="Remove Item" data-index={index} className={css.remove}>
        <CrossIcon size={15} />
      </button>
    )}
    {content}
  </li>
);

// todo maybe ActionValue - so we could add any action to value (such as: select, remove...)
// todo or SelevtableValue in different component

export const ClearableValue = <T, V extends T | T[]>({
  value,
  multiple,
  onChange,
  renderValue,
  className,
  placeholder = '',
  isNoRemovable,
}: Props<T, V>) => {
  const remove = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    ({
      currentTarget: {
        dataset: { index },
      },
    }) => {
      if (!onChange || value === undefined) return;

      if (index === undefined || !multiple) {
        onChange();
      } else {
        const newValue = removeArrayIndex(value as T[], Number(index));

        onChange(newValue.length > 0 ? (newValue as V) : undefined);
      }
    },
    [multiple, onChange, value],
  );

  const defaultRenderValue = (val: T): React.ReactNode => {
    return <>{String(val)}</>;
  };

  const actualRenderValue = renderValue || defaultRenderValue;

  return (
    <div className={clsx(css.wrapper, className)}>
      <ul>
        {multiple ? (
          (value as T[]).map((value, index) => (
            <Item content={actualRenderValue(value)} remove={remove} index={index} key={index} isNoRemovable={isNoRemovable}/>
          ))
        ) : value !== undefined ? (
          <Item content={actualRenderValue(value as T)} remove={remove} isNoRemovable={isNoRemovable}/>
        ) : (
          <li>{placeholder}</li>
        )}
      </ul>
      {multiple && (value as T[]).length > 1 && (
        <button type="button" onClick={remove} title="Remove All Items" className={css.remove}>
          <CrossIcon size={40} />
        </button>
      )}
    </div>
  );
};
