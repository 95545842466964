// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PriceLine_wrapper__47s3P {
  background-color: var(--alt-bg-color);
  padding: 30px 30px 20px 30px;
  border-radius: 8px;
}

.PriceLine_wrapper__47s3P > header {
  margin-bottom: 20px;
}

.PriceLine_chart__Ob0Wo {
  position: relative;
  height: 350px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Chart/PriceLine/index.module.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,4BAA4B;EAC5B,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf","sourcesContent":[".wrapper {\n  background-color: var(--alt-bg-color);\n  padding: 30px 30px 20px 30px;\n  border-radius: 8px;\n}\n\n.wrapper > header {\n  margin-bottom: 20px;\n}\n\n.chart {\n  position: relative;\n  height: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `PriceLine_wrapper__47s3P`,
	"chart": `PriceLine_chart__Ob0Wo`
};
export default ___CSS_LOADER_EXPORT___;
