// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorMessage_container__eDobt {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: #888;
  padding: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ErrorMessage/ErrorMessage.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,mBAAmB;EACnB,WAAW;EACX,aAAa;AACf","sourcesContent":[".container {\n  background-color: rgba(0, 0, 0, 0.2);\n  border-radius: 10px;\n  color: #888;\n  padding: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ErrorMessage_container__eDobt`
};
export default ___CSS_LOADER_EXPORT___;
