import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch, useHistory } from 'react-router-dom';

import Agreement from 'screens/Agreement';
import Dashboard from 'screens/Dashboard';
import Faq from 'screens/Faq';
import MyAccount from 'screens/MyAccount';
import { Onboarding } from 'screens/Onboarding';
import PartnerList from 'screens/PartnerList';
import PartnerProfile from 'screens/PartnerProfile';
import Sidebar from 'screens/Sidebar';
import { CollectibleRoute } from 'screens/collectible';
import { CollectionRoute } from 'screens/collection';

import { AppState } from 'state';

import { AcceptAgreement } from './AcceptAgreement';
import Header from './Header';
import './home.css';
import css from './home.module.css';
import useTradingEvent from './useTradingEvent';

const Home = ({ match }: RouteComponentProps) => {
  useTradingEvent();
  const history = useHistory();
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  useEffect(() => {
    if (walletConnectV2.address !== undefined) {
      walletConnectV2.isCreator ? history.replace('/home/dashboard') : history.replace('/registration');
    } else {
      history.replace('/login');
    }
  }, [walletConnectV2.address, walletConnectV2.isCreator, walletConnectV2.session]);

  return (
    <section data-testid="home-page" className={css.container}>
      <header className={css.header}>
        <Header />
      </header>

      <div className={css.content}>
        <Sidebar />

        <main className={css.routes}>
          <Switch>
            <Route path={`${match.url}/account`} component={MyAccount} />
            <Route path={`${match.url}/dashboard`} component={Dashboard} />
            <Route path={`${match.url}/collection`} component={CollectionRoute} />
            <Route path={`${match.url}/collectible`} component={CollectibleRoute} />
            <Route path={`${match.url}/agreement`} component={Agreement} />
            <Route path={`${match.url}/partner/list`} component={PartnerList} />
            <Route path={`${match.url}/partner/:partnerAddress`} component={PartnerProfile} />
            <Route path={`${match.url}/faq`} component={Faq} />
            <Redirect to={`${match.url}/dashboard`} />
          </Switch>
        </main>
      </div>

      <Onboarding />
      <AcceptAgreement />
    </section>
  );
};

export default Home;
