import { grpc } from '@improbable-eng/grpc-web';
import { captureException } from '@sentry/react';

import { GrpcWebImpl, ActivityReportServiceClientImpl } from 'protobuf/lib/activityReport';

import { environment } from 'environment';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

export enum Activity {
  LOGIN = 'Login',
  LOGOUT = 'Logout',
  TRANSACTION = 'Transaction',
  NEW_COLLECTION = 'New collection',
  NEW_COLLECTIBLE = 'New collectible',
  MINT = "Mint to wallet",
  REGISTRATION = "Registration",
  AUTO_REGISTRATION= "Auto registration"
}

const activityReportServiceClient = new ActivityReportServiceClientImpl(rpc);

export const log = async (ethAddress:string, activity:string, from:string) => {
  try {
    return await activityReportServiceClient.log({ ethAddress, activity, from} );
  } catch (error) {
    captureException(error);
    throw error;
  }
};
