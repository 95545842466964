import React from 'react';

import css from './index.module.css';

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface Props<T extends File | File[]> extends Omit<InputProps, 'value' | 'type' | 'onChange' | 'className'> {
  /** required only if you will unset the value elsewhere (to be able to choose the same file again) */
  value?: T;

  multiple?: T extends File ? false : true;
  onChange?: (value?: T) => void;
}

type FormikProps<T extends File | File[]> =
  | {
      setFieldValue?: never;
    }
  | {
      name: string;
      setFieldValue: (name: string, value?: T) => void;
    };

/** place it inside label */
export const InputFile = <T extends File | File[]>({
  name,
  value,
  multiple,
  onChange,
  setFieldValue,
  ...rest
}: Props<T> & FormikProps<T>) => {
  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!value && ref.current) {
      ref.current.value = ''; // to be able to choose the same file again after unsetting the value elsewhere (will trigger onChange)
    }
  }, [value]);

  const handleChange = React.useCallback(
    ({ currentTarget: { files } }: React.ChangeEvent<HTMLInputElement>) => {
      const value = (multiple ? [...(files as unknown as File[])] : files![0]) as T;

      onChange?.(value);

      if (setFieldValue) {
        if (name == null) throw new TypeError('name must be defined');

        setFieldValue(name, value);
      }
    },
    [multiple, name, onChange, setFieldValue],
  );

  return (
    <input
      {...rest}
      type="file"
      name={name}
      className={css.fileInput}
      multiple={multiple}
      onChange={handleChange}
      ref={ref}
    />
  );
};
