import { EthereumAddress, IpfsFile } from 'beasy-fe-commons';
import { MutableRefObject } from 'react';

import { CreateCollectibleTransactionReq_Contributor } from 'protobuf/lib/collectibleService';
import { MinimalTransactionInfo } from 'protobuf/lib/transactionMessage';

export enum StepIndex {
  TYPE,
  DETAILS,
  ATTRIBUTES,
  TERMS,
  CONTRIBUTORS,
  REVIEW,
}

export interface CombinedStepValues {
  [StepIndex.TYPE]?: TypeStepValue;
  [StepIndex.ATTRIBUTES]?: AttributesStepValue;
  [StepIndex.DETAILS]?: DetailsStepValue;
  [StepIndex.TERMS]?: TermsStepValue;
  [StepIndex.CONTRIBUTORS]?: ContributorsStepValue;
  [StepIndex.REVIEW]?: any; // todo refactor
}

export type TypeStepValue = boolean;

export interface DetailsStepValue {
  collection: EthereumAddress;
  name: string;
  description: string;
  imgUrl?: File;
  videoUrl?: File;
  audioUrl?: File;
  quantity: string;
}

export interface TermsStepValue {
  licenseAgreement?: File | IpfsFile;
  ownershipAgreement?: File | IpfsFile;
}

export interface ContributorsStepValue {
  contributors: CreateCollectibleTransactionReq_Contributor[];
}

export interface AttributesStepValue {
  attributes: Attribute[];
}

export interface Attribute {
  display_type: string;
  trait_type: string;
  value: string;
}

export const STEP_INVALID_VALUE = 'STEP_INVALID_VALUE';

export type GetStepValueRef<T> = MutableRefObject<(() => Promise<T | typeof STEP_INVALID_VALUE>) | undefined>;

export interface StepProps<T> {
  value?: T;
  getValueRef: GetStepValueRef<T>;
  // todo refactor
  // the rest goes for review page
  combinedValues: CombinedStepValues;
  transaction?: MinimalTransactionInfo;
}
