import { IpfsCid } from 'beasy-fe-commons/build/ipfs/types';
import { useMemo } from 'react';
import { useQueries, useQuery, UseQueryResult } from 'react-query';

import { ipfsGateway } from 'services/ipfs';

const queryName = 'getIpfsInfo';

export const useIpfsInfo = <T>(infoCid?: IpfsCid) => {
  const query = useQuery([queryName, infoCid], () => ipfsGateway.get<T>(infoCid!), {
    enabled: Boolean(infoCid),
  });

  return query.data;
};

export const useIpfsInfos = <T>(infoCids: readonly IpfsCid[]) => {
  const options = useMemo(
    () =>
      infoCids.map(infoCid => ({
        queryKey: [queryName, infoCid],
        queryFn: () => ipfsGateway.get<T>(infoCid),
      })),
    [infoCids],
  );

  const queries = useQueries(options) as UseQueryResult<T>[];

  return useMemo(() => queries.reduce(pushLoaded, [] as T[]), [queries]);
};

const pushLoaded = <T>(acc: T[], { data }: UseQueryResult<T>) => {
  if (data) {
    acc.push(data);
  }

  return acc;
};
