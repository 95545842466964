// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Error_wrapper__e2Qdb {
  /* the appeared error does not move down the rest of the page */
  min-height: 20px;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-style: italic;
  color: var(--error-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/field/Error/index.module.css"],"names":[],"mappings":"AAAA;EACE,+DAA+D;EAC/D,gBAAgB;;EAEhB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;AAC3B","sourcesContent":[".wrapper {\n  /* the appeared error does not move down the rest of the page */\n  min-height: 20px;\n\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n  font-style: italic;\n  color: var(--error-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Error_wrapper__e2Qdb`
};
export default ___CSS_LOADER_EXPORT___;
