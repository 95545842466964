import { IconProps } from '../types';

export const Support = ({ className, size = 60 }: IconProps) => (
  <svg
    width={size}
    height={size}
    className={className}
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.6203 3.91162V40.5103C31.6203 42.2902 30.1776 43.7329 28.3977 43.7329H8.58154C6.80156 43.7329 5.35889 42.2902 5.35889 40.5103V3.91162C5.35889 2.13164 6.80156 0.688965 8.58154 0.688965H28.3977C30.1776 0.688965 31.6203 2.13164 31.6203 3.91162Z"
      fill="#71A5F4"
    />
    <path
      d="M31.6203 23.1531V39.609H17.5201C16.9271 39.609 16.4459 39.1278 16.4459 38.5348V24.2273C16.4459 23.6343 16.9271 23.1531 17.5201 23.1531H31.6203Z"
      fill="#71A5F4"
    />
    <path
      d="M29.4719 3.91162V40.5103C29.4719 41.1032 28.9895 41.5845 28.3977 41.5845H8.58154C7.98857 41.5845 7.50732 41.1032 7.50732 40.5103V3.91162C7.50732 3.31865 7.98857 2.8374 8.58154 2.8374H12.2038C12.5465 2.8374 12.8687 3.00176 13.0707 3.27891L14.3103 4.97725C14.5134 5.25439 14.8356 5.41875 15.1783 5.41875H21.7998C22.1425 5.41875 22.4647 5.25439 22.6667 4.97725L23.9063 3.27891C24.1094 3.00176 24.4316 2.8374 24.7743 2.8374H28.3977C28.9895 2.8374 29.4719 3.31865 29.4719 3.91162Z"
      fill="white"
    />
    <path
      d="M29.4719 23.1531V39.609H17.5201C16.9271 39.609 16.4459 39.1278 16.4459 38.5348V24.2273C16.4459 23.6343 16.9271 23.1531 17.5201 23.1531H29.4719Z"
      fill="#D9D9FF"
    />
    <path
      d="M26.2864 21.001H11.4008C11.1042 21.001 10.8637 20.7605 10.8637 20.4639V16.9219C10.8637 16.6253 11.1042 16.3848 11.4008 16.3848H26.2864C26.583 16.3848 26.8235 16.6253 26.8235 16.9219V20.4639C26.8235 20.7606 26.583 21.001 26.2864 21.001Z"
      fill="#71A5F4"
    />
    <path
      d="M22.5634 32.1904H11.8571C11.5605 32.1904 11.3199 31.9499 11.3199 31.6533C11.3199 31.3567 11.5605 31.1162 11.8571 31.1162H22.5634C22.86 31.1162 23.1005 31.3567 23.1005 31.6533C23.1005 31.95 22.8601 32.1904 22.5634 32.1904Z"
      fill="#993399"
    />
    <path
      d="M22.5634 34.2791H11.8571C11.5605 34.2791 11.3199 34.0385 11.3199 33.7419C11.3199 33.4454 11.5605 33.2048 11.8571 33.2048H22.5634C22.86 33.2048 23.1005 33.4454 23.1005 33.7419C23.1005 34.0386 22.8601 34.2791 22.5634 34.2791Z"
      fill="#993399"
    />
    <path
      d="M22.5634 24.2058H11.8571C11.5605 24.2058 11.3199 23.9653 11.3199 23.6687C11.3199 23.3721 11.5605 23.1316 11.8571 23.1316H22.5634C22.86 23.1316 23.1005 23.3721 23.1005 23.6687C23.1005 23.9654 22.8601 24.2058 22.5634 24.2058Z"
      fill="#563181"
    />
    <path
      d="M22.5634 26.2944H11.8571C11.5605 26.2944 11.3199 26.0539 11.3199 25.7573C11.3199 25.4607 11.5605 25.2202 11.8571 25.2202H22.5634C22.86 25.2202 23.1005 25.4607 23.1005 25.7573C23.1005 26.054 22.8601 26.2944 22.5634 26.2944Z"
      fill="#563181"
    />
    <path
      d="M22.5634 28.3831H11.8571C11.5605 28.3831 11.3199 28.1425 11.3199 27.8459C11.3199 27.5494 11.5605 27.3088 11.8571 27.3088H22.5634C22.86 27.3088 23.1005 27.5494 23.1005 27.8459C23.1005 28.1426 22.8601 28.3831 22.5634 28.3831Z"
      fill="#563181"
    />
    <path
      d="M19.6535 11.7371C19.3568 11.7371 19.1164 11.4966 19.1164 11.2V9.39627C19.1164 9.04844 18.8333 8.76538 18.4855 8.76538C18.1377 8.76538 17.8547 9.04833 17.8547 9.39627V11.2C17.8547 11.4966 17.6143 11.7371 17.3176 11.7371C17.0209 11.7371 16.7805 11.4966 16.7805 11.2V9.39627C16.7805 8.45611 17.5454 7.69116 18.4855 7.69116C19.4257 7.69116 20.1906 8.45611 20.1906 9.39627V11.2C20.1906 11.4967 19.9502 11.7371 19.6535 11.7371Z"
      fill="#B3B3FF"
    />
    <path
      d="M20.0212 14.6692H16.9413C16.5853 14.6692 16.2968 14.3806 16.2968 14.0246V11.1877C16.2968 10.8317 16.5853 10.5432 16.9413 10.5432H20.0212C20.3772 10.5432 20.6657 10.8317 20.6657 11.1877V14.0246C20.6657 14.3806 20.3772 14.6692 20.0212 14.6692Z"
      fill="#6C6CFF"
    />
    <path
      d="M18.4812 13.3594C18.7778 13.3594 19.0183 13.1189 19.0183 12.8223C19.0183 12.5256 18.7778 12.2852 18.4812 12.2852C18.1846 12.2852 17.9441 12.5256 17.9441 12.8223C17.9441 13.1189 18.1846 13.3594 18.4812 13.3594Z"
      fill="#2C27FF"
    />
    <path
      d="M21.4796 12.5653L20.6306 12.8119C20.4305 12.8701 20.2293 12.7239 20.2227 12.5157L20.1949 11.632L19.6981 10.9008C19.581 10.7285 19.6578 10.4919 19.8538 10.4214L20.6856 10.1219L21.2276 9.42331C21.3553 9.25864 21.604 9.25864 21.7317 9.42331L22.2736 10.1219L23.1054 10.4214C23.3014 10.4919 23.3783 10.7285 23.2612 10.9008L22.7643 11.632L22.7365 12.5157C22.73 12.724 22.5288 12.8702 22.3286 12.8119L21.4796 12.5653Z"
      fill="#FFAB66"
    />
    <path
      d="M21.4796 4.15698C21.7762 4.15698 22.0167 3.91651 22.0167 3.61987C22.0167 3.32324 21.7762 3.08276 21.4796 3.08276C21.1829 3.08276 20.9425 3.32324 20.9425 3.61987C20.9425 3.91651 21.1829 4.15698 21.4796 4.15698Z"
      fill="#0066FF"
    />
    <path
      d="M19.419 4.15698C19.7156 4.15698 19.9561 3.91651 19.9561 3.61987C19.9561 3.32324 19.7156 3.08276 19.419 3.08276C19.1224 3.08276 18.8819 3.32324 18.8819 3.61987C18.8819 3.91651 19.1224 4.15698 19.419 4.15698Z"
      fill="#0066FF"
    />
    <path
      d="M31.6203 3.91162V40.5103C31.6203 42.2902 30.1776 43.7329 28.3977 43.7329H8.58154C6.80156 43.7329 5.35889 42.2902 5.35889 40.5103V3.91162C5.35889 2.13164 6.80156 0.688965 8.58154 0.688965H28.3977C30.1776 0.688965 31.6203 2.13164 31.6203 3.91162Z"
      fill="url(#paint0_linear_3419_54503)"
    />
    <path
      d="M41.4236 37.0311H17.5199C16.9266 37.0311 16.4457 36.5501 16.4457 35.9569V21.6497C16.4457 21.0564 16.9266 20.5754 17.5199 20.5754H41.4236C42.0168 20.5754 42.4978 21.0564 42.4978 21.6497V35.9569C42.4978 36.5501 42.0168 37.0311 41.4236 37.0311Z"
      fill="#71A5F4"
    />
    <path
      d="M42.4978 35.9569L29.4717 26.2251L16.4457 35.9569C16.4457 36.5502 16.9266 37.0311 17.5199 37.0311H41.4236C42.0168 37.0311 42.4978 36.5502 42.4978 35.9569Z"
      fill="#0066FF"
    />
    <path
      d="M16.4457 21.6497L28.9547 28.5193C29.2767 28.6961 29.6668 28.6961 29.9888 28.5193L42.4978 21.6497C42.4978 21.0564 42.0168 20.5754 41.4236 20.5754H17.5199C16.9266 20.5754 16.4457 21.0564 16.4457 21.6497Z"
      fill="#A6C9FF"
    />
    <path
      d="M37.0404 44.4311C36.7437 44.4311 36.5033 44.1907 36.5033 43.894C36.5033 43.5683 36.2382 43.3032 35.9125 43.3032C35.6158 43.3032 35.3754 43.0628 35.3754 42.7661C35.3754 42.4694 35.6158 42.229 35.9125 42.229C36.2382 42.229 36.5033 41.9639 36.5033 41.6382C36.5033 41.3415 36.7437 41.1011 37.0404 41.1011C37.3371 41.1011 37.5775 41.3415 37.5775 41.6382C37.5775 41.9639 37.8426 42.229 38.1683 42.229C38.465 42.229 38.7054 42.4694 38.7054 42.7661C38.7054 43.0628 38.465 43.3032 38.1683 43.3032C37.8426 43.3032 37.5775 43.5683 37.5775 43.894C37.5775 44.1906 37.3371 44.4311 37.0404 44.4311Z"
      fill="#993399"
    />
    <path
      d="M35.9125 17.3055C35.6158 17.3055 35.3754 17.0651 35.3754 16.7684C35.3754 16.4427 35.1102 16.1776 34.7845 16.1776C34.4878 16.1776 34.2474 15.9372 34.2474 15.6405C34.2474 15.3438 34.4878 15.1034 34.7845 15.1034C35.1102 15.1034 35.3754 14.8383 35.3754 14.5125C35.3754 14.2158 35.6158 13.9753 35.9125 13.9753C36.2092 13.9753 36.4496 14.2158 36.4496 14.5125C36.4496 14.8383 36.7147 15.1034 37.0404 15.1034C37.3371 15.1034 37.5775 15.3438 37.5775 15.6405C37.5775 15.9372 37.3371 16.1776 37.0404 16.1776C36.7147 16.1776 36.4496 16.4427 36.4496 16.7684C36.4496 17.0651 36.2092 17.3055 35.9125 17.3055Z"
      fill="#0066FF"
    />
    <path
      d="M1.69162 22.666C1.39492 22.666 1.15451 22.4256 1.15451 22.1289C1.15451 21.8032 0.889393 21.5381 0.56369 21.5381C0.266991 21.5381 0.0265808 21.2977 0.0265808 21.001C0.0265808 20.7043 0.266991 20.4639 0.56369 20.4639C0.889393 20.4639 1.15451 20.1988 1.15451 19.873C1.15451 19.5763 1.39492 19.3359 1.69162 19.3359C1.98832 19.3359 2.22873 19.5763 2.22873 19.873C2.22873 20.1988 2.49385 20.4639 2.81955 20.4639C3.11625 20.4639 3.35666 20.7043 3.35666 21.001C3.35666 21.2977 3.11625 21.5381 2.81955 21.5381C2.49385 21.5381 2.22873 21.8032 2.22873 22.1289C2.22873 22.4256 1.98832 22.666 1.69162 22.666Z"
      fill="#71A5F4"
    />
    <path
      d="M0.617405 28.3808C0.480012 28.3808 0.342405 28.3284 0.237668 28.2235C0.0278735 28.0138 0.0278735 27.6737 0.237668 27.464L1.31189 26.3898C1.52147 26.18 1.86178 26.18 2.07136 26.3898C2.28116 26.5995 2.28116 26.9395 2.07136 27.1492L0.997141 28.2235C0.892405 28.3283 0.754797 28.3808 0.617405 28.3808Z"
      fill="#0066FF"
    />
    <path
      d="M36.5033 11.6176C36.3659 11.6176 36.2283 11.5652 36.1236 11.4603C35.9138 11.2506 35.9138 10.9105 36.1236 10.7008L37.1978 9.62658C37.4074 9.41679 37.7477 9.41679 37.9573 9.62658C38.167 9.83627 38.167 10.1764 37.9573 10.3861L36.883 11.4603C36.7783 11.5651 36.6408 11.6176 36.5033 11.6176Z"
      fill="#663399"
    />

    <defs>
      <linearGradient
        id="paint0_linear_3419_54503"
        x1="18.4896"
        y1="43.7329"
        x2="18.4896"
        y2="0.688965"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#71A5F4" />
        <stop offset="0.1463" stopColor="#71A5F4" stopOpacity="0.3" />
        <stop offset="1" stopColor="#71A5F4" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
