import clsx from 'clsx';
import { ReactNode } from 'react';

import css from './index.module.css';

interface Props {
  className?: string;
  children?: ReactNode;
}

export const LayoutMenu = ({ children, className }: Props) => {
  return <div className={clsx(css.wrapper, className)}>{children}</div>;
};
