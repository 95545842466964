import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  PointElement,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  ChartData,
  TimeScale,
  ChartOptions,
  TimeUnit,
  TooltipItem,
} from 'chart.js';
// do not uninstall chartjs-adapter-date-fns and date-fns
import 'chartjs-adapter-date-fns';
import React, { useMemo } from 'react';
import { Chart } from 'react-chartjs-2';

// import { roundPrice } from 'constants/helper';
import { computedStyle } from 'services/style';

import css from './index.module.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  PointElement,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  TimeScale,
);

interface Props {
  header: React.ReactChild;
  data: ChartData<'bar' | 'line'>;
  unit?: TimeUnit;
  tooltipFormat?: string;
  currency: string;
}

const PriceLineChart = ({ header, data, unit, tooltipFormat, currency }: Props) => {
  const options = useMemo(() => {
    const o = { ...commonOptions };

    (o as any).scales.x.time.unit = unit;
    (o as any).scales.x.time.tooltipFormat = tooltipFormat;
    (o as any).plugins.tooltip.callbacks.label = ({ dataset: { label }, raw }: TooltipItem<'bar' | 'line'>) =>
      `${label}: ${raw} ${currency}`;
    // `${label}: ${roundPrice(raw as number)} ${currency}`;

    return o;
  }, [unit, tooltipFormat, currency]);

  return (
    <section className={css.wrapper}>
      <header>{header}</header>
      <div className={css.chart}>
        <Chart type="bar" data={data} options={options} />
      </div>
    </section>
  );
};

export default PriceLineChart;

const commonOptions: ChartOptions<'bar' | 'line'> = {
  scales: {
    x: {
      type: 'time',
      time: {
        minUnit: 'second',
        stepSize: 10, // fixed first/last bar showing partially
      },
      offset: true, // did not fix first/last bar showing partially, but it should have, see: https://github.com/novus/nvd3/issues/290
      stacked: true,
      ticks: {
        source: 'labels',
        color: computedStyle.getPropertyValue('--text-color'),
      },
      grid: {
        borderColor: computedStyle.getPropertyValue('--grey-60'),
      },
    },
    y: {
      stacked: false,
      ticks: {
        // callback: value => '$' + value,
        color: computedStyle.getPropertyValue('--text-color'),
      },
      grid: {
        borderColor: computedStyle.getPropertyValue('--grey-60'),
      },
    },
  },
  interaction: {
    mode: 'index',
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        color: computedStyle.getPropertyValue('--body-color'),
      },
    },
    tooltip: {
      filter: ({ raw }) => raw !== 0,
      callbacks: {
        // label: ({ dataset: { label }, raw }: TooltipItem<'bar' | 'line'>) => `${label}: ${roundPrice(raw as number)}$`,
      },
    },
  },
} as const;
