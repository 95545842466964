// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FrontstoreDeleteModal_wrapper__N-FDs {
  max-width: 360px;
}

.FrontstoreDeleteModal_header__Bvy2A {
  display: flex;
  gap: 24px;
}

.FrontstoreDeleteModal_header__Bvy2A > svg {
  margin-top: 2px;
  color: var(--orange);
}

.FrontstoreDeleteModal_label__u0zxH {
  margin: 20px 20px 20px 40px;
  color: var(--text-color);
  word-break: break-all;
}

.FrontstoreDeleteModal_error__haGIa {
  margin-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/MyAccount/FrontstoreDeleteModal/index.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,2BAA2B;EAC3B,wBAAwB;EACxB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".wrapper {\n  max-width: 360px;\n}\n\n.header {\n  display: flex;\n  gap: 24px;\n}\n\n.header > svg {\n  margin-top: 2px;\n  color: var(--orange);\n}\n\n.label {\n  margin: 20px 20px 20px 40px;\n  color: var(--text-color);\n  word-break: break-all;\n}\n\n.error {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `FrontstoreDeleteModal_wrapper__N-FDs`,
	"header": `FrontstoreDeleteModal_header__Bvy2A`,
	"label": `FrontstoreDeleteModal_label__u0zxH`,
	"error": `FrontstoreDeleteModal_error__haGIa`
};
export default ___CSS_LOADER_EXPORT___;
