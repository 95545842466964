import { grpc } from '@improbable-eng/grpc-web';
import { captureException } from '@sentry/react';
import { EthereumAddress } from 'beasy-fe-commons';

import {
  GrpcWebImpl,
  UserProfile,
  UserProfile_AdditionalData,
  UserProfileServiceClientImpl,
} from 'protobuf/lib/userProfileService';

import { environment } from 'environment';

import { settings } from '../settings';
import { authenticated } from './grpc';
import { getIpfsApi } from './ipfs';
import generateThumbnail from './thumbnail';

import { AppState } from 'state';
import { useSelector } from 'react-redux';


const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const userProfileServiceClient = new UserProfileServiceClientImpl(rpc);

export enum UserProfileQueryKey {
  getUserProfile = 'getUserProfile',
  createUserProfile = 'createUserProfile',
  updateUserAvatarAndProfile = 'updateUserAvatarAndProfile',
  getRoleNameMap = 'getRoleNameMap',
  acceptAgreement = 'acceptAgreement',
}

let currentAddress: string
export class UsernameAlreadyExistsError extends Error {
  constructor(readonly nickname: string) {

    super(`User with nickname ${nickname} already exists`);
  }
}

export const useGetCurrentUserProfile = () => {

  const walletConnectV2 = useSelector((state: AppState) => state.session);
  currentAddress = walletConnectV2.address!
  const getCurrentUserProfile = async (): Promise<UserProfile> => {
    try {
      return await userProfileServiceClient.GetUserProfile({ ethAddress: currentAddress });
    } catch (error) {
      captureException(error);
      throw error;
    }
  };

  return getCurrentUserProfile;
};

export const getUserProfile = async (address: EthereumAddress): Promise<UserProfile> => {
  try {
    return await userProfileServiceClient.GetUserProfile({ ethAddress: address });
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const createUserProfile = async (nickname: string, avatarCid?: string): Promise<UserProfile> => {

  try {
    return await userProfileServiceClient.CreateUserProfile({
      ethAddress: currentAddress,
      nickname,
      avatarCid,
    });
  } catch (error: any) {
    if (error.code === grpc.Code.AlreadyExists) {
      throw new UsernameAlreadyExistsError(nickname);
    }
    captureException(error);
    throw error;
  }
};

export const createCreatorProfile = async (
  address: string,
  nickname: string,
  avatarCid?: string,
  additionalData?: UserProfile_AdditionalData,
): Promise<UserProfile> => {
  try {
    return await userProfileServiceClient.CreateUserProfile({
      ethAddress: address,
      nickname,
      avatarCid,
      additionalData,
    });
  } catch (error: any) {
    if (error.code === grpc.Code.AlreadyExists) {
      throw new UsernameAlreadyExistsError(nickname);
    }
    captureException(error);
    throw error;
  }
};

const updateUserProfile = async (
  nickname: string,
  additionalData: UserProfile_AdditionalData,
  avatarCid?: string,
  isAccepted?: boolean,
  stripeId?: string,
): Promise<UserProfile> => {
  try {
    console.log("currentAddress", currentAddress)
    return await authenticated(meta =>
      userProfileServiceClient.UpdateUserProfile(
        {
          ethAddress: currentAddress,
          data: { nickname, avatarCid, additionalData },
          isAccepted: isAccepted,
          stripeId: stripeId
        },
        meta,
      ),
    );
  } catch (error: any) {
    if (error.code === grpc.Code.AlreadyExists) {
      throw new UsernameAlreadyExistsError(nickname);
    }
    captureException(error);
    throw error;
  }
};

export const updateUserAvatarAndProfile = async (
  avatar: File | string,
  nickname: string,
  additionalData: UserProfile_AdditionalData,
  isAccepted?: boolean,
  stripeId?: string
) => {
  if (avatar instanceof File) {
    const avatarThumbnail = await generateThumbnail(avatar, settings.thumbnails);
    const avatarToUpload = avatarThumbnail ?? avatar;
    const avatarCid = await getIpfsApi().add(avatarToUpload);
    await updateUserProfile(nickname, additionalData, avatarCid, isAccepted, stripeId);
  } else {
    await updateUserProfile(nickname, additionalData, avatar, isAccepted, stripeId);
  }
};
export interface RoleNameMap {
  [key: number]: string;
}

export const getRoleNameMap = async (): Promise<RoleNameMap> => {
  try {
    const response = await userProfileServiceClient.GetRoles({});
    return response.roles.reduce((acc, item) => ({ ...acc, [item.role]: item.roleName }), {});
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const acceptAgreement = async () => {
  try {
    await userProfileServiceClient.AcceptLicense({ ethAddress: currentAddress });
    return;
  } catch (error) {
    captureException(error);
    throw error;
  }
};
