import clsx from 'clsx';
import { ReactNode, useMemo } from 'react';

import { TickIcon } from 'assets/icons';

import { ButtonBase } from 'components/button';

import css from './index.module.css';

interface Props<T> {
  value: T;
  select?: (value: T) => void;
  unselect?: (value: T) => void;
  selected?: boolean;
  children?: ReactNode;
  className?: string;
}

export const InputValueSelector = <T,>({ value, select, unselect, selected, children, className }: Props<T>) => {
  const _select = useMemo(() => select && (() => select(value)), [select, value]);
  const _unselect = useMemo(() => unselect && (() => unselect(value)), [unselect, value]);

  return (
    <ButtonBase
      className={clsx(css.wrapper, className, selected && css.selected)}
      onClick={selected ? _unselect : _select}
      disabled={selected && !unselect}
    >
      {children || String(value)}
      {selected && <TickIcon />}
    </ButtonBase>
  );
};