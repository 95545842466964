import clsx from 'clsx';

import { IconProps } from '../types';
import css from './index.module.css';

const ASPECT_RATIO = 1;

export const AudioIcon = ({ className, size = 150, onClick }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(css.icon, className)}
    onClick={onClick}
    width={size}
    height={size / ASPECT_RATIO}
    viewBox="0 0 150 150"
  >
    <path d="M45.75 12.5C44.6454 12.5 43.75 13.3954 43.75 14.5V23C43.75 24.1046 44.6454 25 45.75 25H69.875C70.9796 25 71.875 24.1046 71.875 23V14.5C71.875 13.3954 70.9796 12.5 69.875 12.5H45.75Z" />
    <path d="M43.75 33.25C43.75 32.1454 44.6454 31.25 45.75 31.25H69.875C70.9796 31.25 71.875 32.1454 71.875 33.25V41.75C71.875 42.8546 70.9796 43.75 69.875 43.75H45.75C44.6454 43.75 43.75 42.8546 43.75 41.75V33.25Z" />
    <path d="M45.75 50C44.6454 50 43.75 50.8954 43.75 52V60.5C43.75 61.6046 44.6454 62.5 45.75 62.5H69.875C70.9796 62.5 71.875 61.6046 71.875 60.5V52C71.875 50.8954 70.9796 50 69.875 50H45.75Z" />
    <path d="M9.375 70.75C9.375 69.6454 10.2704 68.75 11.375 68.75H35.5C36.6046 68.75 37.5 69.6454 37.5 70.75V79.25C37.5 80.3546 36.6046 81.25 35.5 81.25H11.375C10.2704 81.25 9.375 80.3546 9.375 79.25V70.75Z" />
    <path d="M11.375 87.5C10.2704 87.5 9.375 88.3954 9.375 89.5V98C9.375 99.1046 10.2704 100 11.375 100H35.5C36.6046 100 37.5 99.1046 37.5 98V89.5C37.5 88.3954 36.6046 87.5 35.5 87.5H11.375Z" />
    <path d="M9.375 108.25C9.375 107.145 10.2704 106.25 11.375 106.25H35.5C36.6046 106.25 37.5 107.145 37.5 108.25V116.75C37.5 117.855 36.6046 118.75 35.5 118.75H11.375C10.2704 118.75 9.375 117.855 9.375 116.75V108.25Z" />
    <path d="M9.375 127C9.375 125.895 10.2704 125 11.375 125H35.5C36.6046 125 37.5 125.895 37.5 127V135.5C37.5 136.605 36.6046 137.5 35.5 137.5H11.375C10.2704 137.5 9.375 136.605 9.375 135.5V127Z" />
    <path d="M45.75 125C44.6454 125 43.75 125.895 43.75 127V135.5C43.75 136.605 44.6454 137.5 45.75 137.5H69.875C70.9796 137.5 71.875 136.605 71.875 135.5V127C71.875 125.895 70.9796 125 69.875 125H45.75Z" />
    <path d="M43.75 108.25C43.75 107.145 44.6454 106.25 45.75 106.25H69.875C70.9796 106.25 71.875 107.145 71.875 108.25V116.75C71.875 117.855 70.9796 118.75 69.875 118.75H45.75C44.6454 118.75 43.75 117.855 43.75 116.75V108.25Z" />
    <path d="M45.75 87.5C44.6454 87.5 43.75 88.3954 43.75 89.5V98C43.75 99.1046 44.6454 100 45.75 100H69.875C70.9796 100 71.875 99.1046 71.875 98V89.5C71.875 88.3954 70.9796 87.5 69.875 87.5H45.75Z" />
    <path d="M43.75 70.75C43.75 69.6454 44.6454 68.75 45.75 68.75H69.875C70.9796 68.75 71.875 69.6454 71.875 70.75V79.25C71.875 80.3546 70.9796 81.25 69.875 81.25H45.75C44.6454 81.25 43.75 80.3546 43.75 79.25V70.75Z" />
    <path d="M78.125 127C78.125 125.895 79.0204 125 80.125 125H104.25C105.355 125 106.25 125.895 106.25 127V135.5C106.25 136.605 105.355 137.5 104.25 137.5H80.125C79.0204 137.5 78.125 136.605 78.125 135.5V127Z" />
    <path d="M114.5 125C113.395 125 112.5 125.895 112.5 127V135.5C112.5 136.605 113.395 137.5 114.5 137.5H138.625C139.73 137.5 140.625 136.605 140.625 135.5V127C140.625 125.895 139.73 125 138.625 125H114.5Z" />
    <path d="M78.125 108.25C78.125 107.145 79.0204 106.25 80.125 106.25H104.25C105.355 106.25 106.25 107.145 106.25 108.25V116.75C106.25 117.855 105.355 118.75 104.25 118.75H80.125C79.0204 118.75 78.125 117.855 78.125 116.75V108.25Z" />
    <path d="M114.5 106.25C113.395 106.25 112.5 107.145 112.5 108.25V116.75C112.5 117.855 113.395 118.75 114.5 118.75H138.625C139.73 118.75 140.625 117.855 140.625 116.75V108.25C140.625 107.145 139.73 106.25 138.625 106.25H114.5Z" />
    <path d="M78.125 89.5C78.125 88.3954 79.0204 87.5 80.125 87.5H104.25C105.355 87.5 106.25 88.3954 106.25 89.5V98C106.25 99.1046 105.355 100 104.25 100H80.125C79.0204 100 78.125 99.1046 78.125 98V89.5Z" />
    <path d="M114.5 87.5C113.395 87.5 112.5 88.3954 112.5 89.5V98C112.5 99.1046 113.395 100 114.5 100H138.625C139.73 100 140.625 99.1046 140.625 98V89.5C140.625 88.3954 139.73 87.5 138.625 87.5H114.5Z" />
    <path d="M112.5 70.75C112.5 69.6454 113.395 68.75 114.5 68.75H138.625C139.73 68.75 140.625 69.6454 140.625 70.75V79.25C140.625 80.3546 139.73 81.25 138.625 81.25H114.5C113.395 81.25 112.5 80.3546 112.5 79.25V70.75Z" />
    <path d="M114.5 50C113.395 50 112.5 50.8954 112.5 52V60.5C112.5 61.6046 113.395 62.5 114.5 62.5H138.625C139.73 62.5 140.625 61.6046 140.625 60.5V52C140.625 50.8954 139.73 50 138.625 50H114.5Z" />
  </svg>
);
