import { useCallback } from 'react';
import { UseQueryResult } from 'react-query';
import { useHistory } from 'react-router-dom';

import { ErrorMessageQueryLoading } from 'components/ErrorMessage';
import ItemList from 'components/ItemList';
import { CollectibleItem } from 'components/collectible';
import itemListCss from 'components/collectible/item-list.module.css';
import { EmptyList } from 'components/layout';

import { padArrayStart } from 'constants/helper';

import { Collectible } from 'protobuf/lib/collectibleService';

interface Props {
  query: UseQueryResult<Collectible[]>;
  emptyMessage: string;
}

export const CollectibleList = ({ query, emptyMessage }: Props) => {
  const history = useHistory();

  const { isLoading, isError, data, refetch } = query;

  const items = isLoading ? padArrayStart<Collectible>(data || [], 12) : data ?? [];

  const handleCollectibleClick = useCallback(
    (collectible: Collectible) => {
      history.push(`/home/collectible/${collectible.collectibleId}`);
    },
    [history],
  );

  return isError ? (
    <ErrorMessageQueryLoading onRetry={() => refetch()} />
  ) : !items?.length ? (
    <EmptyList message={emptyMessage} />
  ) : (
    <ItemList
      items={items!}
      Component={CollectibleItem}
      className={itemListCss.wrapper}
      onItemClick={handleCollectibleClick}
    />
  );
};
