import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import commonCss from './common.module.css';
import { InputPropsDate } from './types';

interface Props extends InputPropsDate<string, HTMLInputElement> {
  className?: string;
  placeholder?: string;
  selected?: Date;
}

export const DateSelector = (props: Props) => {
  return (
    <DatePicker
      className={commonCss.box}
      shouldCloseOnSelect={true}
      {...props}
    />
  );
};
