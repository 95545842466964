import { Period } from 'services/chart.v2';

import css from './index.module.css';

const options: Period[] = ['1D', '1W', '1M', '3M', '1Y', 'ALL'];

interface Props {
  value?: Period;
  setValue?: (next: Period) => void;
}

const PeriodSelector = ({ value = 'ALL', setValue }: Props) => (
  <div className={css.wrapper}>
    {options.map(period => (
      <button
        key={period}
        className={value === period ? css.selected : undefined}
        onClick={() => {
          setValue?.(period);
        }}
      >
        {period}
      </button>
    ))}
  </div>
);

export default PeriodSelector;
