import { Attribute, AttributesStepValue } from '../../../types';
import Cell from '../../Cell';
import css from './index.module.css';

const formatedValue = (value:string, type:string) : string => {
  if (type === 'Percentage') return value + "%";
  return value;
}

const Attr = ({ display_type, trait_type, value }: Attribute) => {
  return (
    <div>
      <div>
        <Cell label="Type" value={display_type || ''} />
      </div>
      <div>
        <Cell label="Name" value={trait_type} />
      </div>
      <div>
        <Cell label="Value" value={formatedValue(value, display_type)} />
      </div>
    </div>
  );
};

const Attributes = ({ attributes }: AttributesStepValue) => {
  return (
    <div className={css.attributes}>
      <h3>Attributes</h3>
      <br />
      {attributes.length === 0 && <p>No Attributes added.</p>}
      {attributes.map(({ display_type, trait_type, value }, index) => (
        <Attr key={index} display_type={display_type} trait_type={trait_type} value={value} />
      ))}
      <br />
    </div>
  );
};

export default Attributes;
