// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Table_searchInput__PtIWK {
  background: var(--input-bg-color);
  border: none;
  border-radius: 6px;
  color: var(--body-color);
  line-height: 20px;
  padding: 10px 15px;
  min-width: 20%;
  min-height: var(--input-min-height);
  margin-bottom: 15px;
  outline: none;
}

.Table_headerColumn__4x8o7 {
  padding-top: 3px;
  margin-right: 5px;
}

.Table_textOverflow__V6Ib\\+ {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Table_tableContainer__O7H6D {
  height: 535px;
}

table {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}

table thead {
  /* head takes the height it requires, 
  and it's not scaled when table is resized */
  flex: 0 0 auto;
  width: calc(100% - 0.9em);
}

table tbody {
  /* body takes all the remaining available space */
  flex: 1 1 auto;
  display: block;
  overflow-y: scroll;
}

table tbody tr {
  width: 100%;
}

table thead, table tbody tr {
  display: table;
  table-layout: fixed;
}`, "",{"version":3,"sources":["webpack://./src/components/Table/index.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,YAAY;EACZ,kBAAkB;EAClB,wBAAwB;EACxB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,mCAAmC;EACnC,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE;6CAC2C;EAC3C,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,iDAAiD;EACjD,cAAc;EACd,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".searchInput {\n  background: var(--input-bg-color);\n  border: none;\n  border-radius: 6px;\n  color: var(--body-color);\n  line-height: 20px;\n  padding: 10px 15px;\n  min-width: 20%;\n  min-height: var(--input-min-height);\n  margin-bottom: 15px;\n  outline: none;\n}\n\n.headerColumn {\n  padding-top: 3px;\n  margin-right: 5px;\n}\n\n.textOverflow {\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.tableContainer {\n  height: 535px;\n}\n\ntable {\n  display: flex;\n  flex-flow: column;\n  height: 100%;\n  width: 100%;\n}\n\ntable thead {\n  /* head takes the height it requires, \n  and it's not scaled when table is resized */\n  flex: 0 0 auto;\n  width: calc(100% - 0.9em);\n}\n\ntable tbody {\n  /* body takes all the remaining available space */\n  flex: 1 1 auto;\n  display: block;\n  overflow-y: scroll;\n}\n\ntable tbody tr {\n  width: 100%;\n}\n\ntable thead, table tbody tr {\n  display: table;\n  table-layout: fixed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchInput": `Table_searchInput__PtIWK`,
	"headerColumn": `Table_headerColumn__4x8o7`,
	"textOverflow": `Table_textOverflow__V6Ib+`,
	"tableContainer": `Table_tableContainer__O7H6D`
};
export default ___CSS_LOADER_EXPORT___;
