// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfirmExit_wrapper__X58\\+R {
  width: 420px;
}

.ConfirmExit_header__qhJmW {
  display: flex;
  gap: 24px;
}

.ConfirmExit_header__qhJmW > svg {
  margin-top: 2px;
  color: var(--orange);
}

.ConfirmExit_label__redjS {
  margin: 20px 20px 20px 40px;
  color: var(--text-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/collectible/ConfirmExit/index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,2BAA2B;EAC3B,wBAAwB;AAC1B","sourcesContent":[".wrapper {\n  width: 420px;\n}\n\n.header {\n  display: flex;\n  gap: 24px;\n}\n\n.header > svg {\n  margin-top: 2px;\n  color: var(--orange);\n}\n\n.label {\n  margin: 20px 20px 20px 40px;\n  color: var(--text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ConfirmExit_wrapper__X58+R`,
	"header": `ConfirmExit_header__qhJmW`,
	"label": `ConfirmExit_label__redjS`
};
export default ___CSS_LOADER_EXPORT___;
