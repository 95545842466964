import * as yup from 'yup';

import {
  supportedAudioExtensions,
  supportedAudioTypes,
  supportedImageTypes,
  supportedVideoExtensions,
  supportedVideoTypes,
} from 'constants/mimeTypes';

export default yup
  .object()
  .shape({
    collection: yup.string().required('You must provide a collection'),
    name: yup
      .string()
      .required('You must provide a name')
      .min(1, 'Minimum length is 1 character')
      .max(100, 'Maximum length is 100 characters'),
    description: yup
      .string()
      .required('You must provide a description')
      .max(1500, 'Maximum length is 1,500 characters'),
    quantity: yup
      .number()
      .required('You must provide a quantity')
      .typeError('Quantity must be a number')
      .positive('Quantity must be greater than zero')
      .max(9999999999, 'Quantity must not exceed 9999999999'),
    imgUrl: yup
      .mixed()
      .test('size', 'The file size must not exceed 150MB', (f?: File) => (f ? f.size <= 157286400 : true))
      .test('type', 'Supported file types are: jpeg, png, jpg', (f?: File) =>
        f ? supportedImageTypes.includes(f.type) : true,
      ),
    videoUrl: yup
      .mixed()
      .test('size', 'The file size must not exceed 800MB', (f?: File) => (f ? f.size <= 838860800 : true))
      .test('type', 'Supported file types are: ' + supportedVideoExtensions.join(', '), (f?: File) =>
        f ? supportedVideoTypes.includes(f.type) : true,
      ),
    audioUrl: yup
      .mixed()
      .test('size', 'The file size must not exceed 150MB', (f?: File) => (f ? f.size <= 157286400 : true))
      .test('type', 'Supported file types are: ' + supportedAudioExtensions.join(', '), (f?: File) =>
        f ? supportedAudioTypes.includes(f.type) : true,
      ),
  })
  .test('noMediaError', function ({ imgUrl, videoUrl, audioUrl }) {
    return imgUrl || videoUrl || audioUrl
      ? true
      : this.createError({
          path: 'noMediaError', // must have path otherwise formik will ignore this error
          message: `You must provide a image or video or audio file.`,
        });
  });
