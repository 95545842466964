// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AudioPlayer_container__SY2Uv {
  align-items: center;
  background: #000;
  background: radial-gradient(circle, blue 0%, #000 50%);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  position: relative;
}

.AudioPlayer_container__SY2Uv > svg {
  margin: 50px;
  height: 200px;
}

.AudioPlayer_audio__fqvlS {
  bottom: 20px;
  height: 40px;
  left: 8px;
  position: absolute;
  right: 8px;
}

.AudioPlayer_audio__fqvlS > audio {
  display: block;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/AudioPlayer/index.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,sDAAsD;EACtD,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,SAAS;EACT,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,WAAW;AACb","sourcesContent":[".container {\n  align-items: center;\n  background: #000;\n  background: radial-gradient(circle, blue 0%, #000 50%);\n  border-radius: 4px;\n  display: flex;\n  justify-content: center;\n  position: relative;\n}\n\n.container > svg {\n  margin: 50px;\n  height: 200px;\n}\n\n.audio {\n  bottom: 20px;\n  height: 40px;\n  left: 8px;\n  position: absolute;\n  right: 8px;\n}\n\n.audio > audio {\n  display: block;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AudioPlayer_container__SY2Uv`,
	"audio": `AudioPlayer_audio__fqvlS`
};
export default ___CSS_LOADER_EXPORT___;
