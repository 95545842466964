import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';

import { ButtonPrimary } from 'components/button';
import { DialogArea, DialogFooter, DialogHeader, DialogOverlay } from 'components/dialog';

import { FreeTierText } from 'constants/free-tier';

import { acceptAgreement, useGetCurrentUserProfile, UserProfileQueryKey } from 'services/userProfile';

import css from './index.module.css';
import { useHistory } from 'react-router';

export const AcceptAgreement = () => {
  const history = useHistory();
  const getCurrentUserProfile = useGetCurrentUserProfile();
  const { data: userProfile } = useQuery(UserProfileQueryKey.getUserProfile, () => getCurrentUserProfile());
  const [isAccepted, setAccepted] = useState(false);
  const handleUpdateUser = useCallback(async () => {
    userProfile!.isAccepted = true;
    await acceptAgreement();
    history.push('/home/dashboard')
  }, [history, userProfile]);
  switch (userProfile?.isAccepted) {
    case false:
      return (
        <DialogOverlay>
          <DialogArea className={css.wrapper}>
            <DialogHeader>
              <div className={css.header}>
                <h3>LICENSE AGREEMENT</h3>
              </div>
            </DialogHeader>

            <div className={css.license}>{FreeTierText()}</div>

            <div className={css.footer}>
              <div className={css.input}>
                <input
                  type="checkbox"
                  name="checked"
                  onChange={() => {
                    setAccepted(!isAccepted);
                  }}
                ></input>
                <p>I understand and agree to the terms of the license agreement.</p>
              </div>
            </div>
            <DialogFooter>
              <ButtonPrimary type="button" onClick={handleUpdateUser} disabled={!isAccepted}>
                Accept
              </ButtonPrimary>
            </DialogFooter>
          </DialogArea>
        </DialogOverlay>
      );
    default:
      return null; // nothing to render
  }
};
