// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Title_wrapper__M1gu8 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.Title_wrapper__M1gu8 > i {
  color: var(--text-color-secondary);
}`, "",{"version":3,"sources":["webpack://./src/components/field/Title/index.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".wrapper {\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n}\n\n.wrapper > i {\n  color: var(--text-color-secondary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Title_wrapper__M1gu8`
};
export default ___CSS_LOADER_EXPORT___;
