import errorImage from 'assets/images/error_loading.svg';

import { ButtonPrimary } from 'components/button';

import css from './ErrorMessageQueryLoading.module.css';

interface Props {
  message?: string;
  isVisibleImage?: boolean;
  onRetry?: () => void;
}
/**
 * Displays a warning about an error loading data.
 */
export const ErrorMessageQueryLoading = ({
  message = 'Something went wrong\nPlease try again',
  isVisibleImage = true,
  onRetry = () => {},
}: Props) => {
  return (
    <div className={css.container}>
      {isVisibleImage && <img className={css.image} src={errorImage} alt="Download error" />}
      <p className={css.message}>{message}</p>
      <ButtonPrimary className={css.buttonRetry} onClick={onRetry}>
        Retry
      </ButtonPrimary>
    </div>
  );
};
