// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common_box__4zoLl {
  background: var(--input-bg-color);
  border: none;
  border-radius: 6px;
  color: var(--body-color);
  line-height: 20px;
  padding: 10px 15px;
  width: 100%;
  max-width: 100%;
  min-height: var(--input-min-height);
}

.common_box__4zoLl:focus {
  outline: none;
  box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/input/common.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,YAAY;EACZ,kBAAkB;EAClB,wBAAwB;EACxB,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".box {\n  background: var(--input-bg-color);\n  border: none;\n  border-radius: 6px;\n  color: var(--body-color);\n  line-height: 20px;\n  padding: 10px 15px;\n  width: 100%;\n  max-width: 100%;\n  min-height: var(--input-min-height);\n}\n\n.box:focus {\n  outline: none;\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `common_box__4zoLl`
};
export default ___CSS_LOADER_EXPORT___;
