import {
  createTextFile,
  EthereumAddress,
  FullUrl,
  IpfsApi,
  IpfsCid,
  IpfsCollectibleInfo,
  IpfsCollectionInfo,
  IpfsFile,
  IpfsGateway,
  UploadController,
} from 'beasy-fe-commons';
import { Attribute } from 'components/collectible/steps/types';

import { supportedVideoTypes } from 'constants/mimeTypes';

export const ipfsGateway = new IpfsGateway('https://beasy.infura-ipfs.io/ipfs');

let ipfsApi: IpfsApi;

export const getIpfsApi = () => ipfsApi;

export const initIpfsApi = (jwt: string) => {
  ipfsApi = new IpfsApi('https://ipfs.infura.io:5001/api/v0', jwt);
};

initIpfsApi('');

export const getIpfsUrl = <T extends IpfsCid | undefined>(cid: T) => cid && `https://beasy.infura-ipfs.io/ipfs/${cid}`;

// Collection

interface UploadCollectionData {
  name: string;
  description: string;
  image: File;
  externalLink: FullUrl;
  totalRoyalty: number;
  feeRecipient: EthereumAddress;
}

export const ipfsUploadCollection = async (
  { name, description, image, externalLink, totalRoyalty, feeRecipient }: UploadCollectionData,
  onUploadProgress: (event: ProgressEvent) => void,
) => {
  const uploadController = new UploadController(onUploadProgress);

  uploadController.incrementTotalSize(image.size);

  const imageCid = await ipfsApi.add(image, uploadController);

  const info: IpfsCollectionInfo = {
    name,
    description,
    image: `https://beasy.infura-ipfs.io/ipfs/${imageCid}`,
    external_link: externalLink,
    //TODO: Use more than one fee_recipient
    // 100 - indicates a 1% seller fee.
    seller_fee_basis_points: totalRoyalty * 100,
    fee_recipient: feeRecipient,
  };

  const infoFile = createTextFile('-', JSON.stringify(info));

  uploadController.incrementTotalSize(infoFile.size);

  return await ipfsApi.add(infoFile, uploadController);
};

// Collectible

export interface UploadData {
  thumbnail?: File;
  media?: File[];
  license?: File | IpfsFile;
  ownership?: File | IpfsFile;
}

export interface CollectibleDetails {
  id: string;
  name: string;
  quantity: number;
  description: string;
  // sellerFeeBasisPoints: number; // 100 - indicates a 1% seller fee.
  // feeRecipient: string; // EthAddress
}

export const ipfsUploadCollectible = async (
  details: CollectibleDetails,
  uploadData: UploadData,
  onUploadProgress: (event: ProgressEvent) => void,
  attributes: Attribute[]
): Promise<string> => {
  const { id: collectibleId } = details;

  const uploadController = makeUploadController(uploadData, onUploadProgress);

  const { thumbnail, media, license, ownership } = uploadData;

  let thumbnailCID = '';

  if (thumbnail) {
    thumbnailCID = await ipfsApi.add(thumbnail, uploadController);
    thumbnailCID = `https://beasy.infura-ipfs.io/ipfs/${thumbnailCID}`;
  }

  const newMedia = [];
  let animationCid: FullUrl | undefined;

  if (media) {
    for (let i = 0; i < media.length; i++) {
      const file = media[i];
      const cid = await ipfsApi.add(file, uploadController);

      newMedia.push({
        name: file.name,
        cid,
      });

      if (!animationCid && supportedVideoTypes.includes(file.type)) {
        animationCid = cid;
      }
    }
  }

  const docs: any = {
    license: [],
    ownership: [],
  };

  if (license instanceof File) {
    docs.license.push({
      name: license.name,
      cid: await ipfsApi.add(license, uploadController),
    });
  } else if (license) {
    docs.license.push(license);
  }

  if (ownership instanceof File) {
    docs.ownership.push({
      name: ownership.name,
      cid: await ipfsApi.add(ownership, uploadController),
    });
  } else if (ownership) {
    docs.ownership.push(ownership);
  }

  const info: IpfsCollectibleInfo = {
    name: details.name,
    description: details.description,
    image: thumbnailCID,
    animation_url: animationCid && `https://beasy.infura-ipfs.io/ipfs/${animationCid}`,
    beasy: {
      id: collectibleId,
      quantity: details.quantity,
      media: newMedia,
      docs,
    },
    attributes
  };

  const infoFile = createTextFile('some-file-name-not-used', JSON.stringify(info));

  return await ipfsApi.add(infoFile, uploadController);
};

// todo move to common frontend library
/*
type CustomAttributeType = 'number' | 'boost_percentage' | 'boost_number' | 'date';
interface CustomAttributes {
  attributes: {
    display_type?: CustomAttributeType;

    //attribute name 
    trait_type?: string;
    value: string | number;
  }[];
}
*/

function makeUploadController(
  uploadData: UploadData,
  onUploadProgress: (event: ProgressEvent) => void,
): UploadController {
  const uploadController = new UploadController(onUploadProgress);

  const { media, license, ownership } = uploadData;

  if (media) {
    media.forEach(file => uploadController.incrementTotalSize(file.size));
  }

  if (license instanceof File) {
    uploadController.incrementTotalSize(license.size);
  }

  if (ownership instanceof File) {
    uploadController.incrementTotalSize(ownership.size);
  }

  return uploadController;
}
