import { TooltipRenderProps } from 'react-joyride';

import { CloseIcon } from 'assets/icons';

import { ButtonPrimary, ButtonSecondary } from 'components/button';
import { DialogFooter, DialogHeader } from 'components/dialog';

import css from './index.module.css';

export const TourStep = ({
  continuous,
  index,
  step,
  isLastStep,
  backProps,
  skipProps,
  primaryProps,
  tooltipProps,
}: TooltipRenderProps) => {
  return (
    <article className={css.wrapper} {...tooltipProps}>
      <DialogHeader>
        <h3>{step.title}</h3>
        <CloseIcon size={12} onClick={skipProps.onClick as any} />
      </DialogHeader>
      <p>{step.content}</p>
      <DialogFooter>
        {index === 0 ? (
          <ButtonSecondary {...skipProps}>No, thanks</ButtonSecondary>
        ) : (
          <ButtonSecondary {...backProps}>Back</ButtonSecondary>
        )}
        {continuous && (
          <ButtonPrimary {...primaryProps}>
            {isLastStep ? 'Finish' : index === 0 ? 'Yes, let’s go!' : 'Next'}
          </ButtonPrimary>
        )}
        {!continuous && <ButtonSecondary {...skipProps}>Close</ButtonSecondary>}
      </DialogFooter>
    </article>
  );
};
