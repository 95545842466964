import clsx from 'clsx';

import { IconProps } from '../types';
import css from './index.module.css';

export const UploadIcon = ({ className, size = 24 }: IconProps) => (
  <svg
    className={clsx(css.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 15.9997H14C14.55 15.9997 15 15.5497 15 14.9997V9.99973H16.59C17.48 9.99973 17.93 8.91973 17.3 8.28973L12.71 3.69973C12.32 3.30973 11.69 3.30973 11.3 3.69973L6.71 8.28973C6.08 8.91973 6.52 9.99973 7.41 9.99973H9V14.9997C9 15.5497 9.45 15.9997 10 15.9997ZM6 17.9997H18C18.55 17.9997 19 18.4497 19 18.9997C19 19.5497 18.55 19.9997 18 19.9997H6C5.45 19.9997 5 19.5497 5 18.9997C5 18.4497 5.45 17.9997 6 17.9997Z"
    />
  </svg>
);
