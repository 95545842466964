import Axios, { Canceler } from 'axios';

import { environment } from 'environment';

export let cancel: Canceler | null = null;

export const axios = Axios.create({
  baseURL: environment.apiUrl,
  responseType: 'json',
  cancelToken: new Axios.CancelToken(c => {
    cancel = c;
  }),
});

// Request interceptor
axios.interceptors.request.use(
  async config => {
    config.headers = {
      'Content-Type': 'application/json',
    };
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

// Response interceptor
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.log('error: ', JSON.stringify(error));
    if (error.message === 'timeout of 10000ms exceeded' || error.message === 'Network Error') {
      setTimeout(() => {
        console.log('error');
      }, 500);
    }
    return Promise.reject(error);
  },
);
