/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "chart.v2";

export interface Filter {
  fromDate: Date | undefined;
  toDate: Date | undefined;
}

export interface Point {
  price: string;
  primarySalesVolume: string;
  secondarySalesVolume: string;
  revenueShare: string;
  royaltyShare: string;
  fromAddress: string;
  toAddress: string;
  timestamp: Date | undefined;
}

export interface Chart {
  points: Point[];
  totalRevenue: string;
  decimals: number;
}

export interface MulticurrencyChart {
  charts: { [key: string]: Chart };
}

export interface MulticurrencyChart_ChartsEntry {
  key: string;
  value: Chart | undefined;
}

export interface GetTokenChartReq {
  tokenId: string;
  filter: Filter | undefined;
}

export interface GetCollectibleChartReq {
  collectibleId: string;
  filter: Filter | undefined;
}

export interface GetDashboardChartReq {
  filter: Filter | undefined;
}

export interface GetCreatorTradesStatsReq {
  filter: Filter | undefined;
}

export interface GetCreatorTradesStatsRes {
  countTrades: number;
  lastTradeAt: Date | undefined;
  mostTradesWith: GetCreatorTradesStatsRes_MostTradesWith | undefined;
}

export interface GetCreatorTradesStatsRes_MostTradesWith {
  address: string;
  count: number;
}

function createBaseFilter(): Filter {
  return { fromDate: undefined, toDate: undefined };
}

export const Filter = {
  encode(message: Filter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fromDate !== undefined) {
      Timestamp.encode(toTimestamp(message.fromDate), writer.uint32(10).fork()).ldelim();
    }
    if (message.toDate !== undefined) {
      Timestamp.encode(toTimestamp(message.toDate), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Filter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fromDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.toDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Filter>, I>>(base?: I): Filter {
    return Filter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Filter>, I>>(object: I): Filter {
    const message = createBaseFilter();
    message.fromDate = object.fromDate ?? undefined;
    message.toDate = object.toDate ?? undefined;
    return message;
  },
};

function createBasePoint(): Point {
  return {
    price: "",
    primarySalesVolume: "",
    secondarySalesVolume: "",
    revenueShare: "",
    royaltyShare: "",
    fromAddress: "",
    toAddress: "",
    timestamp: undefined,
  };
}

export const Point = {
  encode(message: Point, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.price !== "") {
      writer.uint32(10).string(message.price);
    }
    if (message.primarySalesVolume !== "") {
      writer.uint32(18).string(message.primarySalesVolume);
    }
    if (message.secondarySalesVolume !== "") {
      writer.uint32(26).string(message.secondarySalesVolume);
    }
    if (message.revenueShare !== "") {
      writer.uint32(34).string(message.revenueShare);
    }
    if (message.royaltyShare !== "") {
      writer.uint32(42).string(message.royaltyShare);
    }
    if (message.fromAddress !== "") {
      writer.uint32(50).string(message.fromAddress);
    }
    if (message.toAddress !== "") {
      writer.uint32(58).string(message.toAddress);
    }
    if (message.timestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Point {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.price = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.primarySalesVolume = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.secondarySalesVolume = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.revenueShare = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.royaltyShare = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.fromAddress = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.toAddress = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Point>, I>>(base?: I): Point {
    return Point.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Point>, I>>(object: I): Point {
    const message = createBasePoint();
    message.price = object.price ?? "";
    message.primarySalesVolume = object.primarySalesVolume ?? "";
    message.secondarySalesVolume = object.secondarySalesVolume ?? "";
    message.revenueShare = object.revenueShare ?? "";
    message.royaltyShare = object.royaltyShare ?? "";
    message.fromAddress = object.fromAddress ?? "";
    message.toAddress = object.toAddress ?? "";
    message.timestamp = object.timestamp ?? undefined;
    return message;
  },
};

function createBaseChart(): Chart {
  return { points: [], totalRevenue: "", decimals: 0 };
}

export const Chart = {
  encode(message: Chart, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.points) {
      Point.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.totalRevenue !== "") {
      writer.uint32(18).string(message.totalRevenue);
    }
    if (message.decimals !== 0) {
      writer.uint32(24).int32(message.decimals);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Chart {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChart();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.points.push(Point.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.totalRevenue = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.decimals = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Chart>, I>>(base?: I): Chart {
    return Chart.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Chart>, I>>(object: I): Chart {
    const message = createBaseChart();
    message.points = object.points?.map((e) => Point.fromPartial(e)) || [];
    message.totalRevenue = object.totalRevenue ?? "";
    message.decimals = object.decimals ?? 0;
    return message;
  },
};

function createBaseMulticurrencyChart(): MulticurrencyChart {
  return { charts: {} };
}

export const MulticurrencyChart = {
  encode(message: MulticurrencyChart, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.charts).forEach(([key, value]) => {
      MulticurrencyChart_ChartsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MulticurrencyChart {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMulticurrencyChart();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = MulticurrencyChart_ChartsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.charts[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<MulticurrencyChart>, I>>(base?: I): MulticurrencyChart {
    return MulticurrencyChart.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MulticurrencyChart>, I>>(object: I): MulticurrencyChart {
    const message = createBaseMulticurrencyChart();
    message.charts = Object.entries(object.charts ?? {}).reduce<{ [key: string]: Chart }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Chart.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseMulticurrencyChart_ChartsEntry(): MulticurrencyChart_ChartsEntry {
  return { key: "", value: undefined };
}

export const MulticurrencyChart_ChartsEntry = {
  encode(message: MulticurrencyChart_ChartsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Chart.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MulticurrencyChart_ChartsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMulticurrencyChart_ChartsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Chart.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<MulticurrencyChart_ChartsEntry>, I>>(base?: I): MulticurrencyChart_ChartsEntry {
    return MulticurrencyChart_ChartsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MulticurrencyChart_ChartsEntry>, I>>(
    object: I,
  ): MulticurrencyChart_ChartsEntry {
    const message = createBaseMulticurrencyChart_ChartsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null) ? Chart.fromPartial(object.value) : undefined;
    return message;
  },
};

function createBaseGetTokenChartReq(): GetTokenChartReq {
  return { tokenId: "", filter: undefined };
}

export const GetTokenChartReq = {
  encode(message: GetTokenChartReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tokenId !== "") {
      writer.uint32(10).string(message.tokenId);
    }
    if (message.filter !== undefined) {
      Filter.encode(message.filter, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTokenChartReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTokenChartReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tokenId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filter = Filter.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetTokenChartReq>, I>>(base?: I): GetTokenChartReq {
    return GetTokenChartReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTokenChartReq>, I>>(object: I): GetTokenChartReq {
    const message = createBaseGetTokenChartReq();
    message.tokenId = object.tokenId ?? "";
    message.filter = (object.filter !== undefined && object.filter !== null)
      ? Filter.fromPartial(object.filter)
      : undefined;
    return message;
  },
};

function createBaseGetCollectibleChartReq(): GetCollectibleChartReq {
  return { collectibleId: "", filter: undefined };
}

export const GetCollectibleChartReq = {
  encode(message: GetCollectibleChartReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.collectibleId !== "") {
      writer.uint32(10).string(message.collectibleId);
    }
    if (message.filter !== undefined) {
      Filter.encode(message.filter, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCollectibleChartReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCollectibleChartReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectibleId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filter = Filter.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCollectibleChartReq>, I>>(base?: I): GetCollectibleChartReq {
    return GetCollectibleChartReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCollectibleChartReq>, I>>(object: I): GetCollectibleChartReq {
    const message = createBaseGetCollectibleChartReq();
    message.collectibleId = object.collectibleId ?? "";
    message.filter = (object.filter !== undefined && object.filter !== null)
      ? Filter.fromPartial(object.filter)
      : undefined;
    return message;
  },
};

function createBaseGetDashboardChartReq(): GetDashboardChartReq {
  return { filter: undefined };
}

export const GetDashboardChartReq = {
  encode(message: GetDashboardChartReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.filter !== undefined) {
      Filter.encode(message.filter, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDashboardChartReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDashboardChartReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.filter = Filter.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetDashboardChartReq>, I>>(base?: I): GetDashboardChartReq {
    return GetDashboardChartReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDashboardChartReq>, I>>(object: I): GetDashboardChartReq {
    const message = createBaseGetDashboardChartReq();
    message.filter = (object.filter !== undefined && object.filter !== null)
      ? Filter.fromPartial(object.filter)
      : undefined;
    return message;
  },
};

function createBaseGetCreatorTradesStatsReq(): GetCreatorTradesStatsReq {
  return { filter: undefined };
}

export const GetCreatorTradesStatsReq = {
  encode(message: GetCreatorTradesStatsReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.filter !== undefined) {
      Filter.encode(message.filter, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCreatorTradesStatsReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCreatorTradesStatsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.filter = Filter.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCreatorTradesStatsReq>, I>>(base?: I): GetCreatorTradesStatsReq {
    return GetCreatorTradesStatsReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCreatorTradesStatsReq>, I>>(object: I): GetCreatorTradesStatsReq {
    const message = createBaseGetCreatorTradesStatsReq();
    message.filter = (object.filter !== undefined && object.filter !== null)
      ? Filter.fromPartial(object.filter)
      : undefined;
    return message;
  },
};

function createBaseGetCreatorTradesStatsRes(): GetCreatorTradesStatsRes {
  return { countTrades: 0, lastTradeAt: undefined, mostTradesWith: undefined };
}

export const GetCreatorTradesStatsRes = {
  encode(message: GetCreatorTradesStatsRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.countTrades !== 0) {
      writer.uint32(8).int32(message.countTrades);
    }
    if (message.lastTradeAt !== undefined) {
      Timestamp.encode(toTimestamp(message.lastTradeAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.mostTradesWith !== undefined) {
      GetCreatorTradesStatsRes_MostTradesWith.encode(message.mostTradesWith, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCreatorTradesStatsRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCreatorTradesStatsRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.countTrades = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lastTradeAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.mostTradesWith = GetCreatorTradesStatsRes_MostTradesWith.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCreatorTradesStatsRes>, I>>(base?: I): GetCreatorTradesStatsRes {
    return GetCreatorTradesStatsRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCreatorTradesStatsRes>, I>>(object: I): GetCreatorTradesStatsRes {
    const message = createBaseGetCreatorTradesStatsRes();
    message.countTrades = object.countTrades ?? 0;
    message.lastTradeAt = object.lastTradeAt ?? undefined;
    message.mostTradesWith = (object.mostTradesWith !== undefined && object.mostTradesWith !== null)
      ? GetCreatorTradesStatsRes_MostTradesWith.fromPartial(object.mostTradesWith)
      : undefined;
    return message;
  },
};

function createBaseGetCreatorTradesStatsRes_MostTradesWith(): GetCreatorTradesStatsRes_MostTradesWith {
  return { address: "", count: 0 };
}

export const GetCreatorTradesStatsRes_MostTradesWith = {
  encode(message: GetCreatorTradesStatsRes_MostTradesWith, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    if (message.count !== 0) {
      writer.uint32(16).int32(message.count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCreatorTradesStatsRes_MostTradesWith {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCreatorTradesStatsRes_MostTradesWith();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.count = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCreatorTradesStatsRes_MostTradesWith>, I>>(
    base?: I,
  ): GetCreatorTradesStatsRes_MostTradesWith {
    return GetCreatorTradesStatsRes_MostTradesWith.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCreatorTradesStatsRes_MostTradesWith>, I>>(
    object: I,
  ): GetCreatorTradesStatsRes_MostTradesWith {
    const message = createBaseGetCreatorTradesStatsRes_MostTradesWith();
    message.address = object.address ?? "";
    message.count = object.count ?? 0;
    return message;
  },
};

export interface ChartService {
  GetTokenChart(request: DeepPartial<GetTokenChartReq>, metadata?: grpc.Metadata): Promise<MulticurrencyChart>;
  GetCollectibleChart(
    request: DeepPartial<GetCollectibleChartReq>,
    metadata?: grpc.Metadata,
  ): Promise<MulticurrencyChart>;
  GetDashboardChart(request: DeepPartial<GetDashboardChartReq>, metadata?: grpc.Metadata): Promise<MulticurrencyChart>;
  GetCreatorTradesStats(
    request: DeepPartial<GetCreatorTradesStatsReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetCreatorTradesStatsRes>;
}

export class ChartServiceClientImpl implements ChartService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetTokenChart = this.GetTokenChart.bind(this);
    this.GetCollectibleChart = this.GetCollectibleChart.bind(this);
    this.GetDashboardChart = this.GetDashboardChart.bind(this);
    this.GetCreatorTradesStats = this.GetCreatorTradesStats.bind(this);
  }

  GetTokenChart(request: DeepPartial<GetTokenChartReq>, metadata?: grpc.Metadata): Promise<MulticurrencyChart> {
    return this.rpc.unary(ChartServiceGetTokenChartDesc, GetTokenChartReq.fromPartial(request), metadata);
  }

  GetCollectibleChart(
    request: DeepPartial<GetCollectibleChartReq>,
    metadata?: grpc.Metadata,
  ): Promise<MulticurrencyChart> {
    return this.rpc.unary(ChartServiceGetCollectibleChartDesc, GetCollectibleChartReq.fromPartial(request), metadata);
  }

  GetDashboardChart(request: DeepPartial<GetDashboardChartReq>, metadata?: grpc.Metadata): Promise<MulticurrencyChart> {
    return this.rpc.unary(ChartServiceGetDashboardChartDesc, GetDashboardChartReq.fromPartial(request), metadata);
  }

  GetCreatorTradesStats(
    request: DeepPartial<GetCreatorTradesStatsReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetCreatorTradesStatsRes> {
    return this.rpc.unary(
      ChartServiceGetCreatorTradesStatsDesc,
      GetCreatorTradesStatsReq.fromPartial(request),
      metadata,
    );
  }
}

export const ChartServiceDesc = { serviceName: "chart.v2.ChartService" };

export const ChartServiceGetTokenChartDesc: UnaryMethodDefinitionish = {
  methodName: "GetTokenChart",
  service: ChartServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetTokenChartReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = MulticurrencyChart.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ChartServiceGetCollectibleChartDesc: UnaryMethodDefinitionish = {
  methodName: "GetCollectibleChart",
  service: ChartServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCollectibleChartReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = MulticurrencyChart.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ChartServiceGetDashboardChartDesc: UnaryMethodDefinitionish = {
  methodName: "GetDashboardChart",
  service: ChartServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDashboardChartReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = MulticurrencyChart.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ChartServiceGetCreatorTradesStatsDesc: UnaryMethodDefinitionish = {
  methodName: "GetCreatorTradesStats",
  service: ChartServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCreatorTradesStatsReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCreatorTradesStatsRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
