// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Primary_button__tgGoO {
  height: 42px;

  border-radius: 6px;
  padding: 0 25px;

  color: var(--body-color);
  background-color: var(--primary-color);
}

.Primary_button__tgGoO:hover {
  background-color: var(--primary-dark-color);
}

.Primary_button__tgGoO:disabled,
.Primary_button__tgGoO:disabled:hover {
  background-color: var(--disabled-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/button/Primary/index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;;EAEZ,kBAAkB;EAClB,eAAe;;EAEf,wBAAwB;EACxB,sCAAsC;AACxC;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;;EAEE,uCAAuC;AACzC","sourcesContent":[".button {\n  height: 42px;\n\n  border-radius: 6px;\n  padding: 0 25px;\n\n  color: var(--body-color);\n  background-color: var(--primary-color);\n}\n\n.button:hover {\n  background-color: var(--primary-dark-color);\n}\n\n.button:disabled,\n.button:disabled:hover {\n  background-color: var(--disabled-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Primary_button__tgGoO`
};
export default ___CSS_LOADER_EXPORT___;
