import { grpc } from '@improbable-eng/grpc-web';
import { captureException } from '@sentry/react';

import {
  GrpcWebImpl,
  ChartServiceClientImpl,
  GetTokenChartReq,
  GetCollectibleChartReq,
  GetDashboardChartReq,
} from 'protobuf/lib/chart.v2';

import { environment } from '../environment';
import { authenticated } from './grpc';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const collectibleServiceClient = new ChartServiceClientImpl(rpc);

export const getTokenChart = async (request: GetTokenChartReq) => {
  try {
    return await authenticated(meta => collectibleServiceClient.GetTokenChart(request, meta));
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const getCollectibleChart = async (request: GetCollectibleChartReq) => {
  try {
    return await authenticated(meta => collectibleServiceClient.GetCollectibleChart(request, meta));
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const getDashboardChart = async (request: GetDashboardChartReq) => {
  try {
    return await authenticated(meta => collectibleServiceClient.GetDashboardChart(request, meta));
  } catch (error) {
    captureException(error);
    throw error;
  }
};

// below is not written (yet) contract between front & back
// todo extract to common place

export type Period = '1D' | '1W' | '1M' | '3M' | '1Y' | 'ALL';

export const getAggregatedFromDate = (period: Period, toDate: Date) => {
  if (period === 'ALL') return undefined;

  const n = new Date(toDate);

  switch (period) {
    case '1D':
      n.setDate(n.getDate() - 1);
      break;
    case '1W':
      n.setDate(n.getDate() - 7);
      break;
    case '1M':
      n.setMonth(n.getMonth() - 1);
      break;
    case '3M':
      n.setMonth(n.getMonth() - 3);
      break;
    case '1Y':
      n.setFullYear(n.getFullYear() - 1);
      break;
  }

  return n;
};

export const EXACT_TIME_FORMAT = 'MMM d, y, h:mm:ss aaaa';

export const getAggregatedTimeFormat = (period: Period) => {
  switch (period) {
    // format depends on date-fns
    case '1D':
      return 'MMM d, y, h aaaa';
    case '1W':
    case '1M':
      return 'MMM d, y';
    case '3M':
    case '1Y':
      return 'MMM, y';
    case 'ALL':
      return 'y';
  }
};

export const getPeriodUnit = (period: Period) => {
  switch (period) {
    case '1D':
      return 'hour';
    case '1W':
    case '1M':
      return 'day';
    case '3M':
    case '1Y':
      return 'month';
    case 'ALL':
      return 'year';
  }
};
