import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { Navigator } from 'components/Navigator';
import { CollectibleList } from 'components/collectible';
import { CollectionDetails } from 'components/collection';
import { PageArea } from 'components/page';

import { useCollection } from 'hooks/collection';

import { getCollectibles } from 'services/collectible';

export const CollectionViewRoute = () => {
  const { t } = useTranslation();
  const { collection: collectionAddress } = useParams<{ collection: string }>();

  const collection = useCollection(collectionAddress);

  const collectibleReq = {
    collection: collectionAddress,
  };

  const collectibleQuery = useQuery(['getCollectibles', collectibleReq], () => getCollectibles(collectibleReq));
  return (
    <PageArea>
      <Navigator collectionName={collection?.name}></Navigator>
      <CollectionDetails collection={collection} />
      <hr />
      <CollectibleList query={collectibleQuery} emptyMessage={t('collection.emptyGallery')} />
    </PageArea>
  );
};
