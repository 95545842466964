import { grpc } from '@improbable-eng/grpc-web';
import { captureException } from '@sentry/react';

import {
  GrpcWebImpl,
  Product,
  ProductServiceClientImpl
} from 'protobuf/lib/productService';

import { environment } from 'environment';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const productServiceClient = new ProductServiceClientImpl(rpc);

export enum ProductQueryKey {
  getProduct = 'getProduct',
}

export const getProduct = async (collectibleId:string): Promise<Product> => {
  try {
    return await productServiceClient.GetProduct({collectibleId});
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const updateProduct = async (collectibleId:string) => {
  try {
    return await productServiceClient.UpdateProduct({collectibleId});
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const deleteCollectibleProductById = async (collectibleId:string) => {
  try {
    return await productServiceClient.DeleteProduct({collectibleId});
  } catch (error) {
    captureException(error);
    throw error;
  }
};


