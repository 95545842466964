import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import common from 'translations/beasy/en.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    fallbackNS: 'common',
    resources: {
      en: {
        ...(process.env.REACT_APP_DOMAIN === 'beasy'
          ? {}
          : {
              translation: require(`translations/${process.env.REACT_APP_DOMAIN}/en.json`),
            }),
        common,
      },
    },
  });

export default i18n;
