import { IpfsCollectibleInfo, IpfsFile } from 'beasy-fe-commons';
//import Carousel from 'react-elastic-carousel';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import leftNav from 'assets/images/left-Polygon.png';
import rightNav from 'assets/images/right-Polygon.png';

import { MediaView } from 'components/MediaView';
import Rarity from 'components/Rarity';

import { capitalizeFirst } from 'constants/helper';

import { useIpfsInfo } from 'hooks/useIpfs';

import { Collectible } from 'protobuf/lib/collectibleService';

import { getIpfsUrl } from 'services/ipfs';
import { getRoleNameMap, UserProfileQueryKey } from 'services/userProfile';

import { AppState } from 'state';

import css from './index.module.css';

const Carousel: any = require('react-elastic-carousel').default;
interface Props {
  collectible: Collectible;
}

const getFileInfo = (file?: IpfsFile) =>
  file ? (
    <a className={css.agreements} href={getIpfsUrl(file.cid)} target="_blank" rel="noopener noreferrer">
      {file.name}
    </a>
  ) : (
    '-'
  );

export const TokenDetails = ({ collectible }: Props) => {
  const { description, CID, quantity, contributors } = collectible;
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  const info = useIpfsInfo<IpfsCollectibleInfo>(CID);
  const attributes = info ? info.attributes : [];

  const roleMapQuery = useQuery(UserProfileQueryKey.getRoleNameMap, () => getRoleNameMap());

  const setAttributeType = (displayType: string): string => {
    switch (displayType) {
      case 'string': {
        return 'Text';
      }
      case 'boost_percentage': {
        return 'Percentage';
      }
      case 'number': {
        return 'Number';
      }
      case 'date': {
        return 'Date';
      }
    }
    return '';
  };
  const formatedValue = (value: string, type: string): string => {
    if (type === 'boost_percentage') return value + '%';
    if (type === 'date') {
      const d = new Date(parseInt(value));
      return d.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    }
    return value;
  };

  return (
    <>
      <div className={css.heading}>
        <p>NFT</p>
        <Rarity quantity={quantity} />
      </div>

      <Carousel
        className={css.carousel}
        isRTL={false}
        itemsToShow={1}
        renderArrow={({ type, onClick }: any) => (
          <div className={css.slideNav} onClick={onClick}>
            {type === 'PREV' ? <img src={leftNav} alt="" /> : <img src={rightNav} alt="" />}
          </div>
        )}
      >
        {info?.beasy.media.map(mediaItem => (
          <MediaView key={mediaItem.cid} fileName={mediaItem.name} src={getIpfsUrl(mediaItem.cid)} />
        ))}
      </Carousel>

      <div className={css.description}>
        <div className={css.field}>
          <div>Description</div>
          <div>{capitalizeFirst(description)}</div>
        </div>
        <div className={css.field}>
          <div>Ownership Agreement</div>
          <div>{getFileInfo(info?.beasy.docs.ownership[0])}</div>
        </div>
        <div className={css.field}>
          <div>License Agreement</div>
          <div>{getFileInfo(info?.beasy.docs.license[0])}</div>
        </div>
        <div className={css.field}>
          <div>Contributors</div>
          <div>
            {contributors.length === 0
              ? '-'
              : contributors.map(({ userProfile, revenueShare }, index) => {
                  const userRole = userProfile && userProfile.roles.find(r => r.network === walletConnectV2.chainId);
                  return (
                    <div key={index}>
                      {roleMapQuery.data && userRole ? roleMapQuery.data[userRole.role] : '-'}: {userProfile?.nickname}{' '}
                      with a {revenueShare}% Revenue Share
                    </div>
                  );
                })}
          </div>
        </div>
        <div className={css.field}>
          <div>Attributes</div>
          <div>
            {attributes
              ? attributes.length === 0
                ? '-'
                : attributes.map(({ display_type, trait_type, value }, index) => {
                    return (
                      <div key={index}>
                        {setAttributeType(display_type)} - {trait_type} - {formatedValue(value, display_type)}
                      </div>
                    );
                  })
              : '-'}
          </div>
        </div>
      </div>
    </>
  );
};
