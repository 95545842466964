import { grpc } from '@improbable-eng/grpc-web';
import { captureException } from '@sentry/react';

import { TradingEventIn, TradingEventOut } from 'protobuf/lib/subscriber_pb';
import { SubscriberService } from 'protobuf/lib/subscriber_pb_service';

import { environment } from 'environment';

import { authenticated } from './grpc';

type SubscriberClientCallback = (message: TradingEventOut) => void;

// Status Response Codes (https://developers.google.com/maps-booking/reference/grpc-api/status_codes)
const upStreamCodes = [2, 4, 8, 9, 10, 13, 14, 15];
const DEFAULT_TIMEOUT_TIME: number = 3_000;

class SubscriberClient {
  private client: grpc.Client<TradingEventIn, TradingEventOut> | null = null;

  private subscriptions = new Set<SubscriberClientCallback>();

  async start(): Promise<void> {
    try {
      this.client = await this.makeClient();
    } catch (error) {
      captureException(error);
    }
  }

  stop(): void {
    this.client?.close();
  }

  send(message: TradingEventIn): void {
    this.client?.send(message);
  }

  subscribe(callback: SubscriberClientCallback): void {
    this.subscriptions.add(callback);
  }

  unsubscribe(callback: SubscriberClientCallback): void {
    this.subscriptions.delete(callback);
  }

  private async makeClient(): Promise<grpc.Client<TradingEventIn, TradingEventOut>> {
    const client = grpc.client<TradingEventIn, TradingEventOut, grpc.MethodDefinition<TradingEventIn, TradingEventOut>>(
      SubscriberService.SubscribeOnTradingEvents,
      {
        host: environment.grpcUrl,
        transport: grpc.WebsocketTransport(),
      },
    );

    client.onMessage((message: TradingEventOut) => {
      this.subscriptions.forEach(sub => sub(message));
    });

    client.onEnd(async (code: grpc.Code, message: string) => {
      if (code === 0) {
        // Do nothing
      } else if (upStreamCodes.includes(code)) {
        setTimeout(async () => await this.makeClient(), DEFAULT_TIMEOUT_TIME);
      } else {
        throw new Error(`Error ${code} ${message}`);
      }
    });

    await authenticated(meta => {
      meta.append('app', 'forge');
      client.start(meta);
    });

    return client;
  }
}

export const subscriberClient = new SubscriberClient();
