import logoCube from 'assets/images/logo-cube.png';

import s from './index.module.css';

interface Props {
  size?: number;
}

const LoadingSpinner = ({ size = 50 }: Props) => {
  return <img src={logoCube} alt="loading..." className={s.spinner} width={size} />;
};

export default LoadingSpinner;
