// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmptyList_message__W88Ln {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  white-space: pre-line;
  color: var(--alt17-color);
}

.EmptyList_border__mnJUq {
  border: 1px solid var(--grey-80);
  border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/EmptyList/index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;;EAEZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB,eAAe;EACf,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE,gCAAgC;EAChC,kBAAkB;AACpB","sourcesContent":[".message {\n  width: 100%;\n  height: 100%;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  font-size: 14px;\n  white-space: pre-line;\n  color: var(--alt17-color);\n}\n\n.border {\n  border: 1px solid var(--grey-80);\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": `EmptyList_message__W88Ln`,
	"border": `EmptyList_border__mnJUq`
};
export default ___CSS_LOADER_EXPORT___;
