import { EthereumAddress } from 'beasy-fe-commons';
import { useCallback, useMemo, useState } from 'react';

import { ButtonPrimary, ButtonSecondary } from 'components/button';
import { DialogFooter, DialogPicker1 } from 'components/dialog';
import { InputValueSelector } from 'components/input';
import { EmptyList } from 'components/layout';

import { UserPickItem, UserWithoutFriends } from '..';
import css from './index.module.css';

interface Props {
  options?: EthereumAddress[];
  value?: EthereumAddress[];
  setValue?: (value: EthereumAddress[]) => void;
  onClose?: () => void;
  title?: string;
}

export const UserPickDialog = ({ options, value, setValue, onClose, title }: Props) => {
  const [selected, setSelected] = useState(value);

  const select = useCallback(
    (value: EthereumAddress) => {
      setSelected([...(selected ?? []), value]);
    },
    [selected],
  );

  const unselect = useCallback(
    (value: EthereumAddress) => {
      setSelected(selected?.filter(i => i !== value));
    },
    [selected],
  );

  const submit = useMemo(
    () =>
      setValue &&
      (() => {
        setValue(selected ?? []);
        onClose?.();
      }),
    [onClose, selected, setValue],
  );

  return (
    <DialogPicker1 title={title} onClose={onClose} className={css.wrapper}>
      <section>
        {!options?.length && <EmptyList message={<UserWithoutFriends />} />}
        <div className={css.list}>
          {options?.map(address => (
            <InputValueSelector
              key={address}
              value={address}
              select={select}
              unselect={unselect}
              selected={selected?.includes(address)}
            >
              <UserPickItem address={address} />
            </InputValueSelector>
          ))}
        </div>
      </section>

      <DialogFooter>
        {onClose && <ButtonSecondary onClick={onClose}>Cancel</ButtonSecondary>}
        {setValue && <ButtonPrimary onClick={submit}>Continue</ButtonPrimary>}
      </DialogFooter>
    </DialogPicker1>
  );
};
