import clsx from 'clsx';

import { IconProps } from '../types';
import css from './index.module.css';

const ASPECT_RATIO = 0.875;

export const DeleteIcon = ({ className, size = 14, onClick }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(css.icon, className)}
    onClick={onClick}
    width={size}
    height={size / ASPECT_RATIO}
    viewBox="0 0 14 16"
  >
    <path d="M13.4167 2.88H11.0833V1.28C11.0833 0.574 10.5602 0 9.91667 0H4.08333C3.43984 0 2.91667 0.574 2.91667 1.28V2.88H0.583333C0.260677 2.88 0 3.166 0 3.52V4.16C0 4.248 0.065625 4.32 0.145833 4.32H1.24687L1.69714 14.78C1.7263 15.462 2.24036 16 2.86198 16H11.138C11.7615 16 12.2737 15.464 12.3029 14.78L12.7531 4.32H13.8542C13.9344 4.32 14 4.248 14 4.16V3.52C14 3.166 13.7393 2.88 13.4167 2.88ZM9.77083 2.88H4.22917V1.44H9.77083V2.88Z" />
  </svg>
);
