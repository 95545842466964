/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { UserProfile, UserProfile_AdditionalData } from "./userProfileService";

export const protobufPackage = "";

export interface Friend {
  address: string;
  name: string;
  additionalData: UserProfile_AdditionalData | undefined;
  userProfile: UserProfile | undefined;
}

export interface GetFriendsReq {
  address: string;
  filter?: GetFriendsReq_Filter | undefined;
}

export interface GetFriendsReq_Filter {
  roleName: string;
  network: string;
}

export interface GetFriendsRes {
  friends: Friend[];
}

export interface GetFriendReq {
  address: string;
  friendAddress: string;
}

export interface GetFriendRes {
  friend: Friend | undefined;
}

export interface CreateFriendReq {
  address: string;
  friendAddress: string;
}

export interface CreateFriendRes {
  friend: Friend | undefined;
}

export interface CreateFriendSenderReq {
  address: string;
  name: string;
}

export interface CreateFriendSenderRes {
  code: string;
}

export interface CreateFriendRecipientReq {
  address: string;
  code: string;
}

export interface CreateFriendRecipientRes {
  friend: Friend | undefined;
}

function createBaseFriend(): Friend {
  return { address: "", name: "", additionalData: undefined, userProfile: undefined };
}

export const Friend = {
  encode(message: Friend, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.additionalData !== undefined) {
      UserProfile_AdditionalData.encode(message.additionalData, writer.uint32(34).fork()).ldelim();
    }
    if (message.userProfile !== undefined) {
      UserProfile.encode(message.userProfile, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Friend {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFriend();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.additionalData = UserProfile_AdditionalData.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.userProfile = UserProfile.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Friend>, I>>(base?: I): Friend {
    return Friend.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Friend>, I>>(object: I): Friend {
    const message = createBaseFriend();
    message.address = object.address ?? "";
    message.name = object.name ?? "";
    message.additionalData = (object.additionalData !== undefined && object.additionalData !== null)
      ? UserProfile_AdditionalData.fromPartial(object.additionalData)
      : undefined;
    message.userProfile = (object.userProfile !== undefined && object.userProfile !== null)
      ? UserProfile.fromPartial(object.userProfile)
      : undefined;
    return message;
  },
};

function createBaseGetFriendsReq(): GetFriendsReq {
  return { address: "", filter: undefined };
}

export const GetFriendsReq = {
  encode(message: GetFriendsReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    if (message.filter !== undefined) {
      GetFriendsReq_Filter.encode(message.filter, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFriendsReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFriendsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filter = GetFriendsReq_Filter.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetFriendsReq>, I>>(base?: I): GetFriendsReq {
    return GetFriendsReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFriendsReq>, I>>(object: I): GetFriendsReq {
    const message = createBaseGetFriendsReq();
    message.address = object.address ?? "";
    message.filter = (object.filter !== undefined && object.filter !== null)
      ? GetFriendsReq_Filter.fromPartial(object.filter)
      : undefined;
    return message;
  },
};

function createBaseGetFriendsReq_Filter(): GetFriendsReq_Filter {
  return { roleName: "", network: "" };
}

export const GetFriendsReq_Filter = {
  encode(message: GetFriendsReq_Filter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.roleName !== "") {
      writer.uint32(10).string(message.roleName);
    }
    if (message.network !== "") {
      writer.uint32(18).string(message.network);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFriendsReq_Filter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFriendsReq_Filter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roleName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.network = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetFriendsReq_Filter>, I>>(base?: I): GetFriendsReq_Filter {
    return GetFriendsReq_Filter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFriendsReq_Filter>, I>>(object: I): GetFriendsReq_Filter {
    const message = createBaseGetFriendsReq_Filter();
    message.roleName = object.roleName ?? "";
    message.network = object.network ?? "";
    return message;
  },
};

function createBaseGetFriendsRes(): GetFriendsRes {
  return { friends: [] };
}

export const GetFriendsRes = {
  encode(message: GetFriendsRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.friends) {
      Friend.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFriendsRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFriendsRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.friends.push(Friend.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetFriendsRes>, I>>(base?: I): GetFriendsRes {
    return GetFriendsRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFriendsRes>, I>>(object: I): GetFriendsRes {
    const message = createBaseGetFriendsRes();
    message.friends = object.friends?.map((e) => Friend.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetFriendReq(): GetFriendReq {
  return { address: "", friendAddress: "" };
}

export const GetFriendReq = {
  encode(message: GetFriendReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    if (message.friendAddress !== "") {
      writer.uint32(18).string(message.friendAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFriendReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFriendReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.friendAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetFriendReq>, I>>(base?: I): GetFriendReq {
    return GetFriendReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFriendReq>, I>>(object: I): GetFriendReq {
    const message = createBaseGetFriendReq();
    message.address = object.address ?? "";
    message.friendAddress = object.friendAddress ?? "";
    return message;
  },
};

function createBaseGetFriendRes(): GetFriendRes {
  return { friend: undefined };
}

export const GetFriendRes = {
  encode(message: GetFriendRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.friend !== undefined) {
      Friend.encode(message.friend, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFriendRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFriendRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.friend = Friend.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetFriendRes>, I>>(base?: I): GetFriendRes {
    return GetFriendRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFriendRes>, I>>(object: I): GetFriendRes {
    const message = createBaseGetFriendRes();
    message.friend = (object.friend !== undefined && object.friend !== null)
      ? Friend.fromPartial(object.friend)
      : undefined;
    return message;
  },
};

function createBaseCreateFriendReq(): CreateFriendReq {
  return { address: "", friendAddress: "" };
}

export const CreateFriendReq = {
  encode(message: CreateFriendReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    if (message.friendAddress !== "") {
      writer.uint32(18).string(message.friendAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateFriendReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateFriendReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.friendAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateFriendReq>, I>>(base?: I): CreateFriendReq {
    return CreateFriendReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateFriendReq>, I>>(object: I): CreateFriendReq {
    const message = createBaseCreateFriendReq();
    message.address = object.address ?? "";
    message.friendAddress = object.friendAddress ?? "";
    return message;
  },
};

function createBaseCreateFriendRes(): CreateFriendRes {
  return { friend: undefined };
}

export const CreateFriendRes = {
  encode(message: CreateFriendRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.friend !== undefined) {
      Friend.encode(message.friend, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateFriendRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateFriendRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.friend = Friend.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateFriendRes>, I>>(base?: I): CreateFriendRes {
    return CreateFriendRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateFriendRes>, I>>(object: I): CreateFriendRes {
    const message = createBaseCreateFriendRes();
    message.friend = (object.friend !== undefined && object.friend !== null)
      ? Friend.fromPartial(object.friend)
      : undefined;
    return message;
  },
};

function createBaseCreateFriendSenderReq(): CreateFriendSenderReq {
  return { address: "", name: "" };
}

export const CreateFriendSenderReq = {
  encode(message: CreateFriendSenderReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateFriendSenderReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateFriendSenderReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateFriendSenderReq>, I>>(base?: I): CreateFriendSenderReq {
    return CreateFriendSenderReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateFriendSenderReq>, I>>(object: I): CreateFriendSenderReq {
    const message = createBaseCreateFriendSenderReq();
    message.address = object.address ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseCreateFriendSenderRes(): CreateFriendSenderRes {
  return { code: "" };
}

export const CreateFriendSenderRes = {
  encode(message: CreateFriendSenderRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateFriendSenderRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateFriendSenderRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateFriendSenderRes>, I>>(base?: I): CreateFriendSenderRes {
    return CreateFriendSenderRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateFriendSenderRes>, I>>(object: I): CreateFriendSenderRes {
    const message = createBaseCreateFriendSenderRes();
    message.code = object.code ?? "";
    return message;
  },
};

function createBaseCreateFriendRecipientReq(): CreateFriendRecipientReq {
  return { address: "", code: "" };
}

export const CreateFriendRecipientReq = {
  encode(message: CreateFriendRecipientReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    if (message.code !== "") {
      writer.uint32(18).string(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateFriendRecipientReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateFriendRecipientReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateFriendRecipientReq>, I>>(base?: I): CreateFriendRecipientReq {
    return CreateFriendRecipientReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateFriendRecipientReq>, I>>(object: I): CreateFriendRecipientReq {
    const message = createBaseCreateFriendRecipientReq();
    message.address = object.address ?? "";
    message.code = object.code ?? "";
    return message;
  },
};

function createBaseCreateFriendRecipientRes(): CreateFriendRecipientRes {
  return { friend: undefined };
}

export const CreateFriendRecipientRes = {
  encode(message: CreateFriendRecipientRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.friend !== undefined) {
      Friend.encode(message.friend, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateFriendRecipientRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateFriendRecipientRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.friend = Friend.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateFriendRecipientRes>, I>>(base?: I): CreateFriendRecipientRes {
    return CreateFriendRecipientRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateFriendRecipientRes>, I>>(object: I): CreateFriendRecipientRes {
    const message = createBaseCreateFriendRecipientRes();
    message.friend = (object.friend !== undefined && object.friend !== null)
      ? Friend.fromPartial(object.friend)
      : undefined;
    return message;
  },
};

export interface FriendService {
  GetFriends(request: DeepPartial<GetFriendsReq>, metadata?: grpc.Metadata): Promise<GetFriendsRes>;
  GetFriend(request: DeepPartial<GetFriendReq>, metadata?: grpc.Metadata): Promise<GetFriendRes>;
  CreateFriend(request: DeepPartial<CreateFriendReq>, metadata?: grpc.Metadata): Promise<CreateFriendRes>;
  CreateFriendSender(
    request: DeepPartial<CreateFriendSenderReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateFriendSenderRes>;
  CreateFriendRecipient(
    request: DeepPartial<CreateFriendRecipientReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateFriendRecipientRes>;
}

export class FriendServiceClientImpl implements FriendService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetFriends = this.GetFriends.bind(this);
    this.GetFriend = this.GetFriend.bind(this);
    this.CreateFriend = this.CreateFriend.bind(this);
    this.CreateFriendSender = this.CreateFriendSender.bind(this);
    this.CreateFriendRecipient = this.CreateFriendRecipient.bind(this);
  }

  GetFriends(request: DeepPartial<GetFriendsReq>, metadata?: grpc.Metadata): Promise<GetFriendsRes> {
    return this.rpc.unary(FriendServiceGetFriendsDesc, GetFriendsReq.fromPartial(request), metadata);
  }

  GetFriend(request: DeepPartial<GetFriendReq>, metadata?: grpc.Metadata): Promise<GetFriendRes> {
    return this.rpc.unary(FriendServiceGetFriendDesc, GetFriendReq.fromPartial(request), metadata);
  }

  CreateFriend(request: DeepPartial<CreateFriendReq>, metadata?: grpc.Metadata): Promise<CreateFriendRes> {
    return this.rpc.unary(FriendServiceCreateFriendDesc, CreateFriendReq.fromPartial(request), metadata);
  }

  CreateFriendSender(
    request: DeepPartial<CreateFriendSenderReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateFriendSenderRes> {
    return this.rpc.unary(FriendServiceCreateFriendSenderDesc, CreateFriendSenderReq.fromPartial(request), metadata);
  }

  CreateFriendRecipient(
    request: DeepPartial<CreateFriendRecipientReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateFriendRecipientRes> {
    return this.rpc.unary(
      FriendServiceCreateFriendRecipientDesc,
      CreateFriendRecipientReq.fromPartial(request),
      metadata,
    );
  }
}

export const FriendServiceDesc = { serviceName: "FriendService" };

export const FriendServiceGetFriendsDesc: UnaryMethodDefinitionish = {
  methodName: "GetFriends",
  service: FriendServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFriendsReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFriendsRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const FriendServiceGetFriendDesc: UnaryMethodDefinitionish = {
  methodName: "GetFriend",
  service: FriendServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFriendReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFriendRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const FriendServiceCreateFriendDesc: UnaryMethodDefinitionish = {
  methodName: "CreateFriend",
  service: FriendServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateFriendReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateFriendRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const FriendServiceCreateFriendSenderDesc: UnaryMethodDefinitionish = {
  methodName: "CreateFriendSender",
  service: FriendServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateFriendSenderReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateFriendSenderRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const FriendServiceCreateFriendRecipientDesc: UnaryMethodDefinitionish = {
  methodName: "CreateFriendRecipient",
  service: FriendServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateFriendRecipientReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateFriendRecipientRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
