import { captureException } from '@sentry/react';
import { createElement } from 'react';
import { toast } from 'react-toastify';

import { getCollectibleIdFromTokenId } from 'constants/helper';

import { useMount } from 'hooks/mount';

import { TradingEventOut } from 'protobuf/lib/subscriber_pb';

import { getCollectibleById } from 'services/collectible';
import { subscriberClient } from 'services/subscriber';
import { getUserProfile } from 'services/userProfile';

import TraderEventToast from './TraderEventToast';

const useTradingEvent = () => {
  useMount(() => {
    const showToast = async (collectibleId: string, fromAddress: string, toAddress: string) => {
      try {
        const [collectible, from, to] = await Promise.all([
          getCollectibleById(collectibleId),
          getUserProfile(fromAddress),
          getUserProfile(toAddress),
        ]);

        if (collectible) {
          const element = createElement(TraderEventToast, { collectible, from, to });
          toast(element, { autoClose: 10e3 });
        }
      } catch (error) {
        captureException(error);
      }
    };

    const showMintToast = async (collectibleId: string, fromAddress: string) => {
      try {
        const [collectible, from] = await Promise.all([getCollectibleById(collectibleId), getUserProfile(fromAddress)]);

        if (collectible) {
          const element = createElement(TraderEventToast, { collectible, from });
          toast(element, { autoClose: 10e3 });
        }
      } catch (error) {
        captureException(error);
      }
    };

    const handleTradingEvent = async (message: TradingEventOut) => {
      try {
        const event = message.toObject();
        if (event.body) {
          const { tokenid, addresses } = event.body;
          const collectibleId = getCollectibleIdFromTokenId(tokenid);
          if (addresses) {
            if (addresses.from === addresses.to) {
              await showMintToast(collectibleId, addresses.from);
            } else {
              await showToast(collectibleId, addresses.from, addresses.to);
            }
          }
        }
      } catch (error) {
        captureException(error);
      }
    };

    subscriberClient.subscribe(handleTradingEvent);
    return () => subscriberClient.unsubscribe(handleTradingEvent);
  });
};

export default useTradingEvent;
