import { useTranslation } from 'react-i18next';

import { WarningIcon } from 'assets/icons';


import { ButtonPrimary, ButtonSecondary } from 'components/button';
import { DialogArea, DialogClose, DialogFooter, DialogHeader, DialogOverlay } from 'components/dialog';

import css from './index.module.css';

interface Props {
  onYes: () => void;
  onNo: () => void;
}

const PushShopifyModal = ({ onYes, onNo }: Props) => {
  const { t } = useTranslation();
  return (
    <DialogOverlay>
      <DialogArea>
        <DialogHeader>
          <div className={css.header}>
            <WarningIcon className={css.icon} />
            <h3>{t('pushShopifyModal.title')}</h3>
          </div>
          <DialogClose onClick={onNo} />
        </DialogHeader>
        <p className={css.label}>{t('pushShopifyModal.confirmation')}</p>
        <DialogFooter>
          <ButtonSecondary onClick={onNo}>{t('pushShopifyModal.cancel')}</ButtonSecondary>
          <ButtonPrimary onClick={onYes}>{t('pushShopifyModal.yes')}</ButtonPrimary>
        </DialogFooter>
      </DialogArea>
    </DialogOverlay>
  );
};

export default PushShopifyModal;
