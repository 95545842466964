// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Base_button__It5Y3 {
  display: inline-flex;
  align-items: center;
  gap: 8px;

  text-align: left;

  border: 0;
  padding: 0;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: inherit;
  background-color: inherit;

  transition: 0.2s ease-in-out;

  cursor: pointer;
}

.Base_button__It5Y3:disabled {
  cursor: default;
}
`, "",{"version":3,"sources":["webpack://./src/components/button/Base/index.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,QAAQ;;EAER,gBAAgB;;EAEhB,SAAS;EACT,UAAU;;EAEV,gBAAgB;EAChB,eAAe;EACf,iBAAiB;;EAEjB,cAAc;EACd,yBAAyB;;EAEzB,4BAA4B;;EAE5B,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".button {\n  display: inline-flex;\n  align-items: center;\n  gap: 8px;\n\n  text-align: left;\n\n  border: 0;\n  padding: 0;\n\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n\n  color: inherit;\n  background-color: inherit;\n\n  transition: 0.2s ease-in-out;\n\n  cursor: pointer;\n}\n\n.button:disabled {\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Base_button__It5Y3`
};
export default ___CSS_LOADER_EXPORT___;
