import { CollectibleDetailsEditor } from 'components/collectible';
import { CollectibleContributorsEditor } from 'components/collectible';

import Review from './Review';
import Terms from './Terms';
import Attributes from './Attributes';
import { StepIndex } from './types';

export const steps = {
  ...(process.env.REACT_APP_DOMAIN === 'ndau' ? { [StepIndex.TYPE]: require(`./Type/ndau`).default } : {}),
  [StepIndex.DETAILS]: CollectibleDetailsEditor,
  [StepIndex.ATTRIBUTES]: Attributes,
  [StepIndex.TERMS]: Terms,
  [StepIndex.CONTRIBUTORS]: CollectibleContributorsEditor,
  [StepIndex.REVIEW]: Review,
};
