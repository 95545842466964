//import logger from 'logger';
import { useCallback, useMemo, useState } from 'react';

import { AlertProps } from 'components/Alert';
import { LoadingScreenProps } from 'components/loading/Screen';

import { MintMultipleReq } from 'protobuf/lib/transaction';

import history from 'services/history';
import { mintNFT } from 'services/transaction';
import { Activity, log } from 'services/activityReport';

export interface MintNFTController {
  alertProps: AlertProps | undefined;
  loadingScreenProps: LoadingScreenProps | undefined;
  mint: (req: MintMultipleReq) => Promise<void>;
}

const useMintNFT = (): MintNFTController => {
  const [loadingScreenProps, setLoadingScreenProps] = useState<LoadingScreenProps | undefined>();
  const [alertProps, setAlertProps] = useState<AlertProps | undefined>();

  const showErrorAlert = useCallback(() => {
    setLoadingScreenProps(undefined);
    setAlertProps({
      isSuccess: false,
      message: 'We have encountered an error performing this transaction. Please try again.',
      onClose: () => setAlertProps(undefined),
    });
  }, []);

  const mint = useCallback(
    async (req: MintMultipleReq) => {
      try {
        setLoadingScreenProps({
          message: 'Processing the transaction',
          isNotification: true,
        });
        const result = await mintNFT(req);
        setLoadingScreenProps(undefined);
        if (result !== undefined) {
            log(req.from, Activity.MINT, process.env.REACT_APP_DOMAIN);
          setAlertProps({
            isSuccess: true,
            message: 'NFT sucessfully minted!',
            onClose: () => {
              history.push(`/home/collectible/${req.collectibleId}`);
              setAlertProps(undefined);
            },
          });
        }
      } catch (error) {
        //logger.error({ error });
        showErrorAlert();
      }
    },
    [showErrorAlert],
  );

  return useMemo(
    () => ({
      alertProps,
      loadingScreenProps,
      mint,
    }),
    [alertProps, loadingScreenProps, mint],
  );
};

export default useMintNFT;
