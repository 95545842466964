import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Joyride, { Styles, Step as JoyStep, CallBackProps, STATUS } from 'react-joyride';
import { useQuery } from 'react-query';

import { TickIcon } from 'assets/icons';

import UserProfileForm from 'components/UserProfileForm';
import { ButtonPrimary } from 'components/button';
import { DialogArea, DialogFooter, DialogHeader, DialogOverlay } from 'components/dialog';

import {
  businessAgreementsId,
  createNftId,
  faqId,
  collectionsId,
  nftGalleryId,
  overallSalesVolumeStatisticId,
  workWithPartnersId,
} from 'constants/onboarding';

import { TourStep } from 'screens/Onboarding/TourStep';

import { computedStyle } from 'services/style';
import { useGetCurrentUserProfile, UserProfileQueryKey } from 'services/userProfile';

import css from './index.module.css';

const ONBOARDING = 'ONBOARDING';

type Step = 'WELCOME' | 'PROFILE' | 'TOUR' | 'COMPLETE';

export const Onboarding = () => {
  const [step, setStep] = useState<Step>((localStorage.getItem(ONBOARDING) as Step) || 'WELCOME');
  const { t } = useTranslation();
  const gotoProfileStep = useCallback(() => {
    setStep('PROFILE');
    localStorage.setItem(ONBOARDING, 'PROFILE');
  }, []);

  const gotoTourStep = useCallback(() => {
    setStep('TOUR');
    localStorage.setItem(ONBOARDING, 'TOUR');
  }, []);

  const callback = useCallback(({ status }: CallBackProps) => {
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)) {
      setStep('COMPLETE');
      localStorage.setItem(ONBOARDING, 'COMPLETE');
    }
  }, []);
  const getCurrentUserProfile = useGetCurrentUserProfile();
  const { data: userProfile } = useQuery(UserProfileQueryKey.getUserProfile, () => getCurrentUserProfile());

  const steps: JoyStep[] = useMemo(
    () => [
      {
        target: 'body',
        title: `Hi ${userProfile?.nickname}!`,
        content: `${t('onboarding.text')}`,
        placement: 'center',
      },
      {
        target: `#${createNftId}`,
        title: 'Create',
        content:
          'Here you can create a new NFT or NFT Collection. In order to sell your NFTs you will need to Mint them through the Forge or Lazy Mint them through your Shopify store.',
        placement: 'right',
      },
      {
        target: `#${collectionsId}`,
        title: 'Collections',
        content:
          'Once you’ve created a Collection, you can view it here. You will be able to view the Contract Address and all NFTs listed under the Collection.',
        placement: 'right',
      },
      {
        target: `#${nftGalleryId}`,
        title: 'NFT Gallery',
        content:
          'Once you’ve created an NFT, you can view it here. You will be able to view the revenue and ownership data related to each of your NFTs.',
        placement: 'right',
      },
      {
        target: `#${businessAgreementsId}`,
        title: 'Business Agreements',
        content:
          'Here you will be able to view any contractual agreement that you have minted as part of your NFTs. The business agreements are filtered by Ownership Agreements and License Agreements.',
        placement: 'right',
      },
      {
        target: `#${workWithPartnersId}`,
        title: 'Work with Partners',
        content:
          'Here you will be able to view any partners you’ve added to your creator network. In order to add a new partner, you will need to add the user as a Friend in your Beasy Wallet. If that user is a creator on the BEASY network, they will appear as a partner in your Forge.',
        placement: 'right',
      },
      {
        target: `#${overallSalesVolumeStatisticId}`,
        title: 'Overall Sales Volume Statistic',
        content:
          'Here you will be able to track the revenue generated from your NFT sales. You will be able to see your revenue share on both primary and secondary markets, and the revenue share for your partners.',
        placement: 'left',
      },
      {
        target: `#${faqId}`,
        title: 'FAQ',
        content: 'This FAQ section provides quick guides and answers to potential questions you may have.',
        placement: 'right',
        styles: {},
      },
    ],
    [userProfile?.nickname, t],
  );

  switch (step) {
    case 'WELCOME':
      return (
        <DialogOverlay>
          <DialogArea className={css.wrapper}>
            <DialogHeader>
              <div className={css.header}>
                <TickIcon size={16} />
                <h3>{t('onboarding.header')}</h3>
              </div>
            </DialogHeader>
            <p className={css.label}>{t('onboarding.subheader')}</p>
            <DialogFooter>
              <ButtonPrimary type="button" onClick={gotoProfileStep}>
                Next
              </ButtonPrimary>
            </DialogFooter>
          </DialogArea>
        </DialogOverlay>
      );
    case 'PROFILE':
      return userProfile ? (
        <UserProfileForm userProfile={userProfile} onClose={gotoTourStep} cancelButtonText="Skip" />
      ) : null;
    case 'TOUR':
      return (
        <Joyride
          steps={steps}
          callback={callback}
          disableOverlayClose
          continuous
          tooltipComponent={TourStep}
          styles={styles}
        />
      );
    default:
      return null; // nothing to render
  }
};

const styles: Styles = {
  options: {
    arrowColor: computedStyle.getPropertyValue('--dialog-bg-color'),
  },
};
