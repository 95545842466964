// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TabItem_item__VGRsv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  border-bottom: 2px solid transparent;
  border-bottom-color: var(--input-bg-color);
  padding-bottom: 18px;
  color: var(--alt10-color);
  font-size: 16px;
}

.TabItem_item__VGRsv:not(.TabItem_itemDisabled__OqMZh) {
  cursor: pointer;
  border-bottom-color: var(--primary-color);
}

.TabItem_itemSelected__N4TcU {
  color: var(--text-color-accent);
}

.TabItem_check__jG7ZV {
  margin-right: 10px;
}

.TabItem_step__xXedf {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  padding-top: 3px;
  margin-right: 8px;
  opacity: 0.4;
  background-color: var(--alt8-color);
  color: var(--text-color-accent);
  text-align: center;
}
.TabItem_stepSelected__jYpXC {
  opacity: 1;
  background-color: var(--primary-color);
}

.TabItem_item__VGRsv circle {
  fill: transparent;
}

.TabItem_item__VGRsv path {
  stroke: var(--alt10-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/page/TabItem/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,SAAO;EACP,oCAAoC;EACpC,0CAA0C;EAC1C,oBAAoB;EACpB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,yCAAyC;AAC3C;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,mCAAmC;EACnC,+BAA+B;EAC/B,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,sCAAsC;AACxC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".item {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex: 1;\n  border-bottom: 2px solid transparent;\n  border-bottom-color: var(--input-bg-color);\n  padding-bottom: 18px;\n  color: var(--alt10-color);\n  font-size: 16px;\n}\n\n.item:not(.itemDisabled) {\n  cursor: pointer;\n  border-bottom-color: var(--primary-color);\n}\n\n.itemSelected {\n  color: var(--text-color-accent);\n}\n\n.check {\n  margin-right: 10px;\n}\n\n.step {\n  height: 26px;\n  width: 26px;\n  border-radius: 50%;\n  padding-top: 3px;\n  margin-right: 8px;\n  opacity: 0.4;\n  background-color: var(--alt8-color);\n  color: var(--text-color-accent);\n  text-align: center;\n}\n.stepSelected {\n  opacity: 1;\n  background-color: var(--primary-color);\n}\n\n.item circle {\n  fill: transparent;\n}\n\n.item path {\n  stroke: var(--alt10-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `TabItem_item__VGRsv`,
	"itemDisabled": `TabItem_itemDisabled__OqMZh`,
	"itemSelected": `TabItem_itemSelected__N4TcU`,
	"check": `TabItem_check__jG7ZV`,
	"step": `TabItem_step__xXedf`,
	"stepSelected": `TabItem_stepSelected__jYpXC`
};
export default ___CSS_LOADER_EXPORT___;
