// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ValueSelector_wrapper__UkFPd {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
}

.ValueSelector_wrapper__UkFPd > :first-child {
  flex-grow: 1;
}

.ValueSelector_selected__7cEjC,
.ValueSelector_wrapper__UkFPd:hover,
.ValueSelector_wrapper__UkFPd:disabled {
  background-color: var(--grey-70);
}
`, "",{"version":3,"sources":["webpack://./src/components/input/ValueSelector/index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;;;EAGE,gCAAgC;AAClC","sourcesContent":[".wrapper {\n  width: 100%;\n  padding: 8px;\n  border-radius: 4px;\n}\n\n.wrapper > :first-child {\n  flex-grow: 1;\n}\n\n.selected,\n.wrapper:hover,\n.wrapper:disabled {\n  background-color: var(--grey-70);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ValueSelector_wrapper__UkFPd`,
	"selected": `ValueSelector_selected__7cEjC`
};
export default ___CSS_LOADER_EXPORT___;
