import { EthereumAddress } from 'beasy-fe-commons';
import { setNestedObjectValues, FieldArray, useFormik, FormikProvider } from 'formik';
import { useForm, useProgress } from 'hooks';
//import logger from 'logger';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useBoolean } from 'use-boolean';

import { HelpIcon, PlusIcon } from 'assets/icons';

import Alert, { AlertProps } from 'components/Alert';
import ProgressBar from 'components/ProgressBar';
import { Spacer } from 'components/Spacer';
import { Tooltip } from 'components/Tooltip';
import { ButtonPrimary, ButtonPrimary2 } from 'components/button';
import { Header } from 'components/collectible';
import { StepProps, ContributorsStepValue, STEP_INVALID_VALUE } from 'components/collectible/steps/types';
import { FieldError } from 'components/field';
import { InputSlider, NumberInput } from 'components/input';
import { LoadingScreen, LoadingScreenProps } from 'components/loading';
import { ShareHolder, UserPickDialog } from 'components/user';
import { creatorFormikConfig } from 'components/user/CreatorForm';
import { CreatorForm } from 'components/user/CreatorForm';

import { encodeParam } from 'constants/helper';

import { useCreators } from 'hooks/friends';

import { CreateCollectibleTransactionReq_Contributor } from 'protobuf/lib/collectibleService';
import { UserProfile_AdditionalData } from 'protobuf/lib/userProfileService';

import { setAccount } from 'services/account';
import { Activity, log } from 'services/activityReport';
import { axios } from 'services/axios';
import { registerUser } from 'services/chargeMaster';
import { createFriend } from 'services/friend';
import {
  createCreatorProfile,
  useGetCurrentUserProfile,
  UsernameAlreadyExistsError,
  UserProfileQueryKey,
} from 'services/userProfile';

//import { registerContributorHubspot } from 'services/zapier';
import { environment } from 'environment';

import css from './index.module.css';
import { availableTotalContribution, validationSchema } from './validationSchema';

const onSubmit = () => {};

export const CollectibleContributorsEditor = ({ value, getValueRef }: StepProps<ContributorsStepValue>) => {
  const form = useFormik<ContributorsStepValue>({
    initialValues: value ?? {
      contributors: [],
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit,
  });

  const { values, errors, setTouched, validateForm, handleSubmit, handleChange, setFieldValue } = form;
  const getCurrentUserProfile = useGetCurrentUserProfile();
  const getUserProfileQuery = useQuery(UserProfileQueryKey.getUserProfile, () => {
    return getCurrentUserProfile();
  });
  useEffect(() => {
    getValueRef.current = async () => {
      const errors = await validateForm();

      if (Object.keys(errors).length > 0) {
        setTouched(setNestedObjectValues(errors, true));
        return STEP_INVALID_VALUE;
      }

      return {
        contributors: values.contributors.filter(i => i && i.userProfileId && i.revenueShare),
      };
    };
  }, [getValueRef, setTouched, validateForm, values]);

  const allCreators = useCreators();

  const setContributors = useCallback(
    (friends: EthereumAddress[]) => {
      setFieldValue(
        'contributors',
        friends.map<CreateCollectibleTransactionReq_Contributor>(
          address =>
            values.contributors.find(i => i.userProfileId === address) || {
              userProfileId: address,
              revenueShare: '0',
            },
        ),
      );
    },
    [setFieldValue, values.contributors],
  );

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [currentUsername, setUsername] = useState('');
  const { progress, setProgress } = useProgress();
  const [alertProps, setAlertProps] = useState<AlertProps | undefined>();
  const [loadingScreenProps, setLoadingScreenProps] = useState<LoadingScreenProps | undefined>();
  const [isFriendsPickerOpen, showFriendsPicker, closeFriendsPicker] = useBoolean(false);
  const [isCreatorFormOpen, showCreatorForm, closeCreatorForm] = useBoolean(false);
  const { form: creatorForm, getFormValue } = useForm(creatorFormikConfig);
  const progressMessage = useMemo(() => {
    return progress === 1 ? 'Almost done...' : `Processing: ${progress != null ? Math.round(100 * progress) : null}%`;
  }, [progress]);

  const showErrorAlert = useCallback(() => {
    setLoadingScreenProps(undefined);
    setAlertProps({
      isSuccess: false,
      message: 'There was an issue processing your registration. Please try again.',
      onClose: () => setAlertProps(undefined),
    });
  }, []);

  const registerCreator = async () => {
    setLoading(true);
    setError(false);
    const account_id = encodeParam((+new Date()).toString(36).slice(-5) + Math.random().toString(36).substring(2, 5));
    let formValue;
    let address;
    try {
      //Validate username and address on database
      formValue = await getFormValue();
      const { username } = formValue;
      setUsername(username);
      //Set account
      const result = await setAccount(account_id);
      address = result.address;

      const additionalData: UserProfile_AdditionalData = {
        firstName: formValue.name,
        lastName: formValue.lastname,
        businessName: formValue.business,
        source: process.env.REACT_APP_DOMAIN,
      };
      await createCreatorProfile(address, username, undefined, additionalData);
      closeCreatorForm();
    } catch (error) {
      setLoading(false);
      error instanceof UsernameAlreadyExistsError ? setError(true) : setError(false);
      //logger.error(error);
      return;
    }
    setLoading(false);
    //Open alert modal
    setLoadingScreenProps({
      message: 'Please give us a moment while we finish creating your Contributor account.',
      isNotification: false,
    });

    setProgress(0);

    //Register user as creator
    try {
      await axios.get(environment.apiUrl + 'creator/add/' + formValue?.role + '/' + address);
    } catch (error) {
      //logger.error(error);
      showErrorAlert();
      return;
    }

    //Register user on hubspot
    /*setProgress(0.25);
    try {
      await registerContributorHubspot(account_id, {
        name: formValue?.name,
        lastname: formValue?.lastname,
        business: formValue?.business,
        industry: formValue?.industry,
        role: formValue?.role,
        eth_address: address,
        email: formValue?.email,
        phone: formValue?.phone,
        existingUser: getUserProfileQuery?.data?.nickname,
      });
    } catch (error) {
      logger.error(error);
      showErrorAlert();
      return;
    }*/

    //Register user on chargeMaster and setAccount
    setProgress(0.5);
    try {
      const roleEncoded = encodeParam(formValue?.role!.toLowerCase());
      await registerUser(address, roleEncoded, account_id);
    } catch (error) {
      //logger.error(error);
      showErrorAlert();
      return;
    }

    //Create a friend connection
    setProgress(0.75);
    try {
      await createFriend(address);
      const currentContributors = values.contributors;
      currentContributors.push({
        userProfileId: address,
        revenueShare: '0',
      });
      let contributors: string[] = [];
      currentContributors.forEach(currentContributor => {
        contributors.push(currentContributor.userProfileId);
      });
      setContributors(contributors);
    } catch (error) {
      //logger.error(error);
      showErrorAlert();
      return;
    }

    //Finish
    setProgress(1);
    log(address, Activity.AUTO_REGISTRATION, process.env.REACT_APP_DOMAIN);
    setLoadingScreenProps(undefined);
    setAlertProps({
      isSuccess: true,
      message: `Contributor successfully added!`,
      onClose: () => {
        setAlertProps(undefined);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit} className={css.wrapper}>
      <Header
        subtitle="Please select any necessary contributors from your partners list that you wish to share in the revenue and/or reporting for this NFT."
        isOptional
      />

      <div className={css.header}>
        <div className={css.tooltip}>
          <h3>Contributors</h3>
          <Tooltip content="Contributors revenue share is only tracked. This revenue share will not be distributed automatically based on the revenue shared entered.">
            <HelpIcon />
          </Tooltip>
        </div>
        <p>
          Enter the revenue share for your contributors. This revenue share is for primary market sells. It does not
          include secondary market sells.
        </p>
      </div>

      <div className={css.fieldList}>
        <FormikProvider value={form}>
          <FieldArray name="contributors">
            {({ remove, push }) =>
              values.contributors.map(({ userProfileId, revenueShare }, index) => (
                <div key={userProfileId} className={css.field}>
                  <ShareHolder address={userProfileId} />
                  <InputSlider
                    value={Number(revenueShare)}
                    step={0.1}
                    max={availableTotalContribution}
                    onChange={v => setFieldValue(`contributors.${index}.revenueShare`, String(v))}
                  />
                  <NumberInput
                    name={`contributors.${index}.revenueShare`}
                    min={0}
                    max={availableTotalContribution}
                    step={0.01}
                    value={Number(revenueShare)}
                    onChange={handleChange}
                  />
                </div>
              ))
            }
          </FieldArray>
        </FormikProvider>

        {typeof errors['youContributor' as 'contributors'] === 'string' && (
          <FieldError>{String(errors['youContributor' as 'contributors'])}</FieldError>
        )}

        <ButtonPrimary2 onClick={showFriendsPicker}>
          <PlusIcon size={12} />
          <Spacer width="6px" />
          Existing Contributor
        </ButtonPrimary2>

        {/*<ButtonPrimary className={css.button} onClick={showCreatorForm}>
          <PlusIcon size={12} />
          <Spacer width="6px" />
          Create New Contributor
        </ButtonPrimary>*/}

        {isFriendsPickerOpen && (
          <UserPickDialog
            options={allCreators}
            title="Add/Remove Contributors"
            value={values.contributors.map(i => i.userProfileId)}
            setValue={setContributors}
            onClose={closeFriendsPicker}
          />
        )}
        {isCreatorFormOpen && (
          <CreatorForm
            form={creatorForm}
            username={currentUsername}
            isContributor={true}
            isLoading={isLoading}
            isError={isError}
            onClick={registerCreator}
            onClose={closeCreatorForm}
          />
        )}
        {loadingScreenProps && (
          <LoadingScreen {...loadingScreenProps}>
            {progress != null && !loadingScreenProps.isNotification && (
              <ProgressBar message={progressMessage} progress={progress} />
            )}
          </LoadingScreen>
        )}
        {alertProps && <Alert {...alertProps} />}
      </div>
    </form>
  );
};
