import { FC, ReactNode } from 'react';

import css from './ErrorMessage.module.css';

interface ErrorMessageProps {
  children: ReactNode;
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ children }) =>  {
  return <div className={css.container}>{children}</div>;
};
