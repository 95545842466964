/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { Empty } from "./google/protobuf/empty";

export const protobufPackage = "";

export enum Role {
  _UNIVERSAL = 0,
  ATHLETE = 1,
  AGENCY = 2,
  UNIVERSITY = 3,
  ARTIST = 4,
  COLLECTIVE = 5,
  LICENSOR = 6,
  CHARITY = 8,
  SPONSOR = 16,
  ROYALTY = 256,
  UNRECOGNIZED = -1,
}

export interface UserRole {
  network: string;
  role: number;
}

export interface UserProfile {
  ethAddress: string;
  nickname: string;
  avatarCid: string;
  roles: UserRole[];
  additionalData: UserProfile_AdditionalData | undefined;
  isAccepted: boolean;
  stripeId: string;
  customerId: string;
}

export interface UserProfile_AdditionalData {
  firstName: string;
  lastName: string;
  businessName: string;
  source: string;
}

export interface CreateUserProfileReq {
  ethAddress: string;
  nickname: string;
  avatarCid: string;
  additionalData: UserProfile_AdditionalData | undefined;
}

export interface GetUserProfileReq {
  ethAddress: string;
}

export interface UpdateUserProfileReq {
  ethAddress: string;
  data: UpdateUserProfileReq_UserProfileData | undefined;
  isAccepted: boolean;
  stripeId: string;
  customerId: string;
}

export interface UpdateUserProfileReq_UserProfileData {
  nickname: string;
  avatarCid: string;
  additionalData: UserProfile_AdditionalData | undefined;
}

export interface GetRolesRes {
  roles: GetRolesRes_Role[];
}

export interface GetRolesRes_Role {
  role: number;
  roleName: string;
}

function createBaseUserRole(): UserRole {
  return { network: "", role: 0 };
}

export const UserRole = {
  encode(message: UserRole, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.network !== "") {
      writer.uint32(10).string(message.network);
    }
    if (message.role !== 0) {
      writer.uint32(16).int32(message.role);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserRole {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserRole();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.network = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.role = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UserRole>, I>>(base?: I): UserRole {
    return UserRole.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserRole>, I>>(object: I): UserRole {
    const message = createBaseUserRole();
    message.network = object.network ?? "";
    message.role = object.role ?? 0;
    return message;
  },
};

function createBaseUserProfile(): UserProfile {
  return {
    ethAddress: "",
    nickname: "",
    avatarCid: "",
    roles: [],
    additionalData: undefined,
    isAccepted: false,
    stripeId: "",
    customerId: "",
  };
}

export const UserProfile = {
  encode(message: UserProfile, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ethAddress !== "") {
      writer.uint32(10).string(message.ethAddress);
    }
    if (message.nickname !== "") {
      writer.uint32(18).string(message.nickname);
    }
    if (message.avatarCid !== "") {
      writer.uint32(26).string(message.avatarCid);
    }
    for (const v of message.roles) {
      UserRole.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.additionalData !== undefined) {
      UserProfile_AdditionalData.encode(message.additionalData, writer.uint32(42).fork()).ldelim();
    }
    if (message.isAccepted === true) {
      writer.uint32(48).bool(message.isAccepted);
    }
    if (message.stripeId !== "") {
      writer.uint32(58).string(message.stripeId);
    }
    if (message.customerId !== "") {
      writer.uint32(66).string(message.customerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserProfile {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserProfile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ethAddress = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nickname = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.avatarCid = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.roles.push(UserRole.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.additionalData = UserProfile_AdditionalData.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.isAccepted = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.stripeId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.customerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UserProfile>, I>>(base?: I): UserProfile {
    return UserProfile.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserProfile>, I>>(object: I): UserProfile {
    const message = createBaseUserProfile();
    message.ethAddress = object.ethAddress ?? "";
    message.nickname = object.nickname ?? "";
    message.avatarCid = object.avatarCid ?? "";
    message.roles = object.roles?.map((e) => UserRole.fromPartial(e)) || [];
    message.additionalData = (object.additionalData !== undefined && object.additionalData !== null)
      ? UserProfile_AdditionalData.fromPartial(object.additionalData)
      : undefined;
    message.isAccepted = object.isAccepted ?? false;
    message.stripeId = object.stripeId ?? "";
    message.customerId = object.customerId ?? "";
    return message;
  },
};

function createBaseUserProfile_AdditionalData(): UserProfile_AdditionalData {
  return { firstName: "", lastName: "", businessName: "", source: "" };
}

export const UserProfile_AdditionalData = {
  encode(message: UserProfile_AdditionalData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.firstName !== "") {
      writer.uint32(10).string(message.firstName);
    }
    if (message.lastName !== "") {
      writer.uint32(18).string(message.lastName);
    }
    if (message.businessName !== "") {
      writer.uint32(26).string(message.businessName);
    }
    if (message.source !== "") {
      writer.uint32(34).string(message.source);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserProfile_AdditionalData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserProfile_AdditionalData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.firstName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lastName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.businessName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.source = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UserProfile_AdditionalData>, I>>(base?: I): UserProfile_AdditionalData {
    return UserProfile_AdditionalData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserProfile_AdditionalData>, I>>(object: I): UserProfile_AdditionalData {
    const message = createBaseUserProfile_AdditionalData();
    message.firstName = object.firstName ?? "";
    message.lastName = object.lastName ?? "";
    message.businessName = object.businessName ?? "";
    message.source = object.source ?? "";
    return message;
  },
};

function createBaseCreateUserProfileReq(): CreateUserProfileReq {
  return { ethAddress: "", nickname: "", avatarCid: "", additionalData: undefined };
}

export const CreateUserProfileReq = {
  encode(message: CreateUserProfileReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ethAddress !== "") {
      writer.uint32(10).string(message.ethAddress);
    }
    if (message.nickname !== "") {
      writer.uint32(18).string(message.nickname);
    }
    if (message.avatarCid !== "") {
      writer.uint32(26).string(message.avatarCid);
    }
    if (message.additionalData !== undefined) {
      UserProfile_AdditionalData.encode(message.additionalData, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateUserProfileReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateUserProfileReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ethAddress = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nickname = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.avatarCid = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.additionalData = UserProfile_AdditionalData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateUserProfileReq>, I>>(base?: I): CreateUserProfileReq {
    return CreateUserProfileReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateUserProfileReq>, I>>(object: I): CreateUserProfileReq {
    const message = createBaseCreateUserProfileReq();
    message.ethAddress = object.ethAddress ?? "";
    message.nickname = object.nickname ?? "";
    message.avatarCid = object.avatarCid ?? "";
    message.additionalData = (object.additionalData !== undefined && object.additionalData !== null)
      ? UserProfile_AdditionalData.fromPartial(object.additionalData)
      : undefined;
    return message;
  },
};

function createBaseGetUserProfileReq(): GetUserProfileReq {
  return { ethAddress: "" };
}

export const GetUserProfileReq = {
  encode(message: GetUserProfileReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ethAddress !== "") {
      writer.uint32(10).string(message.ethAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserProfileReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserProfileReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ethAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetUserProfileReq>, I>>(base?: I): GetUserProfileReq {
    return GetUserProfileReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserProfileReq>, I>>(object: I): GetUserProfileReq {
    const message = createBaseGetUserProfileReq();
    message.ethAddress = object.ethAddress ?? "";
    return message;
  },
};

function createBaseUpdateUserProfileReq(): UpdateUserProfileReq {
  return { ethAddress: "", data: undefined, isAccepted: false, stripeId: "", customerId: "" };
}

export const UpdateUserProfileReq = {
  encode(message: UpdateUserProfileReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ethAddress !== "") {
      writer.uint32(10).string(message.ethAddress);
    }
    if (message.data !== undefined) {
      UpdateUserProfileReq_UserProfileData.encode(message.data, writer.uint32(18).fork()).ldelim();
    }
    if (message.isAccepted === true) {
      writer.uint32(24).bool(message.isAccepted);
    }
    if (message.stripeId !== "") {
      writer.uint32(34).string(message.stripeId);
    }
    if (message.customerId !== "") {
      writer.uint32(42).string(message.customerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserProfileReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUserProfileReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ethAddress = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.data = UpdateUserProfileReq_UserProfileData.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isAccepted = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.stripeId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.customerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateUserProfileReq>, I>>(base?: I): UpdateUserProfileReq {
    return UpdateUserProfileReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateUserProfileReq>, I>>(object: I): UpdateUserProfileReq {
    const message = createBaseUpdateUserProfileReq();
    message.ethAddress = object.ethAddress ?? "";
    message.data = (object.data !== undefined && object.data !== null)
      ? UpdateUserProfileReq_UserProfileData.fromPartial(object.data)
      : undefined;
    message.isAccepted = object.isAccepted ?? false;
    message.stripeId = object.stripeId ?? "";
    message.customerId = object.customerId ?? "";
    return message;
  },
};

function createBaseUpdateUserProfileReq_UserProfileData(): UpdateUserProfileReq_UserProfileData {
  return { nickname: "", avatarCid: "", additionalData: undefined };
}

export const UpdateUserProfileReq_UserProfileData = {
  encode(message: UpdateUserProfileReq_UserProfileData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nickname !== "") {
      writer.uint32(10).string(message.nickname);
    }
    if (message.avatarCid !== "") {
      writer.uint32(18).string(message.avatarCid);
    }
    if (message.additionalData !== undefined) {
      UserProfile_AdditionalData.encode(message.additionalData, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUserProfileReq_UserProfileData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUserProfileReq_UserProfileData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nickname = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.avatarCid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.additionalData = UserProfile_AdditionalData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateUserProfileReq_UserProfileData>, I>>(
    base?: I,
  ): UpdateUserProfileReq_UserProfileData {
    return UpdateUserProfileReq_UserProfileData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateUserProfileReq_UserProfileData>, I>>(
    object: I,
  ): UpdateUserProfileReq_UserProfileData {
    const message = createBaseUpdateUserProfileReq_UserProfileData();
    message.nickname = object.nickname ?? "";
    message.avatarCid = object.avatarCid ?? "";
    message.additionalData = (object.additionalData !== undefined && object.additionalData !== null)
      ? UserProfile_AdditionalData.fromPartial(object.additionalData)
      : undefined;
    return message;
  },
};

function createBaseGetRolesRes(): GetRolesRes {
  return { roles: [] };
}

export const GetRolesRes = {
  encode(message: GetRolesRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.roles) {
      GetRolesRes_Role.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetRolesRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRolesRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.roles.push(GetRolesRes_Role.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetRolesRes>, I>>(base?: I): GetRolesRes {
    return GetRolesRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRolesRes>, I>>(object: I): GetRolesRes {
    const message = createBaseGetRolesRes();
    message.roles = object.roles?.map((e) => GetRolesRes_Role.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetRolesRes_Role(): GetRolesRes_Role {
  return { role: 0, roleName: "" };
}

export const GetRolesRes_Role = {
  encode(message: GetRolesRes_Role, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.role !== 0) {
      writer.uint32(8).int32(message.role);
    }
    if (message.roleName !== "") {
      writer.uint32(18).string(message.roleName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetRolesRes_Role {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRolesRes_Role();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.role = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.roleName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetRolesRes_Role>, I>>(base?: I): GetRolesRes_Role {
    return GetRolesRes_Role.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRolesRes_Role>, I>>(object: I): GetRolesRes_Role {
    const message = createBaseGetRolesRes_Role();
    message.role = object.role ?? 0;
    message.roleName = object.roleName ?? "";
    return message;
  },
};

export interface UserProfileService {
  CreateUserProfile(request: DeepPartial<CreateUserProfileReq>, metadata?: grpc.Metadata): Promise<UserProfile>;
  GetUserProfile(request: DeepPartial<GetUserProfileReq>, metadata?: grpc.Metadata): Promise<UserProfile>;
  UpdateUserProfile(request: DeepPartial<UpdateUserProfileReq>, metadata?: grpc.Metadata): Promise<UserProfile>;
  GetRoles(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<GetRolesRes>;
  AcceptLicense(request: DeepPartial<GetUserProfileReq>, metadata?: grpc.Metadata): Promise<UserProfile>;
}

export class UserProfileServiceClientImpl implements UserProfileService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.CreateUserProfile = this.CreateUserProfile.bind(this);
    this.GetUserProfile = this.GetUserProfile.bind(this);
    this.UpdateUserProfile = this.UpdateUserProfile.bind(this);
    this.GetRoles = this.GetRoles.bind(this);
    this.AcceptLicense = this.AcceptLicense.bind(this);
  }

  CreateUserProfile(request: DeepPartial<CreateUserProfileReq>, metadata?: grpc.Metadata): Promise<UserProfile> {
    return this.rpc.unary(UserProfileServiceCreateUserProfileDesc, CreateUserProfileReq.fromPartial(request), metadata);
  }

  GetUserProfile(request: DeepPartial<GetUserProfileReq>, metadata?: grpc.Metadata): Promise<UserProfile> {
    return this.rpc.unary(UserProfileServiceGetUserProfileDesc, GetUserProfileReq.fromPartial(request), metadata);
  }

  UpdateUserProfile(request: DeepPartial<UpdateUserProfileReq>, metadata?: grpc.Metadata): Promise<UserProfile> {
    return this.rpc.unary(UserProfileServiceUpdateUserProfileDesc, UpdateUserProfileReq.fromPartial(request), metadata);
  }

  GetRoles(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<GetRolesRes> {
    return this.rpc.unary(UserProfileServiceGetRolesDesc, Empty.fromPartial(request), metadata);
  }

  AcceptLicense(request: DeepPartial<GetUserProfileReq>, metadata?: grpc.Metadata): Promise<UserProfile> {
    return this.rpc.unary(UserProfileServiceAcceptLicenseDesc, GetUserProfileReq.fromPartial(request), metadata);
  }
}

export const UserProfileServiceDesc = { serviceName: "UserProfileService" };

export const UserProfileServiceCreateUserProfileDesc: UnaryMethodDefinitionish = {
  methodName: "CreateUserProfile",
  service: UserProfileServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateUserProfileReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UserProfile.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UserProfileServiceGetUserProfileDesc: UnaryMethodDefinitionish = {
  methodName: "GetUserProfile",
  service: UserProfileServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetUserProfileReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UserProfile.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UserProfileServiceUpdateUserProfileDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateUserProfile",
  service: UserProfileServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateUserProfileReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UserProfile.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UserProfileServiceGetRolesDesc: UnaryMethodDefinitionish = {
  methodName: "GetRoles",
  service: UserProfileServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetRolesRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const UserProfileServiceAcceptLicenseDesc: UnaryMethodDefinitionish = {
  methodName: "AcceptLicense",
  service: UserProfileServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetUserProfileReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UserProfile.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
