import { IpfsCollectionInfo } from 'beasy-fe-commons';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';

import { ClipboardCopy } from 'components/clipboard';
import { ImageView } from 'components/image';
import { EmptyList } from 'components/layout';
import { PageHeader } from 'components/page';
import { ShareHolder } from 'components/user';

import { shortenAddress } from 'constants/helper';

import { useIpfsInfo } from 'hooks/useIpfs';

import { Collection } from 'protobuf/lib/collectionService';
import { RoyaltyHolder } from 'protobuf/lib/royalty';

import { getRoyaltyHolders } from 'services/royalty';

import css from './index.module.css';

interface Props {
  collection?: Collection;
}

export const CollectionDetails = ({ collection }: Props) => {
  const info = useIpfsInfo<IpfsCollectionInfo>(collection?.metadataCid);

  const royaltyReq = {
    collection: collection?.address!, // ! enabled
  };

  const royaltyQuery = useQuery(['getRoyaltyHolders', royaltyReq], () => getRoyaltyHolders(royaltyReq), {
    enabled: Boolean(collection),
  });

  const holders = royaltyQuery.data?.holders;

  return (
    <article className={css.wrapper}>
      {collection?.name ? <PageHeader title={collection?.name} /> : <Skeleton width={50} />}

      <section className={css.collection}>
        <figure>
          <ImageView src={info?.image} />
        </figure>
        <p>{info?.description ?? <Skeleton width={50} />}</p>
        <div className={css.royalty}>
          <h4>Royalty holders</h4>
          <div>
            {(holders || loadingHolders).map((i, index) => (
              <ShareHolder key={index} {...i} />
            ))}
            {holders?.length === 0 && <EmptyList message="There are no royalty holders for this collection." />}
          </div>
          <h4>Collection Address</h4>
          <p>
            <ClipboardCopy text={collection?.address}>{shortenAddress(collection?.address)}</ClipboardCopy>
          </p>
        </div>
      </section>
    </article>
  );
};

const loadingHolders: Partial<RoyaltyHolder>[] = [{}, {}, {}, {}, {}];
