import clsx from 'clsx';

import { PageTabItem } from '../TabItem';
import css from './index.module.css';

interface Props<T extends number> {
  items: { value: T; label: string }[];
  currentItem: T;
  maxAvailableItem: T;
  onNavigate: (value: T) => void;
  className?: string;
}

export const PageTabMenu = <T extends number>({
  items,
  currentItem,
  maxAvailableItem,
  onNavigate,
  className,
}: Props<T>) => {
  return (
    <ul className={clsx(css.wrapper, className)}>
      {items.map(({ value, label }, index) => (
        <PageTabItem
          key={value}
          isComplete={value < maxAvailableItem}
          isDisabled={value > maxAvailableItem}
          isSelected={value === currentItem}
          label={label}
          value={value}
          index={index}
          onSelect={onNavigate}
        />
      ))}
    </ul>
  );
};
