import { grpc } from '@improbable-eng/grpc-web';
import { captureException } from '@sentry/react';
import { TxRes } from 'protobuf/lib/chargeMaster';
import { GrpcWebImpl, TransactionData, MintMultipleReq, TransactionServiceClientImpl } from 'protobuf/lib/transaction';
import { MinimalTransactionInfo, PopulateTransactionReq } from 'protobuf/lib/transactionMessage';

import { environment } from 'environment';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const impl = new TransactionServiceClientImpl(rpc);

export const populateTransaction = async (req: PopulateTransactionReq) => {
  try {
    return await impl.populateTransaction(req);
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const metaTransaction = async (itemToTransact: TransactionData): Promise<TxRes> => {
  try {
    return await impl.metaTransaction(itemToTransact);
  } catch (error) {
    captureException(error, { extra: { itemToTransact } });
    throw error;
  }
};

export const estimateTransactionCost = async (req: MinimalTransactionInfo) => {
  try {
    return await impl.estimateTransactionCost(req);
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const mintNFT = async (req: MintMultipleReq) => {
  try {
    return await impl.MintMultiple(req);
  } catch (error) {
    captureException(error);
    throw error;
  }
};
