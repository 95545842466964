// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Attributes_section__jlUer {
  max-height: 275px;
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  overflow-y:auto;
}

.Attributes_sectionContent__bpGjT {
  min-height: 50px;
}

.Attributes_container__HSlKT {
  display: flex;
  justify-content: space-between;
  flex: 1 1;
  gap: 20px;
  margin-right: 10px;
}

.Attributes_content__0fcTm {
  width: 100%;
}

.Attributes_contentSmall__Gc1FL {
  width: 50%;
} 

.Attributes_contentInvisible__ERz8s {
  visibility: hidden;
  width: 0%;
}

.Attributes_buttons__b3VLS {
  margin-top: 35px;
}

.Attributes_wrapper__m7Jvm {
  margin-right: 20px;
}

.Attributes_list__gwubX {
  position: relative;
  border-radius: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/components/collectible/steps/Attributes/index.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAO;EACP,SAAS;EACT,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".section {\n  max-height: 275px;\n  width: 100%;\n  align-self: center;\n  display: flex;\n  flex-direction: column;\n  overflow-y:auto;\n}\n\n.sectionContent {\n  min-height: 50px;\n}\n\n.container {\n  display: flex;\n  justify-content: space-between;\n  flex: 1;\n  gap: 20px;\n  margin-right: 10px;\n}\n\n.content {\n  width: 100%;\n}\n\n.contentSmall {\n  width: 50%;\n} \n\n.contentInvisible {\n  visibility: hidden;\n  width: 0%;\n}\n\n.buttons {\n  margin-top: 35px;\n}\n\n.wrapper {\n  margin-right: 20px;\n}\n\n.list {\n  position: relative;\n  border-radius: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `Attributes_section__jlUer`,
	"sectionContent": `Attributes_sectionContent__bpGjT`,
	"container": `Attributes_container__HSlKT`,
	"content": `Attributes_content__0fcTm`,
	"contentSmall": `Attributes_contentSmall__Gc1FL`,
	"contentInvisible": `Attributes_contentInvisible__ERz8s`,
	"buttons": `Attributes_buttons__b3VLS`,
	"wrapper": `Attributes_wrapper__m7Jvm`,
	"list": `Attributes_list__gwubX`
};
export default ___CSS_LOADER_EXPORT___;
