// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Faq_content__iQAa8 {
  display: flex;
  align-items: flex-start;
  overflow-y: scroll;
}

.Faq_content__iQAa8 > ul {
  width: 100%;
}

.Faq_support__c8895 {
  display: flex;
  background-color: var(--alt-bg-color);
  padding: 20px 20px;
  margin-left: 20px;
  margin-right: 20px;
  min-width: 355px;
  grid-gap: 14px;
  gap: 14px;
  border-radius: 6px;
  align-items: center;
}

.Faq_support__c8895 > :first-child {
  min-height: 60px;
  min-width: 60px;
}

.Faq_info__JOYeN > p:last-child {
  color: var(--primary-color);
  word-break: break-all;
  text-overflow: ellipsis;
}

.Faq_item__9JHo1 {
  border-bottom: 1px solid var(--text-color-accent);
  padding: 18px 0 18px 0;
}

@media screen and (max-width: 1350px) {
  .Faq_content__iQAa8 {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    overflow-y: scroll;
  }
}`, "",{"version":3,"sources":["webpack://./src/screens/Faq/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;EACd,SAAS;EACT,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,2BAA2B;EAC3B,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EACE,iDAAiD;EACjD,sBAAsB;AACxB;;AAEA;EACE;IACE,aAAa;IACb,8BAA8B;IAC9B,uBAAuB;IACvB,kBAAkB;EACpB;AACF","sourcesContent":[".content {\n  display: flex;\n  align-items: flex-start;\n  overflow-y: scroll;\n}\n\n.content > ul {\n  width: 100%;\n}\n\n.support {\n  display: flex;\n  background-color: var(--alt-bg-color);\n  padding: 20px 20px;\n  margin-left: 20px;\n  margin-right: 20px;\n  min-width: 355px;\n  grid-gap: 14px;\n  gap: 14px;\n  border-radius: 6px;\n  align-items: center;\n}\n\n.support > :first-child {\n  min-height: 60px;\n  min-width: 60px;\n}\n\n.info > p:last-child {\n  color: var(--primary-color);\n  word-break: break-all;\n  text-overflow: ellipsis;\n}\n\n.item {\n  border-bottom: 1px solid var(--text-color-accent);\n  padding: 18px 0 18px 0;\n}\n\n@media screen and (max-width: 1350px) {\n  .content {\n    display: flex;\n    flex-direction: column-reverse;\n    align-items: flex-start;\n    overflow-y: scroll;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `Faq_content__iQAa8`,
	"support": `Faq_support__c8895`,
	"info": `Faq_info__JOYeN`,
	"item": `Faq_item__9JHo1`
};
export default ___CSS_LOADER_EXPORT___;
