/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { MinimalTransactionInfo } from "./transactionMessage";

export const protobufPackage = "";

/** @deprecated */
export interface GetCollectionsByCreatorReq {
  address: string;
}

export interface Collection {
  address: string;
  creator: string;
  name: string;
  symbol: string;
  metadataCid: string;
  network: string;
  chargeMaster: string;
}

/** @deprecated */
export interface GetCollectionsByCreatorRes {
  collections: Collection[];
}

export interface GetCollectionsReq {
  creator?: string | undefined;
  royaltyHolder?: string | undefined;
}

export interface GetCollectionsRes {
  collections: Collection[];
}

export interface GetCollectionByAddressReq {
  address: string;
}

export interface GetCollectionByAddressRes {
  collection: Collection | undefined;
}

export interface CreateCollectionReq {
  creator: string;
  name: string;
  symbol?: string | undefined;
  baseURI: string;
  metadataCid: string;
  network: string;
  royaltyHolders: CreateCollectionReq_RoyaltyHolder[];
  salt: string;
}

export interface CreateCollectionReq_RoyaltyHolder {
  userProfileId: string;
  royalty: string;
}

export interface CreateCollectionRes {
  transaction: MinimalTransactionInfo | undefined;
}

export interface PredictCollectionReq {
  creator: string;
  name: string;
  symbol: string;
  salt: string;
}

export interface PredictCollectionRes {
  collectionAddress: string;
  royaltyDistributorAddress: string;
}

function createBaseGetCollectionsByCreatorReq(): GetCollectionsByCreatorReq {
  return { address: "" };
}

export const GetCollectionsByCreatorReq = {
  encode(message: GetCollectionsByCreatorReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCollectionsByCreatorReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCollectionsByCreatorReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCollectionsByCreatorReq>, I>>(base?: I): GetCollectionsByCreatorReq {
    return GetCollectionsByCreatorReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCollectionsByCreatorReq>, I>>(object: I): GetCollectionsByCreatorReq {
    const message = createBaseGetCollectionsByCreatorReq();
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseCollection(): Collection {
  return { address: "", creator: "", name: "", symbol: "", metadataCid: "", network: "", chargeMaster: "" };
}

export const Collection = {
  encode(message: Collection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    if (message.creator !== "") {
      writer.uint32(18).string(message.creator);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.symbol !== "") {
      writer.uint32(34).string(message.symbol);
    }
    if (message.metadataCid !== "") {
      writer.uint32(42).string(message.metadataCid);
    }
    if (message.network !== "") {
      writer.uint32(50).string(message.network);
    }
    if (message.chargeMaster !== "") {
      writer.uint32(58).string(message.chargeMaster);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Collection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCollection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.creator = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.symbol = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.metadataCid = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.network = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.chargeMaster = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Collection>, I>>(base?: I): Collection {
    return Collection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Collection>, I>>(object: I): Collection {
    const message = createBaseCollection();
    message.address = object.address ?? "";
    message.creator = object.creator ?? "";
    message.name = object.name ?? "";
    message.symbol = object.symbol ?? "";
    message.metadataCid = object.metadataCid ?? "";
    message.network = object.network ?? "";
    message.chargeMaster = object.chargeMaster ?? "";
    return message;
  },
};

function createBaseGetCollectionsByCreatorRes(): GetCollectionsByCreatorRes {
  return { collections: [] };
}

export const GetCollectionsByCreatorRes = {
  encode(message: GetCollectionsByCreatorRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.collections) {
      Collection.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCollectionsByCreatorRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCollectionsByCreatorRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collections.push(Collection.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCollectionsByCreatorRes>, I>>(base?: I): GetCollectionsByCreatorRes {
    return GetCollectionsByCreatorRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCollectionsByCreatorRes>, I>>(object: I): GetCollectionsByCreatorRes {
    const message = createBaseGetCollectionsByCreatorRes();
    message.collections = object.collections?.map((e) => Collection.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetCollectionsReq(): GetCollectionsReq {
  return { creator: undefined, royaltyHolder: undefined };
}

export const GetCollectionsReq = {
  encode(message: GetCollectionsReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.creator !== undefined) {
      writer.uint32(10).string(message.creator);
    }
    if (message.royaltyHolder !== undefined) {
      writer.uint32(18).string(message.royaltyHolder);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCollectionsReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCollectionsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.creator = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.royaltyHolder = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCollectionsReq>, I>>(base?: I): GetCollectionsReq {
    return GetCollectionsReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCollectionsReq>, I>>(object: I): GetCollectionsReq {
    const message = createBaseGetCollectionsReq();
    message.creator = object.creator ?? undefined;
    message.royaltyHolder = object.royaltyHolder ?? undefined;
    return message;
  },
};

function createBaseGetCollectionsRes(): GetCollectionsRes {
  return { collections: [] };
}

export const GetCollectionsRes = {
  encode(message: GetCollectionsRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.collections) {
      Collection.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCollectionsRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCollectionsRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collections.push(Collection.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCollectionsRes>, I>>(base?: I): GetCollectionsRes {
    return GetCollectionsRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCollectionsRes>, I>>(object: I): GetCollectionsRes {
    const message = createBaseGetCollectionsRes();
    message.collections = object.collections?.map((e) => Collection.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetCollectionByAddressReq(): GetCollectionByAddressReq {
  return { address: "" };
}

export const GetCollectionByAddressReq = {
  encode(message: GetCollectionByAddressReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCollectionByAddressReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCollectionByAddressReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCollectionByAddressReq>, I>>(base?: I): GetCollectionByAddressReq {
    return GetCollectionByAddressReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCollectionByAddressReq>, I>>(object: I): GetCollectionByAddressReq {
    const message = createBaseGetCollectionByAddressReq();
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseGetCollectionByAddressRes(): GetCollectionByAddressRes {
  return { collection: undefined };
}

export const GetCollectionByAddressRes = {
  encode(message: GetCollectionByAddressRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.collection !== undefined) {
      Collection.encode(message.collection, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCollectionByAddressRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCollectionByAddressRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collection = Collection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCollectionByAddressRes>, I>>(base?: I): GetCollectionByAddressRes {
    return GetCollectionByAddressRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCollectionByAddressRes>, I>>(object: I): GetCollectionByAddressRes {
    const message = createBaseGetCollectionByAddressRes();
    message.collection = (object.collection !== undefined && object.collection !== null)
      ? Collection.fromPartial(object.collection)
      : undefined;
    return message;
  },
};

function createBaseCreateCollectionReq(): CreateCollectionReq {
  return {
    creator: "",
    name: "",
    symbol: undefined,
    baseURI: "",
    metadataCid: "",
    network: "",
    royaltyHolders: [],
    salt: "",
  };
}

export const CreateCollectionReq = {
  encode(message: CreateCollectionReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.symbol !== undefined) {
      writer.uint32(26).string(message.symbol);
    }
    if (message.baseURI !== "") {
      writer.uint32(34).string(message.baseURI);
    }
    if (message.metadataCid !== "") {
      writer.uint32(42).string(message.metadataCid);
    }
    if (message.network !== "") {
      writer.uint32(50).string(message.network);
    }
    for (const v of message.royaltyHolders) {
      CreateCollectionReq_RoyaltyHolder.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.salt !== "") {
      writer.uint32(66).string(message.salt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCollectionReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCollectionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.creator = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.symbol = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.baseURI = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.metadataCid = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.network = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.royaltyHolders.push(CreateCollectionReq_RoyaltyHolder.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.salt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateCollectionReq>, I>>(base?: I): CreateCollectionReq {
    return CreateCollectionReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateCollectionReq>, I>>(object: I): CreateCollectionReq {
    const message = createBaseCreateCollectionReq();
    message.creator = object.creator ?? "";
    message.name = object.name ?? "";
    message.symbol = object.symbol ?? undefined;
    message.baseURI = object.baseURI ?? "";
    message.metadataCid = object.metadataCid ?? "";
    message.network = object.network ?? "";
    message.royaltyHolders = object.royaltyHolders?.map((e) => CreateCollectionReq_RoyaltyHolder.fromPartial(e)) || [];
    message.salt = object.salt ?? "";
    return message;
  },
};

function createBaseCreateCollectionReq_RoyaltyHolder(): CreateCollectionReq_RoyaltyHolder {
  return { userProfileId: "", royalty: "" };
}

export const CreateCollectionReq_RoyaltyHolder = {
  encode(message: CreateCollectionReq_RoyaltyHolder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userProfileId !== "") {
      writer.uint32(10).string(message.userProfileId);
    }
    if (message.royalty !== "") {
      writer.uint32(18).string(message.royalty);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCollectionReq_RoyaltyHolder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCollectionReq_RoyaltyHolder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userProfileId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.royalty = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateCollectionReq_RoyaltyHolder>, I>>(
    base?: I,
  ): CreateCollectionReq_RoyaltyHolder {
    return CreateCollectionReq_RoyaltyHolder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateCollectionReq_RoyaltyHolder>, I>>(
    object: I,
  ): CreateCollectionReq_RoyaltyHolder {
    const message = createBaseCreateCollectionReq_RoyaltyHolder();
    message.userProfileId = object.userProfileId ?? "";
    message.royalty = object.royalty ?? "";
    return message;
  },
};

function createBaseCreateCollectionRes(): CreateCollectionRes {
  return { transaction: undefined };
}

export const CreateCollectionRes = {
  encode(message: CreateCollectionRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.transaction !== undefined) {
      MinimalTransactionInfo.encode(message.transaction, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCollectionRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCollectionRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transaction = MinimalTransactionInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateCollectionRes>, I>>(base?: I): CreateCollectionRes {
    return CreateCollectionRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateCollectionRes>, I>>(object: I): CreateCollectionRes {
    const message = createBaseCreateCollectionRes();
    message.transaction = (object.transaction !== undefined && object.transaction !== null)
      ? MinimalTransactionInfo.fromPartial(object.transaction)
      : undefined;
    return message;
  },
};

function createBasePredictCollectionReq(): PredictCollectionReq {
  return { creator: "", name: "", symbol: "", salt: "" };
}

export const PredictCollectionReq = {
  encode(message: PredictCollectionReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.creator !== "") {
      writer.uint32(10).string(message.creator);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.symbol !== "") {
      writer.uint32(26).string(message.symbol);
    }
    if (message.salt !== "") {
      writer.uint32(34).string(message.salt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PredictCollectionReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePredictCollectionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.creator = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.symbol = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.salt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<PredictCollectionReq>, I>>(base?: I): PredictCollectionReq {
    return PredictCollectionReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PredictCollectionReq>, I>>(object: I): PredictCollectionReq {
    const message = createBasePredictCollectionReq();
    message.creator = object.creator ?? "";
    message.name = object.name ?? "";
    message.symbol = object.symbol ?? "";
    message.salt = object.salt ?? "";
    return message;
  },
};

function createBasePredictCollectionRes(): PredictCollectionRes {
  return { collectionAddress: "", royaltyDistributorAddress: "" };
}

export const PredictCollectionRes = {
  encode(message: PredictCollectionRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.collectionAddress !== "") {
      writer.uint32(10).string(message.collectionAddress);
    }
    if (message.royaltyDistributorAddress !== "") {
      writer.uint32(18).string(message.royaltyDistributorAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PredictCollectionRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePredictCollectionRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectionAddress = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.royaltyDistributorAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<PredictCollectionRes>, I>>(base?: I): PredictCollectionRes {
    return PredictCollectionRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PredictCollectionRes>, I>>(object: I): PredictCollectionRes {
    const message = createBasePredictCollectionRes();
    message.collectionAddress = object.collectionAddress ?? "";
    message.royaltyDistributorAddress = object.royaltyDistributorAddress ?? "";
    return message;
  },
};

export interface CollectionService {
  PredictCollection(
    request: DeepPartial<PredictCollectionReq>,
    metadata?: grpc.Metadata,
  ): Promise<PredictCollectionRes>;
  CreateCollection(request: DeepPartial<CreateCollectionReq>, metadata?: grpc.Metadata): Promise<CreateCollectionRes>;
  GetCollectionByAddress(
    request: DeepPartial<GetCollectionByAddressReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetCollectionByAddressRes>;
  /** @deprecated */
  GetCollectionsByCreator(
    request: DeepPartial<GetCollectionsByCreatorReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetCollectionsByCreatorRes>;
  GetCollections(request: DeepPartial<GetCollectionsReq>, metadata?: grpc.Metadata): Promise<GetCollectionsRes>;
}

export class CollectionServiceClientImpl implements CollectionService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.PredictCollection = this.PredictCollection.bind(this);
    this.CreateCollection = this.CreateCollection.bind(this);
    this.GetCollectionByAddress = this.GetCollectionByAddress.bind(this);
    this.GetCollectionsByCreator = this.GetCollectionsByCreator.bind(this);
    this.GetCollections = this.GetCollections.bind(this);
  }

  PredictCollection(
    request: DeepPartial<PredictCollectionReq>,
    metadata?: grpc.Metadata,
  ): Promise<PredictCollectionRes> {
    return this.rpc.unary(CollectionServicePredictCollectionDesc, PredictCollectionReq.fromPartial(request), metadata);
  }

  CreateCollection(request: DeepPartial<CreateCollectionReq>, metadata?: grpc.Metadata): Promise<CreateCollectionRes> {
    return this.rpc.unary(CollectionServiceCreateCollectionDesc, CreateCollectionReq.fromPartial(request), metadata);
  }

  GetCollectionByAddress(
    request: DeepPartial<GetCollectionByAddressReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetCollectionByAddressRes> {
    return this.rpc.unary(
      CollectionServiceGetCollectionByAddressDesc,
      GetCollectionByAddressReq.fromPartial(request),
      metadata,
    );
  }

  GetCollectionsByCreator(
    request: DeepPartial<GetCollectionsByCreatorReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetCollectionsByCreatorRes> {
    return this.rpc.unary(
      CollectionServiceGetCollectionsByCreatorDesc,
      GetCollectionsByCreatorReq.fromPartial(request),
      metadata,
    );
  }

  GetCollections(request: DeepPartial<GetCollectionsReq>, metadata?: grpc.Metadata): Promise<GetCollectionsRes> {
    return this.rpc.unary(CollectionServiceGetCollectionsDesc, GetCollectionsReq.fromPartial(request), metadata);
  }
}

export const CollectionServiceDesc = { serviceName: "CollectionService" };

export const CollectionServicePredictCollectionDesc: UnaryMethodDefinitionish = {
  methodName: "PredictCollection",
  service: CollectionServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return PredictCollectionReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PredictCollectionRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CollectionServiceCreateCollectionDesc: UnaryMethodDefinitionish = {
  methodName: "CreateCollection",
  service: CollectionServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateCollectionReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateCollectionRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CollectionServiceGetCollectionByAddressDesc: UnaryMethodDefinitionish = {
  methodName: "GetCollectionByAddress",
  service: CollectionServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCollectionByAddressReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCollectionByAddressRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CollectionServiceGetCollectionsByCreatorDesc: UnaryMethodDefinitionish = {
  methodName: "GetCollectionsByCreator",
  service: CollectionServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCollectionsByCreatorReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCollectionsByCreatorRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CollectionServiceGetCollectionsDesc: UnaryMethodDefinitionish = {
  methodName: "GetCollections",
  service: CollectionServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCollectionsReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCollectionsRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
