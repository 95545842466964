// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Secondary_button__rHWHi {
  background-color: var(--secondary-color);
}

.Secondary_button__rHWHi:hover {
  background-color: var(--secondary-dark-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/button/Secondary/index.module.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C;;AAEA;EACE,6CAA6C;AAC/C","sourcesContent":[".button {\n  background-color: var(--secondary-color);\n}\n\n.button:hover {\n  background-color: var(--secondary-dark-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Secondary_button__rHWHi`
};
export default ___CSS_LOADER_EXPORT___;
