// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PartnerList_partnerListTable__Igi3A {
  height: 80%;
  overflow: scroll;
  position: relative;
}

.PartnerList_partnerListTable__Igi3A th {
  background: var(--grey-100);
}

.PartnerList_partnerListTable__Igi3A thead th {
  position: sticky;
  top: 0;
}

.PartnerList_partnerListTable__Igi3A table thead tr th {
  padding: 10px 0 10px 15px;
  text-align: left;
  background: var(--alt5-color);
}

.PartnerList_partnerListTable__Igi3A table tbody tr td {
  padding: 10px 10px 10px 45px;
  text-align: left;
  cursor: pointer;
  border-bottom: 1px solid var(--alt5-color);
  max-width: 120px;
}

.PartnerList_partnerListTable__Igi3A table {
  width: 100%;
  border-spacing: 0;
}

.PartnerList_partnerListTable__Igi3A table thead tr th div {
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/screens/PartnerList/index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,MAAM;AACR;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,4BAA4B;EAC5B,gBAAgB;EAChB,eAAe;EACf,0CAA0C;EAC1C,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".partnerListTable {\n  height: 80%;\n  overflow: scroll;\n  position: relative;\n}\n\n.partnerListTable th {\n  background: var(--grey-100);\n}\n\n.partnerListTable thead th {\n  position: sticky;\n  top: 0;\n}\n\n.partnerListTable table thead tr th {\n  padding: 10px 0 10px 15px;\n  text-align: left;\n  background: var(--alt5-color);\n}\n\n.partnerListTable table tbody tr td {\n  padding: 10px 10px 10px 45px;\n  text-align: left;\n  cursor: pointer;\n  border-bottom: 1px solid var(--alt5-color);\n  max-width: 120px;\n}\n\n.partnerListTable table {\n  width: 100%;\n  border-spacing: 0;\n}\n\n.partnerListTable table thead tr th div {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"partnerListTable": `PartnerList_partnerListTable__Igi3A`
};
export default ___CSS_LOADER_EXPORT___;
