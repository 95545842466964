import { useState } from 'react';

import { FilterDropdown } from 'components/input';
import { EmptyList } from 'components/layout';
import { PageArea, PageBody, PageHeader } from 'components/page';

import { useTermsOptions } from 'hooks/collectible';

import Item from './Item';
import css from './index.module.css';

const options = [
  { label: 'Ownership Agreement', value: 'ownership' },
  { label: 'License Agreement', value: 'license' },
];

function Agreements() {
  const { licenseOptions, ownershipOptions } = useTermsOptions();

  const [filterValue, setFilterValue] = useState(options[0].value);

  const items = filterValue === options[0].value ? ownershipOptions : licenseOptions;

  return (
    <PageArea>
      <PageHeader title="Business Agreements">
        <FilterDropdown value={filterValue} onChange={setFilterValue} options={options} />
      </PageHeader>
      <PageBody className={css.body}>
        {items.length ? (
          <div className={css.data}>
            <table>
              <tbody>
                {items.map(i => (
                  <Item key={i.cid} {...i} />
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <EmptyList
            message={`You do not have any <license/ownership> agreements.
                          You can add them to your NFTs as you create them.
                          Press "Create" to get started.`}
          />
        )}
      </PageBody>
    </PageArea>
  );
}

export default Agreements;
