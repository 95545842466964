// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Available_wrapper__5z8L\\+ {
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: var(--alt-bg-color);
  padding: 12px;
}

.Available_wrapper__5z8L\\+ > div > p {
  color: var(--alt10-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/collectible/Available/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,qCAAqC;EACrC,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  border-radius: 6px;\n  background-color: var(--alt-bg-color);\n  padding: 12px;\n}\n\n.wrapper > div > p {\n  color: var(--alt10-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Available_wrapper__5z8L+`
};
export default ___CSS_LOADER_EXPORT___;
