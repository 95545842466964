// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserProfileForm_wrapper__ymKt7 > header > h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.UserProfileForm_wrapper__ymKt7 > fieldset {
  border: none;
  display: grid;
  gap: 16px;
  grid-template-columns: 84px 220px 220px;
}

.UserProfileForm_wrapper__ymKt7 > fieldset > figure {
  grid-row: 1 / 4;
  height: 84px;
  border-radius: 8px;
}

.UserProfileForm_wrapper__ymKt7 > fieldset > .UserProfileForm_username__M9RtY {
  grid-column: 2 / 4;
}

.UserProfileForm_wrapper__ymKt7 > fieldset > .UserProfileForm_business__ZQrLF {
  grid-column: 2 / 4;
}

.UserProfileForm_wrapper__ymKt7 > fieldset > .UserProfileForm_error__H-nFg {
  grid-column: 1 / 4;
}
`, "",{"version":3,"sources":["webpack://./src/components/UserProfileForm/index.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,SAAS;EACT,uCAAuC;AACzC;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".wrapper > header > h3 {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 20px;\n}\n\n.wrapper > fieldset {\n  border: none;\n  display: grid;\n  gap: 16px;\n  grid-template-columns: 84px 220px 220px;\n}\n\n.wrapper > fieldset > figure {\n  grid-row: 1 / 4;\n  height: 84px;\n  border-radius: 8px;\n}\n\n.wrapper > fieldset > .username {\n  grid-column: 2 / 4;\n}\n\n.wrapper > fieldset > .business {\n  grid-column: 2 / 4;\n}\n\n.wrapper > fieldset > .error {\n  grid-column: 1 / 4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `UserProfileForm_wrapper__ymKt7`,
	"username": `UserProfileForm_username__M9RtY`,
	"business": `UserProfileForm_business__ZQrLF`,
	"error": `UserProfileForm_error__H-nFg`
};
export default ___CSS_LOADER_EXPORT___;
