export interface ChainConfig {
  name: string;
  url: string;
  explorer: string;
  coin: string;
}

export interface AppConfig {
  apiUrl: string;
  grpcUrl: string;
  scUrl: string;
  walletRelayUrl: string;
  ipfsApiUrl: string;
  ipfsGatewayUrl: string;
  isDevelopment: boolean;
  brokerUrl: string;
  walletUrl: string;
  externalLinks: {
    termsOfService: string;
    privacyPolicy: string;
    licenseAgreement: string;
  };
  walletconnectId: string;
  chains: Record<string, ChainConfig>;
}

declare const window: Window & { __config: AppConfig };

export const environment: AppConfig = {
  ...window.__config,
  isDevelopment: process.env.NODE_ENV === 'development',
};

// ! Do not do this!
// export const domain: 'beasy' | 'ndau' = process.env.REACT_APP_DOMAIN as any;
// ! it will not work inside `require` in production build
// ! It will not tree-shake properly for unknown reason
// ! Use {process.env.REACT_APP_DOMAIN} diectly.
