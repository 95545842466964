// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Registration_wrapper__PogS6 {
  display: flex;
  margin-top: 50px;
  justify-content: center;
}
.Registration_wrapper__PogS6 fieldset {
  border: 0;
  margin-top: 20px;
  margin-right: 24px;
  min-width: 280px;
  max-width: 280px;
}

.Registration_wrapper__PogS6 fieldset span {
  font-size: 12px;
}

/* FileInput has its own label */
.Registration_wrapper__PogS6 fieldset > label {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  color: var(--text-color);
}

.Registration_container__DnkQF {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Registration_details__znQN1 {
  display: flex;
  flex-direction: column;
}

.Registration_content__QbILj {
  display: flex;
  flex-direction: row;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.Registration_wallet__0x-or > input {
  font-size: 12px;
}

.Registration_list__XLpKA {
  border-radius: 6px;
}

.Registration_optional__xRoC3 {
  display: flex;
}

.Registration_optional__xRoC3 > i {
  color: var(--text-color-secondary);
}

.Registration_optional__xRoC3 > label {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  color: var(--text-color);
}
`, "",{"version":3,"sources":["webpack://./src/screens/Registration/steps/Registration/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,SAAS;EACT,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA,gCAAgC;AAChC;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAAsB;EAAtB,sBAAsB;AACxB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,wBAAwB;AAC1B","sourcesContent":[".wrapper {\n  display: flex;\n  margin-top: 50px;\n  justify-content: center;\n}\n.wrapper fieldset {\n  border: 0;\n  margin-top: 20px;\n  margin-right: 24px;\n  min-width: 280px;\n  max-width: 280px;\n}\n\n.wrapper fieldset span {\n  font-size: 12px;\n}\n\n/* FileInput has its own label */\n.wrapper fieldset > label {\n  font-size: 14px;\n  line-height: 20px;\n  margin-bottom: 4px;\n  display: flex;\n  align-items: center;\n  color: var(--text-color);\n}\n\n.container {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.details {\n  display: flex;\n  flex-direction: column;\n}\n\n.content {\n  display: flex;\n  flex-direction: row;\n  max-width: fit-content;\n}\n\n.wallet > input {\n  font-size: 12px;\n}\n\n.list {\n  border-radius: 6px;\n}\n\n.optional {\n  display: flex;\n}\n\n.optional > i {\n  color: var(--text-color-secondary);\n}\n\n.optional > label {\n  font-size: 14px;\n  line-height: 20px;\n  margin-bottom: 4px;\n  display: flex;\n  align-items: center;\n  color: var(--text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Registration_wrapper__PogS6`,
	"container": `Registration_container__DnkQF`,
	"details": `Registration_details__znQN1`,
	"content": `Registration_content__QbILj`,
	"wallet": `Registration_wallet__0x-or`,
	"list": `Registration_list__XLpKA`,
	"optional": `Registration_optional__xRoC3`
};
export default ___CSS_LOADER_EXPORT___;
