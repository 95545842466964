import { grpc } from '@improbable-eng/grpc-web';
import { captureException } from '@sentry/react';

import { assert } from '../assert';
import { hasJwtExpired } from './jwt';
import { store } from 'state';
import { useWalletConnectClient } from 'context/ClientContext';
import { clearWalletConnectV2 } from 'state/session';
import { useDispatch } from 'react-redux';
interface GrpcError {
  code: grpc.Code;
}

export function isGrpcError(error: unknown): error is GrpcError {
  const { code } = error as GrpcError;
  return code !== undefined && code >= 0 && code <= 16;
}


export const authenticated = async <R>(callback: (meta: grpc.Metadata) => R): Promise<R> => {
  const state = store.getState();
  const walletConnectV2 = state.session;
  const jwt = walletConnectV2.jwt;
  assert(jwt);

  let meta: grpc.Metadata;

  try {
    meta = new grpc.Metadata({ jwt });
    return await callback(meta);
  } catch (error) {
    captureException(error);

    if (isGrpcError(error) && error.code === grpc.Code.PermissionDenied) {
      store.dispatch(clearWalletConnectV2());
     const onboardingData = localStorage.getItem("ONBOARDING"); // Save the data you want to keep

    localStorage.clear(); // Clear all local storage

    if (onboardingData) {
        localStorage.setItem("ONBOARDING", onboardingData); // Restore the saved data
    }      
window.location.href = '/login';
    }

    throw error;
  }
};
