// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InvalidNetworkModal_header__DparN {
  display: flex;
  gap: 24px;
}

.InvalidNetworkModal_header__DparN > svg {
  margin-top: 2px;
  color: var(--orange);
}

.InvalidNetworkModal_label__9meUZ {
  margin: 20px 20px 20px 40px;
  color: var(--text-color);
}
`, "",{"version":3,"sources":["webpack://./src/screens/Login/InvalidNetworkModal/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,2BAA2B;EAC3B,wBAAwB;AAC1B","sourcesContent":[".header {\n  display: flex;\n  gap: 24px;\n}\n\n.header > svg {\n  margin-top: 2px;\n  color: var(--orange);\n}\n\n.label {\n  margin: 20px 20px 20px 40px;\n  color: var(--text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `InvalidNetworkModal_header__DparN`,
	"label": `InvalidNetworkModal_label__9meUZ`
};
export default ___CSS_LOADER_EXPORT___;
