import { FormikValues } from 'formik';
import { useCallback, useMemo } from 'react';

/** create named setters for Formiks setFieldValue */
export const useSetFieldValue = (setFieldValue: (name: string, value?: any, shouldValidate?: boolean) => void) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cache = useMemo<FormikValues>(() => ({}), [setFieldValue]); // clear cache every time setFieldValue changes

  const createSetter = useCallback(
    (name: string, shouldValidate?: boolean) => {
      if (cache[name]) return cache[name];

      const setter = (value?: any) => setFieldValue(name, value, shouldValidate);

      cache[name] = setter;

      return setter;
    },
    [cache, setFieldValue],
  );

  return createSetter;
};
