import { Form, Formik, FormikProps, setNestedObjectValues } from 'formik';

import { FreeTierText } from 'constants/free-tier';

import { StepProps, AgreementStepValue, STEP_INVALID_VALUE } from '../types';
import css from './index.module.css';
import validationSchema from './validationSchema';

const onSubmit = () => {};

const Agreement = ({ value, getValueRef }: StepProps<AgreementStepValue>) => {
  return (
    <div className={css.wrapper}>
      <Formik
        initialValues={
          value ?? {
            checked: false,
          }
        }
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          setTouched,
          validateForm,
          handleSubmit,
          handleChange,
        }: FormikProps<AgreementStepValue>) => {
          getValueRef.current = async () => {
            const errors = await validateForm();

            if (Object.keys(errors).length > 0) {
              setTouched(setNestedObjectValues(errors, true));
              return STEP_INVALID_VALUE;
            }

            return values;
          };

          return (
            <Form onSubmit={handleSubmit}>
              <div className={css.content}>
                <p>LICENSE AGREEMENT</p>

                <div className={css.license}>{FreeTierText()}</div>

                <div className={css.footer}>
                  <div className={css.input}>
                    <input type="checkbox" name="checked" onChange={handleChange('checked')}></input>
                    <p>I understand and agree to the terms of the license agreement</p>
                  </div>
                  {errors.checked && <span>{errors.checked}</span>}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Agreement;
