import ReactFloater from 'react-floater';

import { computedStyle } from 'services/style';

interface Props {
  content: React.ReactNode;
  children: React.ReactNode;
  isBig?: boolean;
}

export const Tooltip = ({ content, children, isBig }: Props) => (
  <ReactFloater content={content} event="hover" styles={isBig ? bigStyle : style}>
    {children}
  </ReactFloater>
);

const bgColor = computedStyle.getPropertyValue('--alt5-color');

const style = {
  arrow: {
    color: bgColor,
    length: 10,
    spread: 16,
  },
  container: {
    width: 200,
    padding: 8,
    borderRadius: 4,
    backgroundColor: bgColor,
    color: computedStyle.getPropertyValue('--body-color'),
  },
  content: {
    fontSize: 12,
    lineHeight: '16px',
  },
} as const;

const bigStyle = {
  arrow: {
    color: bgColor,
    length: 10,
    spread: 16,
  },
  container: {
    width: 400,
    padding: 8,
    borderRadius: 4,
    backgroundColor: bgColor,
    color: computedStyle.getPropertyValue('--body-color'),
  },
  content: {
    fontSize: 12,
    lineHeight: '16px',
  },
} as const;
