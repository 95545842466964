/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { TransactionReceipt } from "./transactionMessage";

export const protobufPackage = "";

export interface AccountReq {
  account: string;
}

export interface TierReq {
  price0: number;
  price1: number;
  price2: number;
  price3: number;
  cut1: number;
  cut2: number;
  cut3: number;
  numberOfAccounts: number;
  tierID: number;
}

export interface ChargeMasterUserReq {
  user: string;
  tierID: number;
  role: string;
  accountID: string;
}

export interface ChangeAccountTierReq {
  accountID: string;
  tierID: number;
}

export interface TxRes {
  transactionReceipt: TransactionReceipt | undefined;
}

export interface WeiRes {
  wei: number;
}

export interface FeeRes {
  fee: WeiRes | undefined;
}

export interface AddressReq {
  address: string;
}

export interface Bytes32Req {
  bytes32: string;
}

export interface Bytes32Res {
  bytes32: string;
}

export interface AccountIDRes {
  ID: Bytes32Res | undefined;
}

export interface AccountRes {
  accountData: Account | undefined;
}

export interface Account {
  nRegisteredAddresses: number;
  tierID: number;
  txNumber: number;
}

export interface AddUserToAccountAdminReq {
  address: string;
  accountID: string;
}

function createBaseAccountReq(): AccountReq {
  return { account: "" };
}

export const AccountReq = {
  encode(message: AccountReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.account !== "") {
      writer.uint32(10).string(message.account);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.account = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AccountReq>, I>>(base?: I): AccountReq {
    return AccountReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountReq>, I>>(object: I): AccountReq {
    const message = createBaseAccountReq();
    message.account = object.account ?? "";
    return message;
  },
};

function createBaseTierReq(): TierReq {
  return { price0: 0, price1: 0, price2: 0, price3: 0, cut1: 0, cut2: 0, cut3: 0, numberOfAccounts: 0, tierID: 0 };
}

export const TierReq = {
  encode(message: TierReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.price0 !== 0) {
      writer.uint32(8).int64(message.price0);
    }
    if (message.price1 !== 0) {
      writer.uint32(16).int64(message.price1);
    }
    if (message.price2 !== 0) {
      writer.uint32(24).int64(message.price2);
    }
    if (message.price3 !== 0) {
      writer.uint32(32).int64(message.price3);
    }
    if (message.cut1 !== 0) {
      writer.uint32(40).int64(message.cut1);
    }
    if (message.cut2 !== 0) {
      writer.uint32(48).int64(message.cut2);
    }
    if (message.cut3 !== 0) {
      writer.uint32(56).int64(message.cut3);
    }
    if (message.numberOfAccounts !== 0) {
      writer.uint32(64).int32(message.numberOfAccounts);
    }
    if (message.tierID !== 0) {
      writer.uint32(72).int32(message.tierID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TierReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTierReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.price0 = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.price1 = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.price2 = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.price3 = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.cut1 = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.cut2 = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.cut3 = longToNumber(reader.int64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.numberOfAccounts = reader.int32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.tierID = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<TierReq>, I>>(base?: I): TierReq {
    return TierReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TierReq>, I>>(object: I): TierReq {
    const message = createBaseTierReq();
    message.price0 = object.price0 ?? 0;
    message.price1 = object.price1 ?? 0;
    message.price2 = object.price2 ?? 0;
    message.price3 = object.price3 ?? 0;
    message.cut1 = object.cut1 ?? 0;
    message.cut2 = object.cut2 ?? 0;
    message.cut3 = object.cut3 ?? 0;
    message.numberOfAccounts = object.numberOfAccounts ?? 0;
    message.tierID = object.tierID ?? 0;
    return message;
  },
};

function createBaseChargeMasterUserReq(): ChargeMasterUserReq {
  return { user: "", tierID: 0, role: "", accountID: "" };
}

export const ChargeMasterUserReq = {
  encode(message: ChargeMasterUserReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.user !== "") {
      writer.uint32(10).string(message.user);
    }
    if (message.tierID !== 0) {
      writer.uint32(16).int32(message.tierID);
    }
    if (message.role !== "") {
      writer.uint32(26).string(message.role);
    }
    if (message.accountID !== "") {
      writer.uint32(34).string(message.accountID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChargeMasterUserReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChargeMasterUserReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.user = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.tierID = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.role = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.accountID = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ChargeMasterUserReq>, I>>(base?: I): ChargeMasterUserReq {
    return ChargeMasterUserReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChargeMasterUserReq>, I>>(object: I): ChargeMasterUserReq {
    const message = createBaseChargeMasterUserReq();
    message.user = object.user ?? "";
    message.tierID = object.tierID ?? 0;
    message.role = object.role ?? "";
    message.accountID = object.accountID ?? "";
    return message;
  },
};

function createBaseChangeAccountTierReq(): ChangeAccountTierReq {
  return { accountID: "", tierID: 0 };
}

export const ChangeAccountTierReq = {
  encode(message: ChangeAccountTierReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountID !== "") {
      writer.uint32(10).string(message.accountID);
    }
    if (message.tierID !== 0) {
      writer.uint32(16).int32(message.tierID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChangeAccountTierReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChangeAccountTierReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountID = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.tierID = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ChangeAccountTierReq>, I>>(base?: I): ChangeAccountTierReq {
    return ChangeAccountTierReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChangeAccountTierReq>, I>>(object: I): ChangeAccountTierReq {
    const message = createBaseChangeAccountTierReq();
    message.accountID = object.accountID ?? "";
    message.tierID = object.tierID ?? 0;
    return message;
  },
};

function createBaseTxRes(): TxRes {
  return { transactionReceipt: undefined };
}

export const TxRes = {
  encode(message: TxRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.transactionReceipt !== undefined) {
      TransactionReceipt.encode(message.transactionReceipt, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TxRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTxRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionReceipt = TransactionReceipt.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<TxRes>, I>>(base?: I): TxRes {
    return TxRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TxRes>, I>>(object: I): TxRes {
    const message = createBaseTxRes();
    message.transactionReceipt = (object.transactionReceipt !== undefined && object.transactionReceipt !== null)
      ? TransactionReceipt.fromPartial(object.transactionReceipt)
      : undefined;
    return message;
  },
};

function createBaseWeiRes(): WeiRes {
  return { wei: 0 };
}

export const WeiRes = {
  encode(message: WeiRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.wei !== 0) {
      writer.uint32(8).int64(message.wei);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WeiRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWeiRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.wei = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<WeiRes>, I>>(base?: I): WeiRes {
    return WeiRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WeiRes>, I>>(object: I): WeiRes {
    const message = createBaseWeiRes();
    message.wei = object.wei ?? 0;
    return message;
  },
};

function createBaseFeeRes(): FeeRes {
  return { fee: undefined };
}

export const FeeRes = {
  encode(message: FeeRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fee !== undefined) {
      WeiRes.encode(message.fee, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FeeRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFeeRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fee = WeiRes.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<FeeRes>, I>>(base?: I): FeeRes {
    return FeeRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FeeRes>, I>>(object: I): FeeRes {
    const message = createBaseFeeRes();
    message.fee = (object.fee !== undefined && object.fee !== null) ? WeiRes.fromPartial(object.fee) : undefined;
    return message;
  },
};

function createBaseAddressReq(): AddressReq {
  return { address: "" };
}

export const AddressReq = {
  encode(message: AddressReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddressReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddressReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AddressReq>, I>>(base?: I): AddressReq {
    return AddressReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddressReq>, I>>(object: I): AddressReq {
    const message = createBaseAddressReq();
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseBytes32Req(): Bytes32Req {
  return { bytes32: "" };
}

export const Bytes32Req = {
  encode(message: Bytes32Req, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bytes32 !== "") {
      writer.uint32(10).string(message.bytes32);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Bytes32Req {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBytes32Req();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bytes32 = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Bytes32Req>, I>>(base?: I): Bytes32Req {
    return Bytes32Req.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Bytes32Req>, I>>(object: I): Bytes32Req {
    const message = createBaseBytes32Req();
    message.bytes32 = object.bytes32 ?? "";
    return message;
  },
};

function createBaseBytes32Res(): Bytes32Res {
  return { bytes32: "" };
}

export const Bytes32Res = {
  encode(message: Bytes32Res, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bytes32 !== "") {
      writer.uint32(10).string(message.bytes32);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Bytes32Res {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBytes32Res();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bytes32 = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Bytes32Res>, I>>(base?: I): Bytes32Res {
    return Bytes32Res.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Bytes32Res>, I>>(object: I): Bytes32Res {
    const message = createBaseBytes32Res();
    message.bytes32 = object.bytes32 ?? "";
    return message;
  },
};

function createBaseAccountIDRes(): AccountIDRes {
  return { ID: undefined };
}

export const AccountIDRes = {
  encode(message: AccountIDRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ID !== undefined) {
      Bytes32Res.encode(message.ID, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountIDRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountIDRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ID = Bytes32Res.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AccountIDRes>, I>>(base?: I): AccountIDRes {
    return AccountIDRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountIDRes>, I>>(object: I): AccountIDRes {
    const message = createBaseAccountIDRes();
    message.ID = (object.ID !== undefined && object.ID !== null) ? Bytes32Res.fromPartial(object.ID) : undefined;
    return message;
  },
};

function createBaseAccountRes(): AccountRes {
  return { accountData: undefined };
}

export const AccountRes = {
  encode(message: AccountRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountData !== undefined) {
      Account.encode(message.accountData, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccountRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccountRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountData = Account.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AccountRes>, I>>(base?: I): AccountRes {
    return AccountRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AccountRes>, I>>(object: I): AccountRes {
    const message = createBaseAccountRes();
    message.accountData = (object.accountData !== undefined && object.accountData !== null)
      ? Account.fromPartial(object.accountData)
      : undefined;
    return message;
  },
};

function createBaseAccount(): Account {
  return { nRegisteredAddresses: 0, tierID: 0, txNumber: 0 };
}

export const Account = {
  encode(message: Account, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nRegisteredAddresses !== 0) {
      writer.uint32(8).int32(message.nRegisteredAddresses);
    }
    if (message.tierID !== 0) {
      writer.uint32(16).int32(message.tierID);
    }
    if (message.txNumber !== 0) {
      writer.uint32(24).int32(message.txNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Account {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.nRegisteredAddresses = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.tierID = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.txNumber = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Account>, I>>(base?: I): Account {
    return Account.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Account>, I>>(object: I): Account {
    const message = createBaseAccount();
    message.nRegisteredAddresses = object.nRegisteredAddresses ?? 0;
    message.tierID = object.tierID ?? 0;
    message.txNumber = object.txNumber ?? 0;
    return message;
  },
};

function createBaseAddUserToAccountAdminReq(): AddUserToAccountAdminReq {
  return { address: "", accountID: "" };
}

export const AddUserToAccountAdminReq = {
  encode(message: AddUserToAccountAdminReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    if (message.accountID !== "") {
      writer.uint32(18).string(message.accountID);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddUserToAccountAdminReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddUserToAccountAdminReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountID = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AddUserToAccountAdminReq>, I>>(base?: I): AddUserToAccountAdminReq {
    return AddUserToAccountAdminReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddUserToAccountAdminReq>, I>>(object: I): AddUserToAccountAdminReq {
    const message = createBaseAddUserToAccountAdminReq();
    message.address = object.address ?? "";
    message.accountID = object.accountID ?? "";
    return message;
  },
};

export interface ChargeMasterService {
  UpdateReceiver(request: DeepPartial<AccountReq>, metadata?: grpc.Metadata): Promise<TxRes>;
  CreateTier(request: DeepPartial<TierReq>, metadata?: grpc.Metadata): Promise<TxRes>;
  UpdateTier(request: DeepPartial<TierReq>, metadata?: grpc.Metadata): Promise<TxRes>;
  RegisterUser(request: DeepPartial<ChargeMasterUserReq>, metadata?: grpc.Metadata): Promise<TxRes>;
  ChangeAccountTier(request: DeepPartial<ChangeAccountTierReq>, metadata?: grpc.Metadata): Promise<TxRes>;
  AddUserToMyAccount(request: DeepPartial<AccountReq>, metadata?: grpc.Metadata): Promise<TxRes>;
  DeleteUserToMyAccount(request: DeepPartial<AccountReq>, metadata?: grpc.Metadata): Promise<TxRes>;
  GetFee(request: DeepPartial<AccountReq>, metadata?: grpc.Metadata): Promise<FeeRes>;
  GetAccountData(request: DeepPartial<AddressReq>, metadata?: grpc.Metadata): Promise<AccountRes>;
  GetUserAccount(request: DeepPartial<Bytes32Req>, metadata?: grpc.Metadata): Promise<AccountIDRes>;
  AddUserToAccountAdmin(request: DeepPartial<AddUserToAccountAdminReq>, metadata?: grpc.Metadata): Promise<TxRes>;
}

export class ChargeMasterServiceClientImpl implements ChargeMasterService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.UpdateReceiver = this.UpdateReceiver.bind(this);
    this.CreateTier = this.CreateTier.bind(this);
    this.UpdateTier = this.UpdateTier.bind(this);
    this.RegisterUser = this.RegisterUser.bind(this);
    this.ChangeAccountTier = this.ChangeAccountTier.bind(this);
    this.AddUserToMyAccount = this.AddUserToMyAccount.bind(this);
    this.DeleteUserToMyAccount = this.DeleteUserToMyAccount.bind(this);
    this.GetFee = this.GetFee.bind(this);
    this.GetAccountData = this.GetAccountData.bind(this);
    this.GetUserAccount = this.GetUserAccount.bind(this);
    this.AddUserToAccountAdmin = this.AddUserToAccountAdmin.bind(this);
  }

  UpdateReceiver(request: DeepPartial<AccountReq>, metadata?: grpc.Metadata): Promise<TxRes> {
    return this.rpc.unary(ChargeMasterServiceUpdateReceiverDesc, AccountReq.fromPartial(request), metadata);
  }

  CreateTier(request: DeepPartial<TierReq>, metadata?: grpc.Metadata): Promise<TxRes> {
    return this.rpc.unary(ChargeMasterServiceCreateTierDesc, TierReq.fromPartial(request), metadata);
  }

  UpdateTier(request: DeepPartial<TierReq>, metadata?: grpc.Metadata): Promise<TxRes> {
    return this.rpc.unary(ChargeMasterServiceUpdateTierDesc, TierReq.fromPartial(request), metadata);
  }

  RegisterUser(request: DeepPartial<ChargeMasterUserReq>, metadata?: grpc.Metadata): Promise<TxRes> {
    return this.rpc.unary(ChargeMasterServiceRegisterUserDesc, ChargeMasterUserReq.fromPartial(request), metadata);
  }

  ChangeAccountTier(request: DeepPartial<ChangeAccountTierReq>, metadata?: grpc.Metadata): Promise<TxRes> {
    return this.rpc.unary(
      ChargeMasterServiceChangeAccountTierDesc,
      ChangeAccountTierReq.fromPartial(request),
      metadata,
    );
  }

  AddUserToMyAccount(request: DeepPartial<AccountReq>, metadata?: grpc.Metadata): Promise<TxRes> {
    return this.rpc.unary(ChargeMasterServiceAddUserToMyAccountDesc, AccountReq.fromPartial(request), metadata);
  }

  DeleteUserToMyAccount(request: DeepPartial<AccountReq>, metadata?: grpc.Metadata): Promise<TxRes> {
    return this.rpc.unary(ChargeMasterServiceDeleteUserToMyAccountDesc, AccountReq.fromPartial(request), metadata);
  }

  GetFee(request: DeepPartial<AccountReq>, metadata?: grpc.Metadata): Promise<FeeRes> {
    return this.rpc.unary(ChargeMasterServiceGetFeeDesc, AccountReq.fromPartial(request), metadata);
  }

  GetAccountData(request: DeepPartial<AddressReq>, metadata?: grpc.Metadata): Promise<AccountRes> {
    return this.rpc.unary(ChargeMasterServiceGetAccountDataDesc, AddressReq.fromPartial(request), metadata);
  }

  GetUserAccount(request: DeepPartial<Bytes32Req>, metadata?: grpc.Metadata): Promise<AccountIDRes> {
    return this.rpc.unary(ChargeMasterServiceGetUserAccountDesc, Bytes32Req.fromPartial(request), metadata);
  }

  AddUserToAccountAdmin(request: DeepPartial<AddUserToAccountAdminReq>, metadata?: grpc.Metadata): Promise<TxRes> {
    return this.rpc.unary(
      ChargeMasterServiceAddUserToAccountAdminDesc,
      AddUserToAccountAdminReq.fromPartial(request),
      metadata,
    );
  }
}

export const ChargeMasterServiceDesc = { serviceName: "ChargeMasterService" };

export const ChargeMasterServiceUpdateReceiverDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateReceiver",
  service: ChargeMasterServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AccountReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TxRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ChargeMasterServiceCreateTierDesc: UnaryMethodDefinitionish = {
  methodName: "CreateTier",
  service: ChargeMasterServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return TierReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TxRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ChargeMasterServiceUpdateTierDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateTier",
  service: ChargeMasterServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return TierReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TxRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ChargeMasterServiceRegisterUserDesc: UnaryMethodDefinitionish = {
  methodName: "RegisterUser",
  service: ChargeMasterServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ChargeMasterUserReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TxRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ChargeMasterServiceChangeAccountTierDesc: UnaryMethodDefinitionish = {
  methodName: "ChangeAccountTier",
  service: ChargeMasterServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ChangeAccountTierReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TxRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ChargeMasterServiceAddUserToMyAccountDesc: UnaryMethodDefinitionish = {
  methodName: "AddUserToMyAccount",
  service: ChargeMasterServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AccountReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TxRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ChargeMasterServiceDeleteUserToMyAccountDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteUserToMyAccount",
  service: ChargeMasterServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AccountReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TxRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ChargeMasterServiceGetFeeDesc: UnaryMethodDefinitionish = {
  methodName: "GetFee",
  service: ChargeMasterServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AccountReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FeeRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ChargeMasterServiceGetAccountDataDesc: UnaryMethodDefinitionish = {
  methodName: "GetAccountData",
  service: ChargeMasterServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddressReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AccountRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ChargeMasterServiceGetUserAccountDesc: UnaryMethodDefinitionish = {
  methodName: "GetUserAccount",
  service: ChargeMasterServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Bytes32Req.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AccountIDRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ChargeMasterServiceAddUserToAccountAdminDesc: UnaryMethodDefinitionish = {
  methodName: "AddUserToAccountAdmin",
  service: ChargeMasterServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AddUserToAccountAdminReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TxRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
