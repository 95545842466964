// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ClearableFile_wrapper__Jt6rP {
  display: flex;
  color: var(--text-color-accent);
  border-radius: 6px 0px 0px 6px;
}

.ClearableFile_clearable__VkA3N {
  flex: 1 1;
  min-width: 80%;
  border-radius: 6px 0px 0px 6px;
}

.ClearableFile_wrapper__Jt6rP > label {
  height: var(--input-min-height);
  line-height: var(--input-min-height);
  background: var(--alt2-color);
  color: var(--alt3-color);
  font-weight: 500;
  font-size: 14px;
  padding: 0 15px;
  cursor: pointer;
  border-radius: 0px 6px 6px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ClearableFile_wrapper__Jt6rP > label:hover {
  background: var(--primary-color);
  color: var(--body-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/input/ClearableFile/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+BAA+B;EAC/B,8BAA8B;AAChC;;AAEA;EACE,SAAO;EACP,cAAc;EACd,8BAA8B;AAChC;;AAEA;EACE,+BAA+B;EAC/B,oCAAoC;EACpC,6BAA6B;EAC7B,wBAAwB;EACxB,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,eAAe;EACf,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,gCAAgC;EAChC,wBAAwB;AAC1B","sourcesContent":[".wrapper {\n  display: flex;\n  color: var(--text-color-accent);\n  border-radius: 6px 0px 0px 6px;\n}\n\n.clearable {\n  flex: 1;\n  min-width: 80%;\n  border-radius: 6px 0px 0px 6px;\n}\n\n.wrapper > label {\n  height: var(--input-min-height);\n  line-height: var(--input-min-height);\n  background: var(--alt2-color);\n  color: var(--alt3-color);\n  font-weight: 500;\n  font-size: 14px;\n  padding: 0 15px;\n  cursor: pointer;\n  border-radius: 0px 6px 6px 0px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.wrapper > label:hover {\n  background: var(--primary-color);\n  color: var(--body-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ClearableFile_wrapper__Jt6rP`,
	"clearable": `ClearableFile_clearable__VkA3N`
};
export default ___CSS_LOADER_EXPORT___;
