// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DetailsEditor_wrapper__x2HIT {
  margin-right: 20px;
}

.DetailsEditor_quantityBlock__YAs6I {
  display: flex;
}

.DetailsEditor_container__G3n3P {
  display: flex;
  justify-content: space-between;
  flex: 1 1;
}

.DetailsEditor_content__F9L1h {
  width: 49%;
}
`, "",{"version":3,"sources":["webpack://./src/components/collectible/DetailsEditor/index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAO;AACT;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".wrapper {\n  margin-right: 20px;\n}\n\n.quantityBlock {\n  display: flex;\n}\n\n.container {\n  display: flex;\n  justify-content: space-between;\n  flex: 1;\n}\n\n.content {\n  width: 49%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `DetailsEditor_wrapper__x2HIT`,
	"quantityBlock": `DetailsEditor_quantityBlock__YAs6I`,
	"container": `DetailsEditor_container__G3n3P`,
	"content": `DetailsEditor_content__F9L1h`
};
export default ___CSS_LOADER_EXPORT___;
