import Graph from 'assets/images/ndau/graph-going-up.svg';
import HandIcon from 'assets/images/ndau/hand-holding-money.svg';
import Diagram from 'assets/images/ndau/ndau-diagram.png';
import StackIcon from 'assets/images/ndau/stack-of-coins.svg';

import { Header } from 'components/collectible';

import { StepProps, TypeStepValue } from '../../types';
import css from './index.module.css';

const Type = ({ getValueRef }: StepProps<TypeStepValue>) => {
  getValueRef.current = async () => true; // do not delete!

  return (
    <div>
      <Header />
      <div className={css.info}>
        <img src={Diagram} alt=""></img>
        <div className={css.details}>
          <h3>COMING SOON</h3>
          <p>
            Creators, Collaborators, Promoters, Distributors, Charities, can all receive their royalty through their
            unique ndau address.
          </p>
          <div className={css.items}>
            <img src={HandIcon} alt=""></img>
            <b>Receive NFT sales in ndau and keep your earnings in long-term store of value</b>
          </div>
          <div className={css.items}>
            <img src={Graph} alt=""></img>
            <b>Earn yields on your NFT earnings by simply holding your ndau in your wallet</b>
          </div>
          <div className={css.items}>
            <img src={StackIcon} alt=""></img>
            <b>User your new ndau yields to pay for new NFT minting fees</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Type;
