import clsx from 'clsx';
import { ReactNode } from 'react';

import css from './index.module.css';

interface Props {
  message?: ReactNode;
  border?: boolean;
}

export const EmptyList = ({ message = 'There are no items in this list.', border }: Props) => {
  return <div className={clsx(css.message, { [css.border]: border })}>{message}</div>;
};
