import clsx from 'clsx';

import { IconProps } from '../types';
import css from './index.module.css';

const ASPECT_RATIO = 1;

export const IconCopy = ({ className, size = 20, onClick }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(css.icon, className)}
    onClick={onClick}
    width={size}
    height={size / ASPECT_RATIO}
    viewBox="0 0 20 20"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.6 8.73333C9.12135 8.73333 8.73333 9.12135 8.73333 9.6V17.4C8.73333 17.8786 9.12135 18.2667 9.6 18.2667H17.4C17.8786 18.2667 18.2667 17.8786 18.2667 17.4V9.6C18.2667 9.12135 17.8786 8.73333 17.4 8.73333H9.6ZM7 9.6C7 8.16406 8.16406 7 9.6 7H17.4C18.8359 7 20 8.16406 20 9.6V17.4C20 18.8359 18.8359 20 17.4 20H9.6C8.16406 20 7 18.8359 7 17.4V9.6Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.6 1.73333C2.37015 1.73333 2.14971 1.82464 1.98717 1.98717C1.82464 2.14971 1.73333 2.37015 1.73333 2.6V10.4C1.73333 10.6299 1.82464 10.8503 1.98717 11.0128C2.14971 11.1754 2.37015 11.2667 2.6 11.2667H3.46667C3.94531 11.2667 4.33333 11.6547 4.33333 12.1333C4.33333 12.612 3.94531 13 3.46667 13H2.6C1.91044 13 1.24912 12.7261 0.761522 12.2385C0.273928 11.7509 0 11.0896 0 10.4V2.6C0 1.91044 0.273928 1.24912 0.761522 0.761522C1.24912 0.273928 1.91044 0 2.6 0H10.4C11.0896 0 11.7509 0.273928 12.2385 0.761522C12.7261 1.24912 13 1.91044 13 2.6V3.46667C13 3.94531 12.612 4.33333 12.1333 4.33333C11.6547 4.33333 11.2667 3.94531 11.2667 3.46667V2.6C11.2667 2.37015 11.1754 2.14971 11.0128 1.98717C10.8503 1.82464 10.6299 1.73333 10.4 1.73333H2.6Z"
    />
  </svg>
);
