import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import { CollectionCreateRoute } from './Create';
import { CollectionListRoute } from './List';
import { CollectionViewRoute } from './View';

export const CollectionRoute = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route path={`${match.url}/create`} component={CollectionCreateRoute} />
      <Route path={`${match.url}/list`} component={CollectionListRoute} />
      <Route path={`${match.url}/view/:collection`} component={CollectionViewRoute} />
      <Redirect to={`${match.url}/list`} />
    </Switch>
  );
};
