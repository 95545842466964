import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { CreateCollectibleTransactionReq_Contributor } from 'protobuf/lib/collectibleService';

import { getFriends } from 'services/friend';

import { ContributorsStepValue } from '../../../types';
import Cell from '../../Cell';
import css from './index.module.css';

const Contributor = ({ userProfileId, revenueShare }: CreateCollectibleTransactionReq_Contributor) => {
  const friendsQuery = useQuery('getFriends', () => getFriends());

  const friend = useMemo(
    () => friendsQuery.data?.friends.find(({ address }) => address === userProfileId.toLowerCase()),
    [friendsQuery.data?.friends, userProfileId],
  );

  return (
    <div>
      <div>
        <Cell label="Name" value={friend?.name || ''} />
      </div>
      <div>
        <Cell label="Percentage" value={revenueShare + '%'} />
      </div>
    </div>
  );
};

const Contributors = ({ contributors }: ContributorsStepValue) => {
  return (
    <div className={css.contributors}>
      <h3>Contribution</h3>
      <br />
      {contributors.length === 0 && <p>No Contributors added.</p>}
      {contributors.map(({ userProfileId, revenueShare }, index) => (
        <Contributor key={index} userProfileId={userProfileId} revenueShare={revenueShare} />
      ))}
      <br />
    </div>
  );
};

export default Contributors;
