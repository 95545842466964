// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Copy_wrapper__vz21n {
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/components/clipboard/Copy/index.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,oBAAoB;AACtB","sourcesContent":[".wrapper {\n  font-weight: inherit;\n  font-size: inherit;\n  line-height: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Copy_wrapper__vz21n`
};
export default ___CSS_LOADER_EXPORT___;
