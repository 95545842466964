import clsx from 'clsx';

import { IconProps } from '../types';
import css from './index.module.css';

const ASPECT_RATIO = 1;

export const CrossIcon = ({ className, size = 28.149, onClick }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(css.icon, className)}
    onClick={onClick}
    width={size}
    height={size / ASPECT_RATIO}
    viewBox="0 0 28.149 28.149"
  >
    <path
      d="M11.123,11.074V19.9H8.83v-8.83H0V8.781H8.83V0h2.293V8.781H19.9v2.293Z"
      transform="translate(14.074) rotate(45)"
    />
  </svg>
);
