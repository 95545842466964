// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FilterDropdown_dropDownwrap__CKUK4 {
  position: relative;
  background: var(--input-bg-color);
  border-radius: 6px;
}
.FilterDropdown_dropDownbttn__6NXEu {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  flex: 1 1;
  padding: 6px 12px;
  color: var(--text-color);
  cursor: pointer;
}

.FilterDropdown_dropDownbttnArrow__euMGn {
  transition: 0.25s all ease;
  margin-left: 12px;
}
.FilterDropdown_dropDownbttnArrowRotated__zux3o {
  transform: rotate(180deg);
}

.FilterDropdown_wrapper__XEn9I {
  z-index: 1;
  position: absolute;
  top: calc(100% + 8px);
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/input/FilterDropdown/index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iCAAiC;EACjC,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,SAAS;EACT,iBAAiB;EACjB,wBAAwB;EACxB,eAAe;AACjB;;AAEA;EACE,0BAA0B;EAC1B,iBAAiB;AACnB;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;AACb","sourcesContent":[".dropDownwrap {\n  position: relative;\n  background: var(--input-bg-color);\n  border-radius: 6px;\n}\n.dropDownbttn {\n  display: flex;\n  align-items: center;\n  font-size: 14px;\n  line-height: 20px;\n  flex: 1 1;\n  padding: 6px 12px;\n  color: var(--text-color);\n  cursor: pointer;\n}\n\n.dropDownbttnArrow {\n  transition: 0.25s all ease;\n  margin-left: 12px;\n}\n.dropDownbttnArrowRotated {\n  transform: rotate(180deg);\n}\n\n.wrapper {\n  z-index: 1;\n  position: absolute;\n  top: calc(100% + 8px);\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropDownwrap": `FilterDropdown_dropDownwrap__CKUK4`,
	"dropDownbttn": `FilterDropdown_dropDownbttn__6NXEu`,
	"dropDownbttnArrow": `FilterDropdown_dropDownbttnArrow__euMGn`,
	"dropDownbttnArrowRotated": `FilterDropdown_dropDownbttnArrowRotated__zux3o`,
	"wrapper": `FilterDropdown_wrapper__XEn9I`
};
export default ___CSS_LOADER_EXPORT___;
