import { EthereumAddress } from 'beasy-fe-commons';
import clsx from 'clsx';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import avatarPlaceholder from 'assets/images/avatar-placeholder.png';

import { ErrorMessageQueryLoading } from 'components/ErrorMessage';
import ItemList from 'components/ItemList';
import { Spacer } from 'components/Spacer';
import { CollectibleItem } from 'components/collectible';
import itemListCss from 'components/collectible/item-list.module.css';
import { EmptyList } from 'components/layout';
import { PageArea, PageHeader } from 'components/page';

import { Collectible } from 'protobuf/lib/collectibleService';

import { getSharedCollectiblesWithCreator } from 'services/collectible';
import { getIpfsUrl } from 'services/ipfs';
import { getRoleNameMap, getUserProfile, UserProfileQueryKey } from 'services/userProfile';

import { AppState } from 'state';

import css from './index.module.css';

const PartnerProfile = () => {
  const { t } = useTranslation();
  const { partnerAddress } = useParams<{ partnerAddress: EthereumAddress }>();
  const getRoleNameMapQuery = useQuery(UserProfileQueryKey.getRoleNameMap, () => getRoleNameMap());
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  const getUserProfileQuery = useQuery(['getUserProfile', partnerAddress], () => {
    return getUserProfile(partnerAddress);
  });

  const userRole = getUserProfileQuery.data?.roles?.find(r => r.network === walletConnectV2.chainId!);

  const {
    data: collectibles,
    isError: isCollectiblesError,
    refetch: refetchCollectibles,
  } = useQuery('getSharedCollectiblesWithCreator', () => getSharedCollectiblesWithCreator(partnerAddress));

  const history = useHistory();

  const handleCollectibleClick = useCallback(
    ({ collectibleId }: Collectible) => {
      history.push(`/home/collectible/${collectibleId}`);
    },
    [history],
  );

  return (
    <PageArea>
      {getUserProfileQuery.isSuccess && <PageHeader title={getUserProfileQuery.data.nickname}></PageHeader>}

      {getUserProfileQuery.isSuccess && getRoleNameMapQuery.isSuccess && (
        <div>
          <div className={css.header}>
            <img
              className={css.profileImage}
              src={getIpfsUrl(getUserProfileQuery.data?.avatarCid) || avatarPlaceholder}
              alt=""
            />
            <div className={css.profile}>
              <p>{t('partnerProfile.firstName')}</p>
              <p>{t('partnerProfile.lastName')}</p>
              <p>{t('partnerProfile.businessName')}</p>
              <p>{t('partnerProfile.role')}</p>
            </div>
            <div className={clsx(css.value, css.profile)}>
              <p>{getUserProfileQuery.data.additionalData?.firstName || '-'}</p>
              <p>{getUserProfileQuery.data.additionalData?.lastName || '-'}</p>
              <p>{getUserProfileQuery.data.additionalData?.businessName || '-'}</p>
              <p>{(userRole && getRoleNameMapQuery?.data[userRole.role]) || '-'}</p>
            </div>
          </div>
          <div className={css.content}>
            <h3>{t('partnerProfile.address')}</h3>
            <p>{getUserProfileQuery.data.ethAddress}</p>
          </div>
        </div>
      )}

      <div className={css.boxGallery}>
        <h3>{t('partnerProfile.sharedNFT')}</h3>
        <Spacer height="32px" />
        <div>
          {isCollectiblesError ? (
            <ErrorMessageQueryLoading onRetry={() => refetchCollectibles()} />
          ) : collectibles?.length ? (
            <ItemList
              items={collectibles}
              Component={CollectibleItem}
              className={itemListCss.wrapper}
              onItemClick={handleCollectibleClick}
            />
          ) : (
            <EmptyList message="You do not have any shared NFTs with this partner." />
          )}
        </div>
      </div>
    </PageArea>
  );
};

export default PartnerProfile;
