// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Area_wrapper__bG0yN {
  padding: 24px;
  border-radius: 6px;
  background-color: var(--alt-bg-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/dialog/Area/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,qCAAqC;AACvC","sourcesContent":[".wrapper {\n  padding: 24px;\n  border-radius: 6px;\n  background-color: var(--alt-bg-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Area_wrapper__bG0yN`
};
export default ___CSS_LOADER_EXPORT___;
