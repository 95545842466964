import clsx from 'clsx';
import React, { useCallback, useState } from 'react';

import { ChevronIcon } from 'assets/icons';

import css from './index.module.css';

interface Props {
  title: string;
  children?: React.ReactNode;
}

const FaqItem = ({ title, children }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const toggle = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  return (
    <li className={css.item}>
      <div role="button" onKeyPress={toggle} onClick={toggle}>
        <div className={css.dropDownbttn}>
          <b>{title}</b>
          <ChevronIcon className={clsx(css.dropDownbttnArrow, isOpen ? css.dropDownbttnArrowRotated : '')} />
        </div>
      </div>

      {isOpen && <div className={css.itemContent}>{children}</div>}
    </li>
  );
};

export default FaqItem;
