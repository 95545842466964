import { MutableRefObject } from 'react';

export enum StepIndex {
  AGREEMENT,
  REGISTRATION,
}

export enum SubscriptionType {
  FREE,
  PERSONAL,
  BUSINESS,
  ENTERPRISE,
}

type MaxUsersType = Record<SubscriptionType, number>;
export const MaxUsers: MaxUsersType = {
  [SubscriptionType.FREE]: 1,
  [SubscriptionType.PERSONAL]: 3,
  [SubscriptionType.BUSINESS]: 5,
  [SubscriptionType.ENTERPRISE]: 10,
};

export const firstStepIndex = StepIndex.AGREEMENT;
export const lastStepIndex = StepIndex.REGISTRATION;

export interface CombinedStepValues {
  [StepIndex.AGREEMENT]?: AgreementStepValue;
  [StepIndex.REGISTRATION]?: RegistrationStepValue;
}

export type SubscriptionStepValue = SubscriptionType;
export interface AgreementStepValue {
  checked: boolean;
}

export interface RegistrationStepValue {
  name: string;
  lastname: string;
  business: string;
  industry: string | undefined;
  role: string | undefined;
  eth_address: string;
  email: string;
  phone: string;
  referral: string;
}

export const STEP_INVALID_VALUE = 'STEP_INVALID_VALUE';

export type GetStepValueRef<T> = MutableRefObject<(() => Promise<T | typeof STEP_INVALID_VALUE>) | undefined>;

export interface StepProps<T> {
  value?: T;
  getValueRef: GetStepValueRef<T>;
  combinedValues: CombinedStepValues;
}
