import { useTranslation } from 'react-i18next';

import { WarningIcon } from 'assets/icons';

import { ButtonPrimary, ButtonSecondary } from 'components/button';
import { DialogArea, DialogClose, DialogFooter, DialogHeader, DialogOverlay } from 'components/dialog';

import css from './index.module.css';

interface Props {
  onYes: () => void;
  onNo: () => void;
  isDisabled: boolean;
}

const LogoutModal = ({ onYes, onNo, isDisabled }: Props) => {
  const { t } = useTranslation();
  return (
    <DialogOverlay>
      <DialogArea>
        <DialogHeader>
          <div className={css.header}>
            <WarningIcon className={css.icon} />
            <h3>{t('logoutModal.logOut')}</h3>
          </div>
          <DialogClose onClick={onNo} />
        </DialogHeader>
        <p className={css.label}>{t('logoutModal.confirmation')}</p>
        <DialogFooter>
          <ButtonSecondary onClick={onNo} disabled={isDisabled}>
            {t('logoutModal.cancel')}
          </ButtonSecondary>
          <ButtonPrimary onClick={onYes} disabled={isDisabled}>
            {t('logoutModal.logOut')}
          </ButtonPrimary>
        </DialogFooter>
      </DialogArea>
    </DialogOverlay>
  );
};

export default LogoutModal;
