// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MintDialog_area__siRvQ {
  width: 550px;
}

.MintDialog_area__siRvQ > p {
  color: var(--alt10-color);
}

.MintDialog_area__siRvQ > fieldset {
  border: none;
}

.MintDialog_area__siRvQ > fieldset > div {
  display: flex;
}

.MintDialog_area__siRvQ > fieldset > div > input {
  width: 20px;
  height: 20px;
}

.MintDialog_keys__wHAT7 {
  color: var(--alt15-color);
  margin-block-start: 50px;
}

.MintDialog_box__6wG4\\+ {
  background: var(--alt2-color);
  border: none;
  border-radius: 6px;
  color: var(--body-color);
  line-height: 20px;
  padding: 10px 15px;
  width: 100%;
  min-height: var(--input-min-height);
}

.MintDialog_box__6wG4\\+:focus {
  outline: none;
  box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/collectible/MintDialog/index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,wBAAwB;AAC1B;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,kBAAkB;EAClB,wBAAwB;EACxB,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".area {\n  width: 550px;\n}\n\n.area > p {\n  color: var(--alt10-color);\n}\n\n.area > fieldset {\n  border: none;\n}\n\n.area > fieldset > div {\n  display: flex;\n}\n\n.area > fieldset > div > input {\n  width: 20px;\n  height: 20px;\n}\n\n.keys {\n  color: var(--alt15-color);\n  margin-block-start: 50px;\n}\n\n.box {\n  background: var(--alt2-color);\n  border: none;\n  border-radius: 6px;\n  color: var(--body-color);\n  line-height: 20px;\n  padding: 10px 15px;\n  width: 100%;\n  min-height: var(--input-min-height);\n}\n\n.box:focus {\n  outline: none;\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"area": `MintDialog_area__siRvQ`,
	"keys": `MintDialog_keys__wHAT7`,
	"box": `MintDialog_box__6wG4+`
};
export default ___CSS_LOADER_EXPORT___;
