// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Menu_wrapper__deBeU {
  display: flex;
  flex-direction: column;
  gap: 16px;

  border-radius: 6px;
  padding: 16px;
  background-color: var(--alt18-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/Menu/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;;EAET,kBAAkB;EAClB,aAAa;EACb,oCAAoC;AACtC","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n\n  border-radius: 6px;\n  padding: 16px;\n  background-color: var(--alt18-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Menu_wrapper__deBeU`
};
export default ___CSS_LOADER_EXPORT___;
