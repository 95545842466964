// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ClearableValue_wrapper__SsLmG > ul {
  list-style: none;
}

.ClearableValue_wrapper__SsLmG > ul > li {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 200;
  color: var(--text-color);
}

.ClearableValue_remove__b\\+JQT {
  margin-right: 0.5em;
  background-color: inherit;
  border: none;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  padding: 0;
  opacity: 0.8;
  color: var(--text-color-accent);

  position: relative;
  top: 3px;
}

.ClearableValue_remove__b\\+JQT:hover {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/input/ClearableValue/index.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,wBAAwB;AAC1B;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,UAAU;EACV,YAAY;EACZ,+BAA+B;;EAE/B,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".wrapper > ul {\n  list-style: none;\n}\n\n.wrapper > ul > li {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  font-size: 14px;\n  font-weight: 200;\n  color: var(--text-color);\n}\n\n.remove {\n  margin-right: 0.5em;\n  background-color: inherit;\n  border: none;\n  border-radius: 50%;\n  width: 15px;\n  height: 15px;\n  padding: 0;\n  opacity: 0.8;\n  color: var(--text-color-accent);\n\n  position: relative;\n  top: 3px;\n}\n\n.remove:hover {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ClearableValue_wrapper__SsLmG`,
	"remove": `ClearableValue_remove__b+JQT`
};
export default ___CSS_LOADER_EXPORT___;
