import { IpfsCollectionInfo } from 'beasy-fe-commons';
import Skeleton from 'react-loading-skeleton';
import { useMutation } from 'react-query';

import { Spacer } from 'components/Spacer';
import { ImageView } from 'components/image';

import { useCollection } from 'hooks/collection';
import { useIpfsInfo } from 'hooks/useIpfs';

import { GetUserCollectionBalanceRes_CollectionBalance } from 'protobuf/lib/royalty';

import { MATIC_CURRENCY, WETH_CURRENCY } from 'services/currency';
import { withdrawMatic, withdrawWeth } from 'services/royalties';

import { ClaimRoyaltyItem } from '../ClaimRoyaltyItem';
import css from './index.module.css';

interface Props {
  collectionRoyaltyInfo?: GetUserCollectionBalanceRes_CollectionBalance;
  handleSuccess: () => void;
}

export const ClaimRoyaltyList = ({ collectionRoyaltyInfo, handleSuccess }: Props) => {
  const collection = useCollection(collectionRoyaltyInfo?.collectionId);
  const info = useIpfsInfo<IpfsCollectionInfo>(collection?.metadataCid);
  const withdrawMaticMutation = useMutation(
    async () => {
      return withdrawMatic(collectionRoyaltyInfo?.collectionId!);
    },
    {
      onSuccess: async () => {
        handleSuccess();
      },
    },
  );

  const withdrawWethMutation = useMutation(
    async () => {
      return withdrawWeth(collectionRoyaltyInfo?.collectionId!);
    },
    {
      onSuccess: async () => {
        handleSuccess();
      },
    },
  );

  return (
    <article className={css.wrapper}>
      <div>
        <figure>
          <ImageView src={info?.image} />
        </figure>
        <Spacer width="10px"></Spacer>
        <h4>{collection?.name ?? <Skeleton width={50} />}</h4>
        <Spacer width="20px"></Spacer>
      </div>
      <div>
        <div className={css.content}>
          <ClaimRoyaltyItem
            priceAsWei={collectionRoyaltyInfo?.balance!}
            cryptocurrency={MATIC_CURRENCY}
            onSubmit={withdrawMaticMutation.mutate}
            isLoading={withdrawMaticMutation.isLoading}
          />
          <ClaimRoyaltyItem
            priceAsWei={collectionRoyaltyInfo?.balanceToken!}
            cryptocurrency={WETH_CURRENCY}
            onSubmit={withdrawWethMutation.mutate}
            isLoading={withdrawWethMutation.isLoading}
          />
        </div>
      </div>
    </article>
  );
};
