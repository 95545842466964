// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Outline_button__O7vDX {
  padding: 8px 16px;
  background: var(--secondary-color);
  border-radius: 6px;
  color: var(--text-color-accent);
}

.Outline_button__O7vDX:hover {
  opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/components/button/Outline/index.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kCAAkC;EAClC,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,YAAY;AACd","sourcesContent":[".button {\n  padding: 8px 16px;\n  background: var(--secondary-color);\n  border-radius: 6px;\n  color: var(--text-color-accent);\n}\n\n.button:hover {\n  opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Outline_button__O7vDX`
};
export default ___CSS_LOADER_EXPORT___;
