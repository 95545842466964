// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.List_filter__vBzGm {
  display: flex;
  gap: 24px;
}

.List_filter__vBzGm > .List_active__BzuS8 {
  color: var(--text-color-accent);
  text-decoration: underline;
}

.List_list__7g8Er {
  display: flex;
  overflow: scroll;
  flex-wrap: wrap;
  gap: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/collection/List/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,+BAA+B;EAC/B,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,SAAS;AACX","sourcesContent":[".filter {\n  display: flex;\n  gap: 24px;\n}\n\n.filter > .active {\n  color: var(--text-color-accent);\n  text-decoration: underline;\n}\n\n.list {\n  display: flex;\n  overflow: scroll;\n  flex-wrap: wrap;\n  gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": `List_filter__vBzGm`,
	"active": `List_active__BzuS8`,
	"list": `List_list__7g8Er`
};
export default ___CSS_LOADER_EXPORT___;
