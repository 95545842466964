import { FormikConfig, FormikValues, setNestedObjectValues, useFormik } from 'formik';
import { useCallback, useMemo } from 'react';

export const useForm = <FormValue extends FormikValues>(config: FormikConfig<FormValue>) => {
  const form = useFormik<FormValue>(config);

  const getFormValue = useCallback(async () => {
    const errors = await form.validateForm();

    if (Object.keys(errors).length > 0) {
      form.setTouched(setNestedObjectValues(errors, true));

      throw new Error('there are some validation errors');
    }

    return form.values;
  }, [form]);

  return useMemo(
    () => ({
      form,
      getFormValue,
    }),
    [form, getFormValue],
  );
};
