import clsx from 'clsx';

import { IconProps } from '../types';
import css from './index.module.css';

const ASPECT_RATIO = 1;

export const ImagePlaceholderIcon = ({ className, size = 54, onClick }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(css.icon, className)}
    onClick={onClick}
    width={size}
    height={size / ASPECT_RATIO}
    viewBox="0 0 54 54"
  >
    <path d="M53.6666 37.6668V5.66683C53.6666 2.7335 51.2666 0.333496 48.3333 0.333496H16.3333C13.3999 0.333496 10.9999 2.7335 10.9999 5.66683V37.6668C10.9999 40.6002 13.3999 43.0002 16.3333 43.0002H48.3333C51.2666 43.0002 53.6666 40.6002 53.6666 37.6668ZM24.3333 27.0002L29.7466 34.2268L37.6666 24.3335L48.3333 37.6668H16.3333L24.3333 27.0002ZM0.333252 11.0002V48.3335C0.333252 51.2668 2.73325 53.6668 5.66659 53.6668H42.9999V48.3335H5.66659V11.0002H0.333252Z" />
  </svg>
);
