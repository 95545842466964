import React from 'react';

import { ClearableSelect } from '../ClearableSelect';
import css from './index.module.css';

interface Props<T> {
  options?: T[];
  value?: T | File;
  // todo multiple like in FileInput
  renderValue?: (value: T) => React.ReactNode;
  onChange?: (value?: T | File) => void;
  accept?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

// todo refactor with FileInput
export function FileWithSelect<T>({ options, value, renderValue, accept, onChange, onBlur }: Props<T>) {
  const ref = React.useRef<HTMLInputElement>(null);

  const handleValueChange = React.useCallback(
    (value?: T) => {
      onChange?.(value);
      ref.current!.value = ''; // to be able to choose the same file after deletion
    },
    [onChange],
  );

  const handleFileChange = React.useCallback(
    ({ currentTarget: { files } }: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(files![0]);
    },
    [onChange],
  );

  const handleRenderValue = React.useCallback(
    (value: T | File) => {
      if (value instanceof File) {
        return value.name;
      } else if (renderValue) {
        return renderValue(value);
      } else {
        return <>{String(value)}</>;
      }
    },
    [renderValue],
  );

  return (
    <div className={css.wrapper}>
      <ClearableSelect
        className={css.selector}
        isFile={true}
        options={options}
        value={value as T}
        renderValue={handleRenderValue}
        onChange={handleValueChange}
      />
      <label>
        Choose file
        <input ref={ref} type="file" accept={accept} onChange={handleFileChange} onBlur={onBlur} />
      </label>
    </div>
  );
}
