import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Spacer } from 'components/Spacer';
import { ButtonPrimary, ButtonSecondary } from 'components/button';
import { DialogArea, DialogClose, DialogFooter, DialogHeader, DialogOverlay } from 'components/dialog';
import { FilterDropdown } from 'components/input';

import { formatServiceFee, roundPrice } from 'constants/helper';

import { useNetwork } from 'hooks/useNetwork';
import { useTransactionCost } from 'hooks/useTransactionCost';

import { TransactionCost } from 'protobuf/lib/transaction';
import {
  MinimalTransactionInfo,
  PopulateTransactionReq,
  PopulateTransactionReq_FeeKind,
} from 'protobuf/lib/transactionMessage';

import { DEFAULT_CURRENCY } from 'services/currency';


import css from './index.module.css';
import { AppState } from 'state';

interface Props {
  transaction: MinimalTransactionInfo;
  onCancel: () => void;
  onSubmit: (transaction: PopulateTransactionReq) => void;
  entityTitle?: string;
  /*childToParent: any;
  metaTx: any;
  normalTx: any;*/
}

export const TransactionPickSpeed = ({
  transaction,
  onCancel,
  onSubmit,
  entityTitle = 'NFT',
}: /*childToParent,
  metaTx,
  normalTx,*/
Props) => {
  const { t } = useTranslation();
  const { currentNetwork } = useNetwork();
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  console.log("TRANSACTIOPN PICK SPEED: ",walletConnectV2)
  //const {balance } = useAccount()
  /* const [checkMetaTx, setCheckMetaTx] = useState(false);
  const [normalTransaction, setNormalTx] = useState(false);
  const [metaCost, setMetaCost] = useState(0);*/
  type TransactionSpeedKey = Exclude<keyof TransactionCost, 'serviceFee'>;

  const { transactionCost, getTransactionCostAmount } = useTransactionCost(transaction);

  const options = useMemo(
    () =>
      transactionCost
        ? [
            ...(transactionCost.min.length === 0 ? [] : [{ label: 'Min', value: 'min' }]),
            { label: 'Average', value: 'avg' },
            { label: 'High', value: 'high' },
            { label: 'Rapid', value: 'rapid' },
          ]
        : [],
    [transactionCost],
  );

  const [speed, setSpeed] = useState<TransactionSpeedKey>('rapid');



  const { isOverdraft, costInCoins } = useMemo(() => {

    const balanceInCoins = Number(walletConnectV2.balance);
    const costInCoins = getTransactionCostAmount(speed, currentNetwork?.coin?.code);
    const serviceFee = getTransactionCostAmount('serviceFee', currentNetwork?.coin?.code);
    const isOverdraft = balanceInCoins < serviceFee + costInCoins;

    return { isOverdraft, costInCoins };
  }, [walletConnectV2.balance, currentNetwork?.coin?.code, getTransactionCostAmount, speed]);

  /*
  useEffect(() => {
    if (isOverdraft) {
      childToParent(2)
      setCheckMetaTx(true)
    }
  }, [childToParent, isOverdraft])
  */
  /*
  useEffect(() => {
    if (getTransactionCostAmount(speed, DEFAULT_CURRENCY) < 1) {
      setMetaCost(1);
    } else {
      let costMeta = getTransactionCostAmount(speed, DEFAULT_CURRENCY);
      setMetaCost(costMeta * 1.25);
    }
  }, [getTransactionCostAmount, speed]);

  const setMethod = (methodId: any) => {
    if (methodId === 1) {
      childToParent(1);
      // setMetaTransaction(false);
      setNormalTx(true);
      setCheckMetaTx(false);
    } else {
      childToParent(2);
      setNormalTx(false);
      setCheckMetaTx(true);
    }
  };
*/

  const submit = useCallback(() => {
    onSubmit({
      ...transaction,
      fee: PopulateTransactionReq_FeeKind[speed],
    });
  }, [onSubmit, transaction, speed]);

  /* <>
      {metaTx && (
        <DialogOverlay>
          <DialogArea className={css.wrapperDialog}>
            <DialogHeader>
              <h3>{t('components.transaction.details')}</h3>
              <DialogClose onClick={onCancel} />
            </DialogHeader>

            <Spacer height="12px" />
            <p>Please select payment method</p>
            <Spacer width="12px" />

            <Spacer width="12px" />
            <DialogFooter>
              <ButtonPrimary onClick={() => setMethod(1)}>Crypto currency</ButtonPrimary>
              <ButtonPrimary onClick={() => setMethod(2)}>Credit Card</ButtonPrimary>
            </DialogFooter>
          </DialogArea>
        </DialogOverlay>
      )}

      {checkMetaTx && !normalTx && (
        <DialogOverlay>
          <DialogArea className={css.wrapper}>
            <DialogHeader>
              <h3>{t('components.transaction.details')}</h3>
              <DialogClose onClick={onCancel} />
            </DialogHeader>

            <Spacer height="12px" />
            <p>
              There are some fees associated with minting your collection. In order to complete the minting process, you
              will need to pay these fees through your BEASY wallet with you credit card.
            </p>
            <Spacer width="12px" />
            <section>
              Transaction Fee
              <Spacer height="5px" />
              <span>
                {metaCost} {DEFAULT_CURRENCY}
              </span>
            </section>
            <Spacer width="12px" />
            <DialogFooter>
              <ButtonSecondary onClick={onCancel}>Cancel</ButtonSecondary>
              <ButtonPrimary onClick={submit}>Mint {entityTitle}</ButtonPrimary>
            </DialogFooter>
          </DialogArea>
        </DialogOverlay>
      )}
      {(normalTransaction || normalTx ) &&  (
        <DialogOverlay>
          <DialogArea className={css.wrapper}>
            <DialogHeader>
              <h3>{t('components.transaction.details')}</h3>
              <DialogClose onClick={onCancel} />
            </DialogHeader>

            <Spacer height="12px" />
            <p>
              There are some fees associated with minting your {entityTitle}. In order to complete the minting process,
              you will need to pay these fees through your BEASY wallet with either Crypto or Fiat.
            </p>
            <Spacer width="12px" />
            <section>
              Speed
              <Spacer height="5px" />
              <FilterDropdown value={speed} onChange={setSpeed} options={options} />
            </section>
            <Spacer width="12px" />
            <section>
              Transaction Fee
              <Spacer height="5px" />
              <div>
                <span>
                  {costInCoins} {currentNetwork?.coin?.code}
                </span>
                <Spacer height="1px" />
                <span>
                  {roundPrice(getTransactionCostAmount(speed, DEFAULT_CURRENCY))} {DEFAULT_CURRENCY}
                </span>
              </div>
            </section>
            <Spacer width="12px" />
            <section>
              Service Fee
              <Spacer height="5px" />
              <div>
                <span>
                  {formatServiceFee(getTransactionCostAmount('serviceFee', currentNetwork?.coin?.code))}{' '}
                  {currentNetwork?.coin?.code}
                </span>
                <Spacer height="1px" />
                <span>
                  {formatServiceFee(getTransactionCostAmount('serviceFee', DEFAULT_CURRENCY))} {DEFAULT_CURRENCY}
                </span>
              </div>
              {isOverdraft && (
                <div>
                  <br />
                  You have insufficient funds, please top up your balance.
                </div>
              )}
            </section>
            <Spacer width="12px" />
            <DialogFooter>
              <ButtonSecondary onClick={onCancel}>Cancel</ButtonSecondary>
              <ButtonPrimary onClick={submit} disabled={!transactionCost || isOverdraft}>
                Mint {entityTitle}
              </ButtonPrimary>
            </DialogFooter>
          </DialogArea>
        </DialogOverlay>
      )}
    </>*/
  return (
    <DialogOverlay>
      <DialogArea className={css.wrapper}>
        <DialogHeader>
          <h3>{t('components.transaction.details')}</h3>
          <DialogClose onClick={onCancel} />
        </DialogHeader>

        <Spacer height="12px" />
        <p>
          There are some fees associated with minting your {entityTitle}. In order to complete the minting process, you
          will need to pay these fees through your BEASY wallet with either Crypto or Fiat.
        </p>
        <Spacer width="12px" />
        <section>
          Speed
          <Spacer height="5px" />
          <FilterDropdown value={speed} onChange={setSpeed} options={options} />
        </section>
        <Spacer width="12px" />
        <section>
          Transaction Fee
          <Spacer height="5px" />
          <div>
            <span>
              {costInCoins} {currentNetwork?.coin?.code}
            </span>
            <Spacer height="1px" />
            <span>
              {roundPrice(getTransactionCostAmount(speed, DEFAULT_CURRENCY))} {DEFAULT_CURRENCY}
            </span>
          </div>
        </section>
        <Spacer width="12px" />
        <section>
          Service Fee
          <Spacer height="5px" />
          <div>
            <span>
              {formatServiceFee(getTransactionCostAmount('serviceFee', currentNetwork?.coin?.code))}{' '}
              {currentNetwork?.coin?.code}
            </span>
            <Spacer height="1px" />
            <span>
              {formatServiceFee(getTransactionCostAmount('serviceFee', DEFAULT_CURRENCY))} {DEFAULT_CURRENCY}
            </span>
          </div>
          {isOverdraft && (
            <div>
              <br />
              You have insufficient funds, please top up your balance.
            </div>
          )}
        </section>
        <Spacer width="12px" />
        <DialogFooter>
          <ButtonSecondary onClick={onCancel}>Cancel</ButtonSecondary>
          <ButtonPrimary onClick={submit} disabled={!transactionCost || isOverdraft}>
            Mint {entityTitle}
          </ButtonPrimary>
        </DialogFooter>
      </DialogArea>
    </DialogOverlay>
  );
};


