// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Alert_wrapper__jPayn {
  width: 364px;
  display: flex;
  flex-direction: column;
}

.Alert_wrapper__jPayn > div:first-of-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Alert_header__5RnuY {
  flex-direction: row-reverse;
}

.Alert_icon__4vD7m {
  position: relative;
  top: -20px;
  left: 30px;
}

.Alert_tick__4dLIM {
  color: var(--accent-green);
}

.Alert_warning__UPFtJ {
  color: var(--orange);
}

.Alert_button__a-iAR {
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Alert/index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".wrapper {\n  width: 364px;\n  display: flex;\n  flex-direction: column;\n}\n\n.wrapper > div:first-of-type {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n}\n\n.header {\n  flex-direction: row-reverse;\n}\n\n.icon {\n  position: relative;\n  top: -20px;\n  left: 30px;\n}\n\n.tick {\n  color: var(--accent-green);\n}\n\n.warning {\n  color: var(--orange);\n}\n\n.button {\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Alert_wrapper__jPayn`,
	"header": `Alert_header__5RnuY`,
	"icon": `Alert_icon__4vD7m`,
	"tick": `Alert_tick__4dLIM`,
	"warning": `Alert_warning__UPFtJ`,
	"button": `Alert_button__a-iAR`
};
export default ___CSS_LOADER_EXPORT___;
