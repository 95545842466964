/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { TransactionReceipt } from "./transactionMessage";

export const protobufPackage = "";

export interface GetRoyaltyBalanceReq {
  collectionId: string;
}

export interface GetRoyaltyBalanceRes {
  balance: string;
}

export interface WithdrawReq {
  collectionId: string;
}

export interface WithdrawRes {
  receipt: TransactionReceipt | undefined;
}

export interface GetUserCollectionBalanceReq {
  address: string;
}

export interface GetUserCollectionBalanceRes {
  collections: GetUserCollectionBalanceRes_CollectionBalance[];
}

export interface GetUserCollectionBalanceRes_CollectionBalance {
  collectionId: string;
  balance: string;
  balanceToken: string;
}

export interface RoyaltyHolder {
  address: string;
  share: string;
}

export interface GetRoyaltyHoldersReq {
  collection: string;
}

export interface GetRoyaltyHoldersRes {
  holders: RoyaltyHolder[];
}

function createBaseGetRoyaltyBalanceReq(): GetRoyaltyBalanceReq {
  return { collectionId: "" };
}

export const GetRoyaltyBalanceReq = {
  encode(message: GetRoyaltyBalanceReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.collectionId !== "") {
      writer.uint32(10).string(message.collectionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetRoyaltyBalanceReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRoyaltyBalanceReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetRoyaltyBalanceReq>, I>>(base?: I): GetRoyaltyBalanceReq {
    return GetRoyaltyBalanceReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRoyaltyBalanceReq>, I>>(object: I): GetRoyaltyBalanceReq {
    const message = createBaseGetRoyaltyBalanceReq();
    message.collectionId = object.collectionId ?? "";
    return message;
  },
};

function createBaseGetRoyaltyBalanceRes(): GetRoyaltyBalanceRes {
  return { balance: "" };
}

export const GetRoyaltyBalanceRes = {
  encode(message: GetRoyaltyBalanceRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.balance !== "") {
      writer.uint32(10).string(message.balance);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetRoyaltyBalanceRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRoyaltyBalanceRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.balance = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetRoyaltyBalanceRes>, I>>(base?: I): GetRoyaltyBalanceRes {
    return GetRoyaltyBalanceRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRoyaltyBalanceRes>, I>>(object: I): GetRoyaltyBalanceRes {
    const message = createBaseGetRoyaltyBalanceRes();
    message.balance = object.balance ?? "";
    return message;
  },
};

function createBaseWithdrawReq(): WithdrawReq {
  return { collectionId: "" };
}

export const WithdrawReq = {
  encode(message: WithdrawReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.collectionId !== "") {
      writer.uint32(10).string(message.collectionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WithdrawReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWithdrawReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<WithdrawReq>, I>>(base?: I): WithdrawReq {
    return WithdrawReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WithdrawReq>, I>>(object: I): WithdrawReq {
    const message = createBaseWithdrawReq();
    message.collectionId = object.collectionId ?? "";
    return message;
  },
};

function createBaseWithdrawRes(): WithdrawRes {
  return { receipt: undefined };
}

export const WithdrawRes = {
  encode(message: WithdrawRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.receipt !== undefined) {
      TransactionReceipt.encode(message.receipt, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): WithdrawRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWithdrawRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.receipt = TransactionReceipt.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<WithdrawRes>, I>>(base?: I): WithdrawRes {
    return WithdrawRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<WithdrawRes>, I>>(object: I): WithdrawRes {
    const message = createBaseWithdrawRes();
    message.receipt = (object.receipt !== undefined && object.receipt !== null)
      ? TransactionReceipt.fromPartial(object.receipt)
      : undefined;
    return message;
  },
};

function createBaseGetUserCollectionBalanceReq(): GetUserCollectionBalanceReq {
  return { address: "" };
}

export const GetUserCollectionBalanceReq = {
  encode(message: GetUserCollectionBalanceReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserCollectionBalanceReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserCollectionBalanceReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetUserCollectionBalanceReq>, I>>(base?: I): GetUserCollectionBalanceReq {
    return GetUserCollectionBalanceReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserCollectionBalanceReq>, I>>(object: I): GetUserCollectionBalanceReq {
    const message = createBaseGetUserCollectionBalanceReq();
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseGetUserCollectionBalanceRes(): GetUserCollectionBalanceRes {
  return { collections: [] };
}

export const GetUserCollectionBalanceRes = {
  encode(message: GetUserCollectionBalanceRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.collections) {
      GetUserCollectionBalanceRes_CollectionBalance.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserCollectionBalanceRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserCollectionBalanceRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collections.push(GetUserCollectionBalanceRes_CollectionBalance.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetUserCollectionBalanceRes>, I>>(base?: I): GetUserCollectionBalanceRes {
    return GetUserCollectionBalanceRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserCollectionBalanceRes>, I>>(object: I): GetUserCollectionBalanceRes {
    const message = createBaseGetUserCollectionBalanceRes();
    message.collections =
      object.collections?.map((e) => GetUserCollectionBalanceRes_CollectionBalance.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetUserCollectionBalanceRes_CollectionBalance(): GetUserCollectionBalanceRes_CollectionBalance {
  return { collectionId: "", balance: "", balanceToken: "" };
}

export const GetUserCollectionBalanceRes_CollectionBalance = {
  encode(message: GetUserCollectionBalanceRes_CollectionBalance, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.collectionId !== "") {
      writer.uint32(10).string(message.collectionId);
    }
    if (message.balance !== "") {
      writer.uint32(18).string(message.balance);
    }
    if (message.balanceToken !== "") {
      writer.uint32(26).string(message.balanceToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserCollectionBalanceRes_CollectionBalance {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserCollectionBalanceRes_CollectionBalance();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectionId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.balance = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.balanceToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetUserCollectionBalanceRes_CollectionBalance>, I>>(
    base?: I,
  ): GetUserCollectionBalanceRes_CollectionBalance {
    return GetUserCollectionBalanceRes_CollectionBalance.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserCollectionBalanceRes_CollectionBalance>, I>>(
    object: I,
  ): GetUserCollectionBalanceRes_CollectionBalance {
    const message = createBaseGetUserCollectionBalanceRes_CollectionBalance();
    message.collectionId = object.collectionId ?? "";
    message.balance = object.balance ?? "";
    message.balanceToken = object.balanceToken ?? "";
    return message;
  },
};

function createBaseRoyaltyHolder(): RoyaltyHolder {
  return { address: "", share: "" };
}

export const RoyaltyHolder = {
  encode(message: RoyaltyHolder, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    if (message.share !== "") {
      writer.uint32(18).string(message.share);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RoyaltyHolder {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRoyaltyHolder();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.share = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RoyaltyHolder>, I>>(base?: I): RoyaltyHolder {
    return RoyaltyHolder.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RoyaltyHolder>, I>>(object: I): RoyaltyHolder {
    const message = createBaseRoyaltyHolder();
    message.address = object.address ?? "";
    message.share = object.share ?? "";
    return message;
  },
};

function createBaseGetRoyaltyHoldersReq(): GetRoyaltyHoldersReq {
  return { collection: "" };
}

export const GetRoyaltyHoldersReq = {
  encode(message: GetRoyaltyHoldersReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.collection !== "") {
      writer.uint32(10).string(message.collection);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetRoyaltyHoldersReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRoyaltyHoldersReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collection = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetRoyaltyHoldersReq>, I>>(base?: I): GetRoyaltyHoldersReq {
    return GetRoyaltyHoldersReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRoyaltyHoldersReq>, I>>(object: I): GetRoyaltyHoldersReq {
    const message = createBaseGetRoyaltyHoldersReq();
    message.collection = object.collection ?? "";
    return message;
  },
};

function createBaseGetRoyaltyHoldersRes(): GetRoyaltyHoldersRes {
  return { holders: [] };
}

export const GetRoyaltyHoldersRes = {
  encode(message: GetRoyaltyHoldersRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.holders) {
      RoyaltyHolder.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetRoyaltyHoldersRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRoyaltyHoldersRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.holders.push(RoyaltyHolder.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetRoyaltyHoldersRes>, I>>(base?: I): GetRoyaltyHoldersRes {
    return GetRoyaltyHoldersRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRoyaltyHoldersRes>, I>>(object: I): GetRoyaltyHoldersRes {
    const message = createBaseGetRoyaltyHoldersRes();
    message.holders = object.holders?.map((e) => RoyaltyHolder.fromPartial(e)) || [];
    return message;
  },
};

export interface RoyaltyService {
  GetRoyaltyBalance(
    request: DeepPartial<GetRoyaltyBalanceReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetRoyaltyBalanceRes>;
  GetRoyaltyBalanceToken(
    request: DeepPartial<GetRoyaltyBalanceReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetRoyaltyBalanceRes>;
  Widthdraw(request: DeepPartial<WithdrawReq>, metadata?: grpc.Metadata): Promise<WithdrawRes>;
  WidthdrawToken(request: DeepPartial<WithdrawReq>, metadata?: grpc.Metadata): Promise<WithdrawRes>;
  GetUserCollectionsBalance(
    request: DeepPartial<GetUserCollectionBalanceReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetUserCollectionBalanceRes>;
  GetRoyaltyHolders(
    request: DeepPartial<GetRoyaltyHoldersReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetRoyaltyHoldersRes>;
}

export class RoyaltyServiceClientImpl implements RoyaltyService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetRoyaltyBalance = this.GetRoyaltyBalance.bind(this);
    this.GetRoyaltyBalanceToken = this.GetRoyaltyBalanceToken.bind(this);
    this.Widthdraw = this.Widthdraw.bind(this);
    this.WidthdrawToken = this.WidthdrawToken.bind(this);
    this.GetUserCollectionsBalance = this.GetUserCollectionsBalance.bind(this);
    this.GetRoyaltyHolders = this.GetRoyaltyHolders.bind(this);
  }

  GetRoyaltyBalance(
    request: DeepPartial<GetRoyaltyBalanceReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetRoyaltyBalanceRes> {
    return this.rpc.unary(RoyaltyServiceGetRoyaltyBalanceDesc, GetRoyaltyBalanceReq.fromPartial(request), metadata);
  }

  GetRoyaltyBalanceToken(
    request: DeepPartial<GetRoyaltyBalanceReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetRoyaltyBalanceRes> {
    return this.rpc.unary(
      RoyaltyServiceGetRoyaltyBalanceTokenDesc,
      GetRoyaltyBalanceReq.fromPartial(request),
      metadata,
    );
  }

  Widthdraw(request: DeepPartial<WithdrawReq>, metadata?: grpc.Metadata): Promise<WithdrawRes> {
    return this.rpc.unary(RoyaltyServiceWidthdrawDesc, WithdrawReq.fromPartial(request), metadata);
  }

  WidthdrawToken(request: DeepPartial<WithdrawReq>, metadata?: grpc.Metadata): Promise<WithdrawRes> {
    return this.rpc.unary(RoyaltyServiceWidthdrawTokenDesc, WithdrawReq.fromPartial(request), metadata);
  }

  GetUserCollectionsBalance(
    request: DeepPartial<GetUserCollectionBalanceReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetUserCollectionBalanceRes> {
    return this.rpc.unary(
      RoyaltyServiceGetUserCollectionsBalanceDesc,
      GetUserCollectionBalanceReq.fromPartial(request),
      metadata,
    );
  }

  GetRoyaltyHolders(
    request: DeepPartial<GetRoyaltyHoldersReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetRoyaltyHoldersRes> {
    return this.rpc.unary(RoyaltyServiceGetRoyaltyHoldersDesc, GetRoyaltyHoldersReq.fromPartial(request), metadata);
  }
}

export const RoyaltyServiceDesc = { serviceName: "RoyaltyService" };

export const RoyaltyServiceGetRoyaltyBalanceDesc: UnaryMethodDefinitionish = {
  methodName: "GetRoyaltyBalance",
  service: RoyaltyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetRoyaltyBalanceReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetRoyaltyBalanceRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RoyaltyServiceGetRoyaltyBalanceTokenDesc: UnaryMethodDefinitionish = {
  methodName: "GetRoyaltyBalanceToken",
  service: RoyaltyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetRoyaltyBalanceReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetRoyaltyBalanceRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RoyaltyServiceWidthdrawDesc: UnaryMethodDefinitionish = {
  methodName: "Widthdraw",
  service: RoyaltyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return WithdrawReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = WithdrawRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RoyaltyServiceWidthdrawTokenDesc: UnaryMethodDefinitionish = {
  methodName: "WidthdrawToken",
  service: RoyaltyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return WithdrawReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = WithdrawRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RoyaltyServiceGetUserCollectionsBalanceDesc: UnaryMethodDefinitionish = {
  methodName: "GetUserCollectionsBalance",
  service: RoyaltyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetUserCollectionBalanceReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetUserCollectionBalanceRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const RoyaltyServiceGetRoyaltyHoldersDesc: UnaryMethodDefinitionish = {
  methodName: "GetRoyaltyHolders",
  service: RoyaltyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetRoyaltyHoldersReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetRoyaltyHoldersRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
