import { RELAYER_EVENTS } from '@walletconnect/core';
import { WalletConnectModal } from '@walletconnect/modal';
import Client, { SignClient } from '@walletconnect/sign-client';
import { SessionTypes } from '@walletconnect/types';
import { getAppMetadata } from '@walletconnect/utils';
import { RpcMethod, TransactionStatus, TransactionStatusResponse } from 'beasy-fe-commons';
import { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Web3 from 'web3';

import { redirectUrl, handleCrossAuth } from 'services/applicationAuthService';
import { getCode, getJwt } from 'services/auth';
import { initIpfsApi } from 'services/ipfs';
import { setUserInfo } from 'services/userInfoService';
import { getUserProfile } from 'services/userProfile';

import { store } from 'state';

import { clearWalletConnectV2, setWalletConnectV2 } from 'state/session';

import { environment } from 'environment';

/**
 * Types
 */

interface IClient {
  handleConnect: () => Promise<void>;
  handleDisconnect: () => Promise<void>;
  sendTransaction: (tx: any) => Promise<[TransactionStatus, string]>;
}

/**
 * Context
 */

export const ClientContext = createContext<IClient>({} as IClient);

/**
 * Web3Modal Config
 */

const web3Modal = new WalletConnectModal({
  projectId: environment.walletconnectId,
});
/**
 * Provider
 */

export function ClientContextProvider({ children }: { children: ReactNode | ReactNode[] }) {
  const [client, setClient] = useState<Client>();

  const prevRelayerValue = useRef<string>('');

  const relayerRegion = 'wss://relay.walletconnect.com';
  const [origin, setOrigin] = useState<string>(getAppMetadata().url);

  const dispatch = useDispatch();

  const reset = useCallback(() => {
    const onboardingData = localStorage.getItem("ONBOARDING"); // Save the data you want to keep

    localStorage.clear(); // Clear all local storage

    if (onboardingData) {
        localStorage.setItem("ONBOARDING", onboardingData); // Restore the saved data
    }
    dispatch(clearWalletConnectV2());
  }, [dispatch]);

  const extractChainIdAndAddress = (namespaces: SessionTypes.Namespaces) => {
    const accountInfo = namespaces.eip155.accounts[0];
    if (!accountInfo) {
      return null;
    }

    const parts = accountInfo.split(':');
    if (parts.length !== 3) {
      return null;
    }

    const chainId = '137';
    const address = parts[2];

    return { chainId, address };
  };

  async function getBalance(chainId: string, currentAddress: string) {
    const chain = environment.chains[chainId];
    if (chain) {
      const provider = new Web3.providers.HttpProvider(chain.url);
      const web3 = new Web3(provider);
      const weiBalance = await web3.eth.getBalance(currentAddress);
      return web3.utils.fromWei(weiBalance, 'ether');
    }
    return '0';
  }

  async function checkIsCreator(chainId: string, address: string): Promise<boolean> {
    const userProfile = await getUserProfile(address);
    if (userProfile.roles.find(r => r.network === chainId)) {
      return true;
    }
    return false;
  }

  async function checkIsAccepted(address: string): Promise<boolean> {
    const userProfile = await getUserProfile(address);
    if (userProfile.isAccepted === null || userProfile.isAccepted === false) {
      return false;
    } else {
      return true;
    }
  }

  const onSessionConnected = useCallback(
    async (chainId: string, address: string, session: SessionTypes.Struct, client: Client) => {
      const jwt = await getJWT(client, session, address, chainId);
      if (!jwt) {
        window.location.href = '/login';
      }
      initIpfsApi(jwt !== undefined ? jwt : '');

      const balance = await getBalance(chainId, address);
      const creator = await checkIsCreator(chainId, address);
      const accepted = await checkIsAccepted(address);
  
      dispatch(
        setWalletConnectV2({
          session: session,
          address: address,
          balance: balance,
          chainId: chainId,
          jwt: jwt,
          isCreator: creator,
          isAccepted: accepted,
        }),
      );
      setUserInfo({ address: address, chainId });
      if (redirectUrl) {
        await handleCrossAuth({ address, chainId });
        return;
      }
    },

    [],
  );

  async function handleConnect() {
    if (client === undefined) {
      throw new Error('WalletConnect is not initialized');
    }

    try {
      const proposalNamespace = {
        eip155: {
          methods: [
            RpcMethod.EthSendTransaction,
            RpcMethod.PersonalSign,
            RpcMethod.GetJwt,
            RpcMethod.Logout,
            RpcMethod.EthSignTypedData,
            'eth_signTransaction',
            'eth_sign',
          ],
          chains: ['eip155:80001', 'eip155:137'],
          events: ['accountsChanged', 'chainChanged'],
        },
      };
      const { uri, approval } = await client.connect({
        requiredNamespaces: proposalNamespace,
      });

      // Open QRCode modal if a URI was returned (i.e. we're not connecting an existing pairing).
      if (uri) {
        web3Modal.openModal({ uri });
        const session = await approval();
        console.log('Established session:', session);
        const result = extractChainIdAndAddress(session.namespaces);
        await onSessionConnected(result!.chainId, result!.address, session, client);
      }
    } catch (e) {
      console.error(e);

      // ignore rejection
    } finally {
      // close modal in case it was open
      web3Modal.closeModal();
    }
  }

  async function handleDisconnect() {

  
    const state = store.getState();
    const walletConnectV2 = state.session;
    reset();
  }

  async function getJWT(client: Client, session: SessionTypes.Struct, address: string, chainId: string): Promise<string | undefined> {
    try {
      const state = store.getState();
      const walletConnectV2 = state.session;
      const jwt = await client!.request<any>({
        topic: session.topic,
        chainId: 'eip155:' + chainId,
        request: {
          method: RpcMethod.GetJwt,
          params: [],
        },
      });
      if (jwt) {
        return jwt;
      }
      const { value: code } = await getCode({ address });
      const sig = await client!.request<any>({
        topic: session.topic,
        chainId: 'eip155:' + chainId,
        request: {
          method: RpcMethod.PersonalSign,
          params: [code, address],
        },
      });
      if (!sig) {
        return '';
      }
      const jwtResponse = await getJwt({ address, code, sig });
      return jwtResponse.value;
    } catch (error: any) {
      console.log('ERROR JWT: ', error);
    }
  }

  async function sendTransaction(tx: any): Promise<[TransactionStatus, string]> {
    const state = store.getState();
    const walletConnectV2 = state.session;
    try {
      const result = await client!.request<any>({
        topic: walletConnectV2.session!.topic,
        chainId: 'eip155:' + walletConnectV2.chainId,
        request: {
          method: RpcMethod.EthSendTransaction,
          params: [tx],
        },
      });
      if (result.transactionType === TransactionStatusResponse.Success) {
        return [TransactionStatus.Success, result.result];
      }
      if (result.transactionType === TransactionStatusResponse.Pending) {
        return [TransactionStatus.Pending, result.result];
      }
      return [TransactionStatus.Failure, result.result];
    } catch (error: any) {
      if (error.message === TransactionStatusResponse.Rejected) {
        return [TransactionStatus.Rejected, ''];
      }
      return [TransactionStatus.Failure, ''];
    }
  }

  const createClient = useCallback(async () => {
    try {
      const signClient = await SignClient.init({
        projectId: environment.walletconnectId,
      });
      setClient(signClient);
      setOrigin(signClient.metadata.url);
      prevRelayerValue.current = relayerRegion;
    } catch (err) {
      throw err;
    }
  }, [relayerRegion, origin]);

  useEffect(() => {
    if (!client) {
      createClient();
    }
  }, [createClient, relayerRegion, client]);

  useEffect(() => {
    if (!client) return;
    client.core.relayer.on(RELAYER_EVENTS.connect, () => {
      console.log('Network connection is restored!');
    });

    client.core.relayer.on(RELAYER_EVENTS.disconnect, () => {
      console.log('Network connection lost!');
    });
  }, [client]);

  return (
    <ClientContext.Provider
      value={{
        handleConnect: handleConnect,
        sendTransaction: sendTransaction,
        handleDisconnect: handleDisconnect,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
}

export function useWalletConnectClient() {
  const context = useContext(ClientContext);
  if (context === undefined) {
    throw new Error('useWalletConnectClient must be used within a ClientContextProvider');
  }
  return context;
}
