// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TokenList_container__A4obj {
  display: flex;
  margin-bottom: 20px;
}

.TokenList_tokens__\\+yhxv {
  overflow-x: scroll;
  display: flex;
  gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(64px, 1fr));
}

.TokenList_token__IaQQc {
  background-color: var(--alt8-color);
  border-radius: 4px;
  height: 60px;
  width: 66px;
  min-width: 66px;
  cursor: pointer;
  font-size: 10px;
  line-height: 12px;
  padding: 24px 0;
  position: relative;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.TokenList_tokenActive__gKl5U {
  background-color: var(--alt6-color);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  cursor: inherit;
  top: -2px;
}

.TokenList_token__IaQQc:hover {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  top: -2px;
}

.TokenList_allTokens__PO73D {
  margin-top: 10px;
  width: 109px;
  min-width: 109px;
  height: 60px;
  border-radius: 4px;
  background-color: var(--alt8-color);
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  padding: 24px 0;
  text-align: center;
}
.TokenList_allTokensActive__vUkfz {
  background-color: var(--primary-color);
}

.TokenList_border__pdQd- {
  background-color: var(--alt9-color);
  width: 1px;
  min-width: 1px;
  margin: 10px 12px 10px 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/collectible/TokenList/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,QAAQ;EACR,0DAA0D;AAC5D;;AAEA;EACE,mCAAmC;EACnC,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,eAAe;EACf,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mCAAmC;EACnC,yCAAyC;EACzC,eAAe;EACf,SAAS;AACX;;AAEA;EACE,yCAAyC;EACzC,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,mCAAmC;EACnC,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,sCAAsC;AACxC;;AAEA;EACE,mCAAmC;EACnC,UAAU;EACV,cAAc;EACd,2BAA2B;AAC7B","sourcesContent":[".container {\n  display: flex;\n  margin-bottom: 20px;\n}\n\n.tokens {\n  overflow-x: scroll;\n  display: flex;\n  gap: 8px;\n  grid-template-columns: repeat(auto-fit, minmax(64px, 1fr));\n}\n\n.token {\n  background-color: var(--alt8-color);\n  border-radius: 4px;\n  height: 60px;\n  width: 66px;\n  min-width: 66px;\n  cursor: pointer;\n  font-size: 10px;\n  line-height: 12px;\n  padding: 24px 0;\n  position: relative;\n  text-align: center;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.tokenActive {\n  background-color: var(--alt6-color);\n  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);\n  cursor: inherit;\n  top: -2px;\n}\n\n.token:hover {\n  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);\n  top: -2px;\n}\n\n.allTokens {\n  margin-top: 10px;\n  width: 109px;\n  min-width: 109px;\n  height: 60px;\n  border-radius: 4px;\n  background-color: var(--alt8-color);\n  cursor: pointer;\n  font-size: 12px;\n  line-height: 16px;\n  padding: 24px 0;\n  text-align: center;\n}\n.allTokensActive {\n  background-color: var(--primary-color);\n}\n\n.border {\n  background-color: var(--alt9-color);\n  width: 1px;\n  min-width: 1px;\n  margin: 10px 12px 10px 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TokenList_container__A4obj`,
	"tokens": `TokenList_tokens__+yhxv`,
	"token": `TokenList_token__IaQQc`,
	"tokenActive": `TokenList_tokenActive__gKl5U`,
	"allTokens": `TokenList_allTokens__PO73D`,
	"allTokensActive": `TokenList_allTokensActive__vUkfz`,
	"border": `TokenList_border__pdQd-`
};
export default ___CSS_LOADER_EXPORT___;
