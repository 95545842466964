/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { Empty } from "./google/protobuf/empty";

export const protobufPackage = "";

export interface Frontstore {
  frontstoreId: number;
  fid: string;
  fpwd: string;
  fhost: string;
  type: string;
  ethAddress: string;
}

export interface CreateFrontstoreReq {
  fid: string;
  fpwd: string;
  fhost: string;
  type: string;
  ethAddress: string;
}

export interface GetFrontstoreByIdReq {
  frontstoreId: number;
}

export interface GetFrontstoreByEthAddressReq {
  ethAddress: string;
}

export interface GetFrontstoresByEthAddressRes {
  frontstores: Frontstore[];
}

export interface UpdateFrontstoreReq {
  frontstoreId: number;
  data: UpdateFrontstoreReq_UpdateFrontstoreData | undefined;
}

export interface UpdateFrontstoreReq_UpdateFrontstoreData {
  fid: string;
  fpwd: string;
  fhost: string;
  type: string;
}

export interface DeleteFrontstoreReq {
  frontstoreId: number;
}

export interface GetFrontstoreTypesRes {
  frontstoreTypes: GetFrontstoreTypesRes_FrontstoreType[];
}

export interface GetFrontstoreTypesRes_FrontstoreType {
  code: string;
}

export interface AuthFrontstoreReq {
  ethAddress: string;
  applicationToken: string;
}

export interface AuthFrontstoreRes {
  jwt: string;
}

export interface CheckFrontstoreReq {
  applicationToken: string;
}

export interface CheckFrontstoreRes {
  status: string;
}

function createBaseFrontstore(): Frontstore {
  return { frontstoreId: 0, fid: "", fpwd: "", fhost: "", type: "", ethAddress: "" };
}

export const Frontstore = {
  encode(message: Frontstore, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.frontstoreId !== 0) {
      writer.uint32(8).int32(message.frontstoreId);
    }
    if (message.fid !== "") {
      writer.uint32(18).string(message.fid);
    }
    if (message.fpwd !== "") {
      writer.uint32(26).string(message.fpwd);
    }
    if (message.fhost !== "") {
      writer.uint32(34).string(message.fhost);
    }
    if (message.type !== "") {
      writer.uint32(42).string(message.type);
    }
    if (message.ethAddress !== "") {
      writer.uint32(50).string(message.ethAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Frontstore {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFrontstore();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.frontstoreId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fpwd = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fhost = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.type = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.ethAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Frontstore>, I>>(base?: I): Frontstore {
    return Frontstore.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Frontstore>, I>>(object: I): Frontstore {
    const message = createBaseFrontstore();
    message.frontstoreId = object.frontstoreId ?? 0;
    message.fid = object.fid ?? "";
    message.fpwd = object.fpwd ?? "";
    message.fhost = object.fhost ?? "";
    message.type = object.type ?? "";
    message.ethAddress = object.ethAddress ?? "";
    return message;
  },
};

function createBaseCreateFrontstoreReq(): CreateFrontstoreReq {
  return { fid: "", fpwd: "", fhost: "", type: "", ethAddress: "" };
}

export const CreateFrontstoreReq = {
  encode(message: CreateFrontstoreReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fid !== "") {
      writer.uint32(10).string(message.fid);
    }
    if (message.fpwd !== "") {
      writer.uint32(18).string(message.fpwd);
    }
    if (message.fhost !== "") {
      writer.uint32(26).string(message.fhost);
    }
    if (message.type !== "") {
      writer.uint32(34).string(message.type);
    }
    if (message.ethAddress !== "") {
      writer.uint32(42).string(message.ethAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateFrontstoreReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateFrontstoreReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fpwd = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fhost = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.type = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ethAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateFrontstoreReq>, I>>(base?: I): CreateFrontstoreReq {
    return CreateFrontstoreReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateFrontstoreReq>, I>>(object: I): CreateFrontstoreReq {
    const message = createBaseCreateFrontstoreReq();
    message.fid = object.fid ?? "";
    message.fpwd = object.fpwd ?? "";
    message.fhost = object.fhost ?? "";
    message.type = object.type ?? "";
    message.ethAddress = object.ethAddress ?? "";
    return message;
  },
};

function createBaseGetFrontstoreByIdReq(): GetFrontstoreByIdReq {
  return { frontstoreId: 0 };
}

export const GetFrontstoreByIdReq = {
  encode(message: GetFrontstoreByIdReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.frontstoreId !== 0) {
      writer.uint32(8).int32(message.frontstoreId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFrontstoreByIdReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFrontstoreByIdReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.frontstoreId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetFrontstoreByIdReq>, I>>(base?: I): GetFrontstoreByIdReq {
    return GetFrontstoreByIdReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFrontstoreByIdReq>, I>>(object: I): GetFrontstoreByIdReq {
    const message = createBaseGetFrontstoreByIdReq();
    message.frontstoreId = object.frontstoreId ?? 0;
    return message;
  },
};

function createBaseGetFrontstoreByEthAddressReq(): GetFrontstoreByEthAddressReq {
  return { ethAddress: "" };
}

export const GetFrontstoreByEthAddressReq = {
  encode(message: GetFrontstoreByEthAddressReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ethAddress !== "") {
      writer.uint32(10).string(message.ethAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFrontstoreByEthAddressReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFrontstoreByEthAddressReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ethAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetFrontstoreByEthAddressReq>, I>>(base?: I): GetFrontstoreByEthAddressReq {
    return GetFrontstoreByEthAddressReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFrontstoreByEthAddressReq>, I>>(object: I): GetFrontstoreByEthAddressReq {
    const message = createBaseGetFrontstoreByEthAddressReq();
    message.ethAddress = object.ethAddress ?? "";
    return message;
  },
};

function createBaseGetFrontstoresByEthAddressRes(): GetFrontstoresByEthAddressRes {
  return { frontstores: [] };
}

export const GetFrontstoresByEthAddressRes = {
  encode(message: GetFrontstoresByEthAddressRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.frontstores) {
      Frontstore.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFrontstoresByEthAddressRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFrontstoresByEthAddressRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.frontstores.push(Frontstore.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetFrontstoresByEthAddressRes>, I>>(base?: I): GetFrontstoresByEthAddressRes {
    return GetFrontstoresByEthAddressRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFrontstoresByEthAddressRes>, I>>(
    object: I,
  ): GetFrontstoresByEthAddressRes {
    const message = createBaseGetFrontstoresByEthAddressRes();
    message.frontstores = object.frontstores?.map((e) => Frontstore.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateFrontstoreReq(): UpdateFrontstoreReq {
  return { frontstoreId: 0, data: undefined };
}

export const UpdateFrontstoreReq = {
  encode(message: UpdateFrontstoreReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.frontstoreId !== 0) {
      writer.uint32(8).int32(message.frontstoreId);
    }
    if (message.data !== undefined) {
      UpdateFrontstoreReq_UpdateFrontstoreData.encode(message.data, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFrontstoreReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFrontstoreReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.frontstoreId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.data = UpdateFrontstoreReq_UpdateFrontstoreData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateFrontstoreReq>, I>>(base?: I): UpdateFrontstoreReq {
    return UpdateFrontstoreReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateFrontstoreReq>, I>>(object: I): UpdateFrontstoreReq {
    const message = createBaseUpdateFrontstoreReq();
    message.frontstoreId = object.frontstoreId ?? 0;
    message.data = (object.data !== undefined && object.data !== null)
      ? UpdateFrontstoreReq_UpdateFrontstoreData.fromPartial(object.data)
      : undefined;
    return message;
  },
};

function createBaseUpdateFrontstoreReq_UpdateFrontstoreData(): UpdateFrontstoreReq_UpdateFrontstoreData {
  return { fid: "", fpwd: "", fhost: "", type: "" };
}

export const UpdateFrontstoreReq_UpdateFrontstoreData = {
  encode(message: UpdateFrontstoreReq_UpdateFrontstoreData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fid !== "") {
      writer.uint32(10).string(message.fid);
    }
    if (message.fpwd !== "") {
      writer.uint32(18).string(message.fpwd);
    }
    if (message.fhost !== "") {
      writer.uint32(26).string(message.fhost);
    }
    if (message.type !== "") {
      writer.uint32(34).string(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFrontstoreReq_UpdateFrontstoreData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFrontstoreReq_UpdateFrontstoreData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fid = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fpwd = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fhost = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.type = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateFrontstoreReq_UpdateFrontstoreData>, I>>(
    base?: I,
  ): UpdateFrontstoreReq_UpdateFrontstoreData {
    return UpdateFrontstoreReq_UpdateFrontstoreData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateFrontstoreReq_UpdateFrontstoreData>, I>>(
    object: I,
  ): UpdateFrontstoreReq_UpdateFrontstoreData {
    const message = createBaseUpdateFrontstoreReq_UpdateFrontstoreData();
    message.fid = object.fid ?? "";
    message.fpwd = object.fpwd ?? "";
    message.fhost = object.fhost ?? "";
    message.type = object.type ?? "";
    return message;
  },
};

function createBaseDeleteFrontstoreReq(): DeleteFrontstoreReq {
  return { frontstoreId: 0 };
}

export const DeleteFrontstoreReq = {
  encode(message: DeleteFrontstoreReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.frontstoreId !== 0) {
      writer.uint32(8).int32(message.frontstoreId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteFrontstoreReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteFrontstoreReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.frontstoreId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DeleteFrontstoreReq>, I>>(base?: I): DeleteFrontstoreReq {
    return DeleteFrontstoreReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteFrontstoreReq>, I>>(object: I): DeleteFrontstoreReq {
    const message = createBaseDeleteFrontstoreReq();
    message.frontstoreId = object.frontstoreId ?? 0;
    return message;
  },
};

function createBaseGetFrontstoreTypesRes(): GetFrontstoreTypesRes {
  return { frontstoreTypes: [] };
}

export const GetFrontstoreTypesRes = {
  encode(message: GetFrontstoreTypesRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.frontstoreTypes) {
      GetFrontstoreTypesRes_FrontstoreType.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFrontstoreTypesRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFrontstoreTypesRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.frontstoreTypes.push(GetFrontstoreTypesRes_FrontstoreType.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetFrontstoreTypesRes>, I>>(base?: I): GetFrontstoreTypesRes {
    return GetFrontstoreTypesRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFrontstoreTypesRes>, I>>(object: I): GetFrontstoreTypesRes {
    const message = createBaseGetFrontstoreTypesRes();
    message.frontstoreTypes = object.frontstoreTypes?.map((e) => GetFrontstoreTypesRes_FrontstoreType.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseGetFrontstoreTypesRes_FrontstoreType(): GetFrontstoreTypesRes_FrontstoreType {
  return { code: "" };
}

export const GetFrontstoreTypesRes_FrontstoreType = {
  encode(message: GetFrontstoreTypesRes_FrontstoreType, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== "") {
      writer.uint32(10).string(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFrontstoreTypesRes_FrontstoreType {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFrontstoreTypesRes_FrontstoreType();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetFrontstoreTypesRes_FrontstoreType>, I>>(
    base?: I,
  ): GetFrontstoreTypesRes_FrontstoreType {
    return GetFrontstoreTypesRes_FrontstoreType.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFrontstoreTypesRes_FrontstoreType>, I>>(
    object: I,
  ): GetFrontstoreTypesRes_FrontstoreType {
    const message = createBaseGetFrontstoreTypesRes_FrontstoreType();
    message.code = object.code ?? "";
    return message;
  },
};

function createBaseAuthFrontstoreReq(): AuthFrontstoreReq {
  return { ethAddress: "", applicationToken: "" };
}

export const AuthFrontstoreReq = {
  encode(message: AuthFrontstoreReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ethAddress !== "") {
      writer.uint32(10).string(message.ethAddress);
    }
    if (message.applicationToken !== "") {
      writer.uint32(18).string(message.applicationToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthFrontstoreReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthFrontstoreReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ethAddress = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.applicationToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthFrontstoreReq>, I>>(base?: I): AuthFrontstoreReq {
    return AuthFrontstoreReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthFrontstoreReq>, I>>(object: I): AuthFrontstoreReq {
    const message = createBaseAuthFrontstoreReq();
    message.ethAddress = object.ethAddress ?? "";
    message.applicationToken = object.applicationToken ?? "";
    return message;
  },
};

function createBaseAuthFrontstoreRes(): AuthFrontstoreRes {
  return { jwt: "" };
}

export const AuthFrontstoreRes = {
  encode(message: AuthFrontstoreRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.jwt !== "") {
      writer.uint32(10).string(message.jwt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthFrontstoreRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthFrontstoreRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.jwt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthFrontstoreRes>, I>>(base?: I): AuthFrontstoreRes {
    return AuthFrontstoreRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AuthFrontstoreRes>, I>>(object: I): AuthFrontstoreRes {
    const message = createBaseAuthFrontstoreRes();
    message.jwt = object.jwt ?? "";
    return message;
  },
};

function createBaseCheckFrontstoreReq(): CheckFrontstoreReq {
  return { applicationToken: "" };
}

export const CheckFrontstoreReq = {
  encode(message: CheckFrontstoreReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.applicationToken !== "") {
      writer.uint32(18).string(message.applicationToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CheckFrontstoreReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckFrontstoreReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.applicationToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CheckFrontstoreReq>, I>>(base?: I): CheckFrontstoreReq {
    return CheckFrontstoreReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CheckFrontstoreReq>, I>>(object: I): CheckFrontstoreReq {
    const message = createBaseCheckFrontstoreReq();
    message.applicationToken = object.applicationToken ?? "";
    return message;
  },
};

function createBaseCheckFrontstoreRes(): CheckFrontstoreRes {
  return { status: "" };
}

export const CheckFrontstoreRes = {
  encode(message: CheckFrontstoreRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== "") {
      writer.uint32(10).string(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CheckFrontstoreRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCheckFrontstoreRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.status = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CheckFrontstoreRes>, I>>(base?: I): CheckFrontstoreRes {
    return CheckFrontstoreRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CheckFrontstoreRes>, I>>(object: I): CheckFrontstoreRes {
    const message = createBaseCheckFrontstoreRes();
    message.status = object.status ?? "";
    return message;
  },
};

export interface FrontstoreService {
  CreateFrontstore(request: DeepPartial<CreateFrontstoreReq>, metadata?: grpc.Metadata): Promise<Frontstore>;
  GetFrontstoreById(request: DeepPartial<GetFrontstoreByIdReq>, metadata?: grpc.Metadata): Promise<Frontstore>;
  GetFrontstoresByEthAddress(
    request: DeepPartial<GetFrontstoreByEthAddressReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetFrontstoresByEthAddressRes>;
  UpdateFrontstore(request: DeepPartial<UpdateFrontstoreReq>, metadata?: grpc.Metadata): Promise<Frontstore>;
  DeleteFrontstore(request: DeepPartial<DeleteFrontstoreReq>, metadata?: grpc.Metadata): Promise<Empty>;
  GetFrontstoreTypes(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<GetFrontstoreTypesRes>;
  CheckFrontstore(request: DeepPartial<CheckFrontstoreReq>, metadata?: grpc.Metadata): Promise<CheckFrontstoreRes>;
  AuthFrontstore(request: DeepPartial<AuthFrontstoreReq>, metadata?: grpc.Metadata): Promise<AuthFrontstoreRes>;
}

export class FrontstoreServiceClientImpl implements FrontstoreService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.CreateFrontstore = this.CreateFrontstore.bind(this);
    this.GetFrontstoreById = this.GetFrontstoreById.bind(this);
    this.GetFrontstoresByEthAddress = this.GetFrontstoresByEthAddress.bind(this);
    this.UpdateFrontstore = this.UpdateFrontstore.bind(this);
    this.DeleteFrontstore = this.DeleteFrontstore.bind(this);
    this.GetFrontstoreTypes = this.GetFrontstoreTypes.bind(this);
    this.CheckFrontstore = this.CheckFrontstore.bind(this);
    this.AuthFrontstore = this.AuthFrontstore.bind(this);
  }

  CreateFrontstore(request: DeepPartial<CreateFrontstoreReq>, metadata?: grpc.Metadata): Promise<Frontstore> {
    return this.rpc.unary(FrontstoreServiceCreateFrontstoreDesc, CreateFrontstoreReq.fromPartial(request), metadata);
  }

  GetFrontstoreById(request: DeepPartial<GetFrontstoreByIdReq>, metadata?: grpc.Metadata): Promise<Frontstore> {
    return this.rpc.unary(FrontstoreServiceGetFrontstoreByIdDesc, GetFrontstoreByIdReq.fromPartial(request), metadata);
  }

  GetFrontstoresByEthAddress(
    request: DeepPartial<GetFrontstoreByEthAddressReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetFrontstoresByEthAddressRes> {
    return this.rpc.unary(
      FrontstoreServiceGetFrontstoresByEthAddressDesc,
      GetFrontstoreByEthAddressReq.fromPartial(request),
      metadata,
    );
  }

  UpdateFrontstore(request: DeepPartial<UpdateFrontstoreReq>, metadata?: grpc.Metadata): Promise<Frontstore> {
    return this.rpc.unary(FrontstoreServiceUpdateFrontstoreDesc, UpdateFrontstoreReq.fromPartial(request), metadata);
  }

  DeleteFrontstore(request: DeepPartial<DeleteFrontstoreReq>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(FrontstoreServiceDeleteFrontstoreDesc, DeleteFrontstoreReq.fromPartial(request), metadata);
  }

  GetFrontstoreTypes(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<GetFrontstoreTypesRes> {
    return this.rpc.unary(FrontstoreServiceGetFrontstoreTypesDesc, Empty.fromPartial(request), metadata);
  }

  CheckFrontstore(request: DeepPartial<CheckFrontstoreReq>, metadata?: grpc.Metadata): Promise<CheckFrontstoreRes> {
    return this.rpc.unary(FrontstoreServiceCheckFrontstoreDesc, CheckFrontstoreReq.fromPartial(request), metadata);
  }

  AuthFrontstore(request: DeepPartial<AuthFrontstoreReq>, metadata?: grpc.Metadata): Promise<AuthFrontstoreRes> {
    return this.rpc.unary(FrontstoreServiceAuthFrontstoreDesc, AuthFrontstoreReq.fromPartial(request), metadata);
  }
}

export const FrontstoreServiceDesc = { serviceName: "FrontstoreService" };

export const FrontstoreServiceCreateFrontstoreDesc: UnaryMethodDefinitionish = {
  methodName: "CreateFrontstore",
  service: FrontstoreServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateFrontstoreReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Frontstore.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const FrontstoreServiceGetFrontstoreByIdDesc: UnaryMethodDefinitionish = {
  methodName: "GetFrontstoreById",
  service: FrontstoreServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFrontstoreByIdReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Frontstore.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const FrontstoreServiceGetFrontstoresByEthAddressDesc: UnaryMethodDefinitionish = {
  methodName: "GetFrontstoresByEthAddress",
  service: FrontstoreServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetFrontstoreByEthAddressReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFrontstoresByEthAddressRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const FrontstoreServiceUpdateFrontstoreDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateFrontstore",
  service: FrontstoreServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateFrontstoreReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Frontstore.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const FrontstoreServiceDeleteFrontstoreDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteFrontstore",
  service: FrontstoreServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteFrontstoreReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const FrontstoreServiceGetFrontstoreTypesDesc: UnaryMethodDefinitionish = {
  methodName: "GetFrontstoreTypes",
  service: FrontstoreServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetFrontstoreTypesRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const FrontstoreServiceCheckFrontstoreDesc: UnaryMethodDefinitionish = {
  methodName: "CheckFrontstore",
  service: FrontstoreServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CheckFrontstoreReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CheckFrontstoreRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const FrontstoreServiceAuthFrontstoreDesc: UnaryMethodDefinitionish = {
  methodName: "AuthFrontstore",
  service: FrontstoreServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AuthFrontstoreReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AuthFrontstoreRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
