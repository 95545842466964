import clsx from 'clsx';

import { IconProps } from '../types';
import css from './index.module.css';

const ASPECT_RATIO = 1;

export const SortIcon = ({ className, size = 24, onClick }: IconProps) => (
  <svg
    width={size}
    height={size / ASPECT_RATIO}
    viewBox="0 0 24 24"
    onClick={onClick}
    fill="none"
    className={clsx(css.icon, className)}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="6" y="6" width="9" height="3" fill="#4B5362" />
    <rect x="6" y="10.5" width="5.25" height="3" fill="#4B5362" />
    <rect x="6" y="15" width="12" height="3" fill="#4B5362" />
  </svg>
);
