// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavButtons_wrapper__Petia {
  display: flex;
  gap: 24px;
}

.NavButtons_footer__BTC0d {
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/components/collectible/NavButtons/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".wrapper {\n  display: flex;\n  gap: 24px;\n}\n\n.footer {\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `NavButtons_wrapper__Petia`,
	"footer": `NavButtons_footer__BTC0d`
};
export default ___CSS_LOADER_EXPORT___;
