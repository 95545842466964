/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { Currency } from "./currency";

export const protobufPackage = "";

export interface GetNetworksReq {
}

export interface GasPriceSettings {
  url: string;
  extract: string;
  jsonpath: GasPriceSettings_GasPriceSettingsJsonPath | undefined;
}

export interface GasPriceSettings_GasPriceSettingsJsonPath {
  message: string;
  min?: GasPriceSettings_GasPriceSettingsJsonPath_FeeValue | undefined;
  avg: GasPriceSettings_GasPriceSettingsJsonPath_FeeValue | undefined;
  high: GasPriceSettings_GasPriceSettingsJsonPath_FeeValue | undefined;
  rapid: GasPriceSettings_GasPriceSettingsJsonPath_FeeValue | undefined;
  validate: GasPriceSettings_GasPriceSettingsJsonPath_GasPriceSettingsJsonPathValidate | undefined;
}

export interface GasPriceSettings_GasPriceSettingsJsonPath_FeeValue {
  fee: string;
  priorityFee: string;
}

export interface GasPriceSettings_GasPriceSettingsJsonPath_GasPriceSettingsJsonPathValidate {
  message: string;
}

export interface Network {
  id: string;
  name: string;
  chainId?: string | undefined;
  icon?: string | undefined;
  coin: Currency | undefined;
  currencies: Currency[];
  gasPrice: GasPriceSettings | undefined;
  blockExplorerUrl: string;
  rpcUrl: string;
  wethAddress: string;
  listTokenUrl: string;
}

export interface GetNetworksRes {
  networks: Network[];
}

export interface GasPriceReq {
  networkId: string;
}

export interface GasPriceRes {
  min?: GasPriceRes_FeeValue | undefined;
  avg: GasPriceRes_FeeValue | undefined;
  high: GasPriceRes_FeeValue | undefined;
  rapid: GasPriceRes_FeeValue | undefined;
}

export interface GasPriceRes_FeeValue {
  feePerGas: number;
  priorityFeePerGas: number;
}

function createBaseGetNetworksReq(): GetNetworksReq {
  return {};
}

export const GetNetworksReq = {
  encode(_: GetNetworksReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNetworksReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNetworksReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetNetworksReq>, I>>(base?: I): GetNetworksReq {
    return GetNetworksReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNetworksReq>, I>>(_: I): GetNetworksReq {
    const message = createBaseGetNetworksReq();
    return message;
  },
};

function createBaseGasPriceSettings(): GasPriceSettings {
  return { url: "", extract: "", jsonpath: undefined };
}

export const GasPriceSettings = {
  encode(message: GasPriceSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.url !== "") {
      writer.uint32(10).string(message.url);
    }
    if (message.extract !== "") {
      writer.uint32(18).string(message.extract);
    }
    if (message.jsonpath !== undefined) {
      GasPriceSettings_GasPriceSettingsJsonPath.encode(message.jsonpath, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GasPriceSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGasPriceSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.url = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.extract = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.jsonpath = GasPriceSettings_GasPriceSettingsJsonPath.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GasPriceSettings>, I>>(base?: I): GasPriceSettings {
    return GasPriceSettings.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GasPriceSettings>, I>>(object: I): GasPriceSettings {
    const message = createBaseGasPriceSettings();
    message.url = object.url ?? "";
    message.extract = object.extract ?? "";
    message.jsonpath = (object.jsonpath !== undefined && object.jsonpath !== null)
      ? GasPriceSettings_GasPriceSettingsJsonPath.fromPartial(object.jsonpath)
      : undefined;
    return message;
  },
};

function createBaseGasPriceSettings_GasPriceSettingsJsonPath(): GasPriceSettings_GasPriceSettingsJsonPath {
  return { message: "", min: undefined, avg: undefined, high: undefined, rapid: undefined, validate: undefined };
}

export const GasPriceSettings_GasPriceSettingsJsonPath = {
  encode(message: GasPriceSettings_GasPriceSettingsJsonPath, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.message !== "") {
      writer.uint32(10).string(message.message);
    }
    if (message.min !== undefined) {
      GasPriceSettings_GasPriceSettingsJsonPath_FeeValue.encode(message.min, writer.uint32(18).fork()).ldelim();
    }
    if (message.avg !== undefined) {
      GasPriceSettings_GasPriceSettingsJsonPath_FeeValue.encode(message.avg, writer.uint32(26).fork()).ldelim();
    }
    if (message.high !== undefined) {
      GasPriceSettings_GasPriceSettingsJsonPath_FeeValue.encode(message.high, writer.uint32(34).fork()).ldelim();
    }
    if (message.rapid !== undefined) {
      GasPriceSettings_GasPriceSettingsJsonPath_FeeValue.encode(message.rapid, writer.uint32(42).fork()).ldelim();
    }
    if (message.validate !== undefined) {
      GasPriceSettings_GasPriceSettingsJsonPath_GasPriceSettingsJsonPathValidate.encode(
        message.validate,
        writer.uint32(50).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GasPriceSettings_GasPriceSettingsJsonPath {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGasPriceSettings_GasPriceSettingsJsonPath();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.min = GasPriceSettings_GasPriceSettingsJsonPath_FeeValue.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.avg = GasPriceSettings_GasPriceSettingsJsonPath_FeeValue.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.high = GasPriceSettings_GasPriceSettingsJsonPath_FeeValue.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.rapid = GasPriceSettings_GasPriceSettingsJsonPath_FeeValue.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.validate = GasPriceSettings_GasPriceSettingsJsonPath_GasPriceSettingsJsonPathValidate.decode(
            reader,
            reader.uint32(),
          );
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GasPriceSettings_GasPriceSettingsJsonPath>, I>>(
    base?: I,
  ): GasPriceSettings_GasPriceSettingsJsonPath {
    return GasPriceSettings_GasPriceSettingsJsonPath.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GasPriceSettings_GasPriceSettingsJsonPath>, I>>(
    object: I,
  ): GasPriceSettings_GasPriceSettingsJsonPath {
    const message = createBaseGasPriceSettings_GasPriceSettingsJsonPath();
    message.message = object.message ?? "";
    message.min = (object.min !== undefined && object.min !== null)
      ? GasPriceSettings_GasPriceSettingsJsonPath_FeeValue.fromPartial(object.min)
      : undefined;
    message.avg = (object.avg !== undefined && object.avg !== null)
      ? GasPriceSettings_GasPriceSettingsJsonPath_FeeValue.fromPartial(object.avg)
      : undefined;
    message.high = (object.high !== undefined && object.high !== null)
      ? GasPriceSettings_GasPriceSettingsJsonPath_FeeValue.fromPartial(object.high)
      : undefined;
    message.rapid = (object.rapid !== undefined && object.rapid !== null)
      ? GasPriceSettings_GasPriceSettingsJsonPath_FeeValue.fromPartial(object.rapid)
      : undefined;
    message.validate = (object.validate !== undefined && object.validate !== null)
      ? GasPriceSettings_GasPriceSettingsJsonPath_GasPriceSettingsJsonPathValidate.fromPartial(object.validate)
      : undefined;
    return message;
  },
};

function createBaseGasPriceSettings_GasPriceSettingsJsonPath_FeeValue(): GasPriceSettings_GasPriceSettingsJsonPath_FeeValue {
  return { fee: "", priorityFee: "" };
}

export const GasPriceSettings_GasPriceSettingsJsonPath_FeeValue = {
  encode(
    message: GasPriceSettings_GasPriceSettingsJsonPath_FeeValue,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.fee !== "") {
      writer.uint32(10).string(message.fee);
    }
    if (message.priorityFee !== "") {
      writer.uint32(18).string(message.priorityFee);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GasPriceSettings_GasPriceSettingsJsonPath_FeeValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGasPriceSettings_GasPriceSettingsJsonPath_FeeValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fee = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.priorityFee = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GasPriceSettings_GasPriceSettingsJsonPath_FeeValue>, I>>(
    base?: I,
  ): GasPriceSettings_GasPriceSettingsJsonPath_FeeValue {
    return GasPriceSettings_GasPriceSettingsJsonPath_FeeValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GasPriceSettings_GasPriceSettingsJsonPath_FeeValue>, I>>(
    object: I,
  ): GasPriceSettings_GasPriceSettingsJsonPath_FeeValue {
    const message = createBaseGasPriceSettings_GasPriceSettingsJsonPath_FeeValue();
    message.fee = object.fee ?? "";
    message.priorityFee = object.priorityFee ?? "";
    return message;
  },
};

function createBaseGasPriceSettings_GasPriceSettingsJsonPath_GasPriceSettingsJsonPathValidate(): GasPriceSettings_GasPriceSettingsJsonPath_GasPriceSettingsJsonPathValidate {
  return { message: "" };
}

export const GasPriceSettings_GasPriceSettingsJsonPath_GasPriceSettingsJsonPathValidate = {
  encode(
    message: GasPriceSettings_GasPriceSettingsJsonPath_GasPriceSettingsJsonPathValidate,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.message !== "") {
      writer.uint32(10).string(message.message);
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GasPriceSettings_GasPriceSettingsJsonPath_GasPriceSettingsJsonPathValidate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGasPriceSettings_GasPriceSettingsJsonPath_GasPriceSettingsJsonPathValidate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GasPriceSettings_GasPriceSettingsJsonPath_GasPriceSettingsJsonPathValidate>, I>>(
    base?: I,
  ): GasPriceSettings_GasPriceSettingsJsonPath_GasPriceSettingsJsonPathValidate {
    return GasPriceSettings_GasPriceSettingsJsonPath_GasPriceSettingsJsonPathValidate.fromPartial(base ?? ({} as any));
  },
  fromPartial<
    I extends Exact<DeepPartial<GasPriceSettings_GasPriceSettingsJsonPath_GasPriceSettingsJsonPathValidate>, I>,
  >(object: I): GasPriceSettings_GasPriceSettingsJsonPath_GasPriceSettingsJsonPathValidate {
    const message = createBaseGasPriceSettings_GasPriceSettingsJsonPath_GasPriceSettingsJsonPathValidate();
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseNetwork(): Network {
  return {
    id: "",
    name: "",
    chainId: undefined,
    icon: undefined,
    coin: undefined,
    currencies: [],
    gasPrice: undefined,
    blockExplorerUrl: "",
    rpcUrl: "",
    wethAddress: "",
    listTokenUrl: "",
  };
}

export const Network = {
  encode(message: Network, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.chainId !== undefined) {
      writer.uint32(26).string(message.chainId);
    }
    if (message.icon !== undefined) {
      writer.uint32(34).string(message.icon);
    }
    if (message.coin !== undefined) {
      Currency.encode(message.coin, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.currencies) {
      Currency.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.gasPrice !== undefined) {
      GasPriceSettings.encode(message.gasPrice, writer.uint32(58).fork()).ldelim();
    }
    if (message.blockExplorerUrl !== "") {
      writer.uint32(66).string(message.blockExplorerUrl);
    }
    if (message.rpcUrl !== "") {
      writer.uint32(74).string(message.rpcUrl);
    }
    if (message.wethAddress !== "") {
      writer.uint32(82).string(message.wethAddress);
    }
    if (message.listTokenUrl !== "") {
      writer.uint32(90).string(message.listTokenUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Network {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNetwork();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.chainId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.icon = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.coin = Currency.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.currencies.push(Currency.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.gasPrice = GasPriceSettings.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.blockExplorerUrl = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.rpcUrl = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.wethAddress = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.listTokenUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Network>, I>>(base?: I): Network {
    return Network.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Network>, I>>(object: I): Network {
    const message = createBaseNetwork();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.chainId = object.chainId ?? undefined;
    message.icon = object.icon ?? undefined;
    message.coin = (object.coin !== undefined && object.coin !== null) ? Currency.fromPartial(object.coin) : undefined;
    message.currencies = object.currencies?.map((e) => Currency.fromPartial(e)) || [];
    message.gasPrice = (object.gasPrice !== undefined && object.gasPrice !== null)
      ? GasPriceSettings.fromPartial(object.gasPrice)
      : undefined;
    message.blockExplorerUrl = object.blockExplorerUrl ?? "";
    message.rpcUrl = object.rpcUrl ?? "";
    message.wethAddress = object.wethAddress ?? "";
    message.listTokenUrl = object.listTokenUrl ?? "";
    return message;
  },
};

function createBaseGetNetworksRes(): GetNetworksRes {
  return { networks: [] };
}

export const GetNetworksRes = {
  encode(message: GetNetworksRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.networks) {
      Network.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNetworksRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNetworksRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.networks.push(Network.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetNetworksRes>, I>>(base?: I): GetNetworksRes {
    return GetNetworksRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNetworksRes>, I>>(object: I): GetNetworksRes {
    const message = createBaseGetNetworksRes();
    message.networks = object.networks?.map((e) => Network.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGasPriceReq(): GasPriceReq {
  return { networkId: "" };
}

export const GasPriceReq = {
  encode(message: GasPriceReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.networkId !== "") {
      writer.uint32(10).string(message.networkId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GasPriceReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGasPriceReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.networkId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GasPriceReq>, I>>(base?: I): GasPriceReq {
    return GasPriceReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GasPriceReq>, I>>(object: I): GasPriceReq {
    const message = createBaseGasPriceReq();
    message.networkId = object.networkId ?? "";
    return message;
  },
};

function createBaseGasPriceRes(): GasPriceRes {
  return { min: undefined, avg: undefined, high: undefined, rapid: undefined };
}

export const GasPriceRes = {
  encode(message: GasPriceRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.min !== undefined) {
      GasPriceRes_FeeValue.encode(message.min, writer.uint32(10).fork()).ldelim();
    }
    if (message.avg !== undefined) {
      GasPriceRes_FeeValue.encode(message.avg, writer.uint32(18).fork()).ldelim();
    }
    if (message.high !== undefined) {
      GasPriceRes_FeeValue.encode(message.high, writer.uint32(26).fork()).ldelim();
    }
    if (message.rapid !== undefined) {
      GasPriceRes_FeeValue.encode(message.rapid, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GasPriceRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGasPriceRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.min = GasPriceRes_FeeValue.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.avg = GasPriceRes_FeeValue.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.high = GasPriceRes_FeeValue.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.rapid = GasPriceRes_FeeValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GasPriceRes>, I>>(base?: I): GasPriceRes {
    return GasPriceRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GasPriceRes>, I>>(object: I): GasPriceRes {
    const message = createBaseGasPriceRes();
    message.min = (object.min !== undefined && object.min !== null)
      ? GasPriceRes_FeeValue.fromPartial(object.min)
      : undefined;
    message.avg = (object.avg !== undefined && object.avg !== null)
      ? GasPriceRes_FeeValue.fromPartial(object.avg)
      : undefined;
    message.high = (object.high !== undefined && object.high !== null)
      ? GasPriceRes_FeeValue.fromPartial(object.high)
      : undefined;
    message.rapid = (object.rapid !== undefined && object.rapid !== null)
      ? GasPriceRes_FeeValue.fromPartial(object.rapid)
      : undefined;
    return message;
  },
};

function createBaseGasPriceRes_FeeValue(): GasPriceRes_FeeValue {
  return { feePerGas: 0, priorityFeePerGas: 0 };
}

export const GasPriceRes_FeeValue = {
  encode(message: GasPriceRes_FeeValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.feePerGas !== 0) {
      writer.uint32(13).float(message.feePerGas);
    }
    if (message.priorityFeePerGas !== 0) {
      writer.uint32(21).float(message.priorityFeePerGas);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GasPriceRes_FeeValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGasPriceRes_FeeValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.feePerGas = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.priorityFeePerGas = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GasPriceRes_FeeValue>, I>>(base?: I): GasPriceRes_FeeValue {
    return GasPriceRes_FeeValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GasPriceRes_FeeValue>, I>>(object: I): GasPriceRes_FeeValue {
    const message = createBaseGasPriceRes_FeeValue();
    message.feePerGas = object.feePerGas ?? 0;
    message.priorityFeePerGas = object.priorityFeePerGas ?? 0;
    return message;
  },
};

export interface NetworksService {
  GetNetworks(request: DeepPartial<GetNetworksReq>, metadata?: grpc.Metadata): Promise<GetNetworksRes>;
  GetGasPrice(request: DeepPartial<GasPriceReq>, metadata?: grpc.Metadata): Promise<GasPriceRes>;
}

export class NetworksServiceClientImpl implements NetworksService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetNetworks = this.GetNetworks.bind(this);
    this.GetGasPrice = this.GetGasPrice.bind(this);
  }

  GetNetworks(request: DeepPartial<GetNetworksReq>, metadata?: grpc.Metadata): Promise<GetNetworksRes> {
    return this.rpc.unary(NetworksServiceGetNetworksDesc, GetNetworksReq.fromPartial(request), metadata);
  }

  GetGasPrice(request: DeepPartial<GasPriceReq>, metadata?: grpc.Metadata): Promise<GasPriceRes> {
    return this.rpc.unary(NetworksServiceGetGasPriceDesc, GasPriceReq.fromPartial(request), metadata);
  }
}

export const NetworksServiceDesc = { serviceName: "NetworksService" };

export const NetworksServiceGetNetworksDesc: UnaryMethodDefinitionish = {
  methodName: "GetNetworks",
  service: NetworksServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetNetworksReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetNetworksRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const NetworksServiceGetGasPriceDesc: UnaryMethodDefinitionish = {
  methodName: "GetGasPrice",
  service: NetworksServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GasPriceReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GasPriceRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
