import { useTranslation } from 'react-i18next';

import css from './index.module.css';

export const VideoPlayer = ({ src }: { src: string }) => {
  const { t } = useTranslation();
  return (
    <video className={css.video} controls src={src}>
      {t('components.videoPlayer.notSupported')}
    </video>
  );
};
