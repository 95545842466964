import { IpfsCollectibleInfo, IpfsFile } from 'beasy-fe-commons';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getCollectibles } from 'services/collectible';

import { useIpfsInfos } from './useIpfs';
import { useSelector } from 'react-redux';
import { AppState } from 'state';

export const renderOption = ({ name }: IpfsFile) => name;

export interface TermsOptions {
  licenseOptions: IpfsFile[];
  ownershipOptions: IpfsFile[];
}

type Options = { [cid: string]: IpfsFile };

const empty = [] as const;

export const useTermsOptions = (limit?: number) => {
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  const request = { creator: walletConnectV2.address };
  const { data: collectibles } = useQuery(['getCollectibles', request], () => getCollectibles(request));

  const cids = useMemo(
    () => (collectibles ? collectibles.slice(0, limit).map(({ CID }) => CID) : empty),
    [collectibles, limit],
  );

  const infos = useIpfsInfos<IpfsCollectibleInfo>(cids);

  return useMemo<TermsOptions>(() => {
    const licenseMap: Options = {};
    const ownershipMap: Options = {};

    for (const {
      beasy: {
        docs: {
          license: [license],
          ownership: [ownership],
        },
      },
    } of infos) {
      if (license) licenseMap[license.cid] = license;
      if (ownership) ownershipMap[ownership.cid] = ownership;
    }

    return {
      licenseOptions: Object.values(licenseMap),
      ownershipOptions: Object.values(ownershipMap),
    };
  }, [infos]);
};
