import clsx from 'clsx';
import { useCallback } from 'react';

import { ReactComponent as Check } from 'assets/images/check.svg';

import css from './index.module.css';

interface Props<T extends number> {
  isComplete: boolean;
  isDisabled: boolean;
  isSelected: boolean;
  label: string;
  value: T;
  index: number;
  onSelect: (value: T) => void;
}

export const PageTabItem = <T extends number>({
  isComplete,
  isDisabled,
  isSelected,
  label,
  value,
  onSelect,
  index,
}: Props<T>) => {
  const handleClick = useCallback(() => {
    if (!isDisabled) {
      onSelect(value);
    }
  }, [isDisabled, onSelect, value]);

  return (
    <li
      className={clsx(css.item, { [css.itemSelected]: isSelected, [css.itemDisabled]: isDisabled })}
      onClick={handleClick}
    >
      {isComplete ? (
        <span className={css.check}>
          <Check />
        </span>
      ) : (
        <span className={clsx(css.step, { [css.stepSelected]: isSelected })}>
          <p>{index + 1}</p>
        </span>
      )}
      {label}
    </li>
  );
};
