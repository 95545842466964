// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MintSlider_wrapper__vUnuf {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.MintSlider_thumb__kOzlV {
  width: 24px;
  height: 24px;
  border: solid 6px var(--grey-0);
  border-radius: 50%;
  background-color: var(--primary-color);
}

.MintSlider_thumb__kOzlV > p {
  color: var(--text-color-accent);
  margin-block-start: 21px;
}
`, "",{"version":3,"sources":["webpack://./src/components/input/MintSlider/index.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,+BAA+B;EAC/B,kBAAkB;EAClB,sCAAsC;AACxC;;AAEA;EACE,+BAA+B;EAC/B,wBAAwB;AAC1B","sourcesContent":[".wrapper {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  width: 100%;\n  justify-content: space-between;\n}\n\n.thumb {\n  width: 24px;\n  height: 24px;\n  border: solid 6px var(--grey-0);\n  border-radius: 50%;\n  background-color: var(--primary-color);\n}\n\n.thumb > p {\n  color: var(--text-color-accent);\n  margin-block-start: 21px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MintSlider_wrapper__vUnuf`,
	"thumb": `MintSlider_thumb__kOzlV`
};
export default ___CSS_LOADER_EXPORT___;
