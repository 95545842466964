import { useTranslation } from 'react-i18next';

import { Spacer } from 'components/Spacer';
import { ButtonPrimary } from 'components/button';

import css from './index.module.css';

interface Props {
  quantity: number;
  onClick: () => void;
}

export const Available = ({ quantity, onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={css.wrapper}>
      <div>
        <p>{t('components.collectible.available')}</p>
        <h2>
          {quantity} {quantity === 1 ? 'token' : 'tokens'}
        </h2>
      </div>
      <Spacer width="40px" />
      <ButtonPrimary onClick={onClick}>+ Mint new</ButtonPrimary>
    </div>
  );
};
