import clsx from 'clsx';

import { IconProps } from '../types';
import css from './index.module.css';

const ASPECT_RATIO = 0.9439;

export const ArrowIcon = ({ className, size = 13.952, onClick }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(css.icon, className)}
    onClick={onClick}
    width={size}
    height={size / ASPECT_RATIO}
    viewBox="0 0 13.952 14.781"
  >
    <g id="Icon_feather-arrow-right" data-name="Icon feather-arrow-right" transform="translate(1 1.414)">
      <path
        id="Path_248"
        data-name="Path 248"
        d="M19.452,18H7.5"
        transform="translate(-7.5 -12.024)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_249"
        data-name="Path 249"
        d="M23.976,7.5,18,13.476l5.976,5.976"
        transform="translate(-18 -7.5)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);
