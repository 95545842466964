import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Spacer } from 'components/Spacer';
import { ButtonPrimary, ButtonSecondary } from 'components/button';
import { DialogArea, DialogClose, DialogFooter, DialogHeader, DialogOverlay } from 'components/dialog';
import { MintSlider, TextInput } from 'components/input';

import { MintNFTController } from 'screens/collectible/View/useMintNFT';

import css from './index.module.css';
import { useSelector } from 'react-redux';
import { AppState } from 'state';

interface Props {
  controller: MintNFTController;
  collectionId: string;
  collectibleId: string;
  quantity: number;
  onClose: () => void;
}

export const MintDialog = ({ controller, collectionId, collectibleId, quantity, onClose }: Props) => {
  const { t } = useTranslation();
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  const fromAddress = walletConnectV2.address!
  const [isWallet, setWallet] = useState(true);
  const [isOtherUser, setOtherUser] = useState(false);
  const [totalMint, setTotalMint] = useState(0);
  const [address, setAddress] = useState(fromAddress);

  const handleWallet = useCallback(() => {
    if (!isWallet) {
      setAddress(fromAddress);
      setWallet(true);
      setOtherUser(false);
    }
  }, [fromAddress, isWallet]);

  const handleOtherUser = useCallback(() => {
    if (!isOtherUser) {
      setWallet(false);
      setOtherUser(true);
    }
  }, [isOtherUser]);

  const handleMint = useCallback(async () => {
    onClose();
    controller.mint({
      chainId: Number(walletConnectV2.chainId),
      collectionId: collectionId,
      collectibleId: collectibleId,
      from: fromAddress,
      to: address,
      quantity: totalMint,
    });
  }, [onClose, controller, walletConnectV2.chainId, collectionId, collectibleId, fromAddress, address, totalMint]);
  function handleInput(event: any) {
    setAddress(event.target.value);
  }

  return (
    <>
      <DialogOverlay>
        <DialogArea className={clsx(css.area)}>
          <DialogHeader>
            <h3>{t('components.collectible.mintTokens')}</h3>
            {onClose && <DialogClose onClick={onClose} />}
          </DialogHeader>
          <Spacer height="12px" />
          <p>{t('components.collectible.selectTokenNr')}</p>
          <Spacer height="24px" />
          <p>{t('components.collectible.tokensQuantity')}</p>

          <MintSlider
            min={0}
            max={quantity}
            step={1}
            marks={[0, quantity]}
            renderMark={props => {
              return <p className={css.keys}> {props.key} </p>;
            }}
            renderThumb={(props, state) => (
              <div {...props}>
                <p className={css.keys}>{state.valueNow > 0 && state.valueNow < quantity && state.valueNow}</p>
              </div>
            )}
            onChange={value => setTotalMint(value)}
          />
          <Spacer height="12px" />
          <p>{t('components.collectible.selectWallet')}</p>
          <Spacer height="24px" />
          <p>Direction of minting</p>
          <Spacer height="16px" />
          <fieldset>
            <div>
              <input type="radio" onClick={handleWallet} checked={isWallet} />
              <Spacer width="16px" />
              <label>In my wallet</label>
            </div>
            <Spacer height="16px" />
            <div>
              <input type="radio" onClick={handleOtherUser} checked={isOtherUser} />
              <Spacer width="16px" />
              <label>Other user</label>
            </div>
          </fieldset>
          <Spacer height="24px" />

          {isOtherUser && (
            <>
              <p>Wallet Address</p>
              <Spacer height="4px" />
              <TextInput className={css.box} onChange={handleInput} />
              <Spacer height="24px" />
            </>
          )}
          <DialogFooter>
            <ButtonSecondary onClick={onClose}>Cancel</ButtonSecondary>
            <ButtonPrimary disabled={totalMint === 0} onClick={handleMint}>
              Mint
            </ButtonPrimary>
          </DialogFooter>
        </DialogArea>
      </DialogOverlay>
    </>
  );
};
