// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__2kWVk h5 {
  font-size: 14px;
  opacity: 0.93;
  color: var(--body-color);
}

.Header_header__2kWVk p {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  background-color: var(--alt13-color);
  padding: 12px;
  margin-bottom: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/collectible/Header/index.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,oCAAoC;EACpC,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".header h5 {\n  font-size: 14px;\n  opacity: 0.93;\n  color: var(--body-color);\n}\n\n.header p {\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 20px;\n  border-radius: 4px;\n  background-color: var(--alt13-color);\n  padding: 12px;\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__2kWVk`
};
export default ___CSS_LOADER_EXPORT___;
