// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tick_icon__13Auo path {
  fill: currentColor;
  stroke: none;
}
`, "",{"version":3,"sources":["webpack://./src/assets/icons/Tick/index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".icon path {\n  fill: currentColor;\n  stroke: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `Tick_icon__13Auo`
};
export default ___CSS_LOADER_EXPORT___;
