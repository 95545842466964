// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OwnershipHistory_item__rqQag {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 29px;
}

.OwnershipHistory_avatar__oqfsv {
  border-radius: 50%;
  display: block;
  height: 36px;
  margin-right: 12px;
  object-fit: cover;
  width: 36px;
}

.OwnershipHistory_status__tClxE {
  color: var(--grey-60);
  font: var(--typography-label-subtext);
}

.OwnershipHistory_timeline__OXy0z {
  margin-right: 16px;
  border-radius: 50%;
  border: 4px solid var(--alt6-color);
  box-sizing: border-box;
  width: 16px;
  height: 16px;
}

.OwnershipHistory_timelineCreator__8kzrt {
  margin-right: 16px;
  border-radius: 50%;
  background-color: var(--alt6-color);
  box-sizing: border-box;
  width: 16px;
  height: 16px;
}

.OwnershipHistory_line__-wCsO {
  width: 4px;
  margin-top: 10px;
  margin-left: 2px;
  height: 51px;
  background-color: var(--alt6-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/collectible/OwnershipHistory/index.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,qCAAqC;AACvC;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,mCAAmC;EACnC,sBAAsB;EACtB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,mCAAmC;EACnC,sBAAsB;EACtB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;EACZ,mCAAmC;AACrC","sourcesContent":[".item {\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 29px;\n}\n\n.avatar {\n  border-radius: 50%;\n  display: block;\n  height: 36px;\n  margin-right: 12px;\n  object-fit: cover;\n  width: 36px;\n}\n\n.status {\n  color: var(--grey-60);\n  font: var(--typography-label-subtext);\n}\n\n.timeline {\n  margin-right: 16px;\n  border-radius: 50%;\n  border: 4px solid var(--alt6-color);\n  box-sizing: border-box;\n  width: 16px;\n  height: 16px;\n}\n\n.timelineCreator {\n  margin-right: 16px;\n  border-radius: 50%;\n  background-color: var(--alt6-color);\n  box-sizing: border-box;\n  width: 16px;\n  height: 16px;\n}\n\n.line {\n  width: 4px;\n  margin-top: 10px;\n  margin-left: 2px;\n  height: 51px;\n  background-color: var(--alt6-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `OwnershipHistory_item__rqQag`,
	"avatar": `OwnershipHistory_avatar__oqfsv`,
	"status": `OwnershipHistory_status__tClxE`,
	"timeline": `OwnershipHistory_timeline__OXy0z`,
	"timelineCreator": `OwnershipHistory_timelineCreator__8kzrt`,
	"line": `OwnershipHistory_line__-wCsO`
};
export default ___CSS_LOADER_EXPORT___;
