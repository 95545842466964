import { IpfsCid } from 'beasy-fe-commons';

import avatarPlaceholder from 'assets/images/avatar-placeholder.png';

import { ImageView } from 'components/image';

import { getIpfsUrl } from 'services/ipfs';

interface Props {
  cid?: IpfsCid;
}

export const UserAvatar = ({ cid }: Props) => {
  return <ImageView src={getIpfsUrl(cid) || avatarPlaceholder} />;
};
