import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { ClientContextProvider } from 'context/ClientContext';
import { SkeletonTheme } from 'react-loading-skeleton';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Route, Switch, Router, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { PersistGate } from 'redux-persist/integration/react';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { polygonMumbai } from 'wagmi/chains';

import Login from 'screens/Login';
import Registration from 'screens/Registration';
import Home from 'screens/home/home';

import history from 'services/history';

import { store, persistor } from 'state';

import './App.css';
import './services/translator';

const chains = [polygonMumbai];
const projectId = 'e2b66b0d01659f6db98f787451983e6b';

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

require(`./App-${process.env.REACT_APP_DOMAIN}.css`);

export const queryClient = new QueryClient();

const App = () => {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ClientContextProvider>
                <div>
                  <SkeletonTheme color="#00f" highlightColor="#b0b0ff"></SkeletonTheme>
                  <Router history={history}>
                    <Switch>
                      <Route exact path="/login" component={Login} />
                      <Route exact path="/registration" component={Registration} />
                      <Route path="/home" component={Home} />
                      <Redirect from="/" to={'/home'} />
                    </Switch>
                  </Router>
                  <ToastContainer autoClose={false} draggable={false} theme="dark" />
                </div>
              </ClientContextProvider>
            </PersistGate>
          </Provider>
        </QueryClientProvider>
      </WagmiConfig>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
};

export default App;
