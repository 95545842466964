import { useFormik } from 'formik';
import { ChangeEvent, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useMutation, useQueryClient } from 'react-query';

import avatarPlaceholder from 'assets/images/avatar-placeholder.png';

import { ErrorMessage, ErrorMessageWithRetryButton } from 'components/ErrorMessage';
import { Spacer } from 'components/Spacer';
import { ButtonPrimary, ButtonSecondary } from 'components/button';
import { DialogArea, DialogClose, DialogFooter, DialogHeader, DialogOverlay } from 'components/dialog';
import { TextInput } from 'components/input';
import { InputAvatar } from 'components/input';

import { UserProfile } from 'protobuf/lib/userProfileService';

import { getIpfsUrl } from 'services/ipfs';
import { updateUserAvatarAndProfile, UsernameAlreadyExistsError, UserProfileQueryKey } from 'services/userProfile';

import css from './index.module.css';
import validationSchema from './validationSchema';

interface FormValue {
  ethAddress: string;
  nickname: string;
  businessName: string;
  firstName: string;
  lastName: string;
  avatar: File | string;
}

interface Props {
  userProfile: UserProfile;
  onClose: () => void;
  cancelButtonText?: string;
}

const AVATAR_SKELETON_STYLE = { display: 'block' };

export const AvatarSkeleton = () => <Skeleton style={AVATAR_SKELETON_STYLE} height={84} width={84} />;

const UserProfileForm = ({ userProfile, onClose, cancelButtonText = 'Cancel' }: Props) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    UserProfileQueryKey.updateUserAvatarAndProfile,
    async ({ avatar, nickname, ...additionalData }: FormValue) => {
      await updateUserAvatarAndProfile(avatar, nickname, { ...additionalData, source: process.env.REACT_APP_DOMAIN });
      onClose?.();
    },
    { onSuccess: () => queryClient.refetchQueries(UserProfileQueryKey.getUserProfile) },
  );

  const formik = useFormik<FormValue>({
    initialValues: {
      avatar: userProfile.avatarCid,
      nickname: userProfile.nickname,
      ethAddress: userProfile.ethAddress,
      businessName: userProfile?.additionalData?.businessName ?? '',
      firstName: userProfile?.additionalData?.firstName ?? '',
      lastName: userProfile?.additionalData?.lastName ?? '',
    },
    onSubmit: mutation.mutate as (value: FormValue) => Promise<void>,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const handleAvatarChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      formik.setFieldValue('avatar', event.target.files?.[0]);
    },
    [formik],
  );

  return (
    <DialogOverlay>
      <DialogArea>
        <form onSubmit={formik.handleSubmit} className={css.wrapper}>
          <DialogHeader>
            <h3>Fill out your profile</h3>
            <DialogClose onClick={onClose} />
          </DialogHeader>

          <Spacer height="26px" />

          <fieldset>
            <figure className={css.avatar}>
              <InputAvatar
                name="avatar"
                value={getIpfsUrl(userProfile.avatarCid) || avatarPlaceholder}
                fallback={<AvatarSkeleton />}
                onChange={handleAvatarChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.avatar && formik.touched.avatar && <span>{formik.errors.avatar}</span>}
            </figure>

            <label>
              <div>First Name</div>
              <TextInput
                name="firstName"
                value={formik.values.firstName}
                placeholder="Please enter your first name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.firstName && formik.touched.firstName && <span>{formik.errors.firstName}</span>}
            </label>

            <label>
              <div>Last Name</div>
              <TextInput
                name="lastName"
                value={formik.values.lastName}
                placeholder="Please enter your last name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.lastName && formik.touched.lastName && <span>{formik.errors.lastName}</span>}
            </label>

            <label className={css.username}>
              <div>Username</div>
              <TextInput
                name="nickname"
                value={formik.values.nickname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.nickname && formik.touched.nickname && <span>{formik.errors.nickname}</span>}
            </label>

            <label className={css.business}>
              <div>Business Name</div>
              <TextInput
                name="businessName"
                value={formik.values.businessName}
                placeholder="Please enter your business name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.businessName && formik.touched.businessName && <span>{formik.errors.businessName}</span>}
            </label>

            {mutation.isError && (
              <div className={css.error}>
                {mutation.error instanceof UsernameAlreadyExistsError ? (
                  <ErrorMessage>
                    Username <b>{mutation.error.nickname}</b> is taken. Please choose a different username.
                  </ErrorMessage>
                ) : (
                  <ErrorMessageWithRetryButton
                    message="Could not update profile"
                    isButtonDisabled={mutation.isLoading}
                    buttonType="submit"
                  />
                )}
              </div>
            )}
          </fieldset>

          <Spacer height="32px" />

          <DialogFooter>
            <ButtonSecondary onClick={onClose} disabled={mutation.isLoading} type="button">
              {cancelButtonText}
            </ButtonSecondary>
            <ButtonPrimary disabled={mutation.isLoading} type="submit">
              {mutation.isLoading ? 'Saving...' : 'Save'}
            </ButtonPrimary>
          </DialogFooter>
        </form>
      </DialogArea>
    </DialogOverlay>
  );
};

export default UserProfileForm;
