// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Underline_button__LV1YW {
  color: var(--primary-color);
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/button/Underline/index.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,0BAA0B;AAC5B","sourcesContent":[".button {\n  color: var(--primary-color);\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Underline_button__LV1YW`
};
export default ___CSS_LOADER_EXPORT___;
