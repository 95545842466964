// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navigator_wrapper__OisSZ {
  display: flex;
  margin-bottom: 32px;
  gap: 22px;
  align-items: center;
}

.Navigator_wrapper__OisSZ > button {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.Navigator_chevronIcon__BmDRE {
  transform: rotate(270deg);
  color: var(--alt15-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/Navigator/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;AAC3B","sourcesContent":[".wrapper {\n  display: flex;\n  margin-bottom: 32px;\n  gap: 22px;\n  align-items: center;\n}\n\n.wrapper > button {\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 20px;\n}\n\n.chevronIcon {\n  transform: rotate(270deg);\n  color: var(--alt15-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Navigator_wrapper__OisSZ`,
	"chevronIcon": `Navigator_chevronIcon__BmDRE`
};
export default ___CSS_LOADER_EXPORT___;
