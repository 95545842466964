import { IpfsCollectionInfo } from 'beasy-fe-commons';
import Skeleton from 'react-loading-skeleton';

import { ImageView } from 'components/image';

import { useIpfsInfo } from 'hooks/useIpfs';

import { Collection } from 'protobuf/lib/collectionService';

import css from './index.module.css';

interface Props {
  collection?: Collection;
}

export const CollectionPickItem = ({ collection }: Props) => {
  const info = useIpfsInfo<IpfsCollectionInfo>(collection?.metadataCid);

  return (
    <article className={css.wrapper}>
      <figure>
        <ImageView src={info?.image} />
      </figure>
      <h4>{collection?.name ?? <Skeleton width={50} />}</h4>
    </article>
  );
};
