import clsx from 'clsx';
import { useCallback } from 'react';

import { InputFile } from '..';
import { ClearableValue } from '../ClearableValue';
import commonCss from '../common.module.css';
import css from './index.module.css';

interface Props<T extends File | File[]> {
  name: string;
  value?: T;
  accept?: string;
  setFieldValue: (name: string, value?: T) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

const renderValue = (file: File) => file.name;

export const InputClearableFile = <T extends File | File[]>({
  name,
  value,
  accept,
  setFieldValue,
  onBlur,
}: Props<T>) => {
  const onChange = useCallback(
    (value?: T) => {
      setFieldValue(name, value);
    },
    [name, setFieldValue],
  );

  return (
    <div className={css.wrapper}>
      <ClearableValue
        className={clsx(commonCss.box, css.clearable)}
        value={value}
        renderValue={renderValue}
        onChange={onChange}
      />
      <label>
        Choose file
        <InputFile name={name} value={value} onChange={onChange} accept={accept} onBlur={onBlur} />
      </label>
    </div>
  );
};
