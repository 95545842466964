import { IpfsCollectibleInfo } from 'beasy-fe-commons';
import Skeleton from 'react-loading-skeleton';

import { ItemProps } from 'components/ItemList';
import Rarity from 'components/Rarity';
import { ImageView } from 'components/image';

import { useIpfsInfo } from 'hooks/useIpfs';

import { Collectible } from 'protobuf/lib/collectibleService';

import css from './index.module.css';

const CollectibleItem = ({ item, onClick }: ItemProps<Collectible>) => {
  const info = useIpfsInfo<IpfsCollectibleInfo>(item?.CID);

  return (
    <article className={css.wrapper} onClick={onClick}>
      <figure className={css.thumbnail}>
        <ImageView src={info?.image} />
      </figure>
      <div className={css.info}>
        <h3>{item?.name ?? <Skeleton />}</h3>
        <p>NFT</p>
        <div>{item ? <Rarity quantity={item.quantity} /> : <Skeleton width={100} count={2} />}</div>
      </div>
    </article>
  );
};

export default CollectibleItem;
