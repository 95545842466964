// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TraderEventToast_profiles__\\+j9JT {
  align-items: center;
  display: flex;
  margin-bottom: 16px;
}

.TraderEventToast_avatars__L8Q0T {
  flex: 0 0 auto;
  height: 32px;
  margin-right: 12px;
  position: relative;
}

.TraderEventToast_avatar__vPqIv {
  border-radius: 16px;
  height: 32px;
  object-fit: cover;
  width: 32px;
}

.TraderEventToast_avatar__vPqIv:last-child {
  left: -8px;
  position: relative;
}

.TraderEventToast_message__1HF7P {
  color: var(--text-color-accent);
  font: var(--typography-body);
}

.TraderEventToast_addresses__inF-U {
  color: var(--text-color);
  font: var(--typography-label-subtext);
}

.TraderEventToast_collectible__XxnPc {
  align-items: center;
  display: flex;
}

.TraderEventToast_collectibleThumbnail__pehhH {
  flex: 0 0 auto;
  margin-right: 16px;
}

.TraderEventToast_thumbnail__Imu46 {
  border-radius: 2px;
  height: 72px;
  object-fit: cover;
  width: 72px;
}

.TraderEventToast_collectibleName__FxuIO {
  color: var(--text-color-accent);
  font: var(--typography-h3);
}
`, "",{"version":3,"sources":["webpack://./src/screens/home/TraderEventToast/index.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;EAC/B,4BAA4B;AAC9B;;AAEA;EACE,wBAAwB;EACxB,qCAAqC;AACvC;;AAEA;EACE,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,+BAA+B;EAC/B,0BAA0B;AAC5B","sourcesContent":[".profiles {\n  align-items: center;\n  display: flex;\n  margin-bottom: 16px;\n}\n\n.avatars {\n  flex: 0 0 auto;\n  height: 32px;\n  margin-right: 12px;\n  position: relative;\n}\n\n.avatar {\n  border-radius: 16px;\n  height: 32px;\n  object-fit: cover;\n  width: 32px;\n}\n\n.avatar:last-child {\n  left: -8px;\n  position: relative;\n}\n\n.message {\n  color: var(--text-color-accent);\n  font: var(--typography-body);\n}\n\n.addresses {\n  color: var(--text-color);\n  font: var(--typography-label-subtext);\n}\n\n.collectible {\n  align-items: center;\n  display: flex;\n}\n\n.collectibleThumbnail {\n  flex: 0 0 auto;\n  margin-right: 16px;\n}\n\n.thumbnail {\n  border-radius: 2px;\n  height: 72px;\n  object-fit: cover;\n  width: 72px;\n}\n\n.collectibleName {\n  color: var(--text-color-accent);\n  font: var(--typography-h3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profiles": `TraderEventToast_profiles__+j9JT`,
	"avatars": `TraderEventToast_avatars__L8Q0T`,
	"avatar": `TraderEventToast_avatar__vPqIv`,
	"message": `TraderEventToast_message__1HF7P`,
	"addresses": `TraderEventToast_addresses__inF-U`,
	"collectible": `TraderEventToast_collectible__XxnPc`,
	"collectibleThumbnail": `TraderEventToast_collectibleThumbnail__pehhH`,
	"thumbnail": `TraderEventToast_thumbnail__Imu46`,
	"collectibleName": `TraderEventToast_collectibleName__FxuIO`
};
export default ___CSS_LOADER_EXPORT___;
