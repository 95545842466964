// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.View_image__j81J1 {
  object-fit: cover;
}

.View_skeleton__IuyjJ {
  line-height: unset !important;
}

.View_skeleton__IuyjJ,
.View_image__j81J1 {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/components/image/View/index.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,sBAAsB;AACxB","sourcesContent":[".image {\n  object-fit: cover;\n}\n\n.skeleton {\n  line-height: unset !important;\n}\n\n.skeleton,\n.image {\n  width: 100%;\n  height: 100%;\n  border-radius: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `View_image__j81J1`,
	"skeleton": `View_skeleton__IuyjJ`
};
export default ___CSS_LOADER_EXPORT___;
