// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ghost_button__mTTHm {
  color: var(--text-color-accent);
}

.Ghost_button__mTTHm:hover,
.Ghost_button__mTTHm:disabled {
  opacity: 0.5;
}
`, "",{"version":3,"sources":["webpack://./src/components/button/Ghost/index.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;;EAEE,YAAY;AACd","sourcesContent":[".button {\n  color: var(--text-color-accent);\n}\n\n.button:hover,\n.button:disabled {\n  opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Ghost_button__mTTHm`
};
export default ___CSS_LOADER_EXPORT___;
