// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Screen_wrapper__VhMF9 {
  width: 364px;
}

.Screen_wrapper__VhMF9 > div:first-of-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Screen_icon__usGtu {
  position: relative;
  top: -20px;
  left: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/loading/Screen/index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;AACZ","sourcesContent":[".wrapper {\n  width: 364px;\n}\n\n.wrapper > div:first-of-type {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n}\n\n.icon {\n  position: relative;\n  top: -20px;\n  left: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Screen_wrapper__VhMF9`,
	"icon": `Screen_icon__usGtu`
};
export default ___CSS_LOADER_EXPORT___;
