import clsx from 'clsx';
import { useClickout } from 'hooks';
import { useRef } from 'react';
import { useBoolean } from 'use-boolean';

import { ChevronIcon } from 'assets/icons';

import { Spacer } from 'components/Spacer';

import { ButtonPrimary } from '..';
import css from './index.module.css';

interface Props {
  id?: string;
  className?: string;
  title?: React.ReactNode;
  children?: React.ReactNode;
}

export const ButtonDropdown = ({ id, className, title, children }: Props) => {
  const [value, , setFalse, toggle] = useBoolean(false);
  const ref = useRef(null);

  useClickout(ref, setFalse);

  return (
    <div id={id} ref={ref} className={clsx(css.wrapper, className)}>
      <ButtonPrimary className={css.toggle} onClick={toggle}>
        {title}
        <Spacer grow />
        <ChevronIcon size={13.5} className={clsx({ [css.opened]: value })} />
      </ButtonPrimary>
      {value && <div className={css.overpane}>{children}</div>}
    </div>
  );
};
