// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rarity_content__Kfy8B {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
  font-weight: 600;
  color: var(--body-color);
}
.Rarity_content__Kfy8B.Rarity_gold__VQYIS {
  background: var(--accent-gold);
}
.Rarity_content__Kfy8B.Rarity_carrot__W\\+7JN {
  background: var(--orange);
}
.Rarity_content__Kfy8B.Rarity_purple__TkVnL {
  background: var(--purple-60);
}
.Rarity_content__Kfy8B.Rarity_blue__WYzRH {
  background: var(--accent-blue);
}
.Rarity_content__Kfy8B.Rarity_green__79nAY {
  background: var(--accent-green);
}
.Rarity_content__Kfy8B.Rarity_white__6bseV {
  background: var(--grey-0);
  color: var(--grey-85);
}
.Rarity_content__Kfy8B.Rarity_grey__6EF3X {
  background: var(--grey-80);
}
`, "",{"version":3,"sources":["webpack://./src/components/Rarity/index.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,kBAAkB;EAClB,wBAAwB;EACxB,gBAAgB;EAChB,wBAAwB;AAC1B;AACA;EACE,8BAA8B;AAChC;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,8BAA8B;AAChC;AACA;EACE,+BAA+B;AACjC;AACA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".content {\n  width: fit-content;\n  border-radius: 4px;\n  padding: 4px 8px 4px 8px;\n  font-weight: 600;\n  color: var(--body-color);\n}\n.content.gold {\n  background: var(--accent-gold);\n}\n.content.carrot {\n  background: var(--orange);\n}\n.content.purple {\n  background: var(--purple-60);\n}\n.content.blue {\n  background: var(--accent-blue);\n}\n.content.green {\n  background: var(--accent-green);\n}\n.content.white {\n  background: var(--grey-0);\n  color: var(--grey-85);\n}\n.content.grey {\n  background: var(--grey-80);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `Rarity_content__Kfy8B`,
	"gold": `Rarity_gold__VQYIS`,
	"carrot": `Rarity_carrot__W+7JN`,
	"purple": `Rarity_purple__TkVnL`,
	"blue": `Rarity_blue__WYzRH`,
	"green": `Rarity_green__79nAY`,
	"white": `Rarity_white__6bseV`,
	"grey": `Rarity_grey__6EF3X`
};
export default ___CSS_LOADER_EXPORT___;
