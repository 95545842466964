import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';

import { ImageProps, ImageLoading } from '..';
import css from './index.module.css';

export const ImageView = ({ className, ...rest }: ImageProps) => {
  return (
    <ImageLoading className={clsx(css.image, className)} {...rest} fallback={<Skeleton className={css.skeleton} />} />
  );
};
