import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { NavLink, useHistory } from 'react-router-dom';

import { ChevronIcon } from 'assets/icons';

import { ButtonDropdown, ButtonGhost2 } from 'components/button';
import { LayoutMenu } from 'components/layout';
import { UserAvatar } from 'components/user';

import {
  businessAgreementsId,
  collectionsId,
  createNftId,
  faqId,
  nftGalleryId,
  workWithPartnersId,
} from 'constants/onboarding';

import { useGetCurrentUserProfile, UserProfileQueryKey } from 'services/userProfile';

import css from './index.module.css';

const Sidebar = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const pathname = window.location.pathname;
  const getCurrentUserProfile = useGetCurrentUserProfile();
  const getUserProfileQuery = useQuery(UserProfileQueryKey.getUserProfile, () => {
    return getCurrentUserProfile();
  });

  return (
    <nav className={css.wrapper}>
      <ButtonGhost2
        className={css.profile}
        disabled={pathname === '/home/account'}
        onClick={() => {
          history.push('/home/account');
        }}
      >
        <figure>
          <UserAvatar cid={getUserProfileQuery.data?.avatarCid} />
        </figure>
        <div>{getUserProfileQuery?.data?.nickname}</div>
        <ChevronIcon size={13.5} />
      </ButtonGhost2>

      <ButtonDropdown id={createNftId} className={css.create} title="Create">
        <LayoutMenu>
          <ButtonGhost2
            onClick={() => {
              history.push('/home/collectible/create');
            }}
          >
            {t('sidebar.singleNft')}
          </ButtonGhost2>
          <ButtonGhost2 disabled>{t('sidebar.bulkCreate')}</ButtonGhost2>
          <ButtonGhost2
            onClick={() => {
              history.push('/home/collection/create');
            }}
          >
            {t('sidebar.createCollection')}
          </ButtonGhost2>
        </LayoutMenu>
      </ButtonDropdown>

      <ul className={css.listMenu}>
        <li className={pathname === '/home/dashboard' ? css.active : ''}>
          <NavLink to={'/home/dashboard'}>{t('sidebar.dashboard')}</NavLink>
        </li>
        <li id={collectionsId} className={pathname.startsWith('/home/collection') ? css.active : ''}>
          <NavLink to={'/home/collection'}>{t('sidebar.collections')}</NavLink>
        </li>
        <li id={nftGalleryId} className={pathname === '/home/collectible/list' ? css.active : ''}>
          <NavLink to={'/home/collectible/list'}>{t('sidebar.nfts')}</NavLink>
        </li>
        <li id={businessAgreementsId} className={pathname === '/home/agreement' ? css.active : ''}>
          <NavLink to={'/home/agreement'}>{t('sidebar.businessAgreements')}</NavLink>
        </li>
        <li id={workWithPartnersId} className={pathname === '/home/partner/list' ? css.active : ''}>
          <NavLink to={'/home/partner/list'}>{t('sidebar.partners')}</NavLink>
        </li>
        <li id={faqId} className={pathname === '/home/faq' ? css.active : ''}>
          <NavLink to={'/home/faq'}>{t('sidebar.faq')}</NavLink>
        </li>
      </ul>

      <div className={css.copyright}>
        <span>{t('sidebar.copyright')}</span>
      </div>
    </nav>
  );
};

export default Sidebar;
