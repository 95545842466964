/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { Empty } from "./google/protobuf/empty";

export const protobufPackage = "";

export interface Product {
  collectibleId: string;
  productId: string;
}

export interface GetProductReq {
  collectibleId: string;
}

export interface UpdateProductReq {
  collectibleId: string;
}

export interface DeleteProductByCollectibleReq {
  collectibleId: string;
}

function createBaseProduct(): Product {
  return { collectibleId: "", productId: "" };
}

export const Product = {
  encode(message: Product, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.collectibleId !== "") {
      writer.uint32(10).string(message.collectibleId);
    }
    if (message.productId !== "") {
      writer.uint32(18).string(message.productId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Product {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProduct();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectibleId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.productId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Product>, I>>(base?: I): Product {
    return Product.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Product>, I>>(object: I): Product {
    const message = createBaseProduct();
    message.collectibleId = object.collectibleId ?? "";
    message.productId = object.productId ?? "";
    return message;
  },
};

function createBaseGetProductReq(): GetProductReq {
  return { collectibleId: "" };
}

export const GetProductReq = {
  encode(message: GetProductReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.collectibleId !== "") {
      writer.uint32(10).string(message.collectibleId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetProductReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetProductReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectibleId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetProductReq>, I>>(base?: I): GetProductReq {
    return GetProductReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetProductReq>, I>>(object: I): GetProductReq {
    const message = createBaseGetProductReq();
    message.collectibleId = object.collectibleId ?? "";
    return message;
  },
};

function createBaseUpdateProductReq(): UpdateProductReq {
  return { collectibleId: "" };
}

export const UpdateProductReq = {
  encode(message: UpdateProductReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.collectibleId !== "") {
      writer.uint32(10).string(message.collectibleId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateProductReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateProductReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectibleId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateProductReq>, I>>(base?: I): UpdateProductReq {
    return UpdateProductReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateProductReq>, I>>(object: I): UpdateProductReq {
    const message = createBaseUpdateProductReq();
    message.collectibleId = object.collectibleId ?? "";
    return message;
  },
};

function createBaseDeleteProductByCollectibleReq(): DeleteProductByCollectibleReq {
  return { collectibleId: "" };
}

export const DeleteProductByCollectibleReq = {
  encode(message: DeleteProductByCollectibleReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.collectibleId !== "") {
      writer.uint32(10).string(message.collectibleId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteProductByCollectibleReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteProductByCollectibleReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectibleId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DeleteProductByCollectibleReq>, I>>(base?: I): DeleteProductByCollectibleReq {
    return DeleteProductByCollectibleReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteProductByCollectibleReq>, I>>(
    object: I,
  ): DeleteProductByCollectibleReq {
    const message = createBaseDeleteProductByCollectibleReq();
    message.collectibleId = object.collectibleId ?? "";
    return message;
  },
};

export interface ProductService {
  GetProduct(request: DeepPartial<GetProductReq>, metadata?: grpc.Metadata): Promise<Product>;
  UpdateProduct(request: DeepPartial<UpdateProductReq>, metadata?: grpc.Metadata): Promise<Empty>;
  DeleteProduct(request: DeepPartial<DeleteProductByCollectibleReq>, metadata?: grpc.Metadata): Promise<Empty>;
}

export class ProductServiceClientImpl implements ProductService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetProduct = this.GetProduct.bind(this);
    this.UpdateProduct = this.UpdateProduct.bind(this);
    this.DeleteProduct = this.DeleteProduct.bind(this);
  }

  GetProduct(request: DeepPartial<GetProductReq>, metadata?: grpc.Metadata): Promise<Product> {
    return this.rpc.unary(ProductServiceGetProductDesc, GetProductReq.fromPartial(request), metadata);
  }

  UpdateProduct(request: DeepPartial<UpdateProductReq>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(ProductServiceUpdateProductDesc, UpdateProductReq.fromPartial(request), metadata);
  }

  DeleteProduct(request: DeepPartial<DeleteProductByCollectibleReq>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(
      ProductServiceDeleteProductDesc,
      DeleteProductByCollectibleReq.fromPartial(request),
      metadata,
    );
  }
}

export const ProductServiceDesc = { serviceName: "ProductService" };

export const ProductServiceGetProductDesc: UnaryMethodDefinitionish = {
  methodName: "GetProduct",
  service: ProductServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetProductReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Product.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ProductServiceUpdateProductDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateProduct",
  service: ProductServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateProductReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ProductServiceDeleteProductDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteProduct",
  service: ProductServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteProductByCollectibleReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
