import commonCss from './common.module.css';
import { InputProps } from './types';

interface Props {
  min?: number;
  max?: number;
  step?: number;
}

export const NumberInput = (props: InputProps<number, HTMLInputElement> & Props) => {
  return <input className={commonCss.box} type="number" {...props} />;
};
