import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './auth';
import walletConnectV2Reducer from './session';


// Configuración de persistencia
const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['auth'], // solo auth será persistido, puedes agregar más reducers si los tienes
};

const persistedAuthReducer = persistReducer(persistConfig, walletConnectV2Reducer);
const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false, // Desactiva la comprobación de serialización
});
export const store = configureStore({
  reducer: {
    auth: authReducer,
    session: persistedAuthReducer,
  },
  middleware: customizedMiddleware,
});

export const persistor = persistStore(store); // esto crea un persistor basado en el store


export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
