/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Struct } from "./google/protobuf/struct";

export const protobufPackage = "";

export interface MinimalTransactionInfo {
  to: string;
  from: string;
  value?: string | undefined;
  data?: string | undefined;
  chainId: number;
  gasLimit?: string | undefined;
}

export interface PopulateTransactionReq {
  to: string;
  from: string;
  value?: string | undefined;
  data?: string | undefined;
  chainId: number;
  gasLimit?: string | undefined;
  fee?: PopulateTransactionReq_FeeKind | undefined;
  nonce?: string | undefined;
  additional?: { [key: string]: any } | undefined;
}

export enum PopulateTransactionReq_FeeKind {
  min = 0,
  avg = 1,
  high = 2,
  rapid = 3,
  UNRECOGNIZED = -1,
}

export interface TransactionInfo {
  primary: TransactionInfo_PrimaryTransactionInfo | undefined;
  additional: { [key: string]: any } | undefined;
}

export interface TransactionInfo_PrimaryTransactionInfo {
  to: string;
  from: string;
  value?: string | undefined;
  data?: string | undefined;
  chainId: number;
  gasLimit: string;
  nonce: string;
}

export interface TransactionReceipt {
  status: boolean;
  transactionHash: string;
  beasyLogs?: string | undefined;
}

function createBaseMinimalTransactionInfo(): MinimalTransactionInfo {
  return { to: "", from: "", value: undefined, data: undefined, chainId: 0, gasLimit: undefined };
}

export const MinimalTransactionInfo = {
  encode(message: MinimalTransactionInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.to !== "") {
      writer.uint32(10).string(message.to);
    }
    if (message.from !== "") {
      writer.uint32(18).string(message.from);
    }
    if (message.value !== undefined) {
      writer.uint32(26).string(message.value);
    }
    if (message.data !== undefined) {
      writer.uint32(34).string(message.data);
    }
    if (message.chainId !== 0) {
      writer.uint32(40).int32(message.chainId);
    }
    if (message.gasLimit !== undefined) {
      writer.uint32(50).string(message.gasLimit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MinimalTransactionInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMinimalTransactionInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.to = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.from = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.value = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.data = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.chainId = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.gasLimit = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<MinimalTransactionInfo>, I>>(base?: I): MinimalTransactionInfo {
    return MinimalTransactionInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MinimalTransactionInfo>, I>>(object: I): MinimalTransactionInfo {
    const message = createBaseMinimalTransactionInfo();
    message.to = object.to ?? "";
    message.from = object.from ?? "";
    message.value = object.value ?? undefined;
    message.data = object.data ?? undefined;
    message.chainId = object.chainId ?? 0;
    message.gasLimit = object.gasLimit ?? undefined;
    return message;
  },
};

function createBasePopulateTransactionReq(): PopulateTransactionReq {
  return {
    to: "",
    from: "",
    value: undefined,
    data: undefined,
    chainId: 0,
    gasLimit: undefined,
    fee: undefined,
    nonce: undefined,
    additional: undefined,
  };
}

export const PopulateTransactionReq = {
  encode(message: PopulateTransactionReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.to !== "") {
      writer.uint32(10).string(message.to);
    }
    if (message.from !== "") {
      writer.uint32(18).string(message.from);
    }
    if (message.value !== undefined) {
      writer.uint32(26).string(message.value);
    }
    if (message.data !== undefined) {
      writer.uint32(34).string(message.data);
    }
    if (message.chainId !== 0) {
      writer.uint32(40).int32(message.chainId);
    }
    if (message.gasLimit !== undefined) {
      writer.uint32(50).string(message.gasLimit);
    }
    if (message.fee !== undefined) {
      writer.uint32(56).int32(message.fee);
    }
    if (message.nonce !== undefined) {
      writer.uint32(66).string(message.nonce);
    }
    if (message.additional !== undefined) {
      Struct.encode(Struct.wrap(message.additional), writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PopulateTransactionReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePopulateTransactionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.to = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.from = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.value = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.data = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.chainId = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.gasLimit = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.fee = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.nonce = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.additional = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<PopulateTransactionReq>, I>>(base?: I): PopulateTransactionReq {
    return PopulateTransactionReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PopulateTransactionReq>, I>>(object: I): PopulateTransactionReq {
    const message = createBasePopulateTransactionReq();
    message.to = object.to ?? "";
    message.from = object.from ?? "";
    message.value = object.value ?? undefined;
    message.data = object.data ?? undefined;
    message.chainId = object.chainId ?? 0;
    message.gasLimit = object.gasLimit ?? undefined;
    message.fee = object.fee ?? undefined;
    message.nonce = object.nonce ?? undefined;
    message.additional = object.additional ?? undefined;
    return message;
  },
};

function createBaseTransactionInfo(): TransactionInfo {
  return { primary: undefined, additional: undefined };
}

export const TransactionInfo = {
  encode(message: TransactionInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.primary !== undefined) {
      TransactionInfo_PrimaryTransactionInfo.encode(message.primary, writer.uint32(10).fork()).ldelim();
    }
    if (message.additional !== undefined) {
      Struct.encode(Struct.wrap(message.additional), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.primary = TransactionInfo_PrimaryTransactionInfo.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.additional = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<TransactionInfo>, I>>(base?: I): TransactionInfo {
    return TransactionInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TransactionInfo>, I>>(object: I): TransactionInfo {
    const message = createBaseTransactionInfo();
    message.primary = (object.primary !== undefined && object.primary !== null)
      ? TransactionInfo_PrimaryTransactionInfo.fromPartial(object.primary)
      : undefined;
    message.additional = object.additional ?? undefined;
    return message;
  },
};

function createBaseTransactionInfo_PrimaryTransactionInfo(): TransactionInfo_PrimaryTransactionInfo {
  return { to: "", from: "", value: undefined, data: undefined, chainId: 0, gasLimit: "", nonce: "" };
}

export const TransactionInfo_PrimaryTransactionInfo = {
  encode(message: TransactionInfo_PrimaryTransactionInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.to !== "") {
      writer.uint32(10).string(message.to);
    }
    if (message.from !== "") {
      writer.uint32(18).string(message.from);
    }
    if (message.value !== undefined) {
      writer.uint32(26).string(message.value);
    }
    if (message.data !== undefined) {
      writer.uint32(34).string(message.data);
    }
    if (message.chainId !== 0) {
      writer.uint32(40).int32(message.chainId);
    }
    if (message.gasLimit !== "") {
      writer.uint32(50).string(message.gasLimit);
    }
    if (message.nonce !== "") {
      writer.uint32(58).string(message.nonce);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionInfo_PrimaryTransactionInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionInfo_PrimaryTransactionInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.to = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.from = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.value = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.data = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.chainId = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.gasLimit = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.nonce = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<TransactionInfo_PrimaryTransactionInfo>, I>>(
    base?: I,
  ): TransactionInfo_PrimaryTransactionInfo {
    return TransactionInfo_PrimaryTransactionInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TransactionInfo_PrimaryTransactionInfo>, I>>(
    object: I,
  ): TransactionInfo_PrimaryTransactionInfo {
    const message = createBaseTransactionInfo_PrimaryTransactionInfo();
    message.to = object.to ?? "";
    message.from = object.from ?? "";
    message.value = object.value ?? undefined;
    message.data = object.data ?? undefined;
    message.chainId = object.chainId ?? 0;
    message.gasLimit = object.gasLimit ?? "";
    message.nonce = object.nonce ?? "";
    return message;
  },
};

function createBaseTransactionReceipt(): TransactionReceipt {
  return { status: false, transactionHash: "", beasyLogs: undefined };
}

export const TransactionReceipt = {
  encode(message: TransactionReceipt, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status === true) {
      writer.uint32(8).bool(message.status);
    }
    if (message.transactionHash !== "") {
      writer.uint32(18).string(message.transactionHash);
    }
    if (message.beasyLogs !== undefined) {
      writer.uint32(26).string(message.beasyLogs);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionReceipt {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionReceipt();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.transactionHash = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.beasyLogs = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<TransactionReceipt>, I>>(base?: I): TransactionReceipt {
    return TransactionReceipt.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TransactionReceipt>, I>>(object: I): TransactionReceipt {
    const message = createBaseTransactionReceipt();
    message.status = object.status ?? false;
    message.transactionHash = object.transactionHash ?? "";
    message.beasyLogs = object.beasyLogs ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };
