import { EthereumAddress } from 'beasy-fe-commons';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';

import { getUserProfile } from 'services/userProfile';

import { UserAvatar } from '../Avatar';
import css from './index.module.css';

interface Props {
  address?: EthereumAddress;
}

export const UserPickItem = ({ address }: Props) => {
  const { data: profie } = useQuery(['getUserProfile', address], () => getUserProfile(address!), {
    enabled: Boolean(address),
  });

  return (
    <article className={css.wrapper}>
      <figure>
        <UserAvatar cid={profie?.avatarCid} />
      </figure>
      <h4>{profie?.nickname || <Skeleton width={50} />}</h4>
    </article>
  );
};
