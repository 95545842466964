// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Item_wrapper__QF6Ox {
  display: flex;
  height: 110px;
  background: var(--alt-bg-color);
  border-radius: 8px;
  border: 0;
  cursor: pointer;
}

.Item_thumbnail__IFZz7 {
  width: 115px;
  height: 112px;
  border-radius: 8px 0 0 8px;
}

.Item_info__R0cs1 {
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  overflow: hidden;
}

.Item_info__R0cs1 h3 {
  margin-top: 16px;
  color: var(--text-color-accent);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-content: space-between;
}

.Item_info__R0cs1 > p {
  margin-top: 8px;
  color: #89919e;
}

.Item_view__88Fbi {
  padding: 32px 0 0 12px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Dashboard/Item/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,+BAA+B;EAC/B,kBAAkB;EAClB,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,+BAA+B;EAC/B,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,4BAA4B;AAC9B;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".wrapper {\n  display: flex;\n  height: 110px;\n  background: var(--alt-bg-color);\n  border-radius: 8px;\n  border: 0;\n  cursor: pointer;\n}\n\n.thumbnail {\n  width: 115px;\n  height: 112px;\n  border-radius: 8px 0 0 8px;\n}\n\n.info {\n  display: flex;\n  flex-direction: column;\n  padding: 0 25px;\n  overflow: hidden;\n}\n\n.info h3 {\n  margin-top: 16px;\n  color: var(--text-color-accent);\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  align-content: space-between;\n}\n\n.info > p {\n  margin-top: 8px;\n  color: #89919e;\n}\n\n.view {\n  padding: 32px 0 0 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Item_wrapper__QF6Ox`,
	"thumbnail": `Item_thumbnail__IFZz7`,
	"info": `Item_info__R0cs1`,
	"view": `Item_view__88Fbi`
};
export default ___CSS_LOADER_EXPORT___;
