// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cell_container__bMiDg {
  width: 300px;
  max-width: 300px;
}

.Cell_container__bMiDg > dt {
  color: var(--alt15-color);
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  margin-bottom: 2px;
}

.Cell_container__bMiDg > dd {
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  color: var(--text-color-accent);
  line-break: anywhere;
  margin-right: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/components/collectible/steps/Review/Cell/index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,+BAA+B;EAC/B,oBAAoB;EACpB,kBAAkB;AACpB","sourcesContent":[".container {\n  width: 300px;\n  max-width: 300px;\n}\n\n.container > dt {\n  color: var(--alt15-color);\n  font-size: 14px;\n  line-height: 20px;\n  text-align: justify;\n  margin-bottom: 2px;\n}\n\n.container > dd {\n  font-size: 14px;\n  line-height: 20px;\n  text-align: justify;\n  color: var(--text-color-accent);\n  line-break: anywhere;\n  margin-right: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Cell_container__bMiDg`
};
export default ___CSS_LOADER_EXPORT___;
