import { EthereumAddress } from 'beasy-fe-commons';

export interface UserInfo {
  address: EthereumAddress;
  chainId: string;
}

const StorageKey = 'USER_INFO';

export const getUserInfo = (): UserInfo | null => {
  try {
    const contents = localStorage.getItem(StorageKey);
    if (contents) {
      const userInfo = JSON.parse(contents);
      if (userInfo && userInfo.address && userInfo.chainId) {
        return userInfo;
      }
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const setUserInfo = (userInfo: UserInfo): void => {
  localStorage.setItem(StorageKey, JSON.stringify(userInfo));
};

export const removeUserInfo = (): void => {
  localStorage.removeItem(StorageKey);
};
