// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProgressBar_container__tAt3L {
  height: 8px;
  width: 100%;
  margin-bottom: 24px;
}

.ProgressBar_bar__TpOxv {
  margin: 6px 0px 24px 0px;
  border-radius: 6px;
  background-color: var(--primary-color);
  height: 8px;
  overflow: hidden;
  width: 0;
}

.ProgressBar_barInner__lAI7o {
  animation: ProgressBar_progressBackground__CLet0 2s linear 0s infinite;
  background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 100%
    ),
    var(--primary-color);
  height: 6px;
  width: 100%;
}

@keyframes ProgressBar_progressBackground__CLet0 {
  from {
    transform: translate(-200%);
  }
  to {
    transform: translate(200%);
  }
}

.ProgressBar_message__HNpnV {
  color: var(--grey-60);
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/components/ProgressBar/index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,sCAAsC;EACtC,WAAW;EACX,gBAAgB;EAChB,QAAQ;AACV;;AAEA;EACE,sEAAmD;EACnD;;;;;;wBAMsB;EACtB,WAAW;EACX,WAAW;AACb;;AAEA;EACE;IACE,2BAA2B;EAC7B;EACA;IACE,0BAA0B;EAC5B;AACF;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB","sourcesContent":[".container {\n  height: 8px;\n  width: 100%;\n  margin-bottom: 24px;\n}\n\n.bar {\n  margin: 6px 0px 24px 0px;\n  border-radius: 6px;\n  background-color: var(--primary-color);\n  height: 8px;\n  overflow: hidden;\n  width: 0;\n}\n\n.barInner {\n  animation: progressBackground 2s linear 0s infinite;\n  background: linear-gradient(\n      90deg,\n      rgba(255, 255, 255, 0) 0%,\n      rgba(255, 255, 255, 0.3) 50%,\n      rgba(255, 255, 255, 0) 100%\n    ),\n    var(--primary-color);\n  height: 6px;\n  width: 100%;\n}\n\n@keyframes progressBackground {\n  from {\n    transform: translate(-200%);\n  }\n  to {\n    transform: translate(200%);\n  }\n}\n\n.message {\n  color: var(--grey-60);\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProgressBar_container__tAt3L`,
	"bar": `ProgressBar_bar__TpOxv`,
	"barInner": `ProgressBar_barInner__lAI7o`,
	"progressBackground": `ProgressBar_progressBackground__CLet0`,
	"message": `ProgressBar_message__HNpnV`
};
export default ___CSS_LOADER_EXPORT___;
