// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ndau_info__52faC {
  display: flex;
  margin-top: 40px;
}
.ndau_info__52faC > img {
  width: 400px;
  height: 400px;
  min-width: 400px;
  min-height: 400px;
}

.ndau_details__7stpG {
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  margin-top: 10px;
}
.ndau_details__7stpG > h3 {
  color: var(--grey-70);
  margin-bottom: 20px;
}
.ndau_details__7stpG > img {
  width: 30px;
  height: 30px;
}

.ndau_items__\\+9CoK {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.ndau_items__\\+9CoK > b {
  margin-left: 20px;
}
.ndau_items__\\+9CoK > img {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
}
.ndau_itms__RqeLf > img > * {
  margin: 0 4px 0 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/collectible/steps/Type/ndau/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".info {\n  display: flex;\n  margin-top: 40px;\n}\n.info > img {\n  width: 400px;\n  height: 400px;\n  min-width: 400px;\n  min-height: 400px;\n}\n\n.details {\n  display: flex;\n  flex-direction: column;\n  margin-left: 80px;\n  margin-top: 10px;\n}\n.details > h3 {\n  color: var(--grey-70);\n  margin-bottom: 20px;\n}\n.details > img {\n  width: 30px;\n  height: 30px;\n}\n\n.items {\n  display: flex;\n  align-items: center;\n  margin-top: 20px;\n}\n.items > b {\n  margin-left: 20px;\n}\n.items > img {\n  display: flex;\n  align-items: center;\n  margin-bottom: 13px;\n}\n.itms > img > * {\n  margin: 0 4px 0 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": `ndau_info__52faC`,
	"details": `ndau_details__7stpG`,
	"items": `ndau_items__+9CoK`,
	"itms": `ndau_itms__RqeLf`
};
export default ___CSS_LOADER_EXPORT___;
