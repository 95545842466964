// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Item_wrapper__AcEl1 {
  max-width: 350px;
  background: var(--alt-bg-color);
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
}

.Item_thumbnail__Klyvu {
  width: 250px;
  height: 250px;
  border-radius: 8px;
  margin-bottom: 15px;
}

.Item_info__803PB {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.Item_info__803PB h4 {
  font-size: 16px;
  line-height: 20px;
  color: var(--text-color-accent);
  width: 250px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Item_info__803PB > div:nth-child(2) {
  margin-top: 5px;
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-60);
}

.Item_info__803PB > div:nth-child(3) {
  margin-top: 5px;
}

.Item_info__803PB a {
  padding: 2px 10px;
  text-transform: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/collectible/Item/index.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,+BAA+B;EAC/B,kBAAkB;EAClB,aAAa;EACb,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,+BAA+B;EAC/B,YAAY;;EAEZ,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":[".wrapper {\n  max-width: 350px;\n  background: var(--alt-bg-color);\n  border-radius: 8px;\n  padding: 20px;\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-content: center;\n}\n\n.thumbnail {\n  width: 250px;\n  height: 250px;\n  border-radius: 8px;\n  margin-bottom: 15px;\n}\n\n.info {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: flex-start;\n}\n\n.info h4 {\n  font-size: 16px;\n  line-height: 20px;\n  color: var(--text-color-accent);\n  width: 250px;\n\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.info > div:nth-child(2) {\n  margin-top: 5px;\n  font-size: 14px;\n  line-height: 20px;\n  color: var(--grey-60);\n}\n\n.info > div:nth-child(3) {\n  margin-top: 5px;\n}\n\n.info a {\n  padding: 2px 10px;\n  text-transform: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Item_wrapper__AcEl1`,
	"thumbnail": `Item_thumbnail__Klyvu`,
	"info": `Item_info__803PB`
};
export default ___CSS_LOADER_EXPORT___;
