// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-list_wrapper__2Ymrm {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  overflow: scroll;
}

.item-list_wrapper__2Ymrm li {
  display: flex;
}

.item-list_wrapper__2Ymrm li:nth-child(4n) {
  border-right: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/collectible/item-list.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".wrapper {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 32px;\n  overflow: scroll;\n}\n\n.wrapper li {\n  display: flex;\n}\n\n.wrapper li:nth-child(4n) {\n  border-right: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `item-list_wrapper__2Ymrm`
};
export default ___CSS_LOADER_EXPORT___;
