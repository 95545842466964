// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Primary2_button__xThWE {
  background-color: var(--primary2-color);
}

.Primary2_button__xThWE:hover {
  background-color: var(--primary2-dark-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/button/Primary2/index.module.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;AACzC;;AAEA;EACE,4CAA4C;AAC9C","sourcesContent":[".button {\n  background-color: var(--primary2-color);\n}\n\n.button:hover {\n  background-color: var(--primary2-dark-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Primary2_button__xThWE`
};
export default ___CSS_LOADER_EXPORT___;
