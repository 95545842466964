import clsx from 'clsx';
import React from 'react';

import { IconProps } from '../types';
import css from './index.module.css';

const ASPECT_RATIO = 1;

export const Download = ({ className, size = 32, onClick }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(css.icon, className)}
    onClick={onClick}
    width={size}
    height={size / ASPECT_RATIO}
    viewBox="0 0 32 32"
  >
    <g id="Group_413" data-name="Group 413" transform="translate(-1276 -374)">
      <g className={css.ellipse} transform="translate(1276 374)" strokeWidth="1">
        <circle cx="16" cy="16" r="16" />
        <circle cx="16" cy="16" r="15.5" />
      </g>
      <path
        d="M5.943,0V5.943H1.981l5.943,5.943,5.943-5.943H9.9V0ZM0,13.867v1.981H15.848V13.867Z"
        transform="translate(1283.826 381.826)"
      />
    </g>
  </svg>
);
