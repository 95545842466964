import { grpc } from '@improbable-eng/grpc-web';
import { captureException } from '@sentry/react';

import { GrpcWebImpl, NetworksServiceClientImpl } from 'protobuf/lib/network';

import { environment } from 'environment';

import { store } from 'state';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const networksServiceClient = new NetworksServiceClientImpl(rpc);

export const getNetworks = async () => {
  try {
    return await networksServiceClient.GetNetworks({});
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const getGasPrice = async () => {
  try {
    const state = store.getState();
    const walletConnectV2 = state.session;
    return await networksServiceClient.GetGasPrice({
      networkId: walletConnectV2.chainId!,
    });
  } catch (error) {
    captureException(error);
    throw error;
  }
};
