/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { TxRes } from "./chargeMaster";
import { Currency } from "./currency";
import {
  MinimalTransactionInfo,
  PopulateTransactionReq,
  TransactionInfo,
  TransactionReceipt,
} from "./transactionMessage";

export const protobufPackage = "";

export interface TransactionCost {
  min: TransactionCost_AmountAndCurrency[];
  avg: TransactionCost_AmountAndCurrency[];
  high: TransactionCost_AmountAndCurrency[];
  rapid: TransactionCost_AmountAndCurrency[];
  serviceFee: TransactionCost_AmountAndCurrency[];
}

export interface TransactionCost_AmountAndCurrency {
  amount: number;
  currency: Currency | undefined;
}

export interface TransactionCostAnyNetwork {
  slow: number;
  average: number;
  fast: number;
}

export interface Empty {
}

export interface TransactionData {
  to: string;
  address: string;
  functionSignature: string;
  r: string;
  s: string;
  v: string;
}

export interface ExecuteTransactionReq {
  trx: TransactionInfo | undefined;
  key: string;
}

export interface MintMultipleReq {
  chainId: number;
  collectionId: string;
  collectibleId: string;
  from: string;
  to: string;
  quantity: number;
}

function createBaseTransactionCost(): TransactionCost {
  return { min: [], avg: [], high: [], rapid: [], serviceFee: [] };
}

export const TransactionCost = {
  encode(message: TransactionCost, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.min) {
      TransactionCost_AmountAndCurrency.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.avg) {
      TransactionCost_AmountAndCurrency.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.high) {
      TransactionCost_AmountAndCurrency.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.rapid) {
      TransactionCost_AmountAndCurrency.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.serviceFee) {
      TransactionCost_AmountAndCurrency.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionCost {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionCost();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.min.push(TransactionCost_AmountAndCurrency.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.avg.push(TransactionCost_AmountAndCurrency.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.high.push(TransactionCost_AmountAndCurrency.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.rapid.push(TransactionCost_AmountAndCurrency.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.serviceFee.push(TransactionCost_AmountAndCurrency.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<TransactionCost>, I>>(base?: I): TransactionCost {
    return TransactionCost.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TransactionCost>, I>>(object: I): TransactionCost {
    const message = createBaseTransactionCost();
    message.min = object.min?.map((e) => TransactionCost_AmountAndCurrency.fromPartial(e)) || [];
    message.avg = object.avg?.map((e) => TransactionCost_AmountAndCurrency.fromPartial(e)) || [];
    message.high = object.high?.map((e) => TransactionCost_AmountAndCurrency.fromPartial(e)) || [];
    message.rapid = object.rapid?.map((e) => TransactionCost_AmountAndCurrency.fromPartial(e)) || [];
    message.serviceFee = object.serviceFee?.map((e) => TransactionCost_AmountAndCurrency.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTransactionCost_AmountAndCurrency(): TransactionCost_AmountAndCurrency {
  return { amount: 0, currency: undefined };
}

export const TransactionCost_AmountAndCurrency = {
  encode(message: TransactionCost_AmountAndCurrency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.amount !== 0) {
      writer.uint32(9).double(message.amount);
    }
    if (message.currency !== undefined) {
      Currency.encode(message.currency, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionCost_AmountAndCurrency {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionCost_AmountAndCurrency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.amount = reader.double();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.currency = Currency.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<TransactionCost_AmountAndCurrency>, I>>(
    base?: I,
  ): TransactionCost_AmountAndCurrency {
    return TransactionCost_AmountAndCurrency.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TransactionCost_AmountAndCurrency>, I>>(
    object: I,
  ): TransactionCost_AmountAndCurrency {
    const message = createBaseTransactionCost_AmountAndCurrency();
    message.amount = object.amount ?? 0;
    message.currency = (object.currency !== undefined && object.currency !== null)
      ? Currency.fromPartial(object.currency)
      : undefined;
    return message;
  },
};

function createBaseTransactionCostAnyNetwork(): TransactionCostAnyNetwork {
  return { slow: 0, average: 0, fast: 0 };
}

export const TransactionCostAnyNetwork = {
  encode(message: TransactionCostAnyNetwork, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.slow !== 0) {
      writer.uint32(8).int32(message.slow);
    }
    if (message.average !== 0) {
      writer.uint32(16).int32(message.average);
    }
    if (message.fast !== 0) {
      writer.uint32(24).int32(message.fast);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionCostAnyNetwork {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionCostAnyNetwork();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.slow = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.average = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.fast = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<TransactionCostAnyNetwork>, I>>(base?: I): TransactionCostAnyNetwork {
    return TransactionCostAnyNetwork.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TransactionCostAnyNetwork>, I>>(object: I): TransactionCostAnyNetwork {
    const message = createBaseTransactionCostAnyNetwork();
    message.slow = object.slow ?? 0;
    message.average = object.average ?? 0;
    message.fast = object.fast ?? 0;
    return message;
  },
};

function createBaseEmpty(): Empty {
  return {};
}

export const Empty = {
  encode(_: Empty, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Empty {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmpty();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Empty>, I>>(base?: I): Empty {
    return Empty.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Empty>, I>>(_: I): Empty {
    const message = createBaseEmpty();
    return message;
  },
};

function createBaseTransactionData(): TransactionData {
  return { to: "", address: "", functionSignature: "", r: "", s: "", v: "" };
}

export const TransactionData = {
  encode(message: TransactionData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.to !== "") {
      writer.uint32(10).string(message.to);
    }
    if (message.address !== "") {
      writer.uint32(18).string(message.address);
    }
    if (message.functionSignature !== "") {
      writer.uint32(26).string(message.functionSignature);
    }
    if (message.r !== "") {
      writer.uint32(34).string(message.r);
    }
    if (message.s !== "") {
      writer.uint32(42).string(message.s);
    }
    if (message.v !== "") {
      writer.uint32(50).string(message.v);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.to = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.address = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.functionSignature = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.r = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.s = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.v = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<TransactionData>, I>>(base?: I): TransactionData {
    return TransactionData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TransactionData>, I>>(object: I): TransactionData {
    const message = createBaseTransactionData();
    message.to = object.to ?? "";
    message.address = object.address ?? "";
    message.functionSignature = object.functionSignature ?? "";
    message.r = object.r ?? "";
    message.s = object.s ?? "";
    message.v = object.v ?? "";
    return message;
  },
};

function createBaseExecuteTransactionReq(): ExecuteTransactionReq {
  return { trx: undefined, key: "" };
}

export const ExecuteTransactionReq = {
  encode(message: ExecuteTransactionReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.trx !== undefined) {
      TransactionInfo.encode(message.trx, writer.uint32(10).fork()).ldelim();
    }
    if (message.key !== "") {
      writer.uint32(18).string(message.key);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExecuteTransactionReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExecuteTransactionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.trx = TransactionInfo.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.key = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ExecuteTransactionReq>, I>>(base?: I): ExecuteTransactionReq {
    return ExecuteTransactionReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExecuteTransactionReq>, I>>(object: I): ExecuteTransactionReq {
    const message = createBaseExecuteTransactionReq();
    message.trx = (object.trx !== undefined && object.trx !== null)
      ? TransactionInfo.fromPartial(object.trx)
      : undefined;
    message.key = object.key ?? "";
    return message;
  },
};

function createBaseMintMultipleReq(): MintMultipleReq {
  return { chainId: 0, collectionId: "", collectibleId: "", from: "", to: "", quantity: 0 };
}

export const MintMultipleReq = {
  encode(message: MintMultipleReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chainId !== 0) {
      writer.uint32(8).int32(message.chainId);
    }
    if (message.collectionId !== "") {
      writer.uint32(18).string(message.collectionId);
    }
    if (message.collectibleId !== "") {
      writer.uint32(26).string(message.collectibleId);
    }
    if (message.from !== "") {
      writer.uint32(34).string(message.from);
    }
    if (message.to !== "") {
      writer.uint32(42).string(message.to);
    }
    if (message.quantity !== 0) {
      writer.uint32(48).int32(message.quantity);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MintMultipleReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMintMultipleReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.chainId = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.collectionId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.collectibleId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.from = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.to = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.quantity = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<MintMultipleReq>, I>>(base?: I): MintMultipleReq {
    return MintMultipleReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MintMultipleReq>, I>>(object: I): MintMultipleReq {
    const message = createBaseMintMultipleReq();
    message.chainId = object.chainId ?? 0;
    message.collectionId = object.collectionId ?? "";
    message.collectibleId = object.collectibleId ?? "";
    message.from = object.from ?? "";
    message.to = object.to ?? "";
    message.quantity = object.quantity ?? 0;
    return message;
  },
};

export interface TransactionService {
  populateTransaction(request: DeepPartial<PopulateTransactionReq>, metadata?: grpc.Metadata): Promise<TransactionInfo>;
  estimateTransactionCost(
    request: DeepPartial<MinimalTransactionInfo>,
    metadata?: grpc.Metadata,
  ): Promise<TransactionCost>;
  estimateTransactionCostAnyNetwork(
    request: DeepPartial<Empty>,
    metadata?: grpc.Metadata,
  ): Promise<TransactionCostAnyNetwork>;
  metaTransaction(request: DeepPartial<TransactionData>, metadata?: grpc.Metadata): Promise<TxRes>;
  debugExecuteTransaction(
    request: DeepPartial<ExecuteTransactionReq>,
    metadata?: grpc.Metadata,
  ): Promise<TransactionReceipt>;
  MintMultiple(request: DeepPartial<MintMultipleReq>, metadata?: grpc.Metadata): Promise<TransactionReceipt>;
}

export class TransactionServiceClientImpl implements TransactionService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.populateTransaction = this.populateTransaction.bind(this);
    this.estimateTransactionCost = this.estimateTransactionCost.bind(this);
    this.estimateTransactionCostAnyNetwork = this.estimateTransactionCostAnyNetwork.bind(this);
    this.metaTransaction = this.metaTransaction.bind(this);
    this.debugExecuteTransaction = this.debugExecuteTransaction.bind(this);
    this.MintMultiple = this.MintMultiple.bind(this);
  }

  populateTransaction(
    request: DeepPartial<PopulateTransactionReq>,
    metadata?: grpc.Metadata,
  ): Promise<TransactionInfo> {
    return this.rpc.unary(
      TransactionServicepopulateTransactionDesc,
      PopulateTransactionReq.fromPartial(request),
      metadata,
    );
  }

  estimateTransactionCost(
    request: DeepPartial<MinimalTransactionInfo>,
    metadata?: grpc.Metadata,
  ): Promise<TransactionCost> {
    return this.rpc.unary(
      TransactionServiceestimateTransactionCostDesc,
      MinimalTransactionInfo.fromPartial(request),
      metadata,
    );
  }

  estimateTransactionCostAnyNetwork(
    request: DeepPartial<Empty>,
    metadata?: grpc.Metadata,
  ): Promise<TransactionCostAnyNetwork> {
    return this.rpc.unary(
      TransactionServiceestimateTransactionCostAnyNetworkDesc,
      Empty.fromPartial(request),
      metadata,
    );
  }

  metaTransaction(request: DeepPartial<TransactionData>, metadata?: grpc.Metadata): Promise<TxRes> {
    return this.rpc.unary(TransactionServicemetaTransactionDesc, TransactionData.fromPartial(request), metadata);
  }

  debugExecuteTransaction(
    request: DeepPartial<ExecuteTransactionReq>,
    metadata?: grpc.Metadata,
  ): Promise<TransactionReceipt> {
    return this.rpc.unary(
      TransactionServicedebugExecuteTransactionDesc,
      ExecuteTransactionReq.fromPartial(request),
      metadata,
    );
  }

  MintMultiple(request: DeepPartial<MintMultipleReq>, metadata?: grpc.Metadata): Promise<TransactionReceipt> {
    return this.rpc.unary(TransactionServiceMintMultipleDesc, MintMultipleReq.fromPartial(request), metadata);
  }
}

export const TransactionServiceDesc = { serviceName: "TransactionService" };

export const TransactionServicepopulateTransactionDesc: UnaryMethodDefinitionish = {
  methodName: "populateTransaction",
  service: TransactionServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return PopulateTransactionReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TransactionInfo.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionServiceestimateTransactionCostDesc: UnaryMethodDefinitionish = {
  methodName: "estimateTransactionCost",
  service: TransactionServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return MinimalTransactionInfo.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TransactionCost.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionServiceestimateTransactionCostAnyNetworkDesc: UnaryMethodDefinitionish = {
  methodName: "estimateTransactionCostAnyNetwork",
  service: TransactionServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TransactionCostAnyNetwork.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionServicemetaTransactionDesc: UnaryMethodDefinitionish = {
  methodName: "metaTransaction",
  service: TransactionServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return TransactionData.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TxRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionServicedebugExecuteTransactionDesc: UnaryMethodDefinitionish = {
  methodName: "debugExecuteTransaction",
  service: TransactionServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ExecuteTransactionReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TransactionReceipt.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const TransactionServiceMintMultipleDesc: UnaryMethodDefinitionish = {
  methodName: "MintMultiple",
  service: TransactionServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return MintMultipleReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TransactionReceipt.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
