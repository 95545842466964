import { FieldArray, FormikProvider, setNestedObjectValues, useFormik } from 'formik';
import { useCallback, useEffect } from 'react';

import { DeleteIcon } from 'assets/icons';

import { Spacer } from 'components/Spacer';
import { ButtonPrimary } from 'components/button';
import { Header } from 'components/collectible';
import { FieldContainer, FieldError, FieldTitle } from 'components/field';
import { ClearableSelect, NumberInput, TextInput } from 'components/input';
import { DateSelector } from 'components/input/DatePicker';

import { StepProps, STEP_INVALID_VALUE, AttributesStepValue, Attribute } from '../types';
import css from './index.module.css';
import { validationSchema } from './validationSchema';

const onSubmit = () => {};

const Attributes = ({ value, getValueRef }: StepProps<AttributesStepValue>) => {
  const form = useFormik<AttributesStepValue>({
    initialValues: value ?? {
      attributes: [],
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit,
  });

  const { values, errors, setTouched, validateForm, handleSubmit, handleBlur, handleChange, setFieldValue } = form;

  useEffect(() => {
    getValueRef.current = async () => {
      const errors = await validateForm();

      if (Object.keys(errors).length > 0) {
        setTouched(setNestedObjectValues(errors, true));
        return STEP_INVALID_VALUE;
      }

      return {
        attributes: values.attributes.filter(i => i && i.display_type && i.trait_type),
      };
    };
  }, [getValueRef, setTouched, validateForm, values]);

  const renderOption = useCallback((item: string) => {
    return item;
  }, []);

  const newAttribute = () => {
    const newAttrib: Attribute = {
      display_type: 'Text',
      trait_type: '',
      value: '',
    };
    values.attributes.push(newAttrib);
  };

  const deleteAttribute = useCallback(
    (index:any) => {
      values.attributes.splice(index, 1);
      setFieldValue('attributes', values.attributes);
    },
    [values, setFieldValue],
  );

  return (
    <form onSubmit={handleSubmit} className={css.wrapper}>
      <Header
        subtitle="Please add any special meta-data attributes for this NFT. This meta-data will be viewable on any secondary marketplace."
        isOptional
      />

      <Spacer height="20px" />

      <div className={css.section}>
        <div className={css.sectionContent}>
          <FormikProvider value={form}>
            <FieldArray name="attributes">
              {() =>
                values.attributes.map(({ display_type, trait_type, value }, index) => (
                  <div key={index}>
                    <div className={css.container}>
                      <div className={css.contentSmall}>
                        <FieldContainer>
                          <FieldTitle>Type</FieldTitle>
                          <ClearableSelect
                            value={display_type}
                            renderValue={renderOption}
                            onChange={val => {
                              setFieldValue(`attributes.${index}.display_type`, val ? val : display_type, true);
                              setFieldValue(`attributes.${index}.value`, '', true);
                            }}
                            options={['Text', 'Number', 'Percentage', 'Date']}
                            className={css.list}
                            isNoRemovable={true}
                          />
                        </FieldContainer>
                      </div>
                      <div className={css.content}>
                        <FieldContainer>
                          <FieldTitle>Name</FieldTitle>
                          <TextInput
                            name={`attributes.${index}.trait_type`}
                            value={trait_type}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors['youAttributesName' as 'attributes'] && (
                           <FieldError>{String(errors['youAttributesName' as 'attributes'])}</FieldError>
                          )}
                          {!errors['youAttributesName' as 'attributes'] && <Spacer height="10px" />}
                        </FieldContainer>
                      </div>
                      {(display_type === 'Text' || !display_type || display_type === '') && (
                        <div className={css.content}>
                          <FieldContainer>
                            <FieldTitle>Value</FieldTitle>
                            <TextInput
                              name={`attributes.${index}.value`}
                              value={value}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors['youAttributesValueText' as 'attributes'] && (
                              <FieldError>{String(errors['youAttributesValueText' as 'attributes'])}</FieldError>
                            )}
                            {!errors['youAttributesValueText' as 'attributes'] && <Spacer height="10px" />}
                          </FieldContainer>
                        </div>
                      )}
                      {display_type === 'Number' && (
                        <div className={css.content}>
                          <FieldContainer>
                            <FieldTitle>Value</FieldTitle>
                            <NumberInput
                              name={`attributes.${index}.value`}
                              value={Number(value === '' ? 0 : value)}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors['youAttributesValueNumber' as 'attributes'] && (
                              <FieldError>{String(errors['youAttributesValueNumber' as 'attributes'])}</FieldError>
                            )}
                            {!errors['youAttributesValueNumber' as 'attributes'] && <Spacer height="10px" />}
                          </FieldContainer>
                        </div>
                      )}
                      {display_type === 'Percentage' && (
                        <div className={css.content}>
                          <FieldContainer>
                            <FieldTitle>Value</FieldTitle>
                            <NumberInput
                              name={`attributes.${index}.value`}
                              value={Number(value === '' ? 0 : value)}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors['youAttributesValuePercentage' as 'attributes'] && (
                              <FieldError>{String(errors['youAttributesValuePercentage' as 'attributes'])}</FieldError>
                            )}
                            {!errors['youAttributesValuePercentage' as 'attributes'] && <Spacer height="10px" />}
                          </FieldContainer>
                        </div>
                      )}
                      {display_type === 'Date' && (
                        <div className={css.content}>
                          <FieldContainer>
                            <FieldTitle>Value</FieldTitle>
                            <DateSelector
                              name={`attributes.${index}.value`}
                              onBlur={handleBlur}
                              value={value}
                              onChange={date => {
                                setFieldValue(
                                  `attributes.${index}.value`,
                                  date
                                    ? date.toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                      })
                                    : '',
                                  true,
                                );
                              }}
                            />
                            {errors['youAttributesValueDate' as 'attributes'] && (
                              <FieldError>{String(errors['youAttributesValueDate' as 'attributes'])}</FieldError>
                            )}
                            {!errors['youAttributesValueDate' as 'attributes'] && <Spacer height="10px" />}
                          </FieldContainer>
                        </div>
                      )}
                      <div className={css.buttons}>
                        <DeleteIcon onClick={() => deleteAttribute(index)} size={12} />
                      </div>
                    </div>
                  </div>
                ))
              }
            </FieldArray>
          </FormikProvider>
        </div>
      </div>

      <Spacer height="20px" />
      <ButtonPrimary onClick={newAttribute}>Add attribute +</ButtonPrimary>
    </form>
  );
};

export default Attributes;
