import Alert from 'components/Alert';
import ProgressBar from 'components/ProgressBar';
import LoadingScreen from 'components/loading/Screen';

import { CreateCollectibleController } from 'screens/collectible/Create/useCreateCollectible';

interface Props {
  controller: CreateCollectibleController;
}

export const CreateCollectibleModals = ({ controller }: Props) => {
  return (
    <>
      {controller.loadingScreenProps && (
        <LoadingScreen {...controller.loadingScreenProps}>
          {controller.uploadProgress != null && !controller.loadingScreenProps.isNotification && (
            <ProgressBar message={controller.uploadProgressMessage} progress={controller.uploadProgress} />
          )}
        </LoadingScreen>
      )}

      {controller.alertProps && <Alert {...controller.alertProps} />}
    </>
  );
};
