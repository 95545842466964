import { grpc } from '@improbable-eng/grpc-web';
import { captureException } from '@sentry/react';
import { EthereumAddress, RoleName } from 'beasy-fe-commons';

import { GrpcWebImpl, FriendServiceClientImpl } from 'protobuf/lib/friend';

import { environment } from 'environment';

import { authenticated } from './grpc';
import { store } from 'state';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const friendServiceClient = new FriendServiceClientImpl(rpc);

export const getFriends = async (roleName?: RoleName) => {
  try {
    const state = store.getState();
    const walletConnectV2 = state.session;
    return await authenticated(async meta => {
      return await friendServiceClient.GetFriends(
        {
          address: walletConnectV2.address!,
          filter: {
            roleName,
            network: walletConnectV2.chainId!,
          },
        },
        meta,
      );
    });
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const createFriend = async (friendAddress: EthereumAddress) => {
  try {
    const state = store.getState();
    const walletConnectV2 = state.session;
    return await authenticated(async meta => {
      return await friendServiceClient.CreateFriend(
        {
          address: walletConnectV2.address!,
          friendAddress,
        },
        meta,
      );
    });
  } catch (error) {
    captureException(error);
    throw error;
  }
};
