// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Slider_wrapper__OJPJy {
  display: flex;
  align-items: center;
  width: 100%;
}

.Slider_thumb__QpvCd {
  width: 24px;
  height: 24px;
  border: solid 6px var(--alt19-color);
  border-radius: 50%;
  background-color: var(--primary-color);
}`, "",{"version":3,"sources":["webpack://./src/components/input/Slider/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,kBAAkB;EAClB,sCAAsC;AACxC","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n\n.thumb {\n  width: 24px;\n  height: 24px;\n  border: solid 6px var(--alt19-color);\n  border-radius: 50%;\n  background-color: var(--primary-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Slider_wrapper__OJPJy`,
	"thumb": `Slider_thumb__QpvCd`
};
export default ___CSS_LOADER_EXPORT___;
