import { WarningIcon } from 'assets/icons';

import { ErrorMessageWithRetryButton } from 'components/ErrorMessage';
import { ButtonPrimary, ButtonSecondary } from 'components/button';
import { DialogArea, DialogClose, DialogFooter, DialogHeader, DialogOverlay } from 'components/dialog';

import { capitalizeFirst } from 'constants/helper';

import { Frontstore } from 'protobuf/lib/frontstore';

import css from './index.module.css';

interface Props {
  frontstore: Frontstore;
  isError: boolean;
  isLoading: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const FrontstoreDeleteWarningModal = ({ frontstore, isError, isLoading, onSubmit, onClose }: Props) => {
  return (
    <DialogOverlay>
      <DialogArea className={css.wrapper}>
        <DialogHeader>
          <div className={css.header}>
            <WarningIcon className={css.icon} />
            <h3>Delete Store</h3>
          </div>
          <DialogClose onClick={onClose} />
        </DialogHeader>
        <p className={css.label}>
          Are you sure you want to delete {capitalizeFirst(frontstore.type)} store with ID {frontstore.fid}
        </p>
        {isError && (
          <div className={css.error}>
            <ErrorMessageWithRetryButton
              message="Could not delete store"
              isButtonDisabled={isLoading}
              onRetry={onSubmit}
            />
          </div>
        )}

        <DialogFooter>
          <ButtonSecondary disabled={isLoading} onClick={onClose}>
            Cancel
          </ButtonSecondary>
          <ButtonPrimary disabled={isLoading} onClick={onSubmit}>
            {isLoading ? 'Deleting...' : 'Delete'}
          </ButtonPrimary>
        </DialogFooter>
      </DialogArea>
    </DialogOverlay>
  );
};

export default FrontstoreDeleteWarningModal;
