import { useCallback, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useBoolean } from 'use-boolean';

import { PencilIcon } from 'assets/icons';
import avatarPlaceholder from 'assets/images/avatar-placeholder.png';

import { ErrorMessageQueryLoading } from 'components/ErrorMessage';
import UserProfileForm, { AvatarSkeleton } from 'components/UserProfileForm';
import LoadingSpinner from 'components/loading/Spinner';
import { PageArea, PageHeader } from 'components/page';

import { useClearableState } from 'hooks/clearableState';

import { Frontstore } from 'protobuf/lib/frontstore';
import { UserProfile_AdditionalData } from 'protobuf/lib/userProfileService';

import { axios } from 'services/axios';
import {
  createFrontstore,
  deleteFrontstore,
  FrontstoreQueryKey,
  getFrontstores,
  getFrontstoreTypes,
  updateFrontstore,
} from 'services/frontstore';
import { getIpfsUrl } from 'services/ipfs';
import { getAccountLink, getAccountLoginLink, getNewAccountLink, isAccountActivated } from 'services/payment';
import {
  useGetCurrentUserProfile,
  getRoleNameMap,
  updateUserAvatarAndProfile,
  UserProfileQueryKey,
} from 'services/userProfile';


import { AppState } from 'state';

import { environment } from 'environment';
import { useWalletConnectClient } from 'context/ClientContext';
import FrontstoreAddModal, { FrontstoreAddModalFormData } from './FrontstoreAddModal';
import FrontstoreDeleteModal from './FrontstoreDeleteModal';
import FrontstoreEditModal, { FrontstoreEditModalFormData } from './FrontstoreEditModal';
import FrontstoresList from './FrontstoresList';
import LogoutModal from './LogoutModal';
import css from './index.module.css';

const MyAccount = () => {
  const queryClient = useQueryClient();
  const [isLogoutModalOpen, showLogoutModal, closeLogoutModal] = useBoolean(false);
  const getCurrentUserProfile = useGetCurrentUserProfile();
  const getUserProfileQuery = useQuery(UserProfileQueryKey.getUserProfile, () => getCurrentUserProfile());
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  const getRoleNameMapQuery = useQuery(UserProfileQueryKey.getRoleNameMap, () => getRoleNameMap());
  const { handleDisconnect } = useWalletConnectClient();
  const getFrontstoreTypesQuery = useQuery(FrontstoreQueryKey.getFrontstoreTypes, () => getFrontstoreTypes());

  const getFrontstoresQuery = useQuery(FrontstoreQueryKey.getFrontstores, () => getFrontstores());

  const [isAddModalDisplayed, showAddModal, hideAddModal] = useBoolean(false);

  const [frontstoreToDelete, setFrontstoreToDelete, clearFrontstoreIdDelete] = useClearableState<Frontstore>(null);

  const [frontstoreToEdit, setFrontstoreToEdit, clearFrontstoreToEdit] = useClearableState<Frontstore>(null);

  const [isProfileEditorOpen, showProfileEditor, closeProfileEditor] = useBoolean(false);

  const [isFrotstoreAdding, setFrontstoreAdding, setFrontstoreAdded] = useBoolean(false);

  const [isStripeAccountActivated, setStripeAccountActivated] = useState(false);

  const accountId = getUserProfileQuery.data?.stripeId;

  if (getUserProfileQuery.isSuccess && accountId && accountId !== '') {
    isAccountActivated(accountId).then(res => {
      res.accepted === '1' ? setStripeAccountActivated(true) : setStripeAccountActivated(false);
    });
  }

  const getNewAccountUrl = () => {
    const additionalData: UserProfile_AdditionalData = {
      firstName: getUserProfileQuery.data?.additionalData?.firstName || '',
      lastName: getUserProfileQuery.data?.additionalData?.lastName || '',
      businessName: getUserProfileQuery.data?.additionalData?.businessName || '',
      source: process.env.REACT_APP_DOMAIN,
    };

    getNewAccountLink(getUserProfileQuery.data!.ethAddress, additionalData).then(res => {
      window.open(res.accountLink, '_blank')?.focus();

      updateUserAvatarAndProfile(
        getUserProfileQuery.data?.avatarCid!,
        getUserProfileQuery.data?.nickname!,
        additionalData,
        getUserProfileQuery.data?.isAccepted,
        res.accountId,
      );
    });
  };

  const getAccountLoginUrl = () => {
    getAccountLoginLink(accountId!).then(link => {
      window.open(link.loginLink, '_blank')?.focus();
    });
  };

  const getAccountUrl = () => {
    getAccountLink(accountId!).then(link => {
      window.open(link.accountLink, '_blank')?.focus();
    });
  };

  const getRoleName = useCallback(
    (role: number) => {
      return getRoleNameMapQuery?.data![role];
    },
    [getRoleNameMapQuery?.data],
  );

  const createFrontstoreMutation = useMutation(
    FrontstoreQueryKey.createFrontstore,
    async (formData: FrontstoreAddModalFormData) => {
      return createFrontstore(formData);
    },
    {
      onSuccess: async () => {
        hideAddModal();
        await queryClient.refetchQueries(FrontstoreQueryKey.getFrontstores);
      },
    },
  );

  const handleAddFrontstore = async (data: FrontstoreAddModalFormData) => {
    setFrontstoreAdding();

    if (data.type === 'Shopify') {
      try {
        await axios.post(`${environment.brokerUrl}/api/v1/install/manual`, { ...data });

        createFrontstoreMutation.mutate(data);
      } catch (error: any) {
        toast(error?.response?.data?.message ?? error?.message, { autoClose: 10e3, type: 'error' });
      }
    } else {
      createFrontstoreMutation.mutate(data);
    }

    setFrontstoreAdded();
  };

  const updateFrontstoreMutation = useMutation(
    FrontstoreQueryKey.updateFrontstore,
    async (formData: FrontstoreEditModalFormData) => {
      if (frontstoreToEdit) {
        return updateFrontstore(frontstoreToEdit.frontstoreId, formData, frontstoreToEdit.type);
      }
    },
    {
      onSuccess: async () => {
        clearFrontstoreToEdit();
        await queryClient.refetchQueries(FrontstoreQueryKey.getFrontstores);
      },
    },
  );

  const deleteFrontstoreMutation = useMutation(
    FrontstoreQueryKey.deleteFrontstore,
    async () => {
      if (frontstoreToDelete) {
        return deleteFrontstore(frontstoreToDelete.frontstoreId);
      }
    },
    {
      onSuccess: async () => {
        clearFrontstoreIdDelete();
        await queryClient.refetchQueries(FrontstoreQueryKey.getFrontstores);
      },
    },
  );

  const logoutMutation = useMutation(async () => await handleDisconnect());
  const userRole = getUserProfileQuery.data?.roles?.find(r => r.network === walletConnectV2.chainId);

  if (getUserProfileQuery.isError) {
    return (
      <PageArea>
        <PageHeader title="User profile"></PageHeader>
        <ErrorMessageQueryLoading onRetry={() => getUserProfileQuery.refetch()} />
      </PageArea>
    );
  }

  return (
    <PageArea>
      {isAddModalDisplayed && getFrontstoreTypesQuery.isSuccess && (
        <FrontstoreAddModal
          error={createFrontstoreMutation.error as Error}
          frontstoreTypes={getFrontstoreTypesQuery.data?.frontstoreTypes ?? []}
          isError={createFrontstoreMutation.isError}
          isLoading={isFrotstoreAdding}
          onSubmit={handleAddFrontstore}
          onClose={hideAddModal}
        />
      )}

      {frontstoreToEdit && (
        <FrontstoreEditModal
          error={updateFrontstoreMutation.error as Error}
          frontstore={frontstoreToEdit}
          isError={updateFrontstoreMutation.isError}
          isLoading={updateFrontstoreMutation.isLoading}
          onSubmit={updateFrontstoreMutation.mutate}
          onClose={clearFrontstoreToEdit}
        />
      )}

      {frontstoreToDelete && (
        <FrontstoreDeleteModal
          frontstore={frontstoreToDelete}
          isError={deleteFrontstoreMutation.isError}
          isLoading={deleteFrontstoreMutation.isLoading}
          onSubmit={deleteFrontstoreMutation.mutate}
          onClose={clearFrontstoreIdDelete}
        />
      )}

      {getUserProfileQuery.isSuccess && (
        <PageHeader title={getUserProfileQuery.data.nickname} className={css.boxRightContentHeading}>
          <button className={css.editButton} onClick={showProfileEditor}>
            <PencilIcon />
          </button>
        </PageHeader>
      )}

      {getUserProfileQuery.isLoading && <AvatarSkeleton />}

      {getUserProfileQuery.isSuccess && getRoleNameMapQuery.isSuccess && (
        <div>
          <div className={css.profileDiv}>
            <img
              className={css.profileImage}
              src={getIpfsUrl(getUserProfileQuery.data?.avatarCid) || avatarPlaceholder}
              alt="avatar"
            />
            <div className={css.profile}>
              <p>First Name</p>
              <p>Last Name</p>
              <p>Business Name</p>
              <p>Role</p>
            </div>
            <div id={css.value} className={css.profile}>
              <p>
                {getUserProfileQuery.data.additionalData?.firstName
                  ? getUserProfileQuery.data.additionalData?.firstName
                  : '-'}
              </p>
              <p>
                {getUserProfileQuery.data.additionalData?.lastName
                  ? getUserProfileQuery.data.additionalData?.lastName
                  : '-'}
              </p>
              <p>
                {getUserProfileQuery.data.additionalData?.businessName
                  ? getUserProfileQuery.data.additionalData?.businessName
                  : '-'}
              </p>
              <p>{userRole ? getRoleName(userRole.role) : '-'}</p>
            </div>
          </div>
          <div className={css.content}>
            <h3>Address</h3>
            <p>{getUserProfileQuery.data.ethAddress}</p>
          </div>
        </div>
      )}

      {process.env.REACT_APP_DOMAIN === 'obsesh' && (
        <div className={css.content}>
          <h3>Connect Stripe Account</h3>
          {!getUserProfileQuery.data?.stripeId && (
            <button className={css.stripe} onClick={getNewAccountUrl}>
              Create Stripe Connect Account +
            </button>
          )}
          {getUserProfileQuery.data?.stripeId && !isStripeAccountActivated && (
            <button className={css.stripe} onClick={getAccountUrl}>
              Continue Stripe Registration Process
            </button>
          )}
          {getUserProfileQuery.data?.stripeId && isStripeAccountActivated && (
            <button className={css.stripe} onClick={getAccountLoginUrl}>
              Go View Your Stripe Connect Account
            </button>
          )}
        </div>
      )}
      {/* 
      <div className={css.content}>
        <h3>My Stores</h3>
      </div>

      {getFrontstoresQuery.isLoading && <LoadingSpinner />}

      {getFrontstoresQuery.isError && <div>Could not fetch stores</div>}

      {getFrontstoresQuery.isSuccess && getFrontstoreTypesQuery.isSuccess && (
        <FrontstoresList
          frontstores={getFrontstoresQuery.data.frontstores}
          frontstoreTypes={getFrontstoreTypesQuery.data.frontstoreTypes}
          onAdd={showAddModal}
          onDelete={setFrontstoreToDelete}
          onEdit={setFrontstoreToEdit}
        />
      )}
*/}
      {isProfileEditorOpen && getUserProfileQuery.isSuccess && (
        <UserProfileForm userProfile={getUserProfileQuery.data} onClose={closeProfileEditor} />
      )}
      <button className={css.logout} onClick={showLogoutModal}>
        Logout
      </button>
      {isLogoutModalOpen && (
        <LogoutModal
          onNo={closeLogoutModal}
          onYes={logoutMutation.mutate}
          isDisabled={logoutMutation.isLoading || logoutMutation.isSuccess}
        />
      )}
    </PageArea>
  );
};

export default MyAccount;
