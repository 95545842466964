// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorMessageQueryLoading_container__4gKqH {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.ErrorMessageQueryLoading_image__WdUdY {
  width: 85px;
  height: 90px;
  margin-bottom: 20px;
}

.ErrorMessageQueryLoading_message__5iRSy {
  text-align: center;
  white-space: pre-line;
  font-size: 16px;
  color: var(--grey-60);
  margin-bottom: 30px;
}

.ErrorMessageQueryLoading_buttonRetry__v2--K {
  width: 180px;
  text-transform: none;
  font-weight: 600;
  font-size: 16px;
  color: var(--text-color-accent);
}`, "",{"version":3,"sources":["webpack://./src/components/ErrorMessage/ErrorMessageQueryLoading.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;EACf,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,oBAAoB;EACpB,gBAAgB;EAChB,eAAe;EACf,+BAA+B;AACjC","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 20px;\n}\n\n.image {\n  width: 85px;\n  height: 90px;\n  margin-bottom: 20px;\n}\n\n.message {\n  text-align: center;\n  white-space: pre-line;\n  font-size: 16px;\n  color: var(--grey-60);\n  margin-bottom: 30px;\n}\n\n.buttonRetry {\n  width: 180px;\n  text-transform: none;\n  font-weight: 600;\n  font-size: 16px;\n  color: var(--text-color-accent);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ErrorMessageQueryLoading_container__4gKqH`,
	"image": `ErrorMessageQueryLoading_image__WdUdY`,
	"message": `ErrorMessageQueryLoading_message__5iRSy`,
	"buttonRetry": `ErrorMessageQueryLoading_buttonRetry__v2--K`
};
export default ___CSS_LOADER_EXPORT___;
