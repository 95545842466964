// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.List_filter__Ctdlq {
  display: flex;
  gap: 24px;
}

.List_filter__Ctdlq > .List_active__6HG-s {
  color: var(--text-color-accent);
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/screens/collectible/List/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,+BAA+B;EAC/B,0BAA0B;AAC5B","sourcesContent":[".filter {\n  display: flex;\n  gap: 24px;\n}\n\n.filter > .active {\n  color: var(--text-color-accent);\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": `List_filter__Ctdlq`,
	"active": `List_active__6HG-s`
};
export default ___CSS_LOADER_EXPORT___;
