import { TransactionStatus } from 'beasy-fe-commons';
import { useForm, useProgress } from 'hooks';
//import logger from 'logger';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
//import { useSelector } from 'react-redux';
import { useBoolean } from 'use-boolean';
import { v4 } from 'uuid';

import Alert, { AlertProps } from 'components/Alert';
import ProgressBar from 'components/ProgressBar';
import { ButtonPrimary } from 'components/button';
import { CollectionEdit, collectionFormikConfig } from 'components/collection';
import { DialogFooter } from 'components/dialog';
import { LoadingScreen, LoadingScreenProps } from 'components/loading';
import { PageArea } from 'components/page';
import { TransactionPickSpeed } from 'components/transaction';

import { MinimalTransactionInfo, PopulateTransactionReq } from 'protobuf/lib/transactionMessage';

import { Activity, log } from 'services/activityReport';
import { createCollection, predictCollection } from 'services/collection';
import history from 'services/history';
import { ipfsUploadCollection } from 'services/ipfs';
//import { CheckAvailability } from 'services/metaTransactions';
import { populateTransaction } from 'services/transaction';

import { AppState } from 'state';

//import { blockchainSelector, chainConfigSelector } from 'state/blockchain';
import css from './index.module.css';
import { useWalletConnectClient } from 'context/ClientContext';

export const CollectionCreateRoute = () => {
  const { t } = useTranslation();
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  const { sendTransaction } = useWalletConnectClient();
  // const wallet: any = useSelector(blockchainSelector);
  //const chainConfig = useSelector(chainConfigSelector);
  //const [checkMetaTx, setCheckMetaTx] = useState(false);
  const [loadingScreenProps, setLoadingScreenProps] = useState<LoadingScreenProps | undefined>();
  const [alertProps, setAlertProps] = useState<AlertProps | undefined>();
  const { progress, setProgress, handleProgress } = useProgress();
  //const [data, setData] = useState<any>(1);
  const queryClient = useQueryClient();
  //const [metaVerified, setVerified] = useState(false);

  const sameNameCollectionError = 'There is already a registered collection with that name';
  const noMaticCollectionError = 'insufficient funds for gas * price + value';
  const showErrorAlert = useCallback((customMessage?: string, cryptoRequired?: string) => {
    setLoadingScreenProps(undefined);
    setAlertProps({
      message:
        customMessage === undefined
          ? 'We have encountered an error performing this transaction. Please try again.'
          : customMessage,
      cryptoRequired: cryptoRequired,
      isSuccess: false,
      onClose: () => setAlertProps(undefined),
    });
  }, []);

  const { form, getFormValue } = useForm(collectionFormikConfig);
  const [transaction, setTransaction] = useState<MinimalTransactionInfo>();
  const [isSpeedPickerOpen, showSpeedPicker, closeSpeedPicker] = useBoolean(false);
  /*
  let dataPassedFromChild = 1;
  const childToParent = (childdata: any) => {
    if (childdata === 2) {
      setData(2);
      dataPassedFromChild = childdata;
      setData(dataPassedFromChild);
    } else {
      dataPassedFromChild = childdata;
      setData(dataPassedFromChild);
    }
  };
*/
  const prepareTransaction = useCallback(async () => {
    let formValue;

    try {
      formValue = await getFormValue();
    } catch (error) {
      // form validation errors
      return;
    }

    try {
      const { name, description, externalLink, image, royaltyHolders } = formValue;

      // 1. create transaction

      setLoadingScreenProps({
        message: 'Calculating the transaction',
        isNotification: false,
      });

      setProgress(0);

      // predict addresses

      const salt = v4();

      const predictCollectionReq = {
        creator: walletConnectV2.address!,
        name,
        symbol: '',
        salt,
      };

      //logger.http('send predictCollection', predictCollectionReq);

      const { royaltyDistributorAddress: royaltyPredictedAddress } = await predictCollection(predictCollectionReq);

      // upload metadata

      const totalRoyalty = royaltyHolders.reduce((acc, { royalty }) => acc + Number(royalty), 0);

      const metadataCid = await ipfsUploadCollection(
        {
          name,
          description,
          image: image!,
          externalLink,
          totalRoyalty,
          feeRecipient: royaltyPredictedAddress,
        },
        handleProgress,
      );

      //logger.http('created collection metadata: ', { metadataCid });

      // create collection

      const createCollectionReq = {
        creator: walletConnectV2.address!,
        name,
        baseURI: '', // todo deprecated
        metadataCid,
        network: walletConnectV2.chainId!,
        royalty: String(totalRoyalty * 100),
        royaltyHolders: royaltyHolders.map(i => ({ ...i, royalty: String(Number(i.royalty) * 100) })), // todo in formik
        salt,
      };

      //logger.http('send createCollection', createCollectionReq);

      const { transaction } = await createCollection(createCollectionReq);
      /*
      if (transaction) {
        CheckAvailability(transaction.to, wallet, chainConfig.url).then((isAvailable: boolean) => {
          //setVerified(true);
          setCheckMetaTx(isAvailable);
        });
      } */

      setLoadingScreenProps(undefined);

      //walletConnectService.isConnected
      if (!transaction) {
        //logger.error('could not create collection: ' + name);
        showErrorAlert();

        return;
      }

      setTransaction(transaction);
      showSpeedPicker();

      //logger.http('created collection', transaction);
    } catch (error) {
      if (String(error).includes('EstimateGasError')) {
        if (String(error).includes(sameNameCollectionError)) {
          showErrorAlert(sameNameCollectionError);
          return;
        }

        if (String(error).includes(noMaticCollectionError)) {
          const errors = String(error).split('want ');
          const weyRequired = errors[1].split('(');
          const cryptoRequired = Number(Number(weyRequired[0]) / 1000000000000000000).toFixed(18);

          let cryptoFormatted = cryptoRequired.toString();
          while (cryptoFormatted.endsWith('0')) {
            cryptoFormatted = cryptoFormatted.substring(0, cryptoFormatted.length - 2);
          }

          showErrorAlert('You have insufficient funds.\n ' + cryptoFormatted + ' MATIC required.');
          return;
        }

        showErrorAlert('Something went wrong\nPlease try again');
        return;
      }
      console.log(error);
      //logger.error({ error });
      showErrorAlert();
    }
  }, [getFormValue, handleProgress, setProgress, showErrorAlert, showSpeedPicker]);

  const signTransaction = useCallback(
    async (transaction: PopulateTransactionReq) => {
      closeSpeedPicker();

      try {
        // 2. sign transaction

        setLoadingScreenProps({
          message: 'Preparing the transaction',
          isNotification: true,
        });

        const { primary, additional } = await populateTransaction(transaction);
        console.log("PRIMARY: ",primary)
        //let additionalExtra = { ...additional, customData: data };
        setLoadingScreenProps({
          message: 'Please confirm the collection creation in your BEASY Wallet',
          isNotification: true,
        });

        const txForWallet = { ...primary, ...additional };

        //const [status, hash] = await walletConnectService.sendTransaction(txForWallet);
        const [status,hash] = await sendTransaction(txForWallet)
        setLoadingScreenProps(undefined);

        switch (status) {
          case TransactionStatus.Success:
            log(transaction.from, Activity.NEW_COLLECTION, process.env.REACT_APP_DOMAIN);
            setAlertProps({
              isSuccess: true,
              message: `Collection successfully created!`,
              onClose: () => {
                history.push('/home/create-asset');
                setAlertProps(undefined);
              },
            });

            await queryClient.refetchQueries({ queryKey: 'getCollections' });

            break;
          case TransactionStatus.Pending:
            setAlertProps({
              isSuccess: true,
              message:
                'The transaction is taking a bit long. We will complete it in the background, and you will see it shortly.',
              hash,
              onClose: () => {
                history.push('/home/create-asset');
                setAlertProps(undefined);
              },
            });

            await queryClient.refetchQueries({ queryKey: 'getCollections' });

            break;
          case TransactionStatus.Rejected:
            setAlertProps({
              isSuccess: false,
              message: 'You have rejected the transaction',
              onClose: () => setAlertProps(undefined),
            });

            break;
          default:
            //logger.error({ status });
            showErrorAlert();
        }
      } catch (error) {
        console.log(error);
        //logger.error({ error });
        showErrorAlert();
      }
    },
    [closeSpeedPicker, queryClient, showErrorAlert],
  );

  const progressMessage = useMemo(() => {
    return progress === 1 ? 'Almost done...' : `Uploaded: ${progress != null ? Math.round(100 * progress) : null}%`;
  }, [progress]);

  return (
    <PageArea className={css.wrapper}>
      <header>
        <h2>{t('collection.createNew')}</h2>
      </header>
      <CollectionEdit form={form} />
      <DialogFooter>
        <ButtonPrimary onClick={prepareTransaction}>Create</ButtonPrimary>
      </DialogFooter>
      {loadingScreenProps && (
        <LoadingScreen {...loadingScreenProps}>
          {progress != null && !loadingScreenProps.isNotification && (
            <ProgressBar message={progressMessage} progress={progress} />
          )}
        </LoadingScreen>
      )}

      {alertProps && <Alert {...alertProps} />}
      {/*{transaction && isSpeedPickerOpen && metaVerified && (
        <TransactionPickSpeed
          entityTitle="collection"
          transaction={transaction}
          onCancel={closeSpeedPicker}
          onSubmit={signTransaction}
          childToParent={childToParent}
          metaTx={checkMetaTx}
          normalTx={!checkMetaTx}
        />
      )} */}
      {transaction && isSpeedPickerOpen && (
        <TransactionPickSpeed
          entityTitle="collection"
          transaction={transaction}
          onCancel={closeSpeedPicker}
          onSubmit={signTransaction}
        />
      )}
    </PageArea>
  );
};
