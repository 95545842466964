// Image:

export const supportedImageExtensions = ['png', 'jpeg', 'jpg', 'gif'];

export const supportedImageTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];

export const acceptedImageTypes = supportedImageTypes.join(',');

// Support: https://support.pocketcasts.com/article/supported-file-types-audio-video-android/

// Audio:

export const supportedAudioExtensions = ['mp3', 'wav'];

export const supportedAudioTypes = [
  // ['audio/aac', 'm4a'], //	Android, iOS, Chrome, Firefox, Safari
  // ['audio/3gpp', '3gp'], //	iOS, Safari
  // ['audio/3gpp2', '3g2'], //	iOS
  // ['audio/aiff', 'aiff'], //	Android, iOS, Safari
  // ['audio/x-aiff', 'aiff'], //	Android, iOS, Safari
  // ['audio/amr', 'amr'], //	Android, Safari
  ['audio/mp3', 'mp3'], //	Android, iOS, Chrome, Firefox, Edge, Safari
  ['audio/mpeg3', 'mp3'], //	Android, iOS, Chrome, Firefox, Edge, Safari
  ['audio/x-mp3', 'mp3'], //	Android, iOS, Chrome, Firefox, Edge, Safari
  ['audio/x-mpeg3', 'mp3'], //	Android, iOS, Chrome, Firefox, Edge, Safari
  ['audio/mpeg', 'mp3'], //	Android, iOS, Chrome, Firefox, Edge, Safari
  ['audio/x-mpeg', 'mp3'], //	Android, iOS, Chrome, Firefox, Edge, Safari
  // ['audio/mp4', 'mp4'], //	Android, iOS, Chrome, Firefox, Edge, Safari
  // ['audio/x-mp4', 'mp4'], //	Android, iOS, Chrome, Firefox, Edge, Safari
  ['audio/wav', 'wav'], //	Android, iOS, Chrome, Firefox, Edge, Safari
  ['audio/x-wav', 'wav'], //	Android, iOS, Chrome, Firefox, Edge, Safari
  // ['audio/x-m4a', 'm4a'], //	Android, iOS, Chrome, Firefox, Safari
  // ['audio/x-m4b', 'm4b'], //	iOS
  // ['audio/x-m4p', 'm4p'], //	iOS, Safari
].map(([mime]) => mime);

export const acceptedAudioTypes = supportedAudioTypes.join(',');

// Video:

export const supportedVideoExtensions = ['mp4'];

export const supportedVideoTypes = [
  // ['video/3gpp', '3gp'], //	Android, Safari
  // ['video/3gpp2', '3g2'], //	iOS
  ['video/mp4', 'mp4'], //	Android, iOS, Chrome, Firefox, Edge, Safari
  ['video/x-mp4', 'mp4'], //	Android, iOS, Chrome, Firefox, Edge, Safari
  // ['video/quicktime', 'mov'], //	Android, iOS, Chrome, Firefox, Edge, Safari
  // ['video/mpeg2', 'mov'], //	Android, iOS, Chrome, Firefox, Edge, Safari
  // ['video/m4v', 'm4v'], //	Android, iOS, Chrome, Edge, Safari
  ['video/mpeg', 'mp4'], //	Android, iOS, Chrome, Firefox, Edge, Safari
].map(([mime]) => mime);

export const acceptedVideoTypes = supportedVideoTypes.join(',');
