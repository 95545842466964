// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_wrapper__a1GKU {
  width: 254px;
  height: 100%;
  padding: 32px 0;

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.Sidebar_create__JAfxh,
.Sidebar_profile__dta7O {
  --margin: 20px;
  margin: 0 var(--margin);
  width: calc(100% - var(--margin) * 2);
}

.Sidebar_profile__dta7O {
  border: 2px solid var(--grey-70);
  border-radius: 6px;
  padding: 10px;
  color: var(--text-color);
}

.Sidebar_profile__dta7O:disabled {
  color: var(--text-color-accent);
  border: 2px solid var(--primary-color);
  opacity: 1;
}

.Sidebar_profile__dta7O > figure {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.Sidebar_profile__dta7O > div {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
}

.Sidebar_profile__dta7O > svg {
  flex-shrink: 0;
  transform: rotate(-90deg);
}

.Sidebar_listMenu__axI-P li {
  padding-left: 40px;
  color: var(--text-color);
  font-size: 14px;
  line-height: 52px;
}

.Sidebar_listMenu__axI-P li.Sidebar_active__88yog,
.Sidebar_listMenu__axI-P li:hover {
  color: var(--text-color-accent);
}

.Sidebar_listMenu__axI-P li.Sidebar_active__88yog {
  border-left: 4px solid;
  border-color: var(--primary-color);
}

.Sidebar_copyright__v4T2l {
  padding-left: 40px;
  margin-top: auto;
}

.Sidebar_copyright__v4T2l > span {
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Sidebar/index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;;EAEf,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;;EAEE,cAAc;EACd,uBAAuB;EACvB,qCAAqC;AACvC;;AAEA;EACE,gCAAgC;EAChC,kBAAkB;EAClB,aAAa;EACb,wBAAwB;AAC1B;;AAEA;EACE,+BAA+B;EAC/B,sCAAsC;EACtC,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,0BAA0B;AAC5B;;AAEA;EACE,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,wBAAwB;EACxB,eAAe;EACf,iBAAiB;AACnB;;AAEA;;EAEE,+BAA+B;AACjC;;AAEA;EACE,sBAAsB;EACtB,kCAAkC;AACpC;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".wrapper {\n  width: 254px;\n  height: 100%;\n  padding: 32px 0;\n\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.create,\n.profile {\n  --margin: 20px;\n  margin: 0 var(--margin);\n  width: calc(100% - var(--margin) * 2);\n}\n\n.profile {\n  border: 2px solid var(--grey-70);\n  border-radius: 6px;\n  padding: 10px;\n  color: var(--text-color);\n}\n\n.profile:disabled {\n  color: var(--text-color-accent);\n  border: 2px solid var(--primary-color);\n  opacity: 1;\n}\n\n.profile > figure {\n  border-radius: 50%;\n  width: 50px;\n  height: 50px;\n}\n\n.profile > div {\n  flex-grow: 1;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-decoration: underline;\n}\n\n.profile > svg {\n  flex-shrink: 0;\n  transform: rotate(-90deg);\n}\n\n.listMenu li {\n  padding-left: 40px;\n  color: var(--text-color);\n  font-size: 14px;\n  line-height: 52px;\n}\n\n.listMenu li.active,\n.listMenu li:hover {\n  color: var(--text-color-accent);\n}\n\n.listMenu li.active {\n  border-left: 4px solid;\n  border-color: var(--primary-color);\n}\n\n.copyright {\n  padding-left: 40px;\n  margin-top: auto;\n}\n\n.copyright > span {\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Sidebar_wrapper__a1GKU`,
	"create": `Sidebar_create__JAfxh`,
	"profile": `Sidebar_profile__dta7O`,
	"listMenu": `Sidebar_listMenu__axI-P`,
	"active": `Sidebar_active__88yog`,
	"copyright": `Sidebar_copyright__v4T2l`
};
export default ___CSS_LOADER_EXPORT___;
