// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Onboarding_wrapper__Y63fZ {
  width: 420px;
}

.Onboarding_header__3D3TY {
  display: flex;
  gap: 24px;
}

.Onboarding_header__3D3TY > svg {
  color: var(--accent-green);
}

.Onboarding_label__QNxB5 {
  margin: 20px 20px 20px 40px;
  color: var(--text-color);
}
`, "",{"version":3,"sources":["webpack://./src/screens/Onboarding/index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,2BAA2B;EAC3B,wBAAwB;AAC1B","sourcesContent":[".wrapper {\n  width: 420px;\n}\n\n.header {\n  display: flex;\n  gap: 24px;\n}\n\n.header > svg {\n  color: var(--accent-green);\n}\n\n.label {\n  margin: 20px 20px 20px 40px;\n  color: var(--text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Onboarding_wrapper__Y63fZ`,
	"header": `Onboarding_header__3D3TY`,
	"label": `Onboarding_label__QNxB5`
};
export default ___CSS_LOADER_EXPORT___;
