import { Header } from 'components/collectible';

import { formatServiceFee, roundPrice } from 'constants/helper';

import { useTransactionCost } from 'hooks/useTransactionCost';

import { DEFAULT_CURRENCY, MATIC_CURRENCY } from 'services/currency';

import { StepIndex, StepProps } from '../types';
import css from './index.module.css';
import Attributes from './sections/Attributes';
import Contributors from './sections/Contributors';
import Details from './sections/Details';

const Review = ({ combinedValues, transaction }: StepProps<null>) => {
  const contributors = combinedValues[StepIndex.CONTRIBUTORS];
  const details = combinedValues[StepIndex.DETAILS];
  const { licenseAgreement, ownershipAgreement } = combinedValues[StepIndex.TERMS]!;
  const attributes = combinedValues[StepIndex.ATTRIBUTES];

  const { getTransactionCostAmount } = useTransactionCost(transaction);

  return (
    <div className={css.container}>
      <Header subtitle="Please review the information for this NFT. If everything looks good, press Create." />

      <section className={css.section}>
        <Details details={details!} licenseAgreement={licenseAgreement} ownershipAgreement={ownershipAgreement} />
      </section>

      <section className={css.section}>
        <Contributors {...contributors!} />
      </section>

      <section className={css.section}>
        <Attributes {...attributes!} />
      </section>

      <section className={css.section}>
        <h3>Minting details</h3>
        <br />
        <div>
          <div>Transaction Speed: Rapid</div>
          <div>
            Transaction Fee: {roundPrice(getTransactionCostAmount('rapid', MATIC_CURRENCY))} {MATIC_CURRENCY} /{' '}
            {roundPrice(getTransactionCostAmount('rapid', DEFAULT_CURRENCY))} {DEFAULT_CURRENCY}
          </div>
          <div>
            Service Fee: {formatServiceFee(getTransactionCostAmount('serviceFee', MATIC_CURRENCY))} {MATIC_CURRENCY} /{' '}
            {formatServiceFee(getTransactionCostAmount('serviceFee', DEFAULT_CURRENCY))} {DEFAULT_CURRENCY}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Review;
