import { EthereumAddress } from 'beasy-fe-commons';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import avatarPlaceholder from 'assets/images/avatar-placeholder.png';

import { ClipboardCopy } from 'components/clipboard';
import { ImageLoading } from 'components/image';

import { shortenAddress } from 'constants/helper';

import { UserProfile } from 'protobuf/lib/userProfileService';

import { getAssetHistory } from 'services/collectible';
import { getIpfsUrl } from 'services/ipfs';
import { getUserProfile, UserProfileQueryKey } from 'services/userProfile';

import { AppState } from 'state';

import css from './index.module.css';

type ItemProps = {
  status?: string;
  userProfile?: UserProfile;
};

const Item = ({ status, userProfile }: ItemProps) => (
  <div className={css.item}>
    <div className={status === 'Creator' || status === 'Creator, current owner' ? css.timelineCreator : css.timeline}>
      <div className={status === 'Creator' || status === 'Creator, current owner' ? '' : css.line}></div>
    </div>
    <ImageLoading
      className={css.avatar}
      src={getIpfsUrl(userProfile?.avatarCid) || avatarPlaceholder}
      alt={userProfile?.nickname}
      fallback={<Skeleton className={css.avatar} height={36} width={36} circle />}
    />
    <div className={css.description}>
      <ClipboardCopy text={userProfile?.ethAddress}>
        {userProfile?.nickname || shortenAddress(userProfile?.ethAddress)}
      </ClipboardCopy>
      <p className={css.status}>{status || <Skeleton width={100} />}</p>
    </div>
  </div>
);

interface Props {
  tokenId: string;
  creator?: EthereumAddress;
}

export const OwnershipHistory = ({ tokenId, creator }: Props) => {
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  const creatorProfileQuery = useQuery([UserProfileQueryKey.getUserProfile, creator], () =>
    getUserProfile(creator || walletConnectV2.address!),
  );
  const assetHistoryQuery = useQuery(['getAssetHistory', tokenId], () => getAssetHistory(tokenId));

  return (
    <ul>
      {!assetHistoryQuery.data ? (
        <li>
          <Item />
        </li>
      ) : assetHistoryQuery.data.length === 0 ? (
        <li>
          <Item status="Creator, current owner" userProfile={creatorProfileQuery.data} />
        </li>
      ) : (
        <>
          {assetHistoryQuery.data.map((item, i) => (
            <li key={item.block}>
              <Item status={i === 0 ? 'Current owner' : 'Ex-owner'} userProfile={item.to} />
            </li>
          ))}
          <li>
            <Item status="Creator" userProfile={assetHistoryQuery.data[assetHistoryQuery.data.length - 1].from} />
          </li>
        </>
      )}
    </ul>
  );
};
