import clsx from 'clsx';
import commonCss from './common.module.css';
import { InputProps } from './types';

interface Props extends InputProps<string, HTMLInputElement> {
  // Optional:
  className?: string;
  placeholder?: string;
  readOnly?: boolean;
}

export const TextInput = (props: Props) => {
  return <input className={clsx(commonCss.box, props.className)} {...props} />;
};
