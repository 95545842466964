import { AudioPlayer } from 'components/AudioPlayer';
import { VideoPlayer } from 'components/VideoPlayer';
import { ImageView } from 'components/image';

import { supportedAudioExtensions, supportedImageExtensions, supportedVideoExtensions } from 'constants/mimeTypes';

interface Props {
  fileName: string;
  src: string;
}

function getFileExtension(filename:string) {
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
}

export const MediaView = ({ fileName, src }: Props) => {
  const extension = getFileExtension(fileName).toLowerCase();

  // todo use mime-types instead of extensions

  if (supportedImageExtensions.includes(extension)) {
    return <ImageView src={src} />;
  }

  if (supportedAudioExtensions.includes(extension)) {
    return <AudioPlayer src={src} />;
  }

  if (supportedVideoExtensions.includes(extension)) {
    return <VideoPlayer src={src} />;
  }

  return null; // todo
};
