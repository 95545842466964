/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "";

export interface Currency {
  id: number;
  name: string;
  code: string;
  icon?: string | undefined;
}

export interface GetCurrenciesReq {
}

export interface GetCurrenciesRes {
  currencies: Currency[];
}

export interface GetExchangeRatesReq {
  from: string;
  to: string[];
}

export interface CurrencyExchangeRate {
  from: string;
  to: string;
  rate: number;
  timestamp: Date | undefined;
}

export interface GetExchangeRatesRes {
  rates: CurrencyExchangeRate[];
}

function createBaseCurrency(): Currency {
  return { id: 0, name: "", code: "", icon: undefined };
}

export const Currency = {
  encode(message: Currency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.code !== "") {
      writer.uint32(26).string(message.code);
    }
    if (message.icon !== undefined) {
      writer.uint32(34).string(message.icon);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Currency {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.code = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.icon = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Currency>, I>>(base?: I): Currency {
    return Currency.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Currency>, I>>(object: I): Currency {
    const message = createBaseCurrency();
    message.id = object.id ?? 0;
    message.name = object.name ?? "";
    message.code = object.code ?? "";
    message.icon = object.icon ?? undefined;
    return message;
  },
};

function createBaseGetCurrenciesReq(): GetCurrenciesReq {
  return {};
}

export const GetCurrenciesReq = {
  encode(_: GetCurrenciesReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCurrenciesReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCurrenciesReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCurrenciesReq>, I>>(base?: I): GetCurrenciesReq {
    return GetCurrenciesReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCurrenciesReq>, I>>(_: I): GetCurrenciesReq {
    const message = createBaseGetCurrenciesReq();
    return message;
  },
};

function createBaseGetCurrenciesRes(): GetCurrenciesRes {
  return { currencies: [] };
}

export const GetCurrenciesRes = {
  encode(message: GetCurrenciesRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.currencies) {
      Currency.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCurrenciesRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCurrenciesRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.currencies.push(Currency.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCurrenciesRes>, I>>(base?: I): GetCurrenciesRes {
    return GetCurrenciesRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCurrenciesRes>, I>>(object: I): GetCurrenciesRes {
    const message = createBaseGetCurrenciesRes();
    message.currencies = object.currencies?.map((e) => Currency.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetExchangeRatesReq(): GetExchangeRatesReq {
  return { from: "", to: [] };
}

export const GetExchangeRatesReq = {
  encode(message: GetExchangeRatesReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.from !== "") {
      writer.uint32(10).string(message.from);
    }
    for (const v of message.to) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetExchangeRatesReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetExchangeRatesReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.from = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.to.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetExchangeRatesReq>, I>>(base?: I): GetExchangeRatesReq {
    return GetExchangeRatesReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetExchangeRatesReq>, I>>(object: I): GetExchangeRatesReq {
    const message = createBaseGetExchangeRatesReq();
    message.from = object.from ?? "";
    message.to = object.to?.map((e) => e) || [];
    return message;
  },
};

function createBaseCurrencyExchangeRate(): CurrencyExchangeRate {
  return { from: "", to: "", rate: 0, timestamp: undefined };
}

export const CurrencyExchangeRate = {
  encode(message: CurrencyExchangeRate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.from !== "") {
      writer.uint32(10).string(message.from);
    }
    if (message.to !== "") {
      writer.uint32(18).string(message.to);
    }
    if (message.rate !== 0) {
      writer.uint32(29).float(message.rate);
    }
    if (message.timestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CurrencyExchangeRate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrencyExchangeRate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.from = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.to = reader.string();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.rate = reader.float();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CurrencyExchangeRate>, I>>(base?: I): CurrencyExchangeRate {
    return CurrencyExchangeRate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CurrencyExchangeRate>, I>>(object: I): CurrencyExchangeRate {
    const message = createBaseCurrencyExchangeRate();
    message.from = object.from ?? "";
    message.to = object.to ?? "";
    message.rate = object.rate ?? 0;
    message.timestamp = object.timestamp ?? undefined;
    return message;
  },
};

function createBaseGetExchangeRatesRes(): GetExchangeRatesRes {
  return { rates: [] };
}

export const GetExchangeRatesRes = {
  encode(message: GetExchangeRatesRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.rates) {
      CurrencyExchangeRate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetExchangeRatesRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetExchangeRatesRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.rates.push(CurrencyExchangeRate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetExchangeRatesRes>, I>>(base?: I): GetExchangeRatesRes {
    return GetExchangeRatesRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetExchangeRatesRes>, I>>(object: I): GetExchangeRatesRes {
    const message = createBaseGetExchangeRatesRes();
    message.rates = object.rates?.map((e) => CurrencyExchangeRate.fromPartial(e)) || [];
    return message;
  },
};

export interface CurrencyService {
  GetCurrencies(request: DeepPartial<GetCurrenciesReq>, metadata?: grpc.Metadata): Promise<GetCurrenciesRes>;
  GetExchangeRates(request: DeepPartial<GetExchangeRatesReq>, metadata?: grpc.Metadata): Promise<GetExchangeRatesRes>;
}

export class CurrencyServiceClientImpl implements CurrencyService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetCurrencies = this.GetCurrencies.bind(this);
    this.GetExchangeRates = this.GetExchangeRates.bind(this);
  }

  GetCurrencies(request: DeepPartial<GetCurrenciesReq>, metadata?: grpc.Metadata): Promise<GetCurrenciesRes> {
    return this.rpc.unary(CurrencyServiceGetCurrenciesDesc, GetCurrenciesReq.fromPartial(request), metadata);
  }

  GetExchangeRates(request: DeepPartial<GetExchangeRatesReq>, metadata?: grpc.Metadata): Promise<GetExchangeRatesRes> {
    return this.rpc.unary(CurrencyServiceGetExchangeRatesDesc, GetExchangeRatesReq.fromPartial(request), metadata);
  }
}

export const CurrencyServiceDesc = { serviceName: "CurrencyService" };

export const CurrencyServiceGetCurrenciesDesc: UnaryMethodDefinitionish = {
  methodName: "GetCurrencies",
  service: CurrencyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCurrenciesReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCurrenciesRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CurrencyServiceGetExchangeRatesDesc: UnaryMethodDefinitionish = {
  methodName: "GetExchangeRates",
  service: CurrencyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetExchangeRatesReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetExchangeRatesRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new Date(millis);
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
