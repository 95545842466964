export const FreeTierText = () => {
  return (
    <div style={{ paddingRight: '14px' }}>
      <p>
        BEASY and Licensee desire to implement terms and conditions pursuant to which Licens ee will license from BEASY
        the blockchain-enabled BEASY Forge web portal platform as more particularly described in this Agreement and
        Exhibit to this Agreement during the term of this Agreement.
      </p>
      <br></br>
      <p>Incorporated into this Agreement and attached hereto are:</p>
      <br></br>
      <p>● Exhibit A, Licensed Product Description and Specifications; Fees; SOW</p>
      <p>● Exhibit B, Beasy SLA</p>
      <p>● Exhibit C, Beasy Terms of Service</p>
      <br></br>
      <p>
        The terms and conditions of this Agreement will apply to the provision of the BEASY Forge web portal platform to
        Licensee by BEASY during the term of this Agreement. Therefore, BEASY and Licensee agree as follows:
      </p>
      <br></br>
      <p>1. DEFINITIONS</p>
      <br></br>
      <p>
        1.1. “Confidential information” includes, but is not limited to, either Party’s Intellectual Property,
        confidential, technical and business information relating to the BEASY Forge web portal platform and
        Documentation, research and development, production and engineering processes, costs, profit or margin
        information, marketing, production, unreleased products, present and future business plans, software Source
        Code, release notes, bugs, or errors with respect to the BEASY Forge web portal platform, and any other
        information or data not previously known that could reasonably be considered confidential or proprietary.
      </p>
      <br></br>
      <p>
        1.2. “Documentation” means BEASY’s information manuals in printed or electronic form containing operating
        instructions and performance specifications that BEASY generally makes available to users of its BEASY Forge web
        portal platform. Documentation includes statements of work delivered by BEASY to Licensee with respect to Work.
        Documentation does not include marketing materials.
      </p>
      <br></br>
      <p>
        1.3. “Exhibits” means the applicable Exhibits expressly designated in the Agreement, all of which are attached
        hereto and incorporated by reference in this Agreement. During the term of this Agreement, BEASY reserves the
        right to amend any of such Exhibits upon not less than 10 days prior written notice to Licensee; provided,
        however, that no such amendment shall (i) have the effect of increasing the pricing provided in Exhibit A set
        forth therein or (ii) change any agreed upon Service Levels contained in an Exhibit.
      </p>
      <br></br>
      <p>1.4. “Fees” means fees for the BEASY Forge web portal platform as specified in Exhibit A.</p>
      <br></br>
      <p>1.5. “Licensed Product(s)” means, including but not limited to: BEASY Forge web portal platform.</p>
      <br></br>
      <p>
        1.6. “Licensee Interface” means software designed, developed and implemented by Licensee (exclusively) that use
        any standard interface capabilities to the BEASY Forge web portal platform.
      </p>
      <br></br>
      <p>
        1.7. “Hosting” means [if applicable] the hosting services to be provided by BEASY to Licensee in accordance with
        Exhibit A, the SOW.
      </p>
      <br></br>
      <p>
        1.8. “Intellectual Property” shall mean with respect to each Party such Party’s patents, copyrights, trademarks,
        trade secrets or other proprietary information, any ideas, concepts, techniques, inventions, processes,
        comprising, embodied in or practiced in connection with such Party’s products or technologies (including all
        modifications, enhancements, configurations, upgrades and improvements thereto including, without limitation,
        any developed by such Party during the course of this Agreement).
      </p>
      <br></br>
      <p>1.9. “Party” means either Licensee or BEASY.</p>
      <br></br>
      <p>1.10. “Parties” means both Licensee and BEASY.</p>
      <br></br>
      <p>
        1.11. “Statement of Work” or “SOW” means a statement of work in a form of Exhibit A issued by Licensor and
        accepted by BEASY in accordance with the terms and provisions of this Agreement.
      </p>
      <br></br>
      <p>
        1.12. “Work” means any activities and services necessary for the execution and timely completion of BEASY’s
        obligations under the Agreement including, but not limited to, the activities and services which may be set
        forth in a SOW.
      </p>
      <br></br>
      <p>2. GRANT OF LICENSE</p>
      <br></br>
      <p>
        2.1. License. BEASY grants to Licensee a non-transferable, limited, nonexclusive license to use solely for
        Licensee’s own internal business purposes the Licensed Product and any enhancements, upgrades, improvements or
        modifications to the Licensed Product, provided by BEASY pursuant to this Agreement. This license transfers to
        Licensee neither title nor any proprietary or intellectual property rights to the Licensed Product,
        Documentation, or any copyrights, patents, or trademarks, embodied or used in connection with the license,
        except for the internal use rights expressly granted in this Agreement. Additionally, Licensee has the right to
        create and use Licensee Interfaces.
      </p>
      <br></br>
      <p>3. LICENSE RESTRICTIONS</p>
      <br></br>
      <p>
        3.1. Limitations. Licensee will not: (1) sell, lease, license or sublicense the Licensed Product or the
        Documentation to any third party; (ii) decompile, disassemble, translate, or reverse engineer, or attempt to
        create any passwords to allow unauthorized activation of the Licensed Product; or (iii) provide, disclose,
        divulge, make available to, or permit use of the Licensed Product by any third party without BEASY’s prior
        written consent, except for Licensee’s employees, agents or subcontractors on a need to know basis for purposes
        of Licensee’s permitted use hereunder or (iv) create or attempt to create, any derivative works of, or otherwise
        convert, modify, upgrade, enhance, translate or upgrade, the Licensed Product.
      </p>
      <br></br>
      <p>
        3.2. Third-Party Software. Use of the Licensed Product may require that Licensee have third party software, if
        specified on Exhibit C. BEASY will ensure compatibility of all such third party software by integrating all
        third party software with the Licensed Product.
      </p>
      <br></br>
      <p>
        3.3. Optional Software and Work. Licensee may wish to use outside data services and other third-party software
        products in connection with the Licensed Product. Procuring any optional services and/or software, and any fees
        related to installation, use, and third party software or hardware requirements are the direct responsibility of
        Licensee.
      </p>
      <br></br>
      <p>4. FEES</p>
      <br></br>
      <p>4.1. Payment. Licensee shall pay BEASY the Fees as specified in Exhibit A.</p>
      <br></br>
      <p>
        4.2. Payment Terms. All applicable Fees shall be invoiced as set forth in Exhibit A. All recurring fees shall be
        due and payable by Licensee and will be automatically invoiced by BEASY as set forth in Exhibit A. Licensee
        shall pay each invoice within 30 days of the invoice date in U.S. dollars.
      </p>
      <br></br>
      <p>
        4.3. Taxes. All taxes, duties, fees and other governmental charges of any kind (including sales, services and
        use taxes, but excluding taxes based on the gross revenues or net income of BEASY) which are imposed by or under
        the authority of any government or any political subdivision thereof on the Fees for the license hereunder shall
        be borne by Licensee and shall not be considered a part of, a deduction from or an offset against such Fees.
      </p>
      <br></br>
      <p>5. SCOPE OF WORK</p>
      <br></br>
      <p>
        5.1. Work. BEASY shall perform all activities necessary for its completion of the Work specified in each SOW
        entered into in connection with this Agreement.
      </p>
      <br></br>
      <p>
        5.2. BEASY Wallet per Terms of Service. In addition to the license grant in Section 2.1, Licensee shall also be
        subject to BEASY’s Terms of Service for the BEASY wallet set forth on Exhibit E hereto.
      </p>
      <br></br>
      <p>6. OWNERSHIP</p>
      <br></br>
      <p>
        6.1. Warranty. BEASY represents and warrants that it owns the Licensed Product and/or that it has an absolute
        right to allow Licensee to use the Licensed Product in accordance with the provisions of this Agreement.
      </p>
      <br></br>
      <p>
        6.2. Intellectual Property (BEASY). BEASY shall retain and maintain all exclusive rights in connection with
        BEASY Intellectual Property.
      </p>
      <br></br>
      <p>
        6.3. Ownership. Neither Party shall by virtue of this Agreement or the transactions contemplated by this
        Agreement be deemed to have conveyed any right, title or interest in or to any of such Party’s Intellectual
        Property, all of such rights are expressly reserved by such Party.
      </p>
      <br></br>
      <p>7. CONFIDENTIAL INFORMATION</p>
      <br></br>
      <p>
        7.1. Scope. For purposes of this Agreement, “Confidential Information” means but is not necessarily limited to:
        (a) all reports, analyses, notes or other information that are based on, contain or reflect any such
        Confidential Information; (b) existing or contemplated products, services, technology, designs, processes,
        formulas, algorithms, research, training materials, policies, procedures, standards or product developments of
        BEASY, its affiliates or any customer or supplier of BEASY thereof; (c) trade secrets of BEASY or its
        affiliates; (d) business plans, sales or marketing methods, methods of doing business, customer lists, customer
        usages and/or requirements, supplier information of BEASY or its affiliates thereof or any customer or supplier
        of BEASY or its affiliates thereof; (e) any business, marketing, technical, or scientific information including,
        but not limited to, data records. training manuals, policies, procedures, standards of conduct, plans,
        specifications, business strategy documents, business events information, competitor lists, employee
        demographics, business organization design, values and mission statements, current projects, exit interview and
        call center content logs, compensation plans, key performance measures, and other proprietary documents,
        transmitted in any format, related to the business of BEASY or its affiliates; (f) computer programs or software
        sources; (g) data records or data bases; (h) confidential or sensitive business information; (i) photographs;
        (j) plans, specifications, diagrams, documentation or other data related to the design or operation of
        equipment, systems or software; and (k) information, manuals, memoranda, notes, records, plots, sketches,
        reports, letters and materials, or copies thereof.
      </p>
      <br></br>
      <p>
        7.2. Use of Confidential Information. Licensee shall take all steps reasonably necessary to hold in trust and
        confidence the Confidential Information. Licensee shall hold Confidential Information in strict confidence, not
        to disclose it to third parties or to use, reveal, report, publish, transfer or otherwise disclose to any
        person, corporation or other entity, any of the Confidential Information without the prior written consent of
        BEASY other than as permitted under this Agreement. Licensee will limit the disclosure of Confidential
        Information to employees, or directly related subcontractors with a need to know who: (i) have been advised of
        its confidential nature; and (ii) have acknowledged the express obligation to maintain such confidentiality.
        Licensee shall fully indemnify BEASY for any breach of the terms of this confidentiality obligation by its
        employees or third-party subcontractors.
      </p>
      <br></br>
      <p>
        7.3. Exceptions. Notwithstanding the other provisions of this Agreement, information and material received
        hereunder will not be considered to be Confidential Information if: (i) it has been published or is otherwise
        readily available to the public other than by a breach of this Agreement; (ii) it has been rightfully received
        by the receiving Party from a third Party without confidential limitations; (iii) it has been independently
        developed by the receiving Party by personnel having no access to the Confidential Information; or (iv) it was
        known to the receiving Party prior to its first receipt from the disclosing Party. If either Party is requested
        or required to disclose any Confidential Information of the other Party by order of a court, governmental
        entity, or by applicable law, including public disclosure law, the Party so ordered will provide prompt notice
        to the other Party so that such Party may obtain appropriate protective orders.
      </p>
      <br></br>
      <p>
        7.4. Injunctive Relief. In the event of actual or threatened breach of the provisions of this Section 7, the
        non-breaching Party will have no adequate remedy at law and will be entitled to immediate injunctive and other
        equitable relief, without bond and without the necessity of showing actual money damages or those damages are
        capable of quantification.
      </p>
      <br></br>
      <p>
        7.5. Notice. In the event either Party knows or should reasonably know that any third party has gained
        unauthorized access to the (i) BEASY Authentication platform, (ii) Documentation, (iii) third party Software
        provided by BEASY, or (iv) other Confidential Information, the knowing Party shall immediately notify the other
        Party in writing of the full particulars of such access or disclosure.
      </p>
      <br></br>
      <p>
        7.6. Survival. The obligations of confidentiality contained in this Agreement shall survive the termination of
        this Agreement; provided, however, the Parties agree that each Parties trade secrets will remain in effect for
        as long as such information remains a trade secret.
      </p>
      <br></br>
      <p>
        7.7. No Public Announcements Without Consent. Neither Party will publicly release any information concerning
        this Agreement, or any part thereof, or make any public announcements except as may be required by law, to any
        third party, member of the public, press or official body, without the prior written consent of the other Party.
      </p>
      <br></br>
      <p>8. DISPUTE RESOLUTION </p>
      <br></br>
      <p>
        8.1. Prior to initiation of any arbitration or court action by either Party, any disputes under this Agreement
        must be submitted to non-binding mediation to the American Arbitration Association (“AAA”), and be conducted
        under the rules of AAA for non-binding mediation of commercial disputes. Mediation will take place in
        Massachusetts, unless both Parties agree to an alternate location.
      </p>
      <br></br>
      <p>
        8.2. Any action at law, suit in equity or other proceeding against any Party with respect to any term or
        provision of this Agreement or in connection with any of the transactions contemplated by this Agreement shall
        be brought and maintained in the federal courts of the Commonwealth of Massachusetts. Each of the Parties hereby
        submits, to the fullest extent permitted by applicable law, to the exclusive jurisdiction of such courts for the
        purpose of any such action or proceeding.
      </p>
      <br></br>
      <p>9. GENERAL PROVISIONS</p>
      <br></br>
      <p>
        9.1. Force Majeure. Neither Party shall be responsible for any failure to perform due to unforeseen,
        non-commercial circumstances beyond its reasonable control and without fault or negligence of the affected
        Party, including but not limited to, acts of God, war, riot, embargoes, acts of civil or military authorities,
        fire, floods, earthquakes, hurricanes, accidents, strikes, labor disputes among its employees, fuel or energy
        supply disruption. In the event of any such delay, any applicable period of time for action by such Party may be
        deferred for a period of time equal to the time of such delay, except a Party’s failure to make any payment when
        due under this Agreement.
      </p>
      <br></br>
      <p>
        9.2. Non-assignment/Binding Agreement. Neither this Agreement nor any rights under this Agreement may be
        assigned or otherwise transferred by either Party, in whole or in part without the prior written consent of the
        other Party, which consent shall not be unreasonably withheld or delayed. Such consent shall not be required in
        the event of merger, acquisition, or consolidation of a Party. Subject to the foregoing, this Agreement will
        bind and benefit the Parties and their respective successors and assigns.
      </p>
      <br></br>
      <p>
        9.3. Entire Agreement. This Agreement, including any exhibits, sets out the entire agreement between the Parties
        relative to the subject matter of this Agreement and supersedes all prior or contemporaneous agreements or
        representations, oral or written. This Agreement may not be altered or modified, except by written amendment
        which expressly refers to this Agreement and which is duly executed by authorized representatives of both
        Parties.
      </p>
      <br></br>
      <p>
        9.4. Applicable Law. This Agreement and its interpretation shall be governed by the laws of the Commonwealth of
        Massachusetts without regard to its choice of law provisions.
      </p>
      <br></br>
      <p>
        9.5. Additional Terms. No terms, provisions or conditions of any purchase order, acknowledgment or other
        business form that either Party may use in connection with the acquisition or licensing of the BEASY
        Authentication platform will have any effect on the rights, duties or obligations of the Parties under, or
        otherwise modify, this Agreement, regardless of any failure of the other Party to object to such terms,
        provisions or conditions without BEASY’s specific written approval.
      </p>
      <br></br>
    </div>
  );
};
