import clsx from 'clsx';
import { useSelect, UseSelectStateChange } from 'downshift';
import React from 'react';

import { ChevronIcon } from 'assets/icons';

import { ClearableValue } from '../ClearableValue';
import commonCss from '../common.module.css';
import css from './index.module.css';

export interface SelectProps<T> {
  options?: T[];
  value?: T;
  // todo multiple like in FileInput
  renderValue?: (value: T) => React.ReactNode;
  onChange?: (value?: T) => void;
  className?: string;
  isFile?:boolean;
  isAbsolute?:boolean;
  isNoRemovable?: boolean;
}

export function ClearableSelect<T>({ value, renderValue, onChange, options = [], className, isFile, isAbsolute, isNoRemovable }: SelectProps<T>) {
  const onSelectedItemChange = React.useCallback(
    ({ selectedItem }: UseSelectStateChange<T>) => {
      if (selectedItem !== value) onChange?.(selectedItem ?? undefined);
    },
    [onChange, value],
  );

  const { isOpen, selectedItem, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items: options,
    onSelectedItemChange,
    selectedItem: value ?? null, // null is needed for downshift
  });

  return (
    <div className={clsx(css.wrapper, className)}>
      <div className={commonCss.box}>
        <ClearableValue
          className={css.clearable}
          value={selectedItem ?? undefined}
          // todo multiple={multiple}
          renderValue={renderValue}
          onChange={onChange}
          isNoRemovable={isNoRemovable}
        />
        <button type="button" {...getToggleButtonProps()} disabled={options.length === 0}>
          <ChevronIcon />
        </button>
      </div>
      <ul className={isFile ? css.fileList : isAbsolute ? css.listAbsolute : css.list} {...getMenuProps()}>
        {isOpen &&
          options.map((item, index) => (
            <li
              key={`${item}${index}`}
              className={highlightedIndex === index ? 'highlighted' : ''}
              {...getItemProps({ item, index })}
            >
              {renderValue?.(item) ?? item}
            </li>
          ))}
      </ul>
    </div>
  );
}
