import { EthereumAddress } from 'beasy-fe-commons';
import { useQuery } from 'react-query';

import { getCollectionByAddress } from 'services/collection';

export const useCollection = (address?: EthereumAddress) => {
  const query = useQuery(['getCollectionByAddress', address], () => getCollectionByAddress({ address: address! }), {
    enabled: Boolean(address),
  });

  return query.data?.collection;
};
