import axios from 'axios';
import { useForm, useProgress } from 'hooks';
import isEmpty from 'lodash/isEmpty';
//import logger from 'logger';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useBoolean } from 'use-boolean';

import Alert, { AlertProps } from 'components/Alert';
import { ErrorMessageQueryLoading } from 'components/ErrorMessage';
import ProgressBar from 'components/ProgressBar';
import Table from 'components/Table';
import { ButtonPrimary } from 'components/button';
import { EmptyList } from 'components/layout';
import { LoadingScreen, LoadingScreenProps } from 'components/loading';
import LoadingSpinner from 'components/loading/Spinner';
import { PageArea, PageHeader } from 'components/page';
import { CreatorForm, creatorFormikConfig } from 'components/user';

import { encodeParam } from 'constants/helper';

import { UserProfile_AdditionalData } from 'protobuf/lib/userProfileService';

import { setAccount } from 'services/account';
import { Activity, log } from 'services/activityReport';
import { registerUser } from 'services/chargeMaster';
import { createFriend, getFriends } from 'services/friend';
import {
  createCreatorProfile,
  useGetCurrentUserProfile,
  getRoleNameMap,
  UsernameAlreadyExistsError,
  UserProfileQueryKey,
} from 'services/userProfile';

import { AppState } from 'state';

//import { registerContributorHubspot } from 'services/zapier';
import { environment } from 'environment';

import css from './index.module.css';

const PartnerList = () => {
  const COLUMNS_PARTNER_LIST = useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'Username',
        accessor: 'name',
      },
      {
        Header: 'Business Name',
        accessor: 'businessName',
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: 'Address',
        accessor: 'address',
      },
    ],
    [],
  );
  const [isLoadingModal, setLoadingModal] = useState(false);
  const [isErrorModal, setErrorModal] = useState(false);
  const { progress, setProgress } = useProgress();
  const [alertProps, setAlertProps] = useState<AlertProps | undefined>();
  const [loadingScreenProps, setLoadingScreenProps] = useState<LoadingScreenProps | undefined>();
  const { data, isError, isLoading, refetch } = useQuery(['getFriends'], () => getFriends());
  const roleMapQuery = useQuery(UserProfileQueryKey.getRoleNameMap, () => getRoleNameMap());
  const [isCreatorFormOpen, showCreatorForm, closeCreatorForm] = useBoolean(false);
  const { form: creatorForm, getFormValue } = useForm(creatorFormikConfig);
  const [currentUsername, setUsername] = useState('');
  const getCurrentUserProfile = useGetCurrentUserProfile();
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  const byNetwork = ({ network }: { network: string }) => network === walletConnectV2.chainId!;
  const getUserProfileQuery = useQuery(UserProfileQueryKey.getUserProfile, () => {
    return getCurrentUserProfile();
  });
  const showErrorAlert = useCallback(() => {
    setLoadingScreenProps(undefined);
    setAlertProps({
      isSuccess: false,
      message: 'There was an issue processing your registration. Please try again.',
      onClose: () => setAlertProps(undefined),
    });
  }, []);
  const progressMessage = useMemo(() => {
    return progress === 1 ? 'Almost done...' : `Processing: ${progress != null ? Math.round(100 * progress) : null}%`;
  }, [progress]);

  const registerCreator = async () => {
    setLoadingModal(true);
    setErrorModal(false);
    const account_id = encodeParam((+new Date()).toString(36).slice(-5) + Math.random().toString(36).substring(2, 5));
    let formValue;
    let address;
    try {
      //Validate username and address on database
      formValue = await getFormValue();
      const { username } = formValue;
      setUsername(username);
      //Set account
      const result = await setAccount(account_id);
      address = result.address;
      const additionalData: UserProfile_AdditionalData = {
        firstName: formValue.name,
        lastName: formValue.lastname,
        businessName: formValue.business,
        source: process.env.REACT_APP_DOMAIN,
      };
      await createCreatorProfile(address, username, undefined, additionalData);
      closeCreatorForm();
    } catch (error) {
      setLoadingModal(false);
      error instanceof UsernameAlreadyExistsError ? setErrorModal(true) : setErrorModal(false);
      //logger.error(error);
      return;
    }
    setLoadingModal(false);
    //Open alert modal
    setLoadingScreenProps({
      message: 'Please give us a moment while we finish creating your Partner account.',
      isNotification: false,
    });

    setProgress(0);

    //Register user as creator
    try {
      await axios.get(environment.apiUrl + 'creator/add/' + formValue?.role + '/' + address);
    } catch (error) {
      //logger.error(error);
      showErrorAlert();
      return;
    }

    //Register user on hubspot
    /*
    setProgress(0.25);
    try {
      await registerContributorHubspot(account_id, {
        name: formValue?.name,
        lastname: formValue?.lastname,
        business: formValue?.business,
        industry: formValue?.industry,
        role: formValue?.role,
        eth_address: address,
        email: formValue?.email,
        phone: formValue?.phone,
        existingUser: getUserProfileQuery?.data?.nickname,
      });
    } catch (error) {
      //logger.error(error);
      showErrorAlert();
      return;
    }
    */
    //Register user on chargeMaster and setAccount
    setProgress(0.5);
    try {
      const roleEncoded = encodeParam(formValue?.role!.toLowerCase());
      await registerUser(address, roleEncoded, account_id);
    } catch (error) {
      //logger.error(error);
      showErrorAlert();
      return;
    }

    //Create a friend connection
    setProgress(0.75);
    try {
      await createFriend(address);
    } catch (error) {
      //logger.error(error);
      showErrorAlert();
      return;
    }

    //Finish
    await refetch();
    setProgress(1);
    log(address, Activity.AUTO_REGISTRATION, process.env.REACT_APP_DOMAIN);
    setLoadingScreenProps(undefined);
    setAlertProps({
      isSuccess: true,
      message: `Partner successfully added!`,
      onClose: () => {
        setAlertProps(undefined);
      },
    });
  };

  const getContent = () => {
    if (isError) return <ErrorMessageQueryLoading onRetry={() => refetch()} />;
    if (isLoading) return <LoadingSpinner />;

    const friends = data?.friends.filter(({ userProfile }) => userProfile?.roles.find(byNetwork));

    const dataTable = friends?.map(friend => ({
      ...friend,
      firstName: friend.additionalData?.firstName,
      lastName: friend.additionalData?.lastName,
      businessName: friend.additionalData?.businessName,
      role: roleMapQuery.data?.[friend.userProfile?.roles.find(byNetwork)?.role!],
      address: friend.address,
    }));
    if (isEmpty(friends)) {
      return (
        <EmptyList
          message={`You have no partners yet.\n
          In order to add a partner, you must send a BEASY creator and BEASY Wallet user your friend link.`}
        />
      );
    }

    return (
      <div className={css.partnerListTable}>
        <Table data={dataTable} columns={COLUMNS_PARTNER_LIST} />
      </div>
    );
  };

  return (
      <PageArea>
        <PageHeader title="My Partners">
          {/*<ButtonPrimary onClick={showCreatorForm}>+ Invite New Partner</ButtonPrimary> */}
        </PageHeader>
        {getContent()}
      {isCreatorFormOpen && (
        <CreatorForm
          form={creatorForm}
          username={currentUsername}
          isFriend={true}
          isLoading={isLoadingModal}
          isError={isErrorModal}
          onClick={registerCreator}
          onClose={closeCreatorForm}
        />
      )}
      {loadingScreenProps && (
        <LoadingScreen {...loadingScreenProps}>
          {progress != null && !loadingScreenProps.isNotification && (
            <ProgressBar message={progressMessage} progress={progress} />
          )}
        </LoadingScreen>
      )}
      {alertProps && <Alert {...alertProps} />}
      </PageArea>
  );
};

export default PartnerList;
