// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TabMenu_wrapper__yuZba {
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/components/page/TabMenu/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf","sourcesContent":[".wrapper {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `TabMenu_wrapper__yuZba`
};
export default ___CSS_LOADER_EXPORT___;
