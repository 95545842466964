import audioFile from 'assets/images/audio-file.png';

import { getFileFromUrl } from 'constants/helper';

const canvasResize = (canvas: HTMLCanvasElement, width: number, height: number, size: number) => {
  const scaleRatio = size / Math.max(width, height);

  canvas.width = width * scaleRatio;
  canvas.height = height * scaleRatio;
};

const canvasDraw = (el: HTMLImageElement | HTMLVideoElement, canvas: HTMLCanvasElement) => {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Could not get canvas context');
  }

  ctx.drawImage(el, 0, 0, canvas.width, canvas.height);
};

const hasImageData = (canvas: HTMLCanvasElement) => {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Could not get canvas context');
  }

  const { data } = ctx.getImageData(0, 0, canvas.width, canvas.height);
  return data.some(item => item !== 0 && item !== 1);
};

/**
 * Because `ctx.drawImage` is asynchronous, but neither returns a Promise nor provides a callback
 * to indicate that rendering has completed, we have to call `hasImageData` in a loop until
 * there are some pixels with RGBA components that are not equal 0 or 1.
 *
 * For this reason this code will not work on images that only have black and white pixels.
 */
const watchCanvasData = (
  canvas: HTMLCanvasElement,
  fileName: string,
  options: GenerateThumbnailOptions,
  callback: (blob: File | null) => void,
) => {
  let attempt = 0;

  const interval = setInterval(() => {
    if (attempt++ === options.watchCanvasDataAttempts) {
      clearInterval(interval);
      return callback(null);
    }

    const hasData = hasImageData(canvas);
    if (hasData) {
      clearInterval(interval);
      canvas.toBlob(blob => {
        if (blob) {
          const file = new File([blob], fileName);
          return callback(file);
        }

        return callback(null);
      });
    }
  }, options.watchCanvasDataIntervalMs);
};

export interface GenerateThumbnailOptions {
  size: number;
  watchCanvasDataAttempts: number;
  watchCanvasDataIntervalMs: number;
}

const generateImageThumbnail = (file: File, options: GenerateThumbnailOptions): Promise<File | null> => {
  return new Promise(resolve => {
    const el = document.createElement('img');
    el.src = URL.createObjectURL(file);

    const canvas = document.createElement('canvas');

    el.addEventListener('load', () => {
      canvasResize(canvas, el.width, el.height, options.size);
      canvasDraw(el, canvas);
      watchCanvasData(canvas, file.name, options, resolve);
    });
  });
};

const generateVideoThumbnail = (file: File, options: GenerateThumbnailOptions): Promise<File | null> => {
  return new Promise(resolve => {
    const el = document.createElement('video');
    el.src = URL.createObjectURL(file);

    const canvas = document.createElement('canvas');

    el.addEventListener('loadedmetadata', () => {
      canvasResize(canvas, el.videoWidth, el.videoHeight, options.size);
    });

    el.addEventListener('canplay', () => {
      el.currentTime = 1e-3;
      canvasDraw(el, canvas);
      watchCanvasData(canvas, file.name, options, resolve);
    });
  });
};

const generateThumbnail = async (file: File, options: GenerateThumbnailOptions): Promise<File | null> => {
  try {
    if (file.type.startsWith('image/')) {
      return await generateImageThumbnail(file, options);
    }

    if (file.type.startsWith('video/')) {
      return await generateVideoThumbnail(file, options);
    }

    if (file.type.startsWith('audio/')) {
      return await getFileFromUrl(audioFile, 'audio.png');
    }

    return null;
  } catch (error) {
    return null;
  }
};

export default generateThumbnail;
