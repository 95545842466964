import { grpc } from '@improbable-eng/grpc-web';
import { captureException } from '@sentry/react';

import { GrpcWebImpl, PaymentServiceClientImpl } from 'protobuf/lib/payment';

import { environment } from 'environment';
import { UserProfile_AdditionalData } from 'protobuf/lib/userProfileService';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const paymentServiceClient = new PaymentServiceClientImpl(rpc);

export const getNewAccountLink = async (address:string, userData:UserProfile_AdditionalData) => {
  try {
    return await paymentServiceClient.getNewAccountLink({ address: address, name: userData.firstName, lastname: userData.lastName, forge: userData.source} );
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const getAccountLink = async (accountId:string) => {
  try {
    const forge = process.env.REACT_APP_DOMAIN;
    return await paymentServiceClient.getAccountLink({accountId, forge});
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const isAccountActivated = async (accountId:string) => {
  try {
    const forge = process.env.REACT_APP_DOMAIN;
    return await paymentServiceClient.isAccountActivated({accountId, forge});
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const getAccountLoginLink = async (accountId:string) => {
  try {
    const forge = process.env.REACT_APP_DOMAIN;
    return await paymentServiceClient.getAccountLoginLink({accountId, forge});
  } catch (error) {
    captureException(error);
    throw error;
  }
};
