// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_wrapper__HI0g9 {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/components/dialog/Header/index.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".wrapper {\n  flex-shrink: 0;\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Header_wrapper__HI0g9`
};
export default ___CSS_LOADER_EXPORT___;
