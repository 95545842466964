import clsx from 'clsx';

import css from './index.module.css';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const DialogArea = ({ children, className }: Props) => {
  return <section className={clsx(css.wrapper, className)}>{children}</section>;
};
