// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Item_row__7PVMj:nth-child(odd) {
  background-color: var(--alt11-color);
}

.Item_row__7PVMj:nth-child(even) {
  background-color: rgba(54, 64, 81, 0.11);
}

.Item_row__7PVMj td {
  padding: 14px 20px;
}

.Item_row__7PVMj td:nth-child(1) {
  border-right: 1px solid var(--alt12-color);
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Item_row__7PVMj td:nth-child(2) {
  width: 200px;
  text-align: center;
}

.Item_view__5y05Q {
  border: 1px solid var(--body-color);
  color: var(--body-color);
  border-radius: 15px;
  padding: 2px 14px;
  font-size: 11px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Agreement/Item/index.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,0CAA0C;EAC1C,iBAAiB;EACjB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,mCAAmC;EACnC,wBAAwB;EACxB,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".row:nth-child(odd) {\n  background-color: var(--alt11-color);\n}\n\n.row:nth-child(even) {\n  background-color: rgba(54, 64, 81, 0.11);\n}\n\n.row td {\n  padding: 14px 20px;\n}\n\n.row td:nth-child(1) {\n  border-right: 1px solid var(--alt12-color);\n  font-weight: bold;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.row td:nth-child(2) {\n  width: 200px;\n  text-align: center;\n}\n\n.view {\n  border: 1px solid var(--body-color);\n  color: var(--body-color);\n  border-radius: 15px;\n  padding: 2px 14px;\n  font-size: 11px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `Item_row__7PVMj`,
	"view": `Item_view__5y05Q`
};
export default ___CSS_LOADER_EXPORT___;
