// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ghost2_button__400gf {
  font-size: 14px;
  font-weight: 400;

  color: var(--alt15-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/button/Ghost2/index.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;;EAEhB,yBAAyB;AAC3B","sourcesContent":[".button {\n  font-size: 14px;\n  font-weight: 400;\n\n  color: var(--alt15-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Ghost2_button__400gf`
};
export default ___CSS_LOADER_EXPORT___;
