import { grpc } from '@improbable-eng/grpc-web';
import { captureException } from '@sentry/react';

import { GrpcWebImpl, CurrencyServiceClientImpl, GetExchangeRatesReq } from 'protobuf/lib/currency';

import { environment } from 'environment';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const currencyServiceClient = new CurrencyServiceClientImpl(rpc);

export const DEFAULT_CURRENCY = 'USD';
export const MATIC_CURRENCY = 'MATIC';
export const WETH_CURRENCY = 'WETH';

export const WEI_IN_ETH = 1000000000000000000;

export const calcGasPrice = (gasAmount: number, weiPrice: number, exchangeRate: number) =>
  ((gasAmount * weiPrice) / WEI_IN_ETH) * exchangeRate;

export const getCurrencies = async () => {
  try {
    return await currencyServiceClient.GetCurrencies({});
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const getExchangeRates = async (req: GetExchangeRatesReq) => {
  try {
    return await currencyServiceClient.GetExchangeRates(req);
  } catch (error) {
    captureException(error);
    throw error;
  }
};
