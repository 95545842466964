// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PushShopifyModal_label__D1GDN {
  margin: 20px 20px 20px 40px;
  color: var(--text-color);
}

.PushShopifyModal_header__nbKOZ {
  display: flex;
  gap: 24px;
}

.PushShopifyModal_header__nbKOZ > svg {
  margin-top: 2px;
  color: var(--orange);
}
`, "",{"version":3,"sources":["webpack://./src/screens/collectible/View/PushShopifyModal/index.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,eAAe;EACf,oBAAoB;AACtB","sourcesContent":[".label {\n  margin: 20px 20px 20px 40px;\n  color: var(--text-color);\n}\n\n.header {\n  display: flex;\n  gap: 24px;\n}\n\n.header > svg {\n  margin-top: 2px;\n  color: var(--orange);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `PushShopifyModal_label__D1GDN`,
	"header": `PushShopifyModal_header__nbKOZ`
};
export default ___CSS_LOADER_EXPORT___;
