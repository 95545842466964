import { useEffect, useMemo, useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useBoolean } from 'use-boolean';

import { DeleteIcon } from 'assets/icons';

import Alert from 'components/Alert';
import PeriodSelector from 'components/Chart/PeriodSelector';
import PriceLineChart from 'components/Chart/PriceLine';
import Navigator from 'components/Navigator';
import { Spacer } from 'components/Spacer';
import { ButtonPrimary } from 'components/button';
import { ClipboardCopy } from 'components/clipboard';
import { Available, MintDialog, OwnershipHistory, TokenDetails, TokenList } from 'components/collectible';
import { FilterDropdown } from 'components/input';
import { LoadingScreen } from 'components/loading';
import { PageArea } from 'components/page';

import { shortenAddress } from 'constants/helper';

import { useCollection } from 'hooks/collection';

import {
  EXACT_TIME_FORMAT,
  getAggregatedFromDate,
  getAggregatedTimeFormat,
  getCollectibleChart,
  getPeriodUnit,
  getTokenChart,
  Period,
} from 'services/chart.v2';
import { deleteCollectibleById, getCollectibleById } from 'services/collectible';
import { DEFAULT_CURRENCY } from 'services/currency';
import { deleteCollectibleProductById, getProduct, ProductQueryKey } from 'services/product';
import { updateProduct } from 'services/product';
import { computedStyle } from 'services/style';

import { AppState } from 'state';

import DeleteNftModal from './DeleteNftModal';
import PushShopifyModal from './PushShopifyModal';
import css from './index.module.css';
import useMintNFT from './useMintNFT';

export const CollectibleViewRoute = () => {
  const history = useHistory();
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  const mintNFTController = useMintNFT();
  const { collectibleId, tokenId } = useParams<{ collectibleId: string; tokenId: string }>();
  const currentAddress = walletConnectV2.address!.toLowerCase();
  const [isMintModalOpen, showMintModal, closeMintModal] = useBoolean(false);
  const [isPushShopifyModalOpen, showPushShopifyModal, closePushShopifyModal] = useBoolean(false);
  const [isShopifyButtonDisabled, shopifyButtonDisabled] = useState(false);
  const [pushShopifyLabel, setPushShopifyLabel] = useState('Push to Shopify');
  const [isDeleteNftModalOpen, showDeleteNftModal, closeDeleteNftModal] = useBoolean(false);
  const [isDeleteNftButtonDisabled, deleteNftButtonDisabled] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isError, setIsError] = useState(false);

  const collectibleQuery = useQuery(['getCollectibleById', collectibleId], () => {
    return getCollectibleById(collectibleId);
  });

  const productQuery = useQuery([ProductQueryKey.getProduct, collectibleId], () => {
    return getProduct(collectibleId);
  });

  const handleNavigateToCollection = useCallback(
    () => history.push(`/home/collection/view/${collectibleQuery.data?.collection}`),
    [collectibleQuery.data?.collection, history],
  );

  const collection = useCollection(collectibleQuery.data?.collection);
  const [period, setPeriod] = useState<Period>('ALL');
  const [fromDate, setFromDate] = useState<Date | undefined>();
  const [toDate] = useState(new Date());

  useEffect(() => {
    setFromDate(getAggregatedFromDate(period, toDate));
  }, [period, toDate]);

  const [queryFunction, queryFunctionName] = tokenId
    ? [getTokenChart, 'getTokenChart']
    : [getCollectibleChart, 'getCollectibleChart'];

  const { data: stats } = useQuery([queryFunctionName, tokenId || collectibleId, fromDate, toDate], () =>
    queryFunction({
      ...(tokenId ? { tokenId } : { collectibleId }),
      filter: { fromDate, toDate },
    } as any),
  );

  const [currency, setCurrency] = useState(DEFAULT_CURRENCY);
  const currencyOptions = useMemo(
    () => (stats ? Object.keys(stats.charts).map(i => ({ label: i, value: i })) : []),
    [stats],
  );

  const pushCollectibleToShopify = () => {
    updateProduct(collectibleId);
    shopifyButtonDisabled(true);
    deleteNftButtonDisabled(true);
    setPushShopifyLabel('Pushing to Shopify...');
    closePushShopifyModal();
  };

  const deleteNft = async () => {
    try {
      await deleteCollectibleProductById(collectibleId);
      await deleteCollectibleById(collectibleId);
      closeDeleteNftModal();
      setIsDeleted(true);
    } catch {
      closeDeleteNftModal();
      setIsError(true);
    }
  };

  const data = useMemo(() => {
    const labels: Date[] = [];
    const tokenSalePrice: number[] = [];
    const myRevenueShare: number[] = [];
    const overallPrimarySalesVolume: number[] = [];
    const myRoyaltyShare: number[] = [];
    const overallSecondarySalesVolume: number[] = [];

    stats?.charts[currency].points.forEach(
      ({ timestamp, price, revenueShare, primarySalesVolume, royaltyShare, secondarySalesVolume }) => {
        labels.push(timestamp!);
        tokenSalePrice.push(Number(price));
        myRevenueShare.push(Number(revenueShare));
        overallPrimarySalesVolume.push(Number(primarySalesVolume));
        myRoyaltyShare.push(Number(royaltyShare));
        overallSecondarySalesVolume.push(Number(secondarySalesVolume));
      },
    );

    return {
      labels,
      datasets: [
        ...(tokenId
          ? [
              {
                type: 'line' as const,
                label: 'Token Sale Price',
                borderColor: computedStyle.getPropertyValue('--orange'),
                borderWidth: 2,
                fill: false,
                data: tokenSalePrice,
                stack: 'ORANGE',
                order: 100,
              },
            ]
          : [
              {
                type: 'bar' as const,
                label: 'Overall Primary Sales Volume',
                data: overallPrimarySalesVolume,
                backgroundColor: computedStyle.getPropertyValue('--accent-blue-dark'),
                stack: 'BLUE',
                order: 210,
              },
              {
                type: 'bar' as const,
                label: 'Overall Secondary Sales Volume',
                data: overallSecondarySalesVolume,
                backgroundColor: computedStyle.getPropertyValue('--accent-dark-green'),
                stack: 'GREEN',
                order: 310,
              },
            ]),
        {
          type: 'bar' as const,
          label: 'My Revenue Share',
          data: myRevenueShare,
          clip: 1,
          backgroundColor: computedStyle.getPropertyValue('--accent-blue'),
          stack: 'BLUE',
          order: 200,
        },
        {
          type: 'bar' as const,
          label: 'My Royalty Share',
          data: myRoyaltyShare,
          backgroundColor: computedStyle.getPropertyValue('--accent-green'),
          stack: 'GREEN',
          order: 300,
        },
      ],
    };
  }, [currency, stats?.charts, tokenId]);

  return (
    <PageArea>
      <Navigator
        collectionName={collection?.name}
        collectibleName={collectibleQuery.data?.name}
        handleNavigateCollectionView={handleNavigateToCollection}
      ></Navigator>

      <h2 className={css.collectibleName}>{collectibleQuery.data?.name}</h2>

      <div className={css.content}>
        <div className={css.tokenDetails}>
          {collectibleQuery.isSuccess && collectibleQuery.data && <TokenDetails collectible={collectibleQuery.data} />}
          {productQuery.isSuccess &&
            productQuery.data &&
            !productQuery.data.productId &&
            collectibleQuery.isSuccess &&
            collectibleQuery.data &&
            collectibleQuery.data?.tokens.length === 0 &&
            currentAddress === collectibleQuery.data.creator && (
              <ButtonPrimary
                className={css.pushShopify}
                disabled={isShopifyButtonDisabled}
                onClick={showPushShopifyModal}
              >
                {pushShopifyLabel}
              </ButtonPrimary>
            )}
          {collectibleQuery.isSuccess &&
            collectibleQuery.data &&
            collectibleQuery.data?.tokens.length === 0 &&
            currentAddress === collectibleQuery.data.creator && (
              <>
                <Spacer width="10px" />
                <ButtonPrimary
                  className={css.deleteNFT}
                  disabled={isDeleteNftButtonDisabled}
                  onClick={showDeleteNftModal}
                >
                  <DeleteIcon />
                  Delete NFT
                </ButtonPrimary>
              </>
            )}
        </div>

        <div className={css.tokenStats}>
          <div className={css.transactionGraph}>
            <h3 className={css.header}>NFT Transaction Data</h3>

            {collectibleQuery.isSuccess && collectibleQuery.data && collectibleQuery.data?.tokens.length >= 1 && (
              <div className={css.tokenList}>
                <TokenList collectibleId={collectibleId} tokens={collectibleQuery.data.tokens} tokenId={tokenId} />
              </div>
            )}
            <PriceLineChart
              data={data}
              unit={getPeriodUnit(period)}
              tooltipFormat={tokenId ? EXACT_TIME_FORMAT : getAggregatedTimeFormat(period)}
              header={
                <div className={css.chartHeader}>
                  <PeriodSelector value={period} setValue={setPeriod} />
                  <FilterDropdown value={currency} onChange={setCurrency} options={currencyOptions} />
                </div>
              }
              currency={currency}
            />
          </div>

          <div className={css.tokenInfo}>
            {tokenId && (
              <div className={css.history}>
                <p>Token ID</p>
                <p>
                  <ClipboardCopy text={tokenId}>{shortenAddress(tokenId)}</ClipboardCopy>
                </p>
                <OwnershipHistory tokenId={tokenId} creator={collectibleQuery.data?.creator} />
              </div>
            )}
            {collectibleQuery.data !== undefined &&
              collectibleQuery.data.quantity - collectibleQuery.data.traded > 0 &&
              currentAddress === collectibleQuery.data.creator && (
                <Available
                  quantity={collectibleQuery.data.quantity - collectibleQuery.data.traded}
                  onClick={showMintModal}
                />
              )}
            {isMintModalOpen && collectibleQuery.data !== undefined && (
              <MintDialog
                controller={mintNFTController}
                collectionId={collectibleQuery.data.collection}
                collectibleId={collectibleQuery.data.collectibleId}
                quantity={collectibleQuery.data.quantity - collectibleQuery.data.traded}
                onClose={closeMintModal}
              />
            )}
            {mintNFTController.loadingScreenProps && <LoadingScreen {...mintNFTController.loadingScreenProps} />}
            {mintNFTController.alertProps && <Alert {...mintNFTController.alertProps} />}
          </div>
        </div>
      </div>
      {isPushShopifyModalOpen && <PushShopifyModal onNo={closePushShopifyModal} onYes={pushCollectibleToShopify} />}
      {isDeleteNftModalOpen && <DeleteNftModal onNo={closeDeleteNftModal} onYes={deleteNft} />}
      {isDeleted && (
        <Alert
          isSuccess={true}
          message="NFT sucessfully deleted!"
          onClose={() => {
            history.push(`/home/dashboard`);
            setIsError(false);
            setIsDeleted(false);
          }}
        />
      )}
      {isError && (
        <Alert
          isSuccess={false}
          message="'We have encountered an error performing this transaction. Please try again."
          onClose={() => {
            setIsError(false);
            setIsDeleted(false);
          }}
        />
      )}
    </PageArea>
  );
};
