import { IpfsFile } from 'beasy-fe-commons';

import { getIpfsUrl } from 'services/ipfs';

import styles from './index.module.css';

const Item = ({ name, cid }: IpfsFile) => {
  return (
    <tr className={styles.row}>
      <td>{name}</td>
      <td>
        <a href={getIpfsUrl(cid)} className={styles.view} target="_blank" rel="noopener noreferrer">
          View
        </a>
      </td>
    </tr>
  );
};

export default Item;
