import clsx from 'clsx';

import { IconProps } from '../types';
import css from './index.module.css';

const ASPECT_RATIO = 1.749;

export const ChevronIcon = ({ className, size = 11.161, onClick }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(css.icon, className)}
    onClick={onClick}
    width={size}
    height={size / ASPECT_RATIO}
    viewBox="0 0 11.161 6.381"
  >
    <path
      id="Icon_ionic-ios-arrow-forward"
      data-name="Icon ionic-ios-arrow-forward"
      d="M15.7,11.775l-4.223-4.22a.794.794,0,0,1,0-1.126.8.8,0,0,1,1.13,0L17.4,11.21a.8.8,0,0,1,.023,1.1l-4.8,4.815A.8.8,0,1,1,11.484,16Z"
      transform="translate(17.357 -11.246) rotate(90)"
    />
  </svg>
);
