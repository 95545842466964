import { grpc } from '@improbable-eng/grpc-web';
import { captureException } from '@sentry/react';

import { GetRoyaltyHoldersReq, GrpcWebImpl, RoyaltyServiceClientImpl } from 'protobuf/lib/royalty';

import { environment } from 'environment';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const client = new RoyaltyServiceClientImpl(rpc);

export const getRoyaltyHolders = async (request: GetRoyaltyHoldersReq) => {
  try {
    return await client.GetRoyaltyHolders(request);
  } catch (error) {
    captureException(error);
    throw error;
  }
};
