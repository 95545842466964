import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

import { TransactionCost } from 'protobuf/lib/transaction';
import { MinimalTransactionInfo } from 'protobuf/lib/transactionMessage';

import { DEFAULT_CURRENCY } from 'services/currency';
import { estimateTransactionCost } from 'services/transaction';

export const useTransactionCost = (transaction?: MinimalTransactionInfo) => {
  const { data: transactionCost } = useQuery(
    ['estimateTransactionCost', JSON.stringify(transaction)],
    () => estimateTransactionCost(transaction!),
    { enabled: Boolean(transaction) },
  );

  const getTransactionCostAmount = useCallback(
    (type: keyof TransactionCost, currencyCode: string = DEFAULT_CURRENCY) => {
      return transactionCost?.[type].find(({ currency }) => currency?.code === currencyCode)?.amount ?? 0;
    },
    [transactionCost],
  );

  return useMemo(
    () => ({
      transactionCost,
      getTransactionCostAmount,
    }),
    [getTransactionCostAmount, transactionCost],
  );
};
