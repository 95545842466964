import { ArrowIcon } from 'assets/icons';

import { ButtonPrimary, ButtonSecondary } from 'components/button';
import { DialogFooter } from 'components/dialog';

import css from './index.module.css';

type Props = {
  onClickPrevious?: () => void;
  onClickContinue?: () => void;
  onClickCancel?: () => void;
  isLast?: boolean;
  isLastDisabled?: boolean;
};

export const NavButtons = ({ onClickPrevious, onClickContinue, onClickCancel, isLast, isLastDisabled }: Props) => {
  return (
    <DialogFooter className={css.footer}>
      <div>
        {onClickPrevious && (
          <ButtonSecondary onClick={onClickCancel} type="submit">
            Cancel
          </ButtonSecondary>
        )}
      </div>
      <div className={css.wrapper}>
        {onClickPrevious && (
          <ButtonSecondary onClick={onClickPrevious} type="submit">
            <ArrowIcon /> Previous Step
          </ButtonSecondary>
        )}
        {onClickContinue && (
          <ButtonPrimary onClick={onClickContinue} type="submit" disabled={isLastDisabled}>
            {isLast ? 'Create' : 'Continue'}
          </ButtonPrimary>
        )}
      </div>
    </DialogFooter>
  );
};
