// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavButtons_container__e9Y6M {
  display: flex;
  justify-content: flex-end;
}
.NavButtons_wrapper__6wqtT {
  display: flex;
}

.NavButtons_previousButton__zUDcQ {
  background-color: var(--alt5-color);
}

.NavButtons_previousButton__zUDcQ:hover {
  opacity: 0.8;
}

.NavButtons_previousButton__zUDcQ svg,
.NavButtons_last__q\\+u1u svg {
  margin-right: 12px;
}

.NavButtons_continueButton__vGxYz {
  background-color: var(--primary-color);
  color: var(--body-color);
  margin-left: 20px;
}

.NavButtons_continueButton__vGxYz:not(.NavButtons_last__q\\+u1u) svg {
  margin-left: 12px;
}

.NavButtons_continueButton__vGxYz:hover {
  opacity: 0.8;
}

.NavButtons_button__RaiZW {
  border: 0;
  display: flex;
  align-items: center;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-color-accent);
  padding: 0px 15px;
  height: 40px;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.NavButtons_button__RaiZW:hover,
.NavButtons_button__RaiZW:focus {
  outline: none;
  box-shadow: none;
}

/* should be last for specificity */
.NavButtons_button__RaiZW:disabled,
.NavButtons_button__RaiZW:disabled:hover {
  background-color: var(--disabled-color);
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Registration/NavButtons/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,aAAa;AACf;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,YAAY;AACd;;AAEA;;EAEE,kBAAkB;AACpB;;AAEA;EACE,sCAAsC;EACtC,wBAAwB;EACxB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,+BAA+B;EAC/B,iBAAiB;EACjB,YAAY;EACZ,eAAe;EACf;gCAC8B;AAChC;;AAEA;;EAEE,aAAa;EACb,gBAAgB;AAClB;;AAEA,mCAAmC;AACnC;;EAEE,uCAAuC;EACvC,mBAAmB;AACrB","sourcesContent":[".container {\n  display: flex;\n  justify-content: flex-end;\n}\n.wrapper {\n  display: flex;\n}\n\n.previousButton {\n  background-color: var(--alt5-color);\n}\n\n.previousButton:hover {\n  opacity: 0.8;\n}\n\n.previousButton svg,\n.last svg {\n  margin-right: 12px;\n}\n\n.continueButton {\n  background-color: var(--primary-color);\n  color: var(--body-color);\n  margin-left: 20px;\n}\n\n.continueButton:not(.last) svg {\n  margin-left: 12px;\n}\n\n.continueButton:hover {\n  opacity: 0.8;\n}\n\n.button {\n  border: 0;\n  display: flex;\n  align-items: center;\n  border-radius: 6px;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  color: var(--text-color-accent);\n  padding: 0px 15px;\n  height: 40px;\n  cursor: pointer;\n  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,\n    box-shadow 0.15s ease-in-out;\n}\n\n.button:hover,\n.button:focus {\n  outline: none;\n  box-shadow: none;\n}\n\n/* should be last for specificity */\n.button:disabled,\n.button:disabled:hover {\n  background-color: var(--disabled-color);\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NavButtons_container__e9Y6M`,
	"wrapper": `NavButtons_wrapper__6wqtT`,
	"previousButton": `NavButtons_previousButton__zUDcQ`,
	"last": `NavButtons_last__q+u1u`,
	"continueButton": `NavButtons_continueButton__vGxYz`,
	"button": `NavButtons_button__RaiZW`
};
export default ___CSS_LOADER_EXPORT___;
