import { Buffer } from 'buffer';
import { StrictMode } from 'react';
import { render } from 'react-dom';

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import i18n from './services/translator';

global.Buffer = Buffer;
render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();

document.title = i18n.t('title');
