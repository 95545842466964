// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Edit_wrapper__QSGs6 {
  display: flex;
  gap: 32px;
}

.Edit_left__kKJSW {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Edit_right__z4Gf- {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.Edit_image__UYWhy {
  width: 254px;
  height: 254px;
  border-radius: 8px;
  background-color: var(--input-bg-color);
}

.Edit_field__x\\+CSX {
  display: flex;
  gap: 24px;
}

.Edit_field__x\\+CSX > :nth-child(1) {
  width: 200px;
  flex-shrink: 0;
}

.Edit_field__x\\+CSX > :nth-child(2) {
  flex-grow: 1;
}

.Edit_field__x\\+CSX > :nth-child(3) {
  width: 100px;
  flex-shrink: 0;
}

.Edit_button__XgimU {
  display: flex;
  justify-content: center;
}

.Edit_tooltip__ESbWT {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media screen and (max-height: 800px) {
  .Edit_left__kKJSW {
    flex-shrink: 0;
    display: block;
    flex-direction: column;
    gap: 8px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/collection/Edit/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE;IACE,cAAc;IACd,cAAc;IACd,sBAAsB;IACtB,QAAQ;EACV;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  gap: 32px;\n}\n\n.left {\n  flex-shrink: 0;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.right {\n  display: flex;\n  flex-direction: column;\n  gap: 18px;\n}\n\n.image {\n  width: 254px;\n  height: 254px;\n  border-radius: 8px;\n  background-color: var(--input-bg-color);\n}\n\n.field {\n  display: flex;\n  gap: 24px;\n}\n\n.field > :nth-child(1) {\n  width: 200px;\n  flex-shrink: 0;\n}\n\n.field > :nth-child(2) {\n  flex-grow: 1;\n}\n\n.field > :nth-child(3) {\n  width: 100px;\n  flex-shrink: 0;\n}\n\n.button {\n  display: flex;\n  justify-content: center;\n}\n\n.tooltip {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n@media screen and (max-height: 800px) {\n  .left {\n    flex-shrink: 0;\n    display: block;\n    flex-direction: column;\n    gap: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Edit_wrapper__QSGs6`,
	"left": `Edit_left__kKJSW`,
	"right": `Edit_right__z4Gf-`,
	"image": `Edit_image__UYWhy`,
	"field": `Edit_field__x+CSX`,
	"button": `Edit_button__XgimU`,
	"tooltip": `Edit_tooltip__ESbWT`
};
export default ___CSS_LOADER_EXPORT___;
