import { useTranslation } from 'react-i18next';

import { Spacer } from 'components/Spacer';

import css from './index.module.css';

export const UserWithoutFriends = () => {
  const { t } = useTranslation();
  return (
    <div className={css.wrapper}>
      <b>{t('components.user.noFriends')}</b>
      <Spacer height="10px" />
      <p>{t('components.user.addFriend')}</p>
      <Spacer height="10px" />
      <p>{t('components.user.openWallet')}</p>
      <p>{t('components.user.friendsTab')}</p>
      <p>{t('components.user.shareLink')}</p>
    </div>
  );
};
