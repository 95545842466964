import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTable, useSortBy, useGlobalFilter, Column } from 'react-table';

import { SortIcon } from 'assets/icons';

import css from './index.module.css';

interface TableProps<T extends object> {
  columns: Column<T>[];
  data: any;
}

export default function Table<T extends object>({ columns, data }: TableProps<T>): React.ReactElement {
  const history = useHistory();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    disableMultiSort,
    isMultiSortEvent = (e: any) => e.shiftKey,
  } = useTable<T>(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: ['address'],
      },
      sortTypes: {
        alphanumeric: (row1: any, row2: any, columnName: string) => {
          const rowOneColumn = row1.values[columnName];
          const rowTwoColumn = row2.values[columnName];
          if (isNaN(rowOneColumn)) {
            return rowOneColumn.toUpperCase() > rowTwoColumn.toUpperCase() ? 1 : -1;
          }
          return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
        },
      },
    },
    useGlobalFilter,
    useSortBy,
  );

  const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setGlobalFilter(value);
  };
  return (
    <>
      <input className={css.searchInput} placeholder="Search" onChange={handleFilterInputChange} />
      <div className={css.tableContainer}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th>
                  <div>
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      onClick={
                        column.canSort
                          ? e => {
                              e.persist();
                              column.toggleSortBy(undefined, !disableMultiSort && isMultiSortEvent(e));
                            }
                          : undefined
                      }
                      className={css.headerColumn}
                    >
                      <span>
                        <SortIcon />
                      </span>
                    </div>
                    <div>{column.render('Header')}</div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} onClick={() => history.push(`/home/partner/${row.values.address}`)}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()} className={css.textOverflow}>
                      {cell.value === '' ? '-' : cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
    </>
  );
}
