// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreatorForm_wrapper__0Eodm {
  width: 680px;
  min-width: 680px;
  max-width: 680px;
  max-height: 650px;
}

.CreatorForm_content__FqsYE {
  display: flex;
  gap: 40px;
}

.CreatorForm_content__FqsYE > label {
  flex-grow: 1;
  width: 350px;
  max-width: 350px;
}

.CreatorForm_list__8kegl {
  position: relative;
  border-radius: 6px;
}

.CreatorForm_input__Siz5l {
  display: flex;
  gap: 8px;
}

.CreatorForm_input__Siz5l > input {
  width: 16px;
  height: 18px;
}

.CreatorForm_documents__3UzHB {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/user/CreatorForm/index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".wrapper {\n  width: 680px;\n  min-width: 680px;\n  max-width: 680px;\n  max-height: 650px;\n}\n\n.content {\n  display: flex;\n  gap: 40px;\n}\n\n.content > label {\n  flex-grow: 1;\n  width: 350px;\n  max-width: 350px;\n}\n\n.list {\n  position: relative;\n  border-radius: 6px;\n}\n\n.input {\n  display: flex;\n  gap: 8px;\n}\n\n.input > input {\n  width: 16px;\n  height: 18px;\n}\n\n.documents {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CreatorForm_wrapper__0Eodm`,
	"content": `CreatorForm_content__FqsYE`,
	"list": `CreatorForm_list__8kegl`,
	"input": `CreatorForm_input__Siz5l`,
	"documents": `CreatorForm_documents__3UzHB`
};
export default ___CSS_LOADER_EXPORT___;
