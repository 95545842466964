import { grpc } from '@improbable-eng/grpc-web';
import { captureException } from '@sentry/react';

import {
  CollectionServiceClientImpl,
  CreateCollectionReq,
  GetCollectionByAddressReq,
  GetCollectionsReq,
  GrpcWebImpl,
  PredictCollectionReq,
} from 'protobuf/lib/collectionService';

import { environment } from 'environment';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const client = new CollectionServiceClientImpl(rpc);

export const predictCollection = async (request: PredictCollectionReq) => {
  try {
    return await client.PredictCollection(request);
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const createCollection = async (request: CreateCollectionReq) => {
  try {
    return await client.CreateCollection(request);
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const getCollectionByAddress = async (request: GetCollectionByAddressReq) => {
  try {
    return await client.GetCollectionByAddress(request);
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const getCollections = async (req: GetCollectionsReq) => {
  try {
    return await client.GetCollections(req);
  } catch (error) {
    captureException(error);
    throw error;
  }
};
