import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import { CollectibleCreateRoute } from './Create';
import { CollectibleListRoute } from './List';
import { CollectibleViewRoute } from './View';

export const CollectibleRoute = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route path={`${match.url}/list`} component={CollectibleListRoute} />
      <Route path={`${match.url}/create`} component={CollectibleCreateRoute} />
      <Route path={`${match.url}/:collectibleId/:tokenId`} component={CollectibleViewRoute} />
      <Route path={`${match.url}/:collectibleId`} component={CollectibleViewRoute} />
      <Redirect to={`${match.url}/list`} />
    </Switch>
  );
};
