// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notification_icon__1uBlk {
  fill: none;
}

.Notification_icon__1uBlk path {
  fill: currentColor;
}
`, "",{"version":3,"sources":["webpack://./src/assets/icons/Notification/index.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".icon {\n  fill: none;\n}\n\n.icon path {\n  fill: currentColor;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `Notification_icon__1uBlk`
};
export default ___CSS_LOADER_EXPORT___;
