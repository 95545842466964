// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* css reset only */

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;

  /* https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size */
  min-width: 0;
  min-height: 0;
}

ul {
  list-style: none;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
a {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: inherit;
}

hr {
  margin: 24px 0;
  border: 1px solid var(--grey-90);
}

svg,
figure {
  flex-shrink: 0;
}

figure {
  overflow: hidden;
}

#root,
#root > div /* SkeletonTheme hack */ {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 4px;
  height: 2px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
  background-color: var(--body-bg-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroll-color);
  border-radius: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;EACE,SAAS;EACT,UAAU;EACV,qBAAqB;EACrB,sBAAsB;;EAEtB,8FAA8F;EAC9F,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,SAAS;EACT,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,wBAAwB;AAC1B;;AAEA;EACE,cAAc;EACd,gCAAgC;AAClC;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;;EAEE,sCAAsC;AACxC;;AAEA;EACE,qCAAqC;EACrC,kBAAkB;AACpB","sourcesContent":["/* css reset only */\n\n* {\n  margin: 0;\n  padding: 0;\n  text-decoration: none;\n  box-sizing: border-box;\n\n  /* https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size */\n  min-width: 0;\n  min-height: 0;\n}\n\nul {\n  list-style: none;\n}\n\nhtml,\nbody {\n  width: 100%;\n  height: 100%;\n}\n\nbody {\n  margin: 0;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\nbutton,\na {\n  cursor: pointer;\n}\n\na {\n  color: inherit;\n  text-decoration: inherit;\n}\n\nhr {\n  margin: 24px 0;\n  border: 1px solid var(--grey-90);\n}\n\nsvg,\nfigure {\n  flex-shrink: 0;\n}\n\nfigure {\n  overflow: hidden;\n}\n\n#root,\n#root > div /* SkeletonTheme hack */ {\n  width: 100%;\n  height: 100%;\n}\n\n::-webkit-scrollbar {\n  width: 4px;\n  height: 2px;\n}\n\n::-webkit-scrollbar-track,\n::-webkit-scrollbar-corner {\n  background-color: var(--body-bg-color);\n}\n\n::-webkit-scrollbar-thumb {\n  background-color: var(--scroll-color);\n  border-radius: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
