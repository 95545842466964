//import { useAccountContext } from 'context/AccountContext';
import { useWalletConnectClient } from 'context/ClientContext';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import logo from 'assets/images/logo.png';
import welcomeLogo from 'assets/images/welcome.svg';

import Alert, { AlertProps } from 'components/Alert';
import ProgressBar from 'components/ProgressBar';
import { Spacer } from 'components/Spacer';
import { ButtonPrimary, ButtonSecondary } from 'components/button';
import { LoadingScreen, LoadingScreenProps } from 'components/loading';
import { PageTabMenu } from 'components/page';

import { encodeParam } from 'constants/helper';

import { useMount } from 'hooks/mount';
import { useProgress } from 'hooks/useProgress';

import { UserProfile_AdditionalData } from 'protobuf/lib/userProfileService';

import { Activity, log } from 'services/activityReport';
import { applicationToken } from 'services/applicationAuthService';
import { axios } from 'services/axios';
import { registerUser } from 'services/chargeMaster';
import { acceptAgreement, useGetCurrentUserProfile, updateUserAvatarAndProfile } from 'services/userProfile';

import { AppState } from 'state';

import { environment } from 'environment';

import NavButtons from './NavButtons';
import css from './index.module.css';
import { steps } from './steps';
import {
  CombinedStepValues,
  StepIndex,
  GetStepValueRef,
  firstStepIndex,
  lastStepIndex,
  STEP_INVALID_VALUE,
} from './steps/types';
import { setWalletConnectV2 } from 'state/session';

const items = [
  { value: StepIndex.AGREEMENT, label: 'License agreement' },
  { value: StepIndex.REGISTRATION, label: 'Account registration' },
];

function Registration() {
  const { t } = useTranslation();
  const history = useHistory();
  const getCurrentUserProfile = useGetCurrentUserProfile();
  const currentUser = useQuery('getCurrentUserProfile', getCurrentUserProfile);
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [currentStepIndex, setCurrentItem] = useState(StepIndex.AGREEMENT);
  const [maxAvailableStep, setMaxAvailableItem] = useState(StepIndex.AGREEMENT);
  const [loadingScreenProps, setLoadingScreenProps] = useState<LoadingScreenProps | undefined>();
  const [alertProps, setAlertProps] = useState<AlertProps | undefined>();
  const { progress, setProgress } = useProgress();
  const [logo2x, setLogo2x] = useState(null);
  const { handleDisconnect } = useWalletConnectClient();
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  const dispatch = useDispatch();

  useEffect(() => {
    const loadLogo = async () => {
      const logoModule = await import(`assets/images/${process.env.REACT_APP_DOMAIN}/logo-2x.png`);
      setLogo2x(logoModule.default);
    };
    loadLogo();
  }, []);

  const updateMaxAvailableItem = useCallback(
    (item: StepIndex) => {
      if (item > maxAvailableStep) {
        setMaxAvailableItem(item);
      }
    },
    [maxAvailableStep, setMaxAvailableItem],
  );

  const showErrorAlert = useCallback(() => {
    setLoadingScreenProps(undefined);
    setAlertProps({
      isSuccess: false,
      message: 'There was an issue processing your registration. Please try again.',
      onClose: () => setAlertProps(undefined),
    });
  }, []);

  const [combinedValues, setCombinedValues] = useState<CombinedStepValues>({});
  const getValueRef: GetStepValueRef<any> = useRef();

  const handleNavigate = useCallback(
    async (item: StepIndex) => {
      const getTabValue = getValueRef.current; // each tab must set it

      if (getTabValue) {
        const value = await getTabValue();

        if (value === STEP_INVALID_VALUE) return; // block navigation

        setCombinedValues({ ...combinedValues, [currentStepIndex]: value });

        setCurrentItem(item);
        updateMaxAvailableItem(item);
      }
    },
    [updateMaxAvailableItem, combinedValues, currentStepIndex],
  );

  const handleNavigatePrev = useCallback(
    () => setCurrentItem(currentStepIndex - 1),
    [currentStepIndex, setCurrentItem],
  );
  const handleNavigateNext = useCallback(
    () => handleNavigate(currentStepIndex + 1),
    [currentStepIndex, handleNavigate],
  );

  const isLastStep = currentStepIndex === lastStepIndex;

  const TabComponent = steps[currentStepIndex];

  useEffect(() => {
    console.log('REGISTRATION');
    if (walletConnectV2.address !== undefined) {
      if (walletConnectV2.isCreator === true) {
        history.replace('/home');
      }
    } else {
      console.log('REGISTRATION ELSE ');
      history.replace('/login');
    }
  }, [walletConnectV2.address, walletConnectV2.isCreator, walletConnectV2.session]);

  useMount(() => {
    if (!applicationToken) {
      return;
    }
  });

  const handleStarted = () => {
    setIsStarted(!isStarted);
  };

  const handleDisconnectMutation = async () => {
    mutation.mutate();
  };

  const progressMessage = useMemo(() => {
    return progress === 1 ? 'Almost done...' : `Processing: ${progress != null ? Math.round(100 * progress) : null}%`;
  }, [progress]);

  const mutationRegistration = useMutation(async () => {
    setLoadingScreenProps({
      message: 'Please give us a moment while we finish creating your account.',
      isNotification: false,
    });

    setProgress(0);

    const getTabValue = getValueRef.current;

    if (getTabValue) {
      const value = await getTabValue();

      if (value === STEP_INVALID_VALUE) {
        setLoadingScreenProps(undefined);
        return;
      }
      try {
        const account_id = encodeParam(
          (+new Date()).toString(36).slice(-5) + Math.random().toString(36).substring(2, 5),
        );

        const resultClaim = await axios.get(
          environment.apiUrl + 'creator/add/' + value.role + '/' + walletConnectV2.address,
        );

        if (resultClaim.status === 200) {
          setProgress(0.25);
          /*
          const result = await registerUserHubspot(account_id, value);
          if (result.status === 200) {*/
          setProgress(0.5);
          const roleEncoded = encodeParam(value.role.toLowerCase());
          const creatorResult = await registerUser(value.eth_address, roleEncoded, account_id);

          if (!creatorResult.transactionReceipt?.status === false) {
            setLoadingScreenProps(undefined);
          }

          if (creatorResult.transactionReceipt?.status === true) {
            setProgress(0.75);
            const additionalData: UserProfile_AdditionalData = {
              firstName: value.name,
              lastName: value.lastname,
              businessName: value.business,
              source: process.env.REACT_APP_DOMAIN,
            };
            await updateUserAvatarAndProfile(
              currentUser.data?.avatarCid!,
              currentUser.data?.nickname!,
              additionalData,
              true,
            );
            await acceptAgreement();
            setProgress(1);
            log(walletConnectV2.address!, Activity.REGISTRATION, process.env.REACT_APP_DOMAIN);
            await new Promise(r => setTimeout(r, 150));
            setIsFinished(!isFinished);
          }
        }
      } catch (error) {
        showErrorAlert();
      }
    }
  }, {});

  const handleFinish = useCallback(() => mutationRegistration.mutate(), [mutationRegistration]);

  const mutation = useMutation(
    async () => {
      await handleDisconnect();
    },
    { onSuccess: () => history.replace('/login') },
  );



  return (
    <>
      {isStarted ? (
        isFinished ? (
          <section className={css.section}>
            <div className={css.content}>
              <h1> {t('registration.congratulations')} </h1>
              <h3> {t('registration.checkEmail')}</h3>

              {logo2x && (
                <img
                  src={welcomeLogo}
                  alt="logo"
                  style={{
                    height: 300,
                    width: 300,
                  }}
                />
              )}

              <ButtonPrimary
                onClick={() => {
                  log(walletConnectV2.address!, Activity.LOGIN, process.env.REACT_APP_DOMAIN);
                  dispatch(
                    setWalletConnectV2({
                      session: walletConnectV2.session,
                      address: walletConnectV2.address,
                      balance: walletConnectV2.balance,
                      chainId: walletConnectV2.chainId,
                      jwt: walletConnectV2.jwt,
                      isCreator: true,
                      isAccepted: walletConnectV2.isAccepted,
                    }),
                  );
                  history.replace('/home');
                }}
              >
                {t('registration.goDashboard')}
              </ButtonPrimary>
            </div>
          </section>
        ) : (
          <section className={css.section}>
            <div className={css.headerSection}>
              <h1>{t('registration.title')}</h1>
              <img
                src={logo}
                alt="logo"
                srcSet={
                  process.env.REACT_APP_DOMAIN === 'rag'
                    ? require(`assets/images/rag/logo-rag.png`).default
                    : `${logo} 1x, ${logo2x} 2x`
                }
                style={{
                  height: 45,
                }}
              />
            </div>

            <div className={css.header}>
              <PageTabMenu
                items={items}
                currentItem={currentStepIndex}
                maxAvailableItem={maxAvailableStep}
                onNavigate={handleNavigate}
                className={css.tabMenu}
              />
            </div>
            <div className={css.formContainer}>
              <TabComponent getValueRef={getValueRef} combinedValues={combinedValues} />
            </div>
            <NavButtons
              onClickPrevious={currentStepIndex > firstStepIndex ? handleNavigatePrev : undefined}
              onClickContinue={currentStepIndex < lastStepIndex ? handleNavigateNext : handleFinish}
              isLast={isLastStep}
              isDisabled={mutationRegistration.isLoading}
            />
          </section>
        )
      ) : (
        <div className={css.content}>
          <img
            className={css.logo}
            src={logo}
            alt="logo"
            srcSet={`${logo} 1x, ${logo2x} 2x`}
            style={{
              width: {
                beasy: 208,
                ndau: 350,
                rag: 208,
                obsesh: 208,
              }[process.env.REACT_APP_DOMAIN],
            }}
          />
          <h1> {t('registration.registrationRequest')}</h1>
          <p>{t('registration.noCreator')}</p>
          <div className={css.buttons}>
            <ButtonSecondary onClick={handleDisconnectMutation} disabled={mutation.isLoading}>
              {mutation.isLoading ? 'Disconnecting' : 'Cancel'}
            </ButtonSecondary>
            <Spacer width="12px"></Spacer>
            <ButtonPrimary onClick={handleStarted} disabled={mutation.isLoading}>
              {t('registration.start')}
            </ButtonPrimary>
          </div>
        </div>
      )}
      {loadingScreenProps && (
        <LoadingScreen {...loadingScreenProps}>
          {progress != null && !loadingScreenProps.isNotification && (
            <ProgressBar message={progressMessage} progress={progress} />
          )}
        </LoadingScreen>
      )}
      {alertProps && <Alert {...alertProps} />}
    </>
  );
}

export default Registration;
