// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PartnerProfile_boxGallery__DWdEC {
  margin-top: 40px;
  position: relative;
}

.PartnerProfile_profile__C0sj3 {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
}

.PartnerProfile_profile__C0sj3 p {
  color: var(--alt10-color);
  margin-bottom: 14px;
}

.PartnerProfile_profileImage__KXJFt {
  height: 120px;
  width: 120px;
  object-fit: cover;
  border-radius: 8px;
}
.PartnerProfile_header__4thRn {
  display: flex;
  flex-direction: row;
}

.PartnerProfile_value__NefDA p {
  color: var(--text-color-accent);
}

.PartnerProfile_content__T8mae {
  margin-top: 40px;
  flex-direction: column;
}

.PartnerProfile_content__T8mae h3 {
  margin-bottom: 24px;
  color: var(--text-color-accent);
}

.PartnerProfile_content__T8mae p {
  margin-top: 24px;
  color: var(--text-color);
}
`, "",{"version":3,"sources":["webpack://./src/screens/PartnerProfile/index.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;EAChB,wBAAwB;AAC1B","sourcesContent":[".boxGallery {\n  margin-top: 40px;\n  position: relative;\n}\n\n.profile {\n  margin-left: 30px;\n  display: flex;\n  flex-direction: column;\n}\n\n.profile p {\n  color: var(--alt10-color);\n  margin-bottom: 14px;\n}\n\n.profileImage {\n  height: 120px;\n  width: 120px;\n  object-fit: cover;\n  border-radius: 8px;\n}\n.header {\n  display: flex;\n  flex-direction: row;\n}\n\n.value p {\n  color: var(--text-color-accent);\n}\n\n.content {\n  margin-top: 40px;\n  flex-direction: column;\n}\n\n.content h3 {\n  margin-bottom: 24px;\n  color: var(--text-color-accent);\n}\n\n.content p {\n  margin-top: 24px;\n  color: var(--text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxGallery": `PartnerProfile_boxGallery__DWdEC`,
	"profile": `PartnerProfile_profile__C0sj3`,
	"profileImage": `PartnerProfile_profileImage__KXJFt`,
	"header": `PartnerProfile_header__4thRn`,
	"value": `PartnerProfile_value__NefDA`,
	"content": `PartnerProfile_content__T8mae`
};
export default ___CSS_LOADER_EXPORT___;
