import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { getTokenSerialId } from 'constants/helper';

import { Token } from 'protobuf/lib/collectibleService';

import css from './index.module.css';

interface Props {
  collectibleId: string;
  tokens: Token[];
  tokenId?: string;
}

export const TokenList = ({ collectibleId, tokens, tokenId }: Props) => {
  const sortedTokens = useMemo(() => {
    return tokens.map(token => ({ ...token, id: getTokenSerialId(token.tokenId) })).sort((a, b) => a.id - b.id);
  }, [tokens]);

  return (
    <div className={css.container}>
      <NavLink
        className={css.allTokens}
        activeClassName={tokenId ? '' : css.allTokensActive}
        to={`/home/collectible/${collectibleId}`}
      >
        All Tokens
      </NavLink>

      <div className={css.border} />
      <div className={css.tokens}>
        {sortedTokens.map(token => (
          <NavLink
            key={token.id}
            className={css.token}
            activeClassName={css.tokenActive}
            to={`/home/collectible/${collectibleId}/${token.tokenId}`}
          >
            #{token.id + 1}
          </NavLink>
        ))}
      </div>
    </div>
  );
};
