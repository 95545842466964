// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Create_wrapper__DVgYq {
  gap: 32px;
}

.Create_wrapperDialog__iOkb9 {
  width: 420px;
}

.Create_mintingType__FRupZ {
  display: flex;
  align-items: center;
}

.Create_mintingType__FRupZ > span {
  position: relative;
  top: 3px;
}

.Create_mintingType__FRupZ > p {
  color: var(--grey-50);
}

.Create_checkbox__nouWH {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.Create_funds__yrtgc {
  background: var(--input-bg-color);
  padding: 8px 10px;
  border-radius: 6px;
}`, "",{"version":3,"sources":["webpack://./src/screens/collection/Create/index.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,iCAAiC;EACjC,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".wrapper {\n  gap: 32px;\n}\n\n.wrapperDialog {\n  width: 420px;\n}\n\n.mintingType {\n  display: flex;\n  align-items: center;\n}\n\n.mintingType > span {\n  position: relative;\n  top: 3px;\n}\n\n.mintingType > p {\n  color: var(--grey-50);\n}\n\n.checkbox {\n  width: 20px;\n  height: 20px;\n  border-radius: 10px;\n}\n\n.funds {\n  background: var(--input-bg-color);\n  padding: 8px 10px;\n  border-radius: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Create_wrapper__DVgYq`,
	"wrapperDialog": `Create_wrapperDialog__iOkb9`,
	"mintingType": `Create_mintingType__FRupZ`,
	"checkbox": `Create_checkbox__nouWH`,
	"funds": `Create_funds__yrtgc`
};
export default ___CSS_LOADER_EXPORT___;
