import { useFileSrc } from 'hooks';

import { ImageCover, ImageProps } from '..';

interface Props extends Omit<ImageProps, 'src'> {
  file: File;
}

export const ImageFile = ({ file, ...rest }: Props) => {
  const src = useFileSrc(file);

  // eslint-disable-next-line jsx-a11y/alt-text
  return <ImageCover src={src} {...rest} />;
};
