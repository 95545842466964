import {
  ChangeEvent,
  DetailedHTMLProps,
  InputHTMLAttributes,
  ReactElement,
  useCallback,
  useRef,
  useState,
} from 'react';

import { Camera } from 'assets/icons';

import { ImageLoading } from 'components/image';

import css from './index.module.css';

interface Props extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  fallback: ReactElement;
  value: string;
}

/** @deprecated use InputFile */
export const InputAvatar = ({ fallback, value, onChange, ...rest }: Props) => {
  const [uploadedImageSrc, setUploadedImageSrc] = useState<string | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = useCallback(() => {
    inputRef.current?.click();
  }, []);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        const src = URL.createObjectURL(file);
        setUploadedImageSrc(src);
      }

      if (onChange) {
        onChange(event);
      }
    },
    [onChange, setUploadedImageSrc],
  );

  return (
    <div className={css.container} onClick={handleClick}>
      <div className={css.overlay}>
        <Camera />
      </div>

      <ImageLoading className={css.image} src={uploadedImageSrc ?? value} fallback={fallback} title="Click to edit" />

      <input
        ref={inputRef}
        type="file"
        accept=".png,.jpg,.jpeg,.gif,.svg"
        hidden={true}
        onChange={handleChange}
        {...rest}
      />
    </div>
  );
};
