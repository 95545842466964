/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { Empty } from "./google/protobuf/empty";
import { Struct } from "./google/protobuf/struct";

export const protobufPackage = "";

export interface StripeAccountReq {
  accountId: string;
  forge: string;
}

export interface StripeAccountStateRes {
  accepted: string;
}

export interface StripeAccountDataReq {
  address: string;
  name: string;
  lastname: string;
  forge: string;
}

export interface StripeAccountLinkRes {
  accountLink: string;
  accountId: string;
}

export interface StripeLoginLinkRes {
  loginLink: string;
}

export interface CreateCustomerReq {
  address: string;
}

export interface CardInfoReq {
  customerId: string;
  card: string;
}

export interface ListCardReq {
  customerId: string;
}

export interface CreateCardReq {
  customerId: string;
  source: string;
}

export interface DeleteCardReq {
  customerId: string;
  card: string;
}

export interface PaymentIntentBaseReq {
  customerId: string;
  amount: number;
  currency: string;
}

export interface CardId {
  cardId: string;
}

export interface CustomerId {
  customerId: string;
}

export interface CardInfo {
  card: { [key: string]: any } | undefined;
}

export interface CardList {
  cards: { [key: string]: any } | undefined;
}

export interface ClientSecret {
  clientSecret: string;
}

export interface PaymentInfo {
  payment: { [key: string]: any } | undefined;
}

export interface ChargeInfo {
  charge: { [key: string]: any } | undefined;
}

export interface DeleteInfo {
  delete: { [key: string]: any } | undefined;
}

export interface UpdatePaymentReq {
  paymentIntent: string;
  paymentMethod: string;
  customerId: string;
}

export interface UpdatePaymentInfo {
  update: { [key: string]: any } | undefined;
}

export interface PublishableKey {
  publishableKey: string;
}

function createBaseStripeAccountReq(): StripeAccountReq {
  return { accountId: "", forge: "" };
}

export const StripeAccountReq = {
  encode(message: StripeAccountReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    if (message.forge !== "") {
      writer.uint32(18).string(message.forge);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StripeAccountReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeAccountReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.forge = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<StripeAccountReq>, I>>(base?: I): StripeAccountReq {
    return StripeAccountReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StripeAccountReq>, I>>(object: I): StripeAccountReq {
    const message = createBaseStripeAccountReq();
    message.accountId = object.accountId ?? "";
    message.forge = object.forge ?? "";
    return message;
  },
};

function createBaseStripeAccountStateRes(): StripeAccountStateRes {
  return { accepted: "" };
}

export const StripeAccountStateRes = {
  encode(message: StripeAccountStateRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accepted !== "") {
      writer.uint32(10).string(message.accepted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StripeAccountStateRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeAccountStateRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accepted = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<StripeAccountStateRes>, I>>(base?: I): StripeAccountStateRes {
    return StripeAccountStateRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StripeAccountStateRes>, I>>(object: I): StripeAccountStateRes {
    const message = createBaseStripeAccountStateRes();
    message.accepted = object.accepted ?? "";
    return message;
  },
};

function createBaseStripeAccountDataReq(): StripeAccountDataReq {
  return { address: "", name: "", lastname: "", forge: "" };
}

export const StripeAccountDataReq = {
  encode(message: StripeAccountDataReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.lastname !== "") {
      writer.uint32(26).string(message.lastname);
    }
    if (message.forge !== "") {
      writer.uint32(34).string(message.forge);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StripeAccountDataReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeAccountDataReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lastname = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.forge = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<StripeAccountDataReq>, I>>(base?: I): StripeAccountDataReq {
    return StripeAccountDataReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StripeAccountDataReq>, I>>(object: I): StripeAccountDataReq {
    const message = createBaseStripeAccountDataReq();
    message.address = object.address ?? "";
    message.name = object.name ?? "";
    message.lastname = object.lastname ?? "";
    message.forge = object.forge ?? "";
    return message;
  },
};

function createBaseStripeAccountLinkRes(): StripeAccountLinkRes {
  return { accountLink: "", accountId: "" };
}

export const StripeAccountLinkRes = {
  encode(message: StripeAccountLinkRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountLink !== "") {
      writer.uint32(10).string(message.accountLink);
    }
    if (message.accountId !== "") {
      writer.uint32(18).string(message.accountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StripeAccountLinkRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeAccountLinkRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountLink = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<StripeAccountLinkRes>, I>>(base?: I): StripeAccountLinkRes {
    return StripeAccountLinkRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StripeAccountLinkRes>, I>>(object: I): StripeAccountLinkRes {
    const message = createBaseStripeAccountLinkRes();
    message.accountLink = object.accountLink ?? "";
    message.accountId = object.accountId ?? "";
    return message;
  },
};

function createBaseStripeLoginLinkRes(): StripeLoginLinkRes {
  return { loginLink: "" };
}

export const StripeLoginLinkRes = {
  encode(message: StripeLoginLinkRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.loginLink !== "") {
      writer.uint32(10).string(message.loginLink);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StripeLoginLinkRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStripeLoginLinkRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.loginLink = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<StripeLoginLinkRes>, I>>(base?: I): StripeLoginLinkRes {
    return StripeLoginLinkRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StripeLoginLinkRes>, I>>(object: I): StripeLoginLinkRes {
    const message = createBaseStripeLoginLinkRes();
    message.loginLink = object.loginLink ?? "";
    return message;
  },
};

function createBaseCreateCustomerReq(): CreateCustomerReq {
  return { address: "" };
}

export const CreateCustomerReq = {
  encode(message: CreateCustomerReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCustomerReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCustomerReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateCustomerReq>, I>>(base?: I): CreateCustomerReq {
    return CreateCustomerReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateCustomerReq>, I>>(object: I): CreateCustomerReq {
    const message = createBaseCreateCustomerReq();
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseCardInfoReq(): CardInfoReq {
  return { customerId: "", card: "" };
}

export const CardInfoReq = {
  encode(message: CardInfoReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customerId !== "") {
      writer.uint32(10).string(message.customerId);
    }
    if (message.card !== "") {
      writer.uint32(18).string(message.card);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CardInfoReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCardInfoReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.card = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CardInfoReq>, I>>(base?: I): CardInfoReq {
    return CardInfoReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CardInfoReq>, I>>(object: I): CardInfoReq {
    const message = createBaseCardInfoReq();
    message.customerId = object.customerId ?? "";
    message.card = object.card ?? "";
    return message;
  },
};

function createBaseListCardReq(): ListCardReq {
  return { customerId: "" };
}

export const ListCardReq = {
  encode(message: ListCardReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customerId !== "") {
      writer.uint32(10).string(message.customerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCardReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCardReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListCardReq>, I>>(base?: I): ListCardReq {
    return ListCardReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCardReq>, I>>(object: I): ListCardReq {
    const message = createBaseListCardReq();
    message.customerId = object.customerId ?? "";
    return message;
  },
};

function createBaseCreateCardReq(): CreateCardReq {
  return { customerId: "", source: "" };
}

export const CreateCardReq = {
  encode(message: CreateCardReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customerId !== "") {
      writer.uint32(10).string(message.customerId);
    }
    if (message.source !== "") {
      writer.uint32(18).string(message.source);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCardReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCardReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.source = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateCardReq>, I>>(base?: I): CreateCardReq {
    return CreateCardReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateCardReq>, I>>(object: I): CreateCardReq {
    const message = createBaseCreateCardReq();
    message.customerId = object.customerId ?? "";
    message.source = object.source ?? "";
    return message;
  },
};

function createBaseDeleteCardReq(): DeleteCardReq {
  return { customerId: "", card: "" };
}

export const DeleteCardReq = {
  encode(message: DeleteCardReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customerId !== "") {
      writer.uint32(10).string(message.customerId);
    }
    if (message.card !== "") {
      writer.uint32(18).string(message.card);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteCardReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteCardReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.card = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DeleteCardReq>, I>>(base?: I): DeleteCardReq {
    return DeleteCardReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteCardReq>, I>>(object: I): DeleteCardReq {
    const message = createBaseDeleteCardReq();
    message.customerId = object.customerId ?? "";
    message.card = object.card ?? "";
    return message;
  },
};

function createBasePaymentIntentBaseReq(): PaymentIntentBaseReq {
  return { customerId: "", amount: 0, currency: "" };
}

export const PaymentIntentBaseReq = {
  encode(message: PaymentIntentBaseReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customerId !== "") {
      writer.uint32(10).string(message.customerId);
    }
    if (message.amount !== 0) {
      writer.uint32(17).double(message.amount);
    }
    if (message.currency !== "") {
      writer.uint32(26).string(message.currency);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaymentIntentBaseReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentIntentBaseReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customerId = reader.string();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.amount = reader.double();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.currency = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<PaymentIntentBaseReq>, I>>(base?: I): PaymentIntentBaseReq {
    return PaymentIntentBaseReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PaymentIntentBaseReq>, I>>(object: I): PaymentIntentBaseReq {
    const message = createBasePaymentIntentBaseReq();
    message.customerId = object.customerId ?? "";
    message.amount = object.amount ?? 0;
    message.currency = object.currency ?? "";
    return message;
  },
};

function createBaseCardId(): CardId {
  return { cardId: "" };
}

export const CardId = {
  encode(message: CardId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cardId !== "") {
      writer.uint32(10).string(message.cardId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CardId {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCardId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cardId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CardId>, I>>(base?: I): CardId {
    return CardId.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CardId>, I>>(object: I): CardId {
    const message = createBaseCardId();
    message.cardId = object.cardId ?? "";
    return message;
  },
};

function createBaseCustomerId(): CustomerId {
  return { customerId: "" };
}

export const CustomerId = {
  encode(message: CustomerId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.customerId !== "") {
      writer.uint32(10).string(message.customerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CustomerId {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCustomerId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.customerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CustomerId>, I>>(base?: I): CustomerId {
    return CustomerId.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CustomerId>, I>>(object: I): CustomerId {
    const message = createBaseCustomerId();
    message.customerId = object.customerId ?? "";
    return message;
  },
};

function createBaseCardInfo(): CardInfo {
  return { card: undefined };
}

export const CardInfo = {
  encode(message: CardInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.card !== undefined) {
      Struct.encode(Struct.wrap(message.card), writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CardInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCardInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.card = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CardInfo>, I>>(base?: I): CardInfo {
    return CardInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CardInfo>, I>>(object: I): CardInfo {
    const message = createBaseCardInfo();
    message.card = object.card ?? undefined;
    return message;
  },
};

function createBaseCardList(): CardList {
  return { cards: undefined };
}

export const CardList = {
  encode(message: CardList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cards !== undefined) {
      Struct.encode(Struct.wrap(message.cards), writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CardList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCardList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cards = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CardList>, I>>(base?: I): CardList {
    return CardList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CardList>, I>>(object: I): CardList {
    const message = createBaseCardList();
    message.cards = object.cards ?? undefined;
    return message;
  },
};

function createBaseClientSecret(): ClientSecret {
  return { clientSecret: "" };
}

export const ClientSecret = {
  encode(message: ClientSecret, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.clientSecret !== "") {
      writer.uint32(10).string(message.clientSecret);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClientSecret {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClientSecret();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.clientSecret = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ClientSecret>, I>>(base?: I): ClientSecret {
    return ClientSecret.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClientSecret>, I>>(object: I): ClientSecret {
    const message = createBaseClientSecret();
    message.clientSecret = object.clientSecret ?? "";
    return message;
  },
};

function createBasePaymentInfo(): PaymentInfo {
  return { payment: undefined };
}

export const PaymentInfo = {
  encode(message: PaymentInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.payment !== undefined) {
      Struct.encode(Struct.wrap(message.payment), writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PaymentInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePaymentInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.payment = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<PaymentInfo>, I>>(base?: I): PaymentInfo {
    return PaymentInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PaymentInfo>, I>>(object: I): PaymentInfo {
    const message = createBasePaymentInfo();
    message.payment = object.payment ?? undefined;
    return message;
  },
};

function createBaseChargeInfo(): ChargeInfo {
  return { charge: undefined };
}

export const ChargeInfo = {
  encode(message: ChargeInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.charge !== undefined) {
      Struct.encode(Struct.wrap(message.charge), writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChargeInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChargeInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.charge = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ChargeInfo>, I>>(base?: I): ChargeInfo {
    return ChargeInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChargeInfo>, I>>(object: I): ChargeInfo {
    const message = createBaseChargeInfo();
    message.charge = object.charge ?? undefined;
    return message;
  },
};

function createBaseDeleteInfo(): DeleteInfo {
  return { delete: undefined };
}

export const DeleteInfo = {
  encode(message: DeleteInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.delete !== undefined) {
      Struct.encode(Struct.wrap(message.delete), writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.delete = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DeleteInfo>, I>>(base?: I): DeleteInfo {
    return DeleteInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteInfo>, I>>(object: I): DeleteInfo {
    const message = createBaseDeleteInfo();
    message.delete = object.delete ?? undefined;
    return message;
  },
};

function createBaseUpdatePaymentReq(): UpdatePaymentReq {
  return { paymentIntent: "", paymentMethod: "", customerId: "" };
}

export const UpdatePaymentReq = {
  encode(message: UpdatePaymentReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.paymentIntent !== "") {
      writer.uint32(10).string(message.paymentIntent);
    }
    if (message.paymentMethod !== "") {
      writer.uint32(18).string(message.paymentMethod);
    }
    if (message.customerId !== "") {
      writer.uint32(26).string(message.customerId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdatePaymentReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdatePaymentReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.paymentIntent = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.paymentMethod = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.customerId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdatePaymentReq>, I>>(base?: I): UpdatePaymentReq {
    return UpdatePaymentReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdatePaymentReq>, I>>(object: I): UpdatePaymentReq {
    const message = createBaseUpdatePaymentReq();
    message.paymentIntent = object.paymentIntent ?? "";
    message.paymentMethod = object.paymentMethod ?? "";
    message.customerId = object.customerId ?? "";
    return message;
  },
};

function createBaseUpdatePaymentInfo(): UpdatePaymentInfo {
  return { update: undefined };
}

export const UpdatePaymentInfo = {
  encode(message: UpdatePaymentInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.update !== undefined) {
      Struct.encode(Struct.wrap(message.update), writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdatePaymentInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdatePaymentInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.update = Struct.unwrap(Struct.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdatePaymentInfo>, I>>(base?: I): UpdatePaymentInfo {
    return UpdatePaymentInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdatePaymentInfo>, I>>(object: I): UpdatePaymentInfo {
    const message = createBaseUpdatePaymentInfo();
    message.update = object.update ?? undefined;
    return message;
  },
};

function createBasePublishableKey(): PublishableKey {
  return { publishableKey: "" };
}

export const PublishableKey = {
  encode(message: PublishableKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.publishableKey !== "") {
      writer.uint32(10).string(message.publishableKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PublishableKey {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePublishableKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.publishableKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<PublishableKey>, I>>(base?: I): PublishableKey {
    return PublishableKey.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PublishableKey>, I>>(object: I): PublishableKey {
    const message = createBasePublishableKey();
    message.publishableKey = object.publishableKey ?? "";
    return message;
  },
};

export interface PaymentService {
  getNewAccountLink(
    request: DeepPartial<StripeAccountDataReq>,
    metadata?: grpc.Metadata,
  ): Promise<StripeAccountLinkRes>;
  getAccountLink(request: DeepPartial<StripeAccountReq>, metadata?: grpc.Metadata): Promise<StripeAccountLinkRes>;
  isAccountActivated(request: DeepPartial<StripeAccountReq>, metadata?: grpc.Metadata): Promise<StripeAccountStateRes>;
  getAccountLoginLink(request: DeepPartial<StripeAccountReq>, metadata?: grpc.Metadata): Promise<StripeLoginLinkRes>;
  getCardInfo(request: DeepPartial<CardInfoReq>, metadata?: grpc.Metadata): Promise<CardInfo>;
  getCardsList(request: DeepPartial<ListCardReq>, metadata?: grpc.Metadata): Promise<CardList>;
  createCard(request: DeepPartial<CreateCardReq>, metadata?: grpc.Metadata): Promise<CardId>;
  deleteCard(request: DeepPartial<DeleteCardReq>, metadata?: grpc.Metadata): Promise<DeleteInfo>;
  createCustomer(request: DeepPartial<CreateCustomerReq>, metadata?: grpc.Metadata): Promise<CustomerId>;
  createPaymentIntent(request: DeepPartial<PaymentIntentBaseReq>, metadata?: grpc.Metadata): Promise<PaymentInfo>;
  createSetupIntent(request: DeepPartial<CustomerId>, metadata?: grpc.Metadata): Promise<ClientSecret>;
  chargeIntent(request: DeepPartial<PaymentIntentBaseReq>, metadata?: grpc.Metadata): Promise<ChargeInfo>;
  updatePaymentIntent(request: DeepPartial<UpdatePaymentReq>, metadata?: grpc.Metadata): Promise<UpdatePaymentInfo>;
  getPublishableKey(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<PublishableKey>;
}

export class PaymentServiceClientImpl implements PaymentService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.getNewAccountLink = this.getNewAccountLink.bind(this);
    this.getAccountLink = this.getAccountLink.bind(this);
    this.isAccountActivated = this.isAccountActivated.bind(this);
    this.getAccountLoginLink = this.getAccountLoginLink.bind(this);
    this.getCardInfo = this.getCardInfo.bind(this);
    this.getCardsList = this.getCardsList.bind(this);
    this.createCard = this.createCard.bind(this);
    this.deleteCard = this.deleteCard.bind(this);
    this.createCustomer = this.createCustomer.bind(this);
    this.createPaymentIntent = this.createPaymentIntent.bind(this);
    this.createSetupIntent = this.createSetupIntent.bind(this);
    this.chargeIntent = this.chargeIntent.bind(this);
    this.updatePaymentIntent = this.updatePaymentIntent.bind(this);
    this.getPublishableKey = this.getPublishableKey.bind(this);
  }

  getNewAccountLink(
    request: DeepPartial<StripeAccountDataReq>,
    metadata?: grpc.Metadata,
  ): Promise<StripeAccountLinkRes> {
    return this.rpc.unary(PaymentServicegetNewAccountLinkDesc, StripeAccountDataReq.fromPartial(request), metadata);
  }

  getAccountLink(request: DeepPartial<StripeAccountReq>, metadata?: grpc.Metadata): Promise<StripeAccountLinkRes> {
    return this.rpc.unary(PaymentServicegetAccountLinkDesc, StripeAccountReq.fromPartial(request), metadata);
  }

  isAccountActivated(request: DeepPartial<StripeAccountReq>, metadata?: grpc.Metadata): Promise<StripeAccountStateRes> {
    return this.rpc.unary(PaymentServiceisAccountActivatedDesc, StripeAccountReq.fromPartial(request), metadata);
  }

  getAccountLoginLink(request: DeepPartial<StripeAccountReq>, metadata?: grpc.Metadata): Promise<StripeLoginLinkRes> {
    return this.rpc.unary(PaymentServicegetAccountLoginLinkDesc, StripeAccountReq.fromPartial(request), metadata);
  }

  getCardInfo(request: DeepPartial<CardInfoReq>, metadata?: grpc.Metadata): Promise<CardInfo> {
    return this.rpc.unary(PaymentServicegetCardInfoDesc, CardInfoReq.fromPartial(request), metadata);
  }

  getCardsList(request: DeepPartial<ListCardReq>, metadata?: grpc.Metadata): Promise<CardList> {
    return this.rpc.unary(PaymentServicegetCardsListDesc, ListCardReq.fromPartial(request), metadata);
  }

  createCard(request: DeepPartial<CreateCardReq>, metadata?: grpc.Metadata): Promise<CardId> {
    return this.rpc.unary(PaymentServicecreateCardDesc, CreateCardReq.fromPartial(request), metadata);
  }

  deleteCard(request: DeepPartial<DeleteCardReq>, metadata?: grpc.Metadata): Promise<DeleteInfo> {
    return this.rpc.unary(PaymentServicedeleteCardDesc, DeleteCardReq.fromPartial(request), metadata);
  }

  createCustomer(request: DeepPartial<CreateCustomerReq>, metadata?: grpc.Metadata): Promise<CustomerId> {
    return this.rpc.unary(PaymentServicecreateCustomerDesc, CreateCustomerReq.fromPartial(request), metadata);
  }

  createPaymentIntent(request: DeepPartial<PaymentIntentBaseReq>, metadata?: grpc.Metadata): Promise<PaymentInfo> {
    return this.rpc.unary(PaymentServicecreatePaymentIntentDesc, PaymentIntentBaseReq.fromPartial(request), metadata);
  }

  createSetupIntent(request: DeepPartial<CustomerId>, metadata?: grpc.Metadata): Promise<ClientSecret> {
    return this.rpc.unary(PaymentServicecreateSetupIntentDesc, CustomerId.fromPartial(request), metadata);
  }

  chargeIntent(request: DeepPartial<PaymentIntentBaseReq>, metadata?: grpc.Metadata): Promise<ChargeInfo> {
    return this.rpc.unary(PaymentServicechargeIntentDesc, PaymentIntentBaseReq.fromPartial(request), metadata);
  }

  updatePaymentIntent(request: DeepPartial<UpdatePaymentReq>, metadata?: grpc.Metadata): Promise<UpdatePaymentInfo> {
    return this.rpc.unary(PaymentServiceupdatePaymentIntentDesc, UpdatePaymentReq.fromPartial(request), metadata);
  }

  getPublishableKey(request: DeepPartial<Empty>, metadata?: grpc.Metadata): Promise<PublishableKey> {
    return this.rpc.unary(PaymentServicegetPublishableKeyDesc, Empty.fromPartial(request), metadata);
  }
}

export const PaymentServiceDesc = { serviceName: "PaymentService" };

export const PaymentServicegetNewAccountLinkDesc: UnaryMethodDefinitionish = {
  methodName: "getNewAccountLink",
  service: PaymentServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StripeAccountDataReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StripeAccountLinkRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentServicegetAccountLinkDesc: UnaryMethodDefinitionish = {
  methodName: "getAccountLink",
  service: PaymentServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StripeAccountReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StripeAccountLinkRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentServiceisAccountActivatedDesc: UnaryMethodDefinitionish = {
  methodName: "isAccountActivated",
  service: PaymentServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StripeAccountReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StripeAccountStateRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentServicegetAccountLoginLinkDesc: UnaryMethodDefinitionish = {
  methodName: "getAccountLoginLink",
  service: PaymentServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return StripeAccountReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = StripeLoginLinkRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentServicegetCardInfoDesc: UnaryMethodDefinitionish = {
  methodName: "getCardInfo",
  service: PaymentServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CardInfoReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CardInfo.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentServicegetCardsListDesc: UnaryMethodDefinitionish = {
  methodName: "getCardsList",
  service: PaymentServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListCardReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CardList.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentServicecreateCardDesc: UnaryMethodDefinitionish = {
  methodName: "createCard",
  service: PaymentServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateCardReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CardId.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentServicedeleteCardDesc: UnaryMethodDefinitionish = {
  methodName: "deleteCard",
  service: PaymentServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteCardReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = DeleteInfo.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentServicecreateCustomerDesc: UnaryMethodDefinitionish = {
  methodName: "createCustomer",
  service: PaymentServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateCustomerReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CustomerId.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentServicecreatePaymentIntentDesc: UnaryMethodDefinitionish = {
  methodName: "createPaymentIntent",
  service: PaymentServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return PaymentIntentBaseReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PaymentInfo.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentServicecreateSetupIntentDesc: UnaryMethodDefinitionish = {
  methodName: "createSetupIntent",
  service: PaymentServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CustomerId.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ClientSecret.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentServicechargeIntentDesc: UnaryMethodDefinitionish = {
  methodName: "chargeIntent",
  service: PaymentServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return PaymentIntentBaseReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ChargeInfo.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentServiceupdatePaymentIntentDesc: UnaryMethodDefinitionish = {
  methodName: "updatePaymentIntent",
  service: PaymentServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdatePaymentReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdatePaymentInfo.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PaymentServicegetPublishableKeyDesc: UnaryMethodDefinitionish = {
  methodName: "getPublishableKey",
  service: PaymentServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return Empty.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PublishableKey.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
