import { IpfsCollectibleInfo } from 'beasy-fe-commons';
import Skeleton from 'react-loading-skeleton';

import { ItemProps } from 'components/ItemList';
import Rarity from 'components/Rarity';
import { ImageView } from 'components/image';

import { useIpfsInfo } from 'hooks/useIpfs';

import { Collectible } from 'protobuf/lib/collectibleService';

import css from './index.module.css';

export const CollectibleItem = ({ item, onClick }: ItemProps<Collectible>) => {
  const info = useIpfsInfo<IpfsCollectibleInfo>(item?.CID);

  return (
    <article onClick={onClick} className={css.wrapper}>
      <figure className={css.thumbnail}>
        <ImageView src={info?.image} />
      </figure>
      <div className={css.info}>
        <div>
          <h4>{item?.name ?? <Skeleton />}</h4>
        </div>
        <div>NFT</div>
        {item ? <Rarity quantity={item.quantity} /> : <Skeleton width={15} />}
      </div>
    </article>
  );
};
