import { EthereumAddress } from 'beasy-fe-commons';

import { PencilIcon, PlusIcon } from 'assets/icons';

import { Spacer } from 'components/Spacer';
import { ButtonPrimary } from 'components/button';

import { useCollection } from 'hooks/collection';

import { CollectionPickItem } from '..';

interface Props {
  selected?: EthereumAddress;
  onClick?: () => void;
}

export const CollectionPickButton = ({ selected, onClick }: Props) => {
  const collection = useCollection(selected);

  return (
    <ButtonPrimary onClick={onClick}>
      {selected ? (
        <>
          <CollectionPickItem collection={collection} />
          <Spacer width="24px" />
          <PencilIcon size={12} />
        </>
      ) : (
        <>
          <PlusIcon size={12} />
          <Spacer width="6px" />
          Pick collection
        </>
      )}
    </ButtonPrimary>
  );
};
