import clsx from 'clsx';
import React from 'react';

import css from './index.module.css';

interface Props {
  title: string;
  children?: React.ReactNode;
  className?: string;
}

export const PageHeader = ({ title, className, children }: Props) => {
  return (
    <header className={clsx(css.header, className)}>
      <h2>{title}</h2>
      {children}
    </header>
  );
};
