// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FrontstoreAddModal_wrapper__Ryfjx {
  max-width: 286px;
  margin-top: 15px;
}

.FrontstoreAddModal_field__oQv93 {
  display: block;
  margin-bottom: 12px;
}

.FrontstoreAddModal_label__YXTka {
  font-size: 14px;
  line-height: 20px;
  color: var(--text-color);
  display: inline-block;
  margin: 0 0 4px;
}

.FrontstoreAddModal_error__tpubV {
  margin-top: 24px;
}

.FrontstoreAddModal_formikError__X-dWT {
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/MyAccount/FrontstoreAddModal/index.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,wBAAwB;EACxB,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".wrapper {\n  max-width: 286px;\n  margin-top: 15px;\n}\n\n.field {\n  display: block;\n  margin-bottom: 12px;\n}\n\n.label {\n  font-size: 14px;\n  line-height: 20px;\n  color: var(--text-color);\n  display: inline-block;\n  margin: 0 0 4px;\n}\n\n.error {\n  margin-top: 24px;\n}\n\n.formikError {\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `FrontstoreAddModal_wrapper__Ryfjx`,
	"field": `FrontstoreAddModal_field__oQv93`,
	"label": `FrontstoreAddModal_label__YXTka`,
	"error": `FrontstoreAddModal_error__tpubV`,
	"formikError": `FrontstoreAddModal_formikError__X-dWT`
};
export default ___CSS_LOADER_EXPORT___;
