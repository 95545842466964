import css from './index.module.css';

interface CellProps {
  label: string;
  value: string;
}

const Cell = (props: CellProps) => {
  const { label, value } = props;

  return (
    <dl className={css.container}>
      <dt>{label}</dt>
      <dd>{value}</dd>
    </dl>
  );
};

export default Cell;
