import clsx from 'clsx';
import React from 'react';

import { IconProps } from '../types';
import style from './index.module.css';

const ASPECT_RATIO = 1.142;

export const WarningIcon = ({ className, size = 16 }: IconProps) => (
  <svg
    className={clsx(style['warning-icon'], className)}
    width={size}
    height={size / ASPECT_RATIO}
    viewBox="0 0 16 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25685 1.0991C7.02185 -0.260898 8.97885 -0.260898 9.74285 1.0991L15.3228 11.0191C16.0728 12.3531 15.1098 13.9991 13.5808 13.9991H2.41985C0.889848 13.9991 -0.0731517 12.3531 0.676848 11.0191L6.25685 1.0991ZM8.99985 11.0001C8.99985 11.2653 8.89449 11.5197 8.70695 11.7072C8.51942 11.8947 8.26506 12.0001 7.99985 12.0001C7.73463 12.0001 7.48028 11.8947 7.29274 11.7072C7.10521 11.5197 6.99985 11.2653 6.99985 11.0001C6.99985 10.7349 7.10521 10.4805 7.29274 10.293C7.48028 10.1055 7.73463 10.0001 7.99985 10.0001C8.26506 10.0001 8.51942 10.1055 8.70695 10.293C8.89449 10.4805 8.99985 10.7349 8.99985 11.0001ZM7.99985 3.0001C7.73463 3.0001 7.48028 3.10546 7.29274 3.29299C7.10521 3.48053 6.99985 3.73488 6.99985 4.0001V7.0001C6.99985 7.26532 7.10521 7.51967 7.29274 7.70721C7.48028 7.89474 7.73463 8.0001 7.99985 8.0001C8.26506 8.0001 8.51942 7.89474 8.70695 7.70721C8.89449 7.51967 8.99985 7.26532 8.99985 7.0001V4.0001C8.99985 3.73488 8.89449 3.48053 8.70695 3.29299C8.51942 3.10546 8.26506 3.0001 7.99985 3.0001Z"
    />
  </svg>
);
