import clsx from 'clsx';
import css from './index.module.css';

export interface RarityProps {
  quantity: number;
}

export enum BackgroundColor {
  GOLD = 'gold',
  CARROT = 'carrot',
  PURPLE = 'purple',
  BLUE = 'blue',
  GREEN = 'green',
  WHITE = 'white',
  GREY = 'grey',
}

function getBackground(quantity: number): BackgroundColor {
  switch (true) {
    case quantity === 1:
      return BackgroundColor.GOLD;
    case quantity > 1 && quantity <= 25:
      return BackgroundColor.CARROT;
    case quantity > 25 && quantity <= 50:
      return BackgroundColor.PURPLE;
    case quantity > 50 && quantity <= 100:
      return BackgroundColor.BLUE;
    case quantity > 100 && quantity <= 250:
      return BackgroundColor.GREEN;
    case quantity > 250 && quantity <= 500:
      return BackgroundColor.WHITE;
    case quantity >= 500:
      return BackgroundColor.GREY;
    default:
      return BackgroundColor.GREY;
  }
}

function formatQuantity(quantity: number): number | string {
  if (quantity >= 1000 && quantity < 1000000) {
    return quantity / 1000 + 'k';
  }
  return quantity;
}

const Rarity = ({ quantity }: RarityProps) => {
  return (
    <div className={clsx(css.content, css[getBackground(quantity)])}>
      <p>Rarity: 1/{formatQuantity(quantity)}</p>
    </div>
  );
};

export default Rarity;
