import { useTranslation } from 'react-i18next';

import { AudioIcon } from 'assets/icons';

import css from './index.module.css';

export const AudioPlayer = ({ src }: { src: string }) => {
  const { t } = useTranslation();
  return (
    <div className={css.container}>
      <AudioIcon size={200} />

      <div className={css.audio}>
        <audio controls src={src}>
          {t('components.audioPlayer.notSupported')}
        </audio>
      </div>
    </div>
  );
};
