export interface ItemProps<T> {
  item?: T; // could be undefined because of partial Skeleton loading
  onClick?: () => void;
}

interface Props<T> {
  items: T[];
  Component: (p: ItemProps<T>) => JSX.Element;
  onItemClick: (item: T) => void;
  className?: string;
}

/** @deprecated */
const ItemList = <T extends {}>({ items, Component, className, onItemClick }: Props<T>) => {
  return (
    <ul className={className}>
      {items.map((item, index) => (
        <li key={index}>
          <Component item={item} onClick={() => onItemClick(item)} />
        </li>
      ))}
    </ul>
  );
};

export default ItemList;
