import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { ErrorMessageQueryLoading } from 'components/ErrorMessage';
import { ButtonGhost2 } from 'components/button';
import { CollectionListItem } from 'components/collection';
import { EmptyList } from 'components/layout';
import { PageArea, PageBody, PageHeader } from 'components/page';

import { GetCollectionsReq } from 'protobuf/lib/collectionService';

import { getCollections } from 'services/collection';

import { AppState } from 'state';

import css from './index.module.css';

export const CollectionListRoute = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<'created' | 'shared'>('created');
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  const request = useMemo<GetCollectionsReq>(() => {
    switch (filter) {
      case 'created':
        return { creator: walletConnectV2.address! };
      case 'shared':
        return { royaltyHolder: walletConnectV2.address! };
    }
  }, [filter]);

  const { data, isError, refetch } = useQuery(['getCollections', request], () => getCollections(request));
  const items = data?.collections;

  return (
    <PageArea>
      <PageHeader title="Collections">
        <div className={css.filter}>
          <ButtonGhost2 className={clsx({ [css.active]: filter === 'created' })} onClick={() => setFilter('created')}>
            {t('collection.createdByMe')}
          </ButtonGhost2>
          <ButtonGhost2 className={clsx({ [css.active]: filter === 'shared' })} onClick={() => setFilter('shared')}>
            {t('collection.sharedWithMe')}
          </ButtonGhost2>
        </div>
      </PageHeader>
      <PageBody className={css.list}>
        {isError ? (
          <ErrorMessageQueryLoading onRetry={refetch} />
        ) : items?.length === 0 ? (
          <EmptyList
            message={
              filter === 'created'
                ? "You have not created any collections yet. Press 'Create collection' to begin adding to your gallery."
                : 'You do not have any collections shared with you.'
            }
          />
        ) : (
          (items || Array(20).fill(undefined)).map((i, ix) => <CollectionListItem key={ix} collection={i} />)
        )}
      </PageBody>
    </PageArea>
  );
};
