// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_wrapper__VGm0Z {
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/dialog/Footer/index.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,yBAAyB;EACzB,SAAS;AACX","sourcesContent":[".wrapper {\n  flex-shrink: 0;\n  display: flex;\n  justify-content: flex-end;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Footer_wrapper__VGm0Z`
};
export default ___CSS_LOADER_EXPORT___;
