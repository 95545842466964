// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Spinner_spinner__O8h9H {
  animation: Spinner_spin__obksz 5s linear infinite;
  opacity: 0.5;
}

@keyframes Spinner_spin__obksz {
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/loading/Spinner/index.module.css"],"names":[],"mappings":"AAAA;EACE,iDAAkC;EAClC,YAAY;AACd;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".spinner {\n  animation: spin 5s linear infinite;\n  opacity: 0.5;\n}\n\n@keyframes spin {\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `Spinner_spinner__O8h9H`,
	"spin": `Spinner_spin__obksz`
};
export default ___CSS_LOADER_EXPORT___;
