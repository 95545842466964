// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dashboard_transactionGraph__9qHVl {
  margin-bottom: 80px;
  min-height: 450px;
}

.Dashboard_digitalCollectibles__ibGtJ {
  min-height: 100px;
}

.Dashboard_list__mEHrY {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
}

.Dashboard_rightContentHeading__hhHWw {
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

#Dashboard_active__oJL-y {
  color: var(--grey-0);
  border-bottom: 1px solid var(--grey-0);
}

.Dashboard_totalHeader__IjjHS {
  display: flex;
  justify-content: space-between;
}

.Dashboard_totalRevenue__NqWPD {
  font-size: 14px;
  line-height: 20px;
  color: var(--text-color-accent);
}

.Dashboard_totalPrice__kVJfn {
  display: inline-block;
  background: var(--alt2-color);
  border-radius: 4px;
  padding: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Dashboard/index.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,SAAS;AACX;;AAEA;EACE,2BAA2B;EAC3B,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,oBAAoB;EACpB,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;EACrB,6BAA6B;EAC7B,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".transactionGraph {\n  margin-bottom: 80px;\n  min-height: 450px;\n}\n\n.digitalCollectibles {\n  min-height: 100px;\n}\n\n.list {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  gap: 32px;\n}\n\n.rightContentHeading {\n  justify-content: flex-start;\n  align-items: center;\n  gap: 8px;\n}\n\n#active {\n  color: var(--grey-0);\n  border-bottom: 1px solid var(--grey-0);\n}\n\n.totalHeader {\n  display: flex;\n  justify-content: space-between;\n}\n\n.totalRevenue {\n  font-size: 14px;\n  line-height: 20px;\n  color: var(--text-color-accent);\n}\n\n.totalPrice {\n  display: inline-block;\n  background: var(--alt2-color);\n  border-radius: 4px;\n  padding: 4px;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transactionGraph": `Dashboard_transactionGraph__9qHVl`,
	"digitalCollectibles": `Dashboard_digitalCollectibles__ibGtJ`,
	"list": `Dashboard_list__mEHrY`,
	"rightContentHeading": `Dashboard_rightContentHeading__hhHWw`,
	"active": `Dashboard_active__oJL-y`,
	"totalHeader": `Dashboard_totalHeader__IjjHS`,
	"totalRevenue": `Dashboard_totalRevenue__NqWPD`,
	"totalPrice": `Dashboard_totalPrice__kVJfn`
};
export default ___CSS_LOADER_EXPORT___;
