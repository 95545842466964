import * as yup from 'yup';

import { priceRegexp } from 'constants/helper';

export const availableTotalContribution = 100;
export const validationSchema = yup
  .object()
  .shape({
    contributors: yup.array().of(
      yup.object().shape({
        userProfileId: yup.string().required('Value is required'),
        revenueShare: yup
          .number()
          .required('Value is required')
          .test('Royalty', 'Must contain only two numbers after decimal point', n =>
            n ? priceRegexp.test(String(n)) : true,
          ),
      }),
    ),
  })
  .test('youContributor', function ({ contributors }) {
    const total = contributors!.reduce((acc, { revenueShare }) => acc + revenueShare!, 0);

    return total > availableTotalContribution
      ? this.createError({
          path: 'youContributor', // must have path otherwise formik will ignore this error
          message: `The total revenue of all contributors must not exceed ${availableTotalContribution}.`,
        })
      : true;
  });
