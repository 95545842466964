import { Formik, Form, FormikProps, setNestedObjectValues } from 'formik';
import { useCallback } from 'react';

import { Spacer } from 'components/Spacer';
import { FieldError } from 'components/field';
import { ClearableSelect, TextInput } from 'components/input';

import { getUserInfo } from 'services/userInfoService';

import { StepProps, RegistrationStepValue, STEP_INVALID_VALUE } from '../types';
import css from './index.module.css';
import validationSchema from './validationSchema';

const onSubmit = () => {};

const Registration = ({ value, getValueRef }: StepProps<RegistrationStepValue>) => {
  const userInfo = getUserInfo();
  const renderOption = useCallback((industry: string) => {
    return industry;
  }, []);
  return (
    <div className={css.wrapper}>
      <Formik
        initialValues={
          value ?? {
            name: '',
            lastname: '',
            business: '',
            industry: undefined,
            role: undefined,
            eth_address: userInfo?.address || '',
            email: '',
            phone: '',
            referral: '',
          }
        }
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          setTouched,
          validateForm,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }: FormikProps<RegistrationStepValue>) => {
          getValueRef.current = async () => {
            const errors = await validateForm();

            if (Object.keys(errors).length > 0) {
              setTouched(setNestedObjectValues(errors, true));
              return STEP_INVALID_VALUE;
            }

            return values;
          };
          return (
            <Form onSubmit={handleSubmit}>
              <div className={css.container}>
                <div className={css.details}>
                  <div className={css.content}>
                    <fieldset>
                      <label htmlFor="">First name</label>
                      <TextInput
                        name="name"
                        value={values.name}
                        onChange={handleChange('name')}
                        onBlur={handleBlur('name')}
                      />
                      {errors.name && touched.name && <FieldError>{touched.name && errors.name}</FieldError>}
                    </fieldset>

                    <fieldset>
                      <label htmlFor="">Business name</label>
                      <TextInput
                        name="business"
                        value={values.business}
                        onChange={handleChange('business')}
                        onBlur={handleBlur('business')}
                      />
                      {errors.business && touched.business && (
                        <FieldError>{touched.business && errors.business}</FieldError>
                      )}
                    </fieldset>
                    <fieldset>
                      <label htmlFor="">Email Address</label>
                      <TextInput
                        name="email"
                        value={values.email}
                        onChange={handleChange('email')}
                        onBlur={handleBlur('email')}
                      />
                      {errors.email && touched.email && <FieldError>{touched.email && errors.email}</FieldError>}
                    </fieldset>
                    <fieldset>
                      <label htmlFor="">Phone Number</label>
                      <TextInput
                        name="phone"
                        value={values.phone}
                        onChange={handleChange('phone')}
                        onBlur={handleBlur('phone')}
                      />
                      {errors.phone && touched.phone && <FieldError>{touched.phone && errors.phone}</FieldError>}
                    </fieldset>
                  </div>
                  <div className={css.content}>
                    <fieldset>
                      <label htmlFor="">Last name</label>
                      <TextInput
                        name="lastname"
                        value={values.lastname}
                        onChange={handleChange('lastname')}
                        onBlur={handleBlur('lastname')}
                      />
                      {errors.lastname && touched.lastname && (
                        <FieldError>{touched.lastname && errors.lastname}</FieldError>
                      )}
                    </fieldset>
                    <fieldset className={css.wallet}>
                      <label htmlFor="">Wallet Address</label>
                      <TextInput name="wallet" readOnly value={values.eth_address} />
                      {errors.eth_address && touched.eth_address && (
                        <FieldError>{touched.eth_address && errors.eth_address}</FieldError>
                      )}
                    </fieldset>
                    <fieldset>
                      <label htmlFor="industry">Industry</label>
                      <ClearableSelect
                        value={values.industry}
                        renderValue={renderOption}
                        onChange={val => {
                          setFieldValue('industry', val, true);
                        }}
                        options={['Arts', 'Fashion', 'Sports', 'Education', 'Entertainment', 'Music']}
                        className={css.list}
                      />
                      {errors.industry && touched.industry && (
                        <FieldError>{touched.industry && errors.industry}</FieldError>
                      )}
                    </fieldset>
                    <fieldset>
                      <label htmlFor="role">Role</label>
                      <ClearableSelect
                        value={values.role}
                        renderValue={renderOption}
                        onChange={val => {
                          setFieldValue('role', val, true);
                        }}
                        options={[
                          'Agency',
                          'Artist',
                          'Athlete',
                          'Charity',
                          'Collective',
                          'Licensor',
                          'Sponsor',
                          'University',
                        ]}
                        className={css.list}
                      />
                      {errors.role && touched.role && <FieldError>{touched.role && errors.role}</FieldError>}
                    </fieldset>
                    <fieldset className={css.wallet}>
                      <div className={css.optional}>
                        <label htmlFor="">Referral Code</label>
                        <Spacer width="5px" />
                        <i>Optional</i>
                      </div>
                      <TextInput
                        name="referral"
                        value={values.referral}
                        onChange={handleChange('referral')}
                        onBlur={handleBlur('referral')}
                      />
                    </fieldset>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Registration;
