import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { getNetworks } from 'services/network';
import { AppState } from 'state';

export const useNetwork = () => {
  const getNetworksQuery = useQuery('getNetworks', () => getNetworks());
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  const allNetworks = getNetworksQuery?.data?.networks;

  const currentNetwork = allNetworks?.find(({ id }) => id === walletConnectV2.chainId);

  return useMemo(
    () => ({
      allNetworks,
      currentNetwork,
    }),
    [allNetworks, currentNetwork],
  );
};
