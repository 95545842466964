import { useTranslation } from 'react-i18next';

import css from './index.module.css';

type Props = {
  title?: string;
  subtitle?: string;
  isOptional?: boolean;
};

export const Header = ({ title, subtitle, isOptional }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={css.header}>
      {title && <h2>{title}</h2>}
      {isOptional && <p>{t('components.collectible.optionalStep')}</p>}
      {subtitle && <h5>{subtitle}</h5>}
    </div>
  );
};
