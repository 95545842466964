// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Details_wrapper__9MsNN {
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
}

.Details_collection__BASj1 {
  display: flex;
  gap: 16px;

  --height: 256px;
}

.Details_collection__BASj1 > figure {
  width: var(--height);
  height: var(--height);
  border-radius: 4px;
}

.Details_collection__BASj1 > p {
  flex: 1 1;

  height: var(--height);
  overflow-y: auto;

  font-size: 18px;
  line-height: 28px;
}

.Details_royalty__39BFN {
  width: 300px;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Details_royalty__39BFN > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/collection/Details/index.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;;EAEd,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,SAAS;;EAET,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,SAAO;;EAEP,qBAAqB;EACrB,gBAAgB;;EAEhB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,cAAc;;EAEd,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV","sourcesContent":[".wrapper {\n  flex-shrink: 0;\n\n  display: flex;\n  flex-direction: column;\n}\n\n.collection {\n  display: flex;\n  gap: 16px;\n\n  --height: 256px;\n}\n\n.collection > figure {\n  width: var(--height);\n  height: var(--height);\n  border-radius: 4px;\n}\n\n.collection > p {\n  flex: 1;\n\n  height: var(--height);\n  overflow-y: auto;\n\n  font-size: 18px;\n  line-height: 28px;\n}\n\n.royalty {\n  width: 300px;\n  flex-shrink: 0;\n\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n\n.royalty > div {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Details_wrapper__9MsNN`,
	"collection": `Details_collection__BASj1`,
	"royalty": `Details_royalty__39BFN`
};
export default ___CSS_LOADER_EXPORT___;
