import { Formik, Form, FormikProps, setNestedObjectValues } from 'formik';

import { Header } from 'components/collectible';
import { FieldContainer, FieldError, FieldTitle } from 'components/field';
import { FileWithSelect } from 'components/input';

import { useTermsOptions, renderOption } from 'hooks/collectible';

import { StepProps, TermsStepValue, STEP_INVALID_VALUE } from '../types';
import css from './index.module.css';
import validationSchema from './validationSchema';

const onSubmit = () => {};

const Terms = ({ value, getValueRef }: StepProps<TermsStepValue>) => {
  const { licenseOptions, ownershipOptions } = useTermsOptions(50);
  return (
    <div className={css.modWrapper}>
      <Header
        subtitle="If you have any utility you are granted to the buyer, please upload that as a PDF document under the Ownership Agreement section. If you are granting any commercial rights to the NFT buyer, please upload that as a PDF document under the License Agreement section."
        isOptional
      />
      <Formik
        initialValues={value ?? {}}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          setTouched,
          validateForm,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }: FormikProps<TermsStepValue>) => {
          getValueRef.current = async () => {
            const errors = await validateForm();

            if (Object.keys(errors).length > 0) {
              setTouched(setNestedObjectValues(errors, true));
              return STEP_INVALID_VALUE;
            }

            return values;
          };

          return (
            <Form onSubmit={handleSubmit}>
              <div className={css.content}>
                <FieldContainer>
                  <FieldTitle>Ownership Agreement</FieldTitle>
                  <FileWithSelect
                    options={ownershipOptions}
                    value={values.ownershipAgreement}
                    renderValue={renderOption}
                    accept=".pdf"
                    onChange={value => setFieldValue('ownershipAgreement', value)}
                    onBlur={handleBlur('ownershipAgreement')}
                  />
                  <FieldError>{touched.ownershipAgreement && errors.ownershipAgreement}</FieldError>
                </FieldContainer>
                <FieldContainer>
                  <FieldTitle>License Agreement</FieldTitle>
                  <FileWithSelect
                    options={licenseOptions}
                    value={values.licenseAgreement}
                    renderValue={renderOption}
                    accept=".pdf"
                    onChange={value => setFieldValue('licenseAgreement', value)}
                    onBlur={handleBlur('licenseAgreement')}
                  />
                  <FieldError>{touched.licenseAgreement && errors.licenseAgreement}</FieldError>
                </FieldContainer>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Terms;
