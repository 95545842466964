import { FC } from 'react';

import { LogoIcon, NotificationIcon, UploadIcon } from 'assets/icons';

import { Spacer } from 'components/Spacer';
import { DialogArea, DialogOverlay } from 'components/dialog';

import css from './index.module.css';

export interface LoadingScreenProps {
  message?: string;
  isNotification?: boolean;
  children?: React.ReactNode;
}

export const LoadingScreen: FC<LoadingScreenProps> = ({ children, message, isNotification }) => {
  return (
    <DialogOverlay>
      <DialogArea className={css.wrapper}>
        <div>
          <LogoIcon />
          {isNotification ? <NotificationIcon className={css.icon} /> : <UploadIcon className={css.icon} />}
          <h3>{message}</h3>
        </div>
        {children && <Spacer height="24px" />}
        {children}
      </DialogArea>
    </DialogOverlay>
  );
};

export default LoadingScreen;
