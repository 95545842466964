// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PickDialog_wrapper__g5\\+Mx {
  display: flex;
  flex-direction: column;
  width: 500px;
  min-width: 500px;
  gap: 33px;
}

.PickDialog_createButton__lM1BT {
  width: 100%;
  display: flex;
  justify-content: center;
}

.PickDialog_wrapper__g5\\+Mx > section {
  flex-grow: 1;
  height: 300px;
  overflow-y: auto;
}

.PickDialog_wrapper__g5\\+Mx > section > .PickDialog_list__BWTTS {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-right: 15px;
}

.PickDialog_searchInput__3LzIP {
  background: var(--input-bg-color);
  border: none;
  border-radius: 6px;
  color: var(--body-color);
  line-height: 20px;
  padding: 10px 15px;
  min-width: 20%;
  min-height: var(--input-min-height);
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/collection/PickDialog/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,kBAAkB;AACpB;;AAEA;EACE,iCAAiC;EACjC,YAAY;EACZ,kBAAkB;EAClB,wBAAwB;EACxB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,mCAAmC;EACnC,aAAa;AACf","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  width: 500px;\n  min-width: 500px;\n  gap: 33px;\n}\n\n.createButton {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n.wrapper > section {\n  flex-grow: 1;\n  height: 300px;\n  overflow-y: auto;\n}\n\n.wrapper > section > .list {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-right: 15px;\n}\n\n.searchInput {\n  background: var(--input-bg-color);\n  border: none;\n  border-radius: 6px;\n  color: var(--body-color);\n  line-height: 20px;\n  padding: 10px 15px;\n  min-width: 20%;\n  min-height: var(--input-min-height);\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `PickDialog_wrapper__g5+Mx`,
	"createButton": `PickDialog_createButton__lM1BT`,
	"list": `PickDialog_list__BWTTS`,
	"searchInput": `PickDialog_searchInput__3LzIP`
};
export default ___CSS_LOADER_EXPORT___;
