// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__13GEl {
  flex-shrink: 0;
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
  align-items: baseline;
}
`, "",{"version":3,"sources":["webpack://./src/components/page/Header/index.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,oBAAoB;EACpB,8BAA8B;EAC9B,qBAAqB;AACvB","sourcesContent":[".header {\n  flex-shrink: 0;\n  display: flex;\n  padding-bottom: 20px;\n  justify-content: space-between;\n  align-items: baseline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__13GEl`
};
export default ___CSS_LOADER_EXPORT___;
