// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PickSpeed_wrapper__tjkpy {
  width: 420px;
}

.PickSpeed_mintingType__S6zBG {
  display: flex;
  align-items: center;
}

.PickSpeed_mintingType__S6zBG > span {
  position: relative;
  top: 3px;
}

.PickSpeed_mintingType__S6zBG > p {
  color: var(--grey-50);
}

.PickSpeed_checkbox__uhAKM {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.PickSpeed_funds__66QSY {
  background: var(--input-bg-color);
  padding: 8px 10px;
  border-radius: 6px;
}`, "",{"version":3,"sources":["webpack://./src/components/transaction/PickSpeed/index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,iCAAiC;EACjC,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".wrapper {\n  width: 420px;\n}\n\n.mintingType {\n  display: flex;\n  align-items: center;\n}\n\n.mintingType > span {\n  position: relative;\n  top: 3px;\n}\n\n.mintingType > p {\n  color: var(--grey-50);\n}\n\n.checkbox {\n  width: 20px;\n  height: 20px;\n  border-radius: 10px;\n}\n\n.funds {\n  background: var(--input-bg-color);\n  padding: 8px 10px;\n  border-radius: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `PickSpeed_wrapper__tjkpy`,
	"mintingType": `PickSpeed_mintingType__S6zBG`,
	"checkbox": `PickSpeed_checkbox__uhAKM`,
	"funds": `PickSpeed_funds__66QSY`
};
export default ___CSS_LOADER_EXPORT___;
