import Web3 from 'web3';

function showPdfInNewTab(base64Data: string, fileName: string) {
  let pdfWindow = window.open('');
  pdfWindow?.document.write(
    '<html<head><title>' +
      fileName +
      '</title><style>body{margin: 0px;overflow:hidden}iframe{border-width: 0px;}</style></head>' +
      "<body><embed width='100%' height='100%' src='" +
      encodeURI(base64Data) +
      "#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>",
  );
}

function downloadPDF(pdf: string, filename: string) {
  const downloadLink = document.createElement('a');
  downloadLink.href = pdf;
  downloadLink.download = filename;
  downloadLink.click();
}

const capitalize = (sentence: string | undefined, wordLimit?: number) => {
  if (!sentence) return 'N/A';
  const word = sentence.split('_');
  for (let i = 0; i < word.length; i += 1) {
    if (wordLimit) {
      word[i] =
        i < wordLimit ? word[i].charAt(0).toUpperCase() + word[i].slice(1).toLowerCase() : word[i].toLowerCase();
    } else {
      word[i] = word[i].charAt(0).toUpperCase() + word[i].slice(1).toLowerCase();
    }
  }
  return word.join(' ');
};

export { showPdfInNewTab, downloadPDF, capitalize };

export const capitalizeFirst = (s: string) => (s ? s[0].toUpperCase() + s.slice(1) : s);

export const padArrayStart = <T>(arr: T[], len: number, fill?: unknown): T[] =>
  len > arr.length
    ? Array(len - arr.length)
        .fill(fill)
        .concat(arr)
    : arr;

export const removeArrayIndex = <T>(arr: T[], index: number) => [...arr.slice(0, index), ...arr.slice(index + 1)];

export const timeout = <T>(milliseconds: number, promise: Promise<T>): Promise<T | undefined> =>
  Promise.race<Promise<T | undefined>>([
    promise,
    new Promise<T | undefined>(resolve => setTimeout(() => resolve(undefined), milliseconds))
  ]);
 

export const getTokenSerialId = (id: string) => Number('0x' + id.slice(-8));

export const getCollectibleIdFromTokenId = (collectibleId: string): string => {
  const l = 8;
  return collectibleId.slice(0, -l) + '0'.repeat(l);
};

export const shortenAddress = (address?: string) => {
  if (!address) {
    return '';
  }

  const head = address.substr(0, 6);
  const tail = address.substr(-4);

  return `@${head}…${tail}`;
};

export const roundPrice = (num: number) => Math.ceil(num * 10000) / 10000;

export const formatServiceFee = (serviceFee: number) => {
  if (serviceFee.toFixed(6) === '0.000000') {
    return serviceFee.toFixed(20);
  } else {
    return serviceFee.toFixed(6);
  }
};
export const priceRegexp = /^\d+(\.\d{1,2})?$/;

export const identity = <T>(v: T) => v;

export const getFileFromUrl = async (url: string, name: string, defaultType = 'image/jpeg') => {
  const response = await fetch(url);
  const data = await response.blob();

  return new File([data], name, {
    type: data.type || defaultType,
  });
};

export function encodeParam(data: string) {
  const result = Web3.utils.asciiToHex(data);
  return Web3.utils.padRight(result, 64);
}
