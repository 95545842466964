// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Create_container__OX-Bv {
  height: 90%;
}

.Create_header__cL2Ic {
  margin-top: 32px;
}

.Create_tabMenu__Av04m {
  margin-right: 80px;
}

.Create_content__ioadb {
  flex: 1 1;
  margin: 32px 80px 0px 0px;
  display: flex;
  flex-direction: column;
}

.Create_formContainer__EkebM {
  flex: 1 1;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/screens/collectible/Create/index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,SAAO;EACP,yBAAyB;EACzB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,SAAO;EACP,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".container {\n  height: 90%;\n}\n\n.header {\n  margin-top: 32px;\n}\n\n.tabMenu {\n  margin-right: 80px;\n}\n\n.content {\n  flex: 1;\n  margin: 32px 80px 0px 0px;\n  display: flex;\n  flex-direction: column;\n}\n\n.formContainer {\n  flex: 1;\n  overflow-x: hidden;\n  overflow-y: auto;\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Create_container__OX-Bv`,
	"header": `Create_header__cL2Ic`,
	"tabMenu": `Create_tabMenu__Av04m`,
	"content": `Create_content__ioadb`,
	"formContainer": `Create_formContainer__EkebM`
};
export default ___CSS_LOADER_EXPORT___;
