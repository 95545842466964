import * as yup from 'yup';

const pdfFile = yup
  .mixed()
  .test('Document size', 'The file size must not exceed 50MB', (f?: File) => (f ? f.size <= 52428800 : true))
  .test('Document type', 'Supported file types are: pdf', (f?: File) =>
    f ? ['application/pdf'].includes(f.type) : true,
  );

const urlString = yup.object();

const requirePdfFile = () => yup.lazy((value: unknown) => (value instanceof File ? pdfFile : urlString));

export default yup.object().shape({
  ownershipAgreement: requirePdfFile(),
  licenseAgreement: requirePdfFile(),
});
