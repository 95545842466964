// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContributorsEditor_wrapper__7CWT5 {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.ContributorsEditor_tooltip__pg5dt {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 40px;
  margin-bottom: 12px;
}

.ContributorsEditor_fieldList__B6Zrp {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 24px;
}

.ContributorsEditor_field__zV55D {
  display: flex;
  gap: 24px;
}

.ContributorsEditor_field__zV55D > :nth-child(1) {
  width: 200px;
  flex-shrink: 0;
}

.ContributorsEditor_field__zV55D > :nth-child(2) {
  flex-grow: 1;
}

.ContributorsEditor_field__zV55D > :nth-child(3) {
  width: 100px;
  flex-shrink: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/collectible/ContributorsEditor/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  margin-right: 20px;\n}\n\n.tooltip {\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  margin-top: 40px;\n  margin-bottom: 12px;\n}\n\n.fieldList {\n  display: flex;\n  flex-direction: column;\n  gap: 18px;\n  margin-top: 24px;\n}\n\n.field {\n  display: flex;\n  gap: 24px;\n}\n\n.field > :nth-child(1) {\n  width: 200px;\n  flex-shrink: 0;\n}\n\n.field > :nth-child(2) {\n  flex-grow: 1;\n}\n\n.field > :nth-child(3) {\n  width: 100px;\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ContributorsEditor_wrapper__7CWT5`,
	"tooltip": `ContributorsEditor_tooltip__pg5dt`,
	"fieldList": `ContributorsEditor_fieldList__B6Zrp`,
	"field": `ContributorsEditor_field__zV55D`
};
export default ___CSS_LOADER_EXPORT___;
