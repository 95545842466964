import { FormikConfig, FormikProps } from 'formik';
import { useCallback } from 'react';
import * as yup from 'yup';

import { Spacer } from 'components/Spacer';
import { ButtonPrimary, ButtonSecondary } from 'components/button';
import { DialogArea, DialogClose, DialogFooter, DialogHeader, DialogOverlay } from 'components/dialog';
import { FieldTitle, FieldError, FieldContainer } from 'components/field';
import { ClearableSelect, TextInput } from 'components/input';

import css from './index.module.css';

interface Props {
  form: FormikProps<CreatorFormValue>;
  username: string;
  isContributor?: boolean;
  isFriend?: boolean;
  isLoading: boolean;
  isError: boolean;
  onClick: () => void;
  onClose: () => void;
}

export const CreatorForm = ({
  form,
  username,
  isContributor,
  isFriend,
  isLoading,
  isError,
  onClick,
  onClose,
}: Props) => {
  const { values, touched, errors, setFieldValue, handleChange, handleBlur } = form;

  const renderOption = useCallback((item: string) => {
    return item;
  }, []);

  return (
    <DialogOverlay>
      <DialogArea>
        <DialogHeader>
          <h3>Add New Creator</h3>
          <DialogClose onClick={onClose} />
        </DialogHeader>
        <Spacer height="12px" />
        {isContributor ? (
          <p> In order to add a new Contributor we will need to collect some information about this new user.</p>
        ) : isFriend ? (
          <p> In order to add a new Partner we will need to collect some information about this new user.</p>
        ) : (
          <p> In order to add a new Royalty Holder we will need to collect some information about this new user.</p>
        )}
        <Spacer height="20px" />
        <div className={css.content}>
          <FieldContainer>
            <FieldTitle>First Name</FieldTitle>
            <TextInput name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} />
            <FieldError>{touched.name && errors.name}</FieldError>

            <FieldTitle>Email Address</FieldTitle>
            <TextInput name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} />
            <FieldError>{touched.email && errors.email}</FieldError>

            <FieldTitle>Business Name</FieldTitle>
            <TextInput name="business" value={values.business} onChange={handleChange} onBlur={handleBlur} />
            <FieldError>{touched.business && errors.business}</FieldError>
            {/*TODO: USE FILTER DROPDOWN STYLE */}
            <FieldTitle>Role</FieldTitle>
            <ClearableSelect
              value={values.role}
              renderValue={renderOption}
              onChange={val => {
                setFieldValue('role', val, true);
              }}
              options={['Agency', 'Artist', 'Athlete', 'Charity', 'Collective', 'Licensor', 'Sponsor', 'University']}
              className={css.list}
              isAbsolute={true}
            />
            <FieldError>{touched.role && errors.role}</FieldError>
          </FieldContainer>

          <FieldContainer>
            <FieldTitle>Last Name</FieldTitle>
            <TextInput name="lastname" value={values.lastname} onChange={handleChange} onBlur={handleBlur} />
            <FieldError>{touched.lastname && errors.lastname}</FieldError>

            <FieldTitle>Username</FieldTitle>
            <TextInput name="username" value={values.username} onChange={handleChange} onBlur={handleBlur} />
            <FieldError>{touched.username && errors.username}</FieldError>

            <FieldTitle>Phone Number</FieldTitle>
            <TextInput name="phone" value={values.phone} onChange={handleChange} onBlur={handleBlur} />
            <FieldError>{touched.phone && errors.phone}</FieldError>

            <FieldTitle>Industry</FieldTitle>
            <ClearableSelect
              key={values.industry}
              value={values.industry}
              renderValue={renderOption}
              onChange={val => {
                setFieldValue('industry', val, true);
              }}
              options={['Arts', 'Fashion', 'Sports', 'Education', 'Entertainment', 'Music']}
              className={css.list}
              isAbsolute={true}
            />
            <FieldError>{touched.industry && errors.industry}</FieldError>
          </FieldContainer>
        </div>
        <Spacer height="12px" />
        {isError && (
          <FieldError>
            Username <b>{username}</b> is taken. Please choose a different username
          </FieldError>
        )}
        <Spacer height="32px" />
        <DialogFooter>
          <ButtonSecondary onClick={onClose} disabled={isLoading} type="button">
            Cancel
          </ButtonSecondary>
          <ButtonPrimary disabled={isLoading} type="submit" onClick={onClick}>
            {isFriend ? 'Add Partner' : 'Add Creator'}
          </ButtonPrimary>
        </DialogFooter>
      </DialogArea>
    </DialogOverlay>
  );
};

export interface CreatorFormValue {
  name: string;
  lastname: string;
  business: string;
  username: string;
  industry: string | undefined;
  role: string | undefined;
  eth_address: string;
  email: string;
  phone: string;
  percentage: string;
}

export const creatorFormikConfig: FormikConfig<CreatorFormValue> = {
  initialValues: {
    name: '',
    lastname: '',
    business: '',
    username: '',
    industry: undefined,
    role: undefined,
    eth_address: '',
    email: '',
    phone: '',
    percentage: '',
  },
  onSubmit: () => {},
  validateOnBlur: true,
  validateOnChange: true,
  validationSchema: yup.object().shape({
    name: yup
      .string()
      .required('You must provide a name')
      .min(1, 'Minimum length is 1 character')
      .max(100, 'Maximum length is 100 characters'),
    lastname: yup
      .string()
      .required('You must provide a last name')
      .min(1, 'Minimum length is 1 character')
      .max(100, 'Maximum length is 100 characters'),
    business: yup
      .string()
      .required('You must provide a business name')
      .min(1, 'Minimum length is 1 character')
      .max(100, 'Maximum length is 100 characters'),
    email: yup.string().required('You must provide the email').email('You must provide a valid format'),
    phone: yup.string().required('You must provide the phone number'),
    industry: yup.string().required('You must provide the industry'),
    role: yup.string().required('You must provide the user role'),
    username: yup.string().required('You must provide the username'),
  }),
};
