import clsx from 'clsx';
import React from 'react';

import { IconProps } from '../types';
import css from './index.module.css';

const ASPECT_RATIO = 1.12;

export const Camera = ({ className, size = 28, onClick }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(css.icon, className)}
    onClick={onClick}
    width={size}
    height={size / ASPECT_RATIO}
    viewBox="0 0 28 25"
  >
    <path d="M9.99984 0.666992L7.55984 3.33366H3.33317C1.8665 3.33366 0.666504 4.53366 0.666504 6.00033V22.0003C0.666504 23.467 1.8665 24.667 3.33317 24.667H24.6665C26.1332 24.667 27.3332 23.467 27.3332 22.0003V6.00033C27.3332 4.53366 26.1332 3.33366 24.6665 3.33366H20.4398L17.9998 0.666992H9.99984ZM13.9998 20.667C10.3198 20.667 7.33317 17.6803 7.33317 14.0003C7.33317 10.3203 10.3198 7.33366 13.9998 7.33366C17.6798 7.33366 20.6665 10.3203 20.6665 14.0003C20.6665 17.6803 17.6798 20.667 13.9998 20.667Z" />
  </svg>
);
