import { useState, useMemo, useCallback } from 'react';

export const useProgress = (initial: number = 0) => {
  const [progress, setProgress] = useState(initial);

  const handleProgress = useCallback((event: ProgressEvent<EventTarget>) => {
    const { loaded, total } = event;
    const progress = 0.05 + (0.95 * loaded) / total;

    setProgress(progress);
  }, []);

  return useMemo(
    () => ({
      progress,
      setProgress,
      handleProgress,
    }),
    [progress, handleProgress],
  );
};
