// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PickDialog_wrapper__NC9tj {
  display: flex;
  flex-direction: column;
  gap: 33px;
  width: 500px;
  min-width: 500px;
  height: 610px;
  min-height: 610px;
}

.PickDialog_wrapper__NC9tj > section {
  flex-grow: 1;
  overflow-y: auto;
}

.PickDialog_list__SR2dz {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-right: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/user/PickDialog/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,kBAAkB;AACpB","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 33px;\n  width: 500px;\n  min-width: 500px;\n  height: 610px;\n  min-height: 610px;\n}\n\n.wrapper > section {\n  flex-grow: 1;\n  overflow-y: auto;\n}\n\n.list {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-right: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `PickDialog_wrapper__NC9tj`,
	"list": `PickDialog_list__SR2dz`
};
export default ___CSS_LOADER_EXPORT___;
