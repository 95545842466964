// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FaqItem_dropDownbttn__IjU6s {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px 6px 0;
  cursor: pointer;
}

.FaqItem_dropDownbttn__IjU6s > b {
  color: var(--text-color-accent);
}

.FaqItem_dropDownbttnArrow__3NBSm {
  transition: 0.25s all ease;
  margin-left: 12px;
}

.FaqItem_dropDownbttnArrowRotated__PwqvU {
  transform: rotate(180deg);
}

.FaqItem_item__mCF0j {
  border-bottom: 1px solid var(--text-color-accent);
  padding: 18px 0 18px 0;
}

.FaqItem_itemContent__4j4dw > p {
  color: var(--text-color);
}

.FaqItem_itemContent__4j4dw {
  margin-top: 14px;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.FaqItem_itemContent__4j4dw > p > b {
  color: var(--text-color-accent);
}
`, "",{"version":3,"sources":["webpack://./src/components/FaqItem/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,0BAA0B;EAC1B,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iDAAiD;EACjD,sBAAsB;AACxB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".dropDownbttn {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 6px 12px 6px 0;\n  cursor: pointer;\n}\n\n.dropDownbttn > b {\n  color: var(--text-color-accent);\n}\n\n.dropDownbttnArrow {\n  transition: 0.25s all ease;\n  margin-left: 12px;\n}\n\n.dropDownbttnArrowRotated {\n  transform: rotate(180deg);\n}\n\n.item {\n  border-bottom: 1px solid var(--text-color-accent);\n  padding: 18px 0 18px 0;\n}\n\n.itemContent > p {\n  color: var(--text-color);\n}\n\n.itemContent {\n  margin-top: 14px;\n  gap: 20px;\n  display: flex;\n  flex-direction: column;\n}\n\n.itemContent > p > b {\n  color: var(--text-color-accent);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropDownbttn": `FaqItem_dropDownbttn__IjU6s`,
	"dropDownbttnArrow": `FaqItem_dropDownbttnArrow__3NBSm`,
	"dropDownbttnArrowRotated": `FaqItem_dropDownbttnArrowRotated__PwqvU`,
	"item": `FaqItem_item__mCF0j`,
	"itemContent": `FaqItem_itemContent__4j4dw`
};
export default ___CSS_LOADER_EXPORT___;
