import { IpfsCollectibleInfo } from 'beasy-fe-commons';
import Skeleton from 'react-loading-skeleton';

import avatarPlaceholder from 'assets/images/avatar-placeholder.png';

import { ImageLoading } from 'components/image';

import { shortenAddress } from 'constants/helper';

import { useIpfsInfo } from 'hooks/useIpfs';

import { Collectible } from 'protobuf/lib/collectibleService';
import { UserProfile } from 'protobuf/lib/userProfileService';

import { getIpfsUrl } from 'services/ipfs';

import css from './index.module.css';

interface Props {
  collectible: Collectible;
  from: UserProfile;
  to?: UserProfile;
}

const TraderEventToast = ({ collectible: { name, CID }, from, to }: Props) => {
  const info = useIpfsInfo<IpfsCollectibleInfo>(CID);

  return (
    <div>
      <div className={css.profiles}>
        <div className={css.avatars}>
          <ImageLoading
            className={css.avatar}
            src={getIpfsUrl(from.avatarCid) || avatarPlaceholder}
            fallback={<Skeleton height={32} width={32} />}
          />
          {to !== undefined && (
            <ImageLoading
              className={css.avatar}
              src={getIpfsUrl(to.avatarCid) || avatarPlaceholder}
              fallback={<Skeleton height={32} width={32} />}
            />
          )}
        </div>

        <div>
          {to !== undefined && <p className={css.message}>Successfully traded token</p>}
          {to === undefined && <p className={css.message}>Successfully minted token</p>}
          {to !== undefined && (
          <p className={css.addresses}>
            {shortenAddress(from.ethAddress)} ➝ {shortenAddress(to.ethAddress)}
          </p>
          )}
        </div>
      </div>

      <div className={css.collectible}>
        <div className={css.collectibleThumbnail}>
          <ImageLoading className={css.thumbnail} src={info?.image} fallback={<Skeleton height={72} width={72} />} />
        </div>

        <h3 className={css.collectibleName}>{name}</h3>
      </div>
    </div>
  );
};

export default TraderEventToast;
