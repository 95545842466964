import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getFriends } from 'services/friend';

import { useRole } from './useRole';

/** returns all friends that are creators */
export const useCreators = () => {
  const { data: friends } = useQuery('getFriends', () => getFriends());
  const { getRoleNameByNetwork } = useRole();

  return useMemo(
    () =>
      friends?.friends
        .filter(({ userProfile }) => Boolean(userProfile?.roles && getRoleNameByNetwork(userProfile?.roles)))
        .map(i => i.address) || [],

    [friends?.friends, getRoleNameByNetwork],
  );
};
