// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PeriodSelector_wrapper__MT0Ds {
  width: 300px;
  display: flex;
  justify-content: space-between;
}

.PeriodSelector_wrapper__MT0Ds > button {
  bottom: 15px;
  color: var(--text-color-accent);
  border: none;
  padding: 0.2em 0.5em;
  border-radius: 3px;
  background: none;
  font-size: 12px;
  line-height: 16px;
}

.PeriodSelector_wrapper__MT0Ds > button.PeriodSelector_selected__1xjDS {
  background-color: var(--orange);
}

@media screen and (max-width: 1350px) {
  .PeriodSelector_wrapper__MT0Ds {
    width: 300px;
    display: flex;
    justify-content: space-evenly;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Chart/PeriodSelector/index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,+BAA+B;EAC/B,YAAY;EACZ,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE;IACE,YAAY;IACZ,aAAa;IACb,6BAA6B;EAC/B;AACF","sourcesContent":[".wrapper {\n  width: 300px;\n  display: flex;\n  justify-content: space-between;\n}\n\n.wrapper > button {\n  bottom: 15px;\n  color: var(--text-color-accent);\n  border: none;\n  padding: 0.2em 0.5em;\n  border-radius: 3px;\n  background: none;\n  font-size: 12px;\n  line-height: 16px;\n}\n\n.wrapper > button.selected {\n  background-color: var(--orange);\n}\n\n@media screen and (max-width: 1350px) {\n  .wrapper {\n    width: 300px;\n    display: flex;\n    justify-content: space-evenly;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `PeriodSelector_wrapper__MT0Ds`,
	"selected": `PeriodSelector_selected__1xjDS`
};
export default ___CSS_LOADER_EXPORT___;
