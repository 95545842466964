import { IpfsCid, IpfsCollectibleInfo } from 'beasy-fe-commons';

import { ImageView } from 'components/image';

import { useIpfsInfo } from 'hooks/useIpfs';

interface Props {
  metadataCid?: IpfsCid;
}

export const CollectibleThumbnail = ({ metadataCid }: Props) => {
  const metadata = useIpfsInfo<IpfsCollectibleInfo>(metadataCid);

  return <ImageView src={metadata?.image} />;
};
