import { setNestedObjectValues, useFormik } from 'formik';
import { useSetFieldValue } from 'hooks';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useBoolean } from 'use-boolean';

import { Spacer } from 'components/Spacer';
import { Header } from 'components/collectible';
import { StepProps, DetailsStepValue, STEP_INVALID_VALUE } from 'components/collectible/steps/types';
import { CollectionPickButton, CollectionPickDialog } from 'components/collection';
import { FieldContainer, FieldError, FieldTitle } from 'components/field';
import { TextInput, TextareaInput, InputClearableFile } from 'components/input';

import { acceptedAudioTypes, acceptedImageTypes, acceptedVideoTypes } from 'constants/mimeTypes';

import { getCollections } from 'services/collection';

import { AppState } from 'state';

import css from './index.module.css';
import validationSchema from './validationSchema';

const onSubmit = () => {};

export const CollectibleDetailsEditor = ({ value, getValueRef }: StepProps<DetailsStepValue>) => {
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  const { values, errors, touched, setTouched, validateForm, handleSubmit, handleBlur, handleChange, setFieldValue } =
    useFormik<DetailsStepValue>({
      initialValues: value ?? {
        collection: '',
        name: '',
        description: '',
        quantity: '',
      },
      validateOnChange: true,
      validateOnBlur: true,
      validationSchema: validationSchema,
      onSubmit,
    });

  const SetFieldValue = useSetFieldValue(setFieldValue);

  useEffect(() => {
    getValueRef.current = async () => {
      const errors = await validateForm();

      if (Object.keys(errors).length > 0) {
        setTouched(setNestedObjectValues(errors, true));
        return STEP_INVALID_VALUE;
      }

      return values;
    };
  }, [getValueRef, setTouched, validateForm, values]);

  const collectionsReq = { creator: walletConnectV2.address };
  const { data: collectionsData } = useQuery(['getCollections', collectionsReq], () => getCollections(collectionsReq));
  const [isCollectionPickerOpen, showCollectionPicker, closeCollectionPicker] = useBoolean(false);

  return (
    <section className={css.wrapper}>
      <Header subtitle="Please choose a collection which will contain your NFT." />
      <Spacer height="24px" />
      <CollectionPickButton selected={values.collection} onClick={showCollectionPicker} />
      <FieldError>{touched.collection && errors.collection}</FieldError>
      <Spacer height="46px" />
      {isCollectionPickerOpen && (
        <CollectionPickDialog
          options={collectionsData?.collections}
          value={values.collection}
          setValue={SetFieldValue('collection')}
          onClose={closeCollectionPicker}
        />
      )}
      <Header subtitle="Please enter the details to describe your NFT, and upload one or more media files to visualize your NFT." />
      <Spacer height="20px" />
      <form onSubmit={handleSubmit}>
        <div className={css.container}>
          <div className={css.content}>
            <FieldContainer>
              <FieldTitle>Name</FieldTitle>
              <TextInput name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} />
              <FieldError>{touched.name && errors.name}</FieldError>
            </FieldContainer>
            <FieldContainer>
              <FieldTitle>Description</FieldTitle>
              <TextareaInput
                name="description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <FieldError>{touched.description && errors.description}</FieldError>
            </FieldContainer>
            {/*TODO: Remove the quantityBlock div */}
            <div className={css.quantityBlock}>
              <FieldContainer>
                <FieldTitle>Quantity</FieldTitle>
                <TextInput name="quantity" value={values.quantity} onChange={handleChange} />
                <FieldError>{touched.quantity && errors.quantity}</FieldError>
              </FieldContainer>
            </div>
          </div>
          <div className={css.content}>
            <FieldContainer>
              <FieldTitle>Image</FieldTitle>
              <InputClearableFile
                name="imgUrl"
                value={values.imgUrl}
                accept={acceptedImageTypes}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
              />
              <FieldError>{touched.imgUrl && errors.imgUrl}</FieldError>
            </FieldContainer>
            <FieldContainer>
              <FieldTitle>Video</FieldTitle>
              <InputClearableFile
                name="videoUrl"
                value={values.videoUrl}
                accept={acceptedVideoTypes}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
              />
              <FieldError>{touched.videoUrl && errors.videoUrl}</FieldError>
            </FieldContainer>
            <FieldContainer>
              <FieldTitle>Audio</FieldTitle>
              <InputClearableFile
                name="audioUrl"
                value={values.audioUrl}
                accept={acceptedAudioTypes}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
              />
              <FieldError>{touched.audioUrl && errors.audioUrl}</FieldError>
            </FieldContainer>
            <FieldError>{errors['noMediaError' as 'imgUrl']}</FieldError>
          </div>
        </div>
      </form>
    </section>
  );
};
