import { Support } from 'assets/icons/Support';

import FaqItem from 'components/FaqItem';
import { Spacer } from 'components/Spacer';
import { PageArea, PageHeader } from 'components/page';

import css from './index.module.css';

const Faq = () => {
  return (
    <PageArea>
      <PageHeader title="FAQ"></PageHeader>
      <div className={css.content}>
        <ul className={css.list}>
          {process.env.REACT_APP_DOMAIN === 'ndau' && (
            <li className={css.item}>
              <a target="_blank" href="https://ndau.io/ndau-nft-forge" rel="noreferrer">
                <b>Getting started with the ndau NFT Forge</b>
              </a>
            </li>
          )}

          <FaqItem title="What are non-fungible tokens (NFTs)?">
            <p>
              Non-fungible tokens, often referred to as NFTs, are blockchain-based tokens that each represent a unique
              asset like a piece of art, digital content, or media. An NFT can be thought of as an irrevocable digital
              certificate of ownership and authenticity for a given asset, whether digital or physical.
            </p>
            <p>
              NFTs can be created to represent virtually any asset, whether physical, digital or metaphysical. However,
              the most common NFT assets are digital art, digital collectible items, pieces of content like video or
              audio, and event tickets.
            </p>
            <p>
              Non-fungible tokens (NFTs) are designed to be 1) cryptographically verifiable, 2) unique or scarce, and 3)
              easily transferable.
            </p>
            <p>
              By using the cryptographic signatures native to the blockchain on which an NFT is issued, one can easily
              determine the origin and the current owner of the asset in seconds.
            </p>
          </FaqItem>
          <FaqItem title="What is a smart contract?">
            <p>
              Smart contracts are simple programs stored on a blockchain that run when predetermined conditions are met.
              They typically are used to automate the execution of an agreement so that all participants can be
              immediately certain of the outcome, without any intermediary’s involvement or time loss.
            </p>
            <p>
              Smart contracts are the primary means by which developers can create and manage NFTs on a blockchain.
              Smart contracts track who owns which NFT, and what needs to happen when that ownership changes (for
              example, what percent of the sale needs to be paid to the creator as a royalty).
            </p>
          </FaqItem>
          <FaqItem title="How to Mint?">
            <p>
              <b>How to Create a Collection</b>
            </p>
            <p>
              <b>1.</b> Press “Create” and select “Collection” from the drop down
            </p>
            <p>
              <b>2.</b> Upload an Image for your collection, Name your Collection, and enter a Description for your NFT
              Collection
            </p>
            <p>
              <b>3.</b> Add any Royalty Holders from your Partner’s List to the collection. These royalty holders will
              receive a percentage from the secondary sales of the NFTs in this collection
            </p>
            <p>
              <b>4.</b> After you review the details for your collection, press “Create”
            </p>
            <p>
              <b>5.</b> In the modal, select the speed for your transaction, and press “Create” to sign and approve the
              creation of the NFT in your Beasy Wallet
            </p>
            <p>
              <b>6.</b> After a few moments, your NFT Collection will be created. If you don’t have enough funds in your
              Beasy Wallet, you will need to go to an exchange that is connected to Polygon to purchase Matic
            </p>

            <p>
              <b>How to Create an NFT</b>
            </p>
            <p>
              <b>1.</b> Press “Create” and select “Single NFT” from the drop down
            </p>
            <p>
              <b>2.</b> Pick a collection to add this NFT too. If you haven’t created a collection, you will need to do
              so in order to create your NFT.
            </p>
            <p>
              <b>3.</b> You will need to enter the following information for your NFT:
            </p>
            <p>
              <Spacer width="24px"></Spacer>
              <b>a.</b> Enter a Name
            </p>
            <p>
              <Spacer width="24px"></Spacer>
              <b>b.</b> Enter a Description
            </p>
            <p>
              <Spacer width="24px"></Spacer>
              <b>c.</b> Enter a number of editions you want to create
            </p>
            <p>
              <Spacer width="24px"></Spacer>
              <b>d.</b> Upload any media files (png, jpeg, gif, mp4, mp3, or wav) to represent your NFT visually
            </p>
            <p>
              <Spacer width="40px"></Spacer>
              <b>i.</b> The Beasy Forge accepts this list of media files for our fields:
            </p>
            <p>
              <Spacer width="60px"></Spacer>
              <b>1.</b> Image - png, jpeg, and gif
            </p>
            <p>
              <Spacer width="60px"></Spacer>
              <b>2.</b> Video - mp4
            </p>
            <p>
              <Spacer width="60px"></Spacer>
              <b>3.</b> Sound - mp3 and wav
            </p>
            <p>
              <b>4.</b> Press “Continue” to navigate to the Terms and Conditions page
            </p>
            <p>
              <b>5.</b> Upload any contractual Ownership and/or License agreements to enforce any IP and license rights
              for your IP. After uploading any contractual agreement documents press “Continue” to navigate to the
              Contributors page
            </p>
            <p>
              <b>6.</b> Add any Contributors from your Partner’s list onto the NFT to share in primary market revenue.
              After adding all the necessary contributors for your NFT, press “Continue”
            </p>
            <p>
              <b>7.</b> After you review the details for your collection, press “Create”
            </p>
            <p>
              <b>8.</b> In the modal, select the speed for your transaction, and press “Create” to sign and approve the
              creation of the NFT in your Beasy Wallet
            </p>
            <p>
              <b>9.</b> After a few moments, your NFT will be created. If you don’t have enough funds in your Beasy
              Wallet, you will need to go to an exchange that is connected to Polygon to purchase Matic.
            </p>
          </FaqItem>
          <FaqItem title="How is an NFT created?">
            <p>
              An NFT is created by an artist, creator, or license-holder through a process called minting. Minting is a
              process that involves signing a blockchain transaction that outlines the fundamental token details, which
              is then broadcasted to the blockchain to trigger a smart contract function which creates the token and
              assigns it to its owner.
            </p>
            <p>
              Under the hood, an NFT consists of a unique token identifier, or token ID, which is mapped to an owner
              identifier and stored inside a smart contract. When the owner of a given token ID wishes to transfer it to
              another user, it is easy to verify ownership and reassign the token to a new owner.
            </p>
          </FaqItem>
          <FaqItem title="Tell me more about specific NFT use cases">
            <p>
              <b>NFT event tickets</b> — companies can distribute and sell tickets to events using NFTs, reducing
              friction for verification of ownership and authenticity and helping to eliminate fraud. Furthermore, there
              are infinite possibilities for post-purchase collectability of tickets through exclusive experiences and
              digital art.
            </p>
            <p>
              <b>Fan/customer engagement</b> - brands or organizations can issue or sell NFTs that represent exclusive
              collectibles, products, experiences, or voting rights for the future development of a product or service
              in order to deepen the engagement customers / fans have with the brand/organization.
            </p>
            <p>
              <b>In-game items </b> - video games are walled gardens today, players do not own their digital items and
              secondary markets are hard to implement. NFTs can be used to create a widely varied ecosystem of in game
              digital items that can be bought sold and exchanged on open secondary markets and used across a broader
              gaming ecosystem rather than anchored to one game.
            </p>
            <p>
              <b>Digital collectibles</b> - organizations or individuals who have a well-defined brand can create NFTs
              that can be sold on the open market to fans or brand-loyal customers as collectibles. Think of a company
              like Disney that has huge brands of licensed universes like Star Wars and Marvel.
            </p>
            <p>
              <b>Credentialing</b> - identity credentials like driver’s licenses or professional certifications like
              AWS’ wide range of cloud certificates can be issued as NFTs to reduce the burden of proof for these
              credentials and eliminate the siloed nature of credentials today
            </p>
            <p>
              <b>Royalties</b> - NFTs can track fractional ownership or royalty entitlement for a piece of media or
              content or art.
            </p>
          </FaqItem>
          <FaqItem title="What challenges exist for the widespread adoption of NFTs?">
            <p>
              There are various challenges and risks that may affect the adoption of NFTs. Here are some of the biggest
              ones:
            </p>
            <p>
              <b> 1. Complexity:</b> The technology and tooling behind non-fungible tokens and the decentralized
              applications (dapps) that underpin them are still nascent despite the increasing adoption amongst startups
              and enterprises alike; Many of the complexities associated with building NFT-related solutions are not yet
              abstracted by quality tooling.
            </p>
            <p>
              <b>2. Regulatory/Legal Implications:</b> With the introduction of new and innovative technologies,
              particularly ones that involve speculative or high-value assets, come distinct regulatory and legal
              considerations including but not limited to know your customer procedures, anti money laundering
              mechanisms, and securities law compliance.
            </p>
            <p>
              <b>3. Rapid Innovation:</b> The rapid pace of innovation in the NFT ecosystem and the blockchain networks
              on which they are issued present challenges for those adopting the technology in the form of consistent
              change; agility and modularity are critical.
            </p>
            <p>
              <b>4. Concerns Regarding Ecological Impact:</b> Controversy continues in regard to the impact that
              energy-intensive blockchain networks that utilize the Proof-of-Work consensus mechanism have on climate
              change, and NFT-focused products have been a target for such criticism. However, solutions already exist
              to ameliorate this concern, such as the adoption of less energy-intensive consensus mechanisms and the use
              of “Layer 2” or L2 networks where transactions that mint NFTs can be validated more rapidly and
              efficiently outside of the main blockchain network. For example, the Ethereum blockchain network is well
              on its way to shifting towards the more energy-efficient Proof-of-Stake consensus mechanism in its
              Ethereum 2.0 launch, and Layer 2 solutions like Polygon and ImmutableX are already helping reduce the load
              today.
            </p>
          </FaqItem>
        </ul>
        <div className={css.support}>
          <Support></Support>
          <div className={css.info}>
            <p>If you have additional questions, you can contact us:</p>
            {(email => (
              <p>
                <a href={'mailto:' + email}>{email}</a>
              </p>
            ))(process.env.REACT_APP_DOMAIN === 'ndau' ? 'support@oneiro.freshdesk.com' : 'beasysupport@easybeasy.com')}
          </div>
        </div>
      </div>
    </PageArea>
  );
};

export default Faq;
