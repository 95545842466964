// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ListItem_wrapper__ae-Pn {
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 360px;
  height: 330px;

  padding: 16px;
  border-radius: 8px;
  background: var(--alt-bg-color);
}

.ListItem_wrapper__ae-Pn > h4 {
  white-space: nowrap;
  overflow-x: auto;
}

.ListItem_collection__ZivdF {
  display: flex;
  gap: 16px;

  --height: 128px;
}

.ListItem_collection__ZivdF > figure {
  width: var(--height);
  height: var(--height);
  border-radius: 4px;
}

.ListItem_collection__ZivdF > p {
  height: var(--height);
  overflow-y: auto;
}

.ListItem_collectibles__hnCLG {
  display: flex;
  gap: 8px;
  --size: 48px;
  min-height: var(--size);
}

.ListItem_collectibles__hnCLG > figure {
  width: var(--size);
  height: var(--size);
  border-radius: 4px;
}

.ListItem_collectibles__hnCLG > figure.ListItem_counter__3ZYAV {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-weight: 600;
  color: var(--grey-40);
  background-color: var(--grey-80);
}

.ListItem_button__WXI0N:hover {
  background-color: var(--accent-blue);
}`, "",{"version":3,"sources":["webpack://./src/components/collection/ListItem/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;;EAET,YAAY;EACZ,aAAa;;EAEb,aAAa;EACb,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;;EAET,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;;EAEnB,eAAe;EACf,gBAAgB;EAChB,qBAAqB;EACrB,gCAAgC;AAClC;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n\n  width: 360px;\n  height: 330px;\n\n  padding: 16px;\n  border-radius: 8px;\n  background: var(--alt-bg-color);\n}\n\n.wrapper > h4 {\n  white-space: nowrap;\n  overflow-x: auto;\n}\n\n.collection {\n  display: flex;\n  gap: 16px;\n\n  --height: 128px;\n}\n\n.collection > figure {\n  width: var(--height);\n  height: var(--height);\n  border-radius: 4px;\n}\n\n.collection > p {\n  height: var(--height);\n  overflow-y: auto;\n}\n\n.collectibles {\n  display: flex;\n  gap: 8px;\n  --size: 48px;\n  min-height: var(--size);\n}\n\n.collectibles > figure {\n  width: var(--size);\n  height: var(--size);\n  border-radius: 4px;\n}\n\n.collectibles > figure.counter {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  font-size: 16px;\n  font-weight: 600;\n  color: var(--grey-40);\n  background-color: var(--grey-80);\n}\n\n.button:hover {\n  background-color: var(--accent-blue);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ListItem_wrapper__ae-Pn`,
	"collection": `ListItem_collection__ZivdF`,
	"collectibles": `ListItem_collectibles__hnCLG`,
	"counter": `ListItem_counter__3ZYAV`,
	"button": `ListItem_button__WXI0N`
};
export default ___CSS_LOADER_EXPORT___;
