import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useBoolean } from 'use-boolean';

import { ConfirmExit, CreateCollectibleModals, Header, NavButtons } from 'components/collectible';
import { steps } from 'components/collectible/steps';
import { CombinedStepValues, StepIndex, GetStepValueRef, STEP_INVALID_VALUE } from 'components/collectible/steps/types';
import { PageArea } from 'components/page';
import { PageTabMenu } from 'components/page';
import { TransactionPickSpeed } from 'components/transaction';

import { useAsyncMemo } from 'hooks/useAsyncMemo';

import { PopulateTransactionReq } from 'protobuf/lib/transactionMessage';

import { CheckAvailability } from 'services/metaTransactions';

import css from './index.module.css';
import useCreateCollectible, { TransactionType } from './useCreateCollectible';

const firstStepIndex = process.env.REACT_APP_DOMAIN === 'ndau' ? StepIndex.TYPE : StepIndex.DETAILS;
const lastStepIndex = StepIndex.REVIEW;

const items = [
  ...(process.env.REACT_APP_DOMAIN === 'ndau' ? [{ value: StepIndex.TYPE, label: 'Information' }] : []),
  { value: StepIndex.DETAILS, label: 'NFT Details' },
  { value: StepIndex.ATTRIBUTES, label: 'Special Attributes' },
  { value: StepIndex.TERMS, label: 'Terms & Conditions' },
  { value: StepIndex.CONTRIBUTORS, label: 'Contributors' },
  { value: StepIndex.REVIEW, label: 'Review' },
];

export const CollectibleCreateRoute = () => {
  const history = useHistory();
  const createCollectibleController = useCreateCollectible();
  //const [checkMetaTx, setCheckMetaTx] = useState(false);
  //const [metaVerified, setVerified] = useState(false);

  const [isConfirmExit, setConfirmExit] = useState(false);

  const [currentStepIndex, setCurrentItem] = useState(firstStepIndex);
  const [maxAvailableStep, setMaxAvailableItem] = useState(firstStepIndex);

  const updateMaxAvailableItem = useCallback(
    (item: StepIndex) => {
      if (item > maxAvailableStep) {
        setMaxAvailableItem(item);
      }
    },

    [maxAvailableStep, setMaxAvailableItem],
  );

  /*
  const childToParent = (childdata: any) => {
    if (childdata === 2) {
      setData(2);
    } else {
      setData(1);
    }
  };
*/

  const [combinedValues, setCombinedValues] = useState<CombinedStepValues>({});

  const getValueRef: GetStepValueRef<any> = useRef(); // todo any

  const handleNavigate = useCallback(
    async (item: StepIndex) => {
      const getTabValue = getValueRef.current; // each tab must set it

      if (getTabValue) {
        const value = await getTabValue();

        if (value === STEP_INVALID_VALUE) return; // block navigation

        setCombinedValues({ ...combinedValues, [currentStepIndex]: value });
        setCurrentItem(item);
        updateMaxAvailableItem(item);
      }
    },
    [updateMaxAvailableItem, combinedValues, currentStepIndex],
  );

  const handleNavigatePrev = useCallback(
    () => setCurrentItem(currentStepIndex - 1),
    [currentStepIndex, setCurrentItem],
  );
  const handleNavigateNext = useCallback(
    () => handleNavigate(currentStepIndex + 1),
    [currentStepIndex, handleNavigate],
  );

  const isLastStep = currentStepIndex === lastStepIndex;

  const transaction = useAsyncMemo(
    () => {
      if (isLastStep) {
        return createCollectibleController.getTransaction(combinedValues);
      }
    },
    [isLastStep, combinedValues], // todo createCollectibleController],
    undefined,
  );

  const [isSpeedPickerOpen, showSpeedPicker, closeSpeedPicker] = useBoolean(false);

  const handleSubmit = useCallback(
    (transaction: PopulateTransactionReq) => {
      closeSpeedPicker();
      createCollectibleController.handleTransaction(transaction, combinedValues[StepIndex.DETAILS]!.name, {
        data: 1,
      } as TransactionType);
    },
    [closeSpeedPicker, combinedValues, createCollectibleController],
  );

  const TabComponent = steps[currentStepIndex];

  return (
    <>
      <PageArea className={css.container}>
        <Header title="Create New Non-Fungible Token" />
        <div className={css.header}>
          <PageTabMenu
            items={items}
            currentItem={currentStepIndex}
            maxAvailableItem={maxAvailableStep}
            onNavigate={handleNavigate}
            className={css.tabMenu}
          />
        </div>
        <main className={css.content}>
          <div className={css.formContainer}>
            <TabComponent
              value={combinedValues[currentStepIndex]}
              getValueRef={getValueRef}
              combinedValues={combinedValues}
              transaction={transaction}
            />
          </div>
          <NavButtons
            onClickPrevious={currentStepIndex > firstStepIndex ? handleNavigatePrev : undefined}
            onClickContinue={currentStepIndex < lastStepIndex ? handleNavigateNext : showSpeedPicker}
            onClickCancel={() => setConfirmExit(true)}
            isLast={isLastStep}
            isLastDisabled={isLastStep && !transaction}
          />
        </main>
      </PageArea>

      {isConfirmExit && (
        <ConfirmExit
          onNo={() => setConfirmExit(false)}
          onYes={() => {
            history.push('/home/dashboard');
          }}
        />
      )}

      <CreateCollectibleModals controller={createCollectibleController} />

      {transaction && isSpeedPickerOpen && (
        <TransactionPickSpeed transaction={transaction} onCancel={closeSpeedPicker} onSubmit={handleSubmit} />
      )}
    </>
  );
};
