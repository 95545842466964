// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ClaimRoyaltyItem_item__zg9rj {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: flex-end;
  min-width: 380px;
}

.ClaimRoyaltyItem_item__zg9rj > p {
  min-width: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/components/collection/ClaimRoyaltyItem/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".item {\n  display: flex;\n  padding: 20px;\n  align-items: center;\n  justify-content: flex-end;\n  min-width: 380px;\n}\n\n.item > p {\n  min-width: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `ClaimRoyaltyItem_item__zg9rj`
};
export default ___CSS_LOADER_EXPORT___;
