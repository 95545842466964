import { useFormik } from 'formik';

import { ErrorMessage, ErrorMessageWithRetryButton } from 'components/ErrorMessage';
import { Spacer } from 'components/Spacer';
import { ButtonPrimary, ButtonSecondary } from 'components/button';
import { DialogArea, DialogClose, DialogFooter, DialogHeader, DialogOverlay } from 'components/dialog';
import { TextInput } from 'components/input';

import { capitalizeFirst } from 'constants/helper';

import { Frontstore } from 'protobuf/lib/frontstore';

import { FrontstoreAlreadyExistsError } from 'services/frontstore';

import css from './index.module.css';
import validationSchema from './validationSchema';

export interface FrontstoreEditModalFormData {
  fid: string;
  fpwd: string;
  fhost: string;
}

interface Props {
  frontstore: Frontstore;
  isError: boolean;
  isLoading: boolean;
  onSubmit: (data: FrontstoreEditModalFormData) => void;
  onClose: () => void;
  // Optional:
  error?: Error;
}

const FrontstoreEditModal = ({ error, frontstore, isError, isLoading, onSubmit, onClose }: Props) => {
  const formik = useFormik({
    initialValues: {
      fid: frontstore.fid,
      fpwd: frontstore.fpwd,
      fhost: frontstore.fhost,
    },
    onSubmit,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
  });

  return (
    <DialogOverlay>
      <DialogArea>
        <DialogHeader>
          <h3>{capitalizeFirst(frontstore.type)}</h3>
          <DialogClose onClick={onClose} />
        </DialogHeader>
        <form className={css.wrapper} onSubmit={formik.handleSubmit}>
          <label className={css.field}>
            <span className={css.label}>ID</span>
            <TextInput name="fid" value={formik.values.fid} onChange={formik.handleChange} onBlur={formik.handleBlur} />
            {formik.errors.fid && formik.touched.fid && <span>{formik.errors.fid}</span>}
          </label>

          <label className={css.field}>
            <span className={css.label}>Password</span>
            <TextInput
              name="fpwd"
              value={formik.values.fpwd}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </label>

          <label className={css.field}>
            <span className={css.label}>Store host</span>
            <TextInput
              name="fhost"
              value={formik.values.fhost}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </label>

          {isError && (
            <div className={css.error}>
              {error instanceof FrontstoreAlreadyExistsError ? (
                <ErrorMessage>
                  Frontstore <b>{error.fid}</b> already exists.
                </ErrorMessage>
              ) : (
                <ErrorMessageWithRetryButton
                  message="Could not update store"
                  isButtonDisabled={isLoading}
                  buttonType="submit"
                />
              )}
            </div>
          )}

          <Spacer height="32px" />

          <DialogFooter>
            <ButtonSecondary onClick={onClose} disabled={isLoading}>
              Cancel
            </ButtonSecondary>
            <ButtonPrimary disabled={isLoading} type="submit">
              {isLoading ? 'Saving...' : 'Save'}
            </ButtonPrimary>
          </DialogFooter>
        </form>
      </DialogArea>
    </DialogOverlay>
  );
};

export default FrontstoreEditModal;
