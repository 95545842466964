// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AcceptAgreement_wrapper__9RP8W {
  display: flex;
  flex-direction: column;
  margin: 50px 32px 0 32px;
  width: 650px;
  height: 650px;
  background-color: var(--alt-bg-color);
  border-radius: 8px;
  padding: 24px;
}

.AcceptAgreement_license__G3jBb {
  overflow: overlay;
  margin: 24px;
  color: var(--alt10-color);
}


.AcceptAgreement_header__5GCci {
  display: flex;
  margin-left: 24px;
}

.AcceptAgreement_footer__Re\\+Oj {
  margin-bottom: 30px;
}

.AcceptAgreement_footer__Re\\+Oj > span {
  display: flex;
  margin-left: 24px;
  margin-top: 8px;
  font-size: 12px;
}

.AcceptAgreement_input__X\\+XiS {
  display: flex;
  margin-left: 24px;
}

.AcceptAgreement_input__X\\+XiS > input {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.AcceptAgreement_input__X\\+XiS > p {
  color: var(--text-color-accent);
}
`, "",{"version":3,"sources":["webpack://./src/screens/home/AcceptAgreement/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,wBAAwB;EACxB,YAAY;EACZ,aAAa;EACb,qCAAqC;EACrC,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,yBAAyB;AAC3B;;;AAGA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  margin: 50px 32px 0 32px;\n  width: 650px;\n  height: 650px;\n  background-color: var(--alt-bg-color);\n  border-radius: 8px;\n  padding: 24px;\n}\n\n.license {\n  overflow: overlay;\n  margin: 24px;\n  color: var(--alt10-color);\n}\n\n\n.header {\n  display: flex;\n  margin-left: 24px;\n}\n\n.footer {\n  margin-bottom: 30px;\n}\n\n.footer > span {\n  display: flex;\n  margin-left: 24px;\n  margin-top: 8px;\n  font-size: 12px;\n}\n\n.input {\n  display: flex;\n  margin-left: 24px;\n}\n\n.input > input {\n  width: 20px;\n  height: 20px;\n  margin-right: 8px;\n}\n\n.input > p {\n  color: var(--text-color-accent);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `AcceptAgreement_wrapper__9RP8W`,
	"license": `AcceptAgreement_license__G3jBb`,
	"header": `AcceptAgreement_header__5GCci`,
	"footer": `AcceptAgreement_footer__Re+Oj`,
	"input": `AcceptAgreement_input__X+XiS`
};
export default ___CSS_LOADER_EXPORT___;
