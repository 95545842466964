// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Shareholder_wrapper__jYNa5 {
  display: flex;
  gap: 8px;
  align-items: center;
}

.Shareholder_wrapper__jYNa5 > figure {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.Shareholder_wrapper__jYNa5 > div {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.Shareholder_wrapper__jYNa5 > div > span:first-child {
  font-weight: 400;
  font-size: 14px;
  color: var(--text-color-accent);

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Shareholder_wrapper__jYNa5 > div > span:last-child {
  font-weight: 400;
  font-size: 12px;
  color: var(--alt10-color);

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
`, "",{"version":3,"sources":["webpack://./src/components/user/Shareholder/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,+BAA+B;;EAE/B,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,yBAAyB;;EAEzB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".wrapper {\n  display: flex;\n  gap: 8px;\n  align-items: center;\n}\n\n.wrapper > figure {\n  width: 36px;\n  height: 36px;\n  border-radius: 50%;\n}\n\n.wrapper > div {\n  display: flex;\n  flex-direction: column;\n  gap: 2px;\n}\n\n.wrapper > div > span:first-child {\n  font-weight: 400;\n  font-size: 14px;\n  color: var(--text-color-accent);\n\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.wrapper > div > span:last-child {\n  font-weight: 400;\n  font-size: 12px;\n  color: var(--alt10-color);\n\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Shareholder_wrapper__jYNa5`
};
export default ___CSS_LOADER_EXPORT___;
