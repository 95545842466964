// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VR_wrapper__YP52R {
  width: 1px;
  height: 100%;
  background-color: var(--grey-85);
}
`, "",{"version":3,"sources":["webpack://./src/components/layout/VR/index.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,gCAAgC;AAClC","sourcesContent":[".wrapper {\n  width: 1px;\n  height: 100%;\n  background-color: var(--grey-85);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `VR_wrapper__YP52R`
};
export default ___CSS_LOADER_EXPORT___;
