import { captureException } from '@sentry/react';
import { useWalletConnectClient } from 'context/ClientContext';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useBoolean } from 'use-boolean';

import { Spacer } from 'components/Spacer';
import { ButtonPrimary, ButtonUnderline } from 'components/button';

import { useMount } from 'hooks/mount';

import { getApplicationInfo, ApplicationInfo, applicationToken } from 'services/applicationAuthService';

import { AppState } from 'state';

import { environment } from 'environment';

import InvalidNetworkModal from './InvalidNetworkModal';
import css from './index.module.css';

function Login() {
  const history = useHistory();
  const { t } = useTranslation();
  const [applicationInfo, setApplicationInfo] = useState<ApplicationInfo | null>(null);
  const [isInvalidNetworkModalOpen, showInvalidNetworkModal, closeInvalidNetworkModal] = useBoolean(false);
  const { handleConnect } = useWalletConnectClient();
  const [logo2x, setLogo2x] = useState(null);
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  useEffect(() => {
    const loadLogo = async () => {
      const logoModule = await import(`assets/images/${process.env.REACT_APP_DOMAIN}/logo-2x.png`);
      setLogo2x(logoModule.default);
    };
    loadLogo();
  }, []);

  useEffect(() => {

    console.log("WALLET CONNECT: ",walletConnectV2)
    if (walletConnectV2.address !== undefined) {
      console.log("WALLET CONNECT: ",walletConnectV2)
      walletConnectV2.isCreator ? history.replace('/home') : history.replace('/registration');
    }
  }, [walletConnectV2.address, walletConnectV2.isCreator, walletConnectV2.session]);

  useMount(() => {
    if (!applicationToken) {
      return;
    }

    const getApplication = async () => {
      const application = await getApplicationInfo(applicationToken!);

      if (application) {
        setApplicationInfo(application);
      }
    };

    getApplication().catch(captureException);
  });

  return (
    <div className={css.content}>
      {logo2x && (
        <img
          className={css.logo}
          src={logo2x}
          alt="logo"
          style={{
            width: {
              beasy: 208,
              ndau: 350,
              rag: 208,
              obsesh: 208,
            }[process.env.REACT_APP_DOMAIN],
          }}
        />
      )}
      <h1>{t('login.header')}</h1>
      <p>{t('login.text')}</p>
      <ButtonPrimary onClick={handleConnect}>{t('login.button')}</ButtonPrimary>
      <Spacer height="50px" />
      {process.env.REACT_APP_DOMAIN === 'ndau' ? (
        <div className={css.buttons}>
          <ButtonUnderline>
            <a target="_blank" href="https://ndau.io/nft" rel="noreferrer">
              {t('login.ndau')}
            </a>
          </ButtonUnderline>
        </div>
      ) : (
        <ButtonUnderline>
          <a target="_blank" href={environment.walletUrl} rel="noreferrer">
            {t('login.access')}
          </a>
        </ButtonUnderline>
      )}
      {isInvalidNetworkModalOpen && <InvalidNetworkModal onOk={closeInvalidNetworkModal} />}
    </div>
  );
}

export default Login;
