// package: 
// file: subscriber.proto

var subscriber_pb = require("./subscriber_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var SubscriberService = (function () {
  function SubscriberService() {}
  SubscriberService.serviceName = "SubscriberService";
  return SubscriberService;
}());

SubscriberService.SubscribeOnNotifications = {
  methodName: "SubscribeOnNotifications",
  service: SubscriberService,
  requestStream: true,
  responseStream: true,
  requestType: subscriber_pb.NotificationIn,
  responseType: subscriber_pb.NotificationOut
};

SubscriberService.SubscribeOnTradingEvents = {
  methodName: "SubscribeOnTradingEvents",
  service: SubscriberService,
  requestStream: true,
  responseStream: true,
  requestType: subscriber_pb.TradingEventIn,
  responseType: subscriber_pb.TradingEventOut
};

exports.SubscriberService = SubscriberService;

function SubscriberServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

SubscriberServiceClient.prototype.subscribeOnNotifications = function subscribeOnNotifications(metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.client(SubscriberService.SubscribeOnNotifications, {
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport
  });
  client.onEnd(function (status, statusMessage, trailers) {
    listeners.status.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners.end.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners = null;
  });
  client.onMessage(function (message) {
    listeners.data.forEach(function (handler) {
      handler(message);
    })
  });
  client.start(metadata);
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    write: function (requestMessage) {
      client.send(requestMessage);
      return this;
    },
    end: function () {
      client.finishSend();
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

SubscriberServiceClient.prototype.subscribeOnTradingEvents = function subscribeOnTradingEvents(metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.client(SubscriberService.SubscribeOnTradingEvents, {
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport
  });
  client.onEnd(function (status, statusMessage, trailers) {
    listeners.status.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners.end.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners = null;
  });
  client.onMessage(function (message) {
    listeners.data.forEach(function (handler) {
      handler(message);
    })
  });
  client.start(metadata);
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    write: function (requestMessage) {
      client.send(requestMessage);
      return this;
    },
    end: function () {
      client.finishSend();
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

exports.SubscriberServiceClient = SubscriberServiceClient;

