// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_content__PABIG {
  background-color: var(--body-bg-color);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Login_content__PABIG img {
  margin-bottom: 56px;
}

.Login_content__PABIG h1 {
  font-weight: normal;
  font-size: 36px;
  line-height: 44px;
  color: var(--body-color);
  margin-bottom: 16px;
}

.Login_content__PABIG p {
  width: 370px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--text-color);
  margin-bottom: 24px;
}

.Login_error__wMq-L {
  margin-bottom: 50px !important;
}

.Login_logo__OfaB4 {
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}

.Login_buttons__3NVuz {
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Login/index.module.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,wBAAwB;EACxB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,wBAAwB;EACxB,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,iCAAiC,EAAE,YAAY;EAC/C,+BAA+B,EAAE,UAAU;EAC3C,0CAA0C,EAAE,iCAAiC;EAC7E,4BAA4B;EAC5B,wCAAwC,EAAE,+BAA+B;AAC3E;;AAEA;EACE,aAAa;AACf","sourcesContent":[".content {\n  background-color: var(--body-bg-color);\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.content img {\n  margin-bottom: 56px;\n}\n\n.content h1 {\n  font-weight: normal;\n  font-size: 36px;\n  line-height: 44px;\n  color: var(--body-color);\n  margin-bottom: 16px;\n}\n\n.content p {\n  width: 370px;\n  font-size: 16px;\n  line-height: 24px;\n  text-align: center;\n  color: var(--text-color);\n  margin-bottom: 24px;\n}\n\n.error {\n  margin-bottom: 50px !important;\n}\n\n.logo {\n  image-rendering: -moz-crisp-edges; /* Firefox */\n  image-rendering: -o-crisp-edges; /* Opera */\n  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */\n  image-rendering: crisp-edges;\n  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */\n}\n\n.buttons {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `Login_content__PABIG`,
	"error": `Login_error__wMq-L`,
	"logo": `Login_logo__OfaB4`,
	"buttons": `Login_buttons__3NVuz`
};
export default ___CSS_LOADER_EXPORT___;
