import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';

import { Spacer } from 'components/Spacer';

import { useRole } from 'hooks/useRole';

import { RoyaltyHolder } from 'protobuf/lib/royalty';

import { getUserProfile } from 'services/userProfile';

import { UserAvatar } from '../Avatar';
import css from './index.module.css';

export const ShareHolder = ({ address, share }: Partial<RoyaltyHolder>) => {
  const { data: profile } = useQuery(['getUserProfile', address], () => getUserProfile(address!), {
    enabled: Boolean(address),
  });
  const { getRoleNameByNetwork } = useRole();

  return (
    <div className={css.wrapper}>
      <figure>
        <UserAvatar cid={profile?.avatarCid} />
      </figure>
      <div>
        <span>{profile ? profile.nickname || '-' : <Skeleton width={60} />}</span>
        <span>
          {profile ? getRoleNameByNetwork(profile.roles) || '-' : <Skeleton width={40} />}
          <Spacer width="10px" />
          {share && `${Number(share)}%`}
        </span>
      </div>
    </div>
  );
};
