import clsx from 'clsx';
import { ReactNode } from 'react';

import { DialogArea, DialogClose, DialogHeader, DialogOverlay } from 'components/dialog';

import css from './index.module.css';

interface Props {
  title?: ReactNode;
  onClose?: () => void;
  children?: ReactNode;
  className?: string;
}

export const DialogPicker1 = ({ title, onClose, children, className }: Props) => {
  return (
    <DialogOverlay>
      <DialogArea className={clsx(css.area, className)}>
        <DialogHeader>
          {typeof title === 'string' ? <h3>{title}</h3> : title}
          {onClose && <DialogClose onClick={onClose} />}
        </DialogHeader>
        {children}
      </DialogArea>
    </DialogOverlay>
  );
};
