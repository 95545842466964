// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Download_ellipse__0UNJH {
  stroke: #707070;
  fill: none;
}

.Download_icon__x3djb path {
  fill: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/assets/icons/Download/index.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".ellipse {\n  stroke: #707070;\n  fill: none;\n}\n\n.icon path {\n  fill: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ellipse": `Download_ellipse__0UNJH`,
	"icon": `Download_icon__x3djb`
};
export default ___CSS_LOADER_EXPORT___;
