// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Agreement_data__xkjZZ > table {
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
}

.Agreement_body__x\\+5F6 {
  overflow: scroll;
  padding-right: 32px;
}`, "",{"version":3,"sources":["webpack://./src/screens/Agreement/index.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".data > table {\n  table-layout: fixed;\n  width: 100%;\n  border-spacing: 0;\n}\n\n.body {\n  overflow: scroll;\n  padding-right: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"data": `Agreement_data__xkjZZ`,
	"body": `Agreement_body__x+5F6`
};
export default ___CSS_LOADER_EXPORT___;
