import clsx from 'clsx';
import { useCallback, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { ChevronIcon } from 'assets/icons';

import { ButtonGhost2 } from 'components/button';
import { LayoutMenu } from 'components/layout';

import styles from './index.module.css';

export interface DropdownOption<T> {
  label: string;
  value: T;
}

export interface DropdownProps<T extends number | string> {
  value: string;
  onChange: any;
  options: DropdownOption<T>[];
}

export const FilterDropdown = <T extends number | string>(props: DropdownProps<T>) => {
  const { value, onChange, options } = props;
  const [open, setOpen] = useState(false);

  function handleOnClick(item: DropdownOption<T>) {
    if (value !== item.value) {
      onChange(item.value);
    }
    setOpen(false);
  }

  const toggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleOutsideClick = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <div className={styles.dropDownwrap}>
        <div tabIndex={0} role="button" onKeyPress={toggle} onClick={toggle}>
          <div className={styles.dropDownbttn}>
            <p>{options.filter(option => option.value === value).map(option => option.label)}</p>
            <ChevronIcon className={clsx(styles.dropDownbttnArrow, open ? styles.dropDownbttnArrowRotated : '')} />
          </div>
        </div>
        {open && (
          <LayoutMenu className={styles.wrapper}>
            {options.map((item: DropdownOption<T>, index: number) => (
              <ButtonGhost2 onClick={() => handleOnClick(item)}>{item.label}</ButtonGhost2>
            ))}
          </LayoutMenu>
        )}
      </div>
    </OutsideClickHandler>
  );
};
