import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { HelpIcon } from 'assets/icons';

import PeriodSelector from 'components/Chart/PeriodSelector';
import PriceLineChart from 'components/Chart/PriceLine';
import { ErrorMessageQueryLoading } from 'components/ErrorMessage';
import ItemList from 'components/ItemList';
import { Tooltip } from 'components/Tooltip';
import { ButtonOutline } from 'components/button';
import { FilterDropdown } from 'components/input';
import { EmptyList } from 'components/layout';
import { PageHeader, PageArea } from 'components/page';

import { padArrayStart } from 'constants/helper';
import { overallSalesVolumeStatisticId } from 'constants/onboarding';

import { Collectible } from 'protobuf/lib/collectibleService';

import {
  getAggregatedFromDate,
  getAggregatedTimeFormat,
  getDashboardChart,
  getPeriodUnit,
  Period,
} from 'services/chart.v2';
import { getCollectibles } from 'services/collectible';
import { DEFAULT_CURRENCY } from 'services/currency';
import { computedStyle } from 'services/style';

import { AppState } from 'state';

import CollectibleItem from './Item';
import css from './index.module.css';

const Dashboard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  const request = { creator: walletConnectV2.address! };
  const collectiblesQuery = useQuery(['getCollectibles', request], () => getCollectibles(request));

  const collectibles = useMemo(() => {
    return collectiblesQuery.isLoading
      ? padArrayStart<Collectible>((collectiblesQuery.data ?? []).slice(0, 2), 3)
      : collectiblesQuery.data?.slice(0, 3) ?? [];
  }, [collectiblesQuery.isLoading, collectiblesQuery.data]);

  const handleCollectibleClick = useCallback(
    (collectible: Collectible) => {
      history.push(`collectible/${collectible.collectibleId}`);
    },
    [history],
  );

  const [period, setPeriod] = useState<Period>('ALL');
  const [fromDate, setFromDate] = useState<Date | undefined>();
  const [toDate] = useState(new Date());

  useEffect(() => {
    setFromDate(getAggregatedFromDate(period, toDate));
  }, [period, toDate]);

  const { data: stats } = useQuery(['getDashboardChart', fromDate, toDate], () =>
    getDashboardChart({
      filter: { fromDate, toDate },
    }),
  );

  const [currency, setCurrency] = useState(DEFAULT_CURRENCY);
  const currencyOptions = useMemo(
    () => (stats ? Object.keys(stats.charts).map(i => ({ label: i, value: i })) : []),
    [stats],
  );

  const chartData = useMemo(() => {
    const labels: Date[] = [];
    const myRevenueShare: number[] = [];
    const overallPrimarySalesVolume: number[] = [];
    const myRoyaltyShare: number[] = [];
    const overallSecondarySalesVolume: number[] = [];

    stats?.charts[currency].points.forEach(
      ({ timestamp, revenueShare, primarySalesVolume, royaltyShare, secondarySalesVolume }) => {
        labels.push(timestamp!);
        myRevenueShare.push(Number(revenueShare));
        overallPrimarySalesVolume.push(Number(primarySalesVolume));
        myRoyaltyShare.push(Number(royaltyShare));
        overallSecondarySalesVolume.push(Number(secondarySalesVolume));
      },
    );

    return {
      labels,
      datasets: [
        {
          type: 'bar' as const,
          label: 'My Revenue Share',
          data: myRevenueShare,
          clip: 1,
          backgroundColor: computedStyle.getPropertyValue('--accent-blue'),
          stack: 'BLUE',
        },
        {
          type: 'bar' as const,
          label: 'Overall Primary Sales Volume',
          data: overallPrimarySalesVolume,
          backgroundColor: computedStyle.getPropertyValue('--accent-blue-dark'),
          stack: 'BLUE',
        },
        {
          type: 'bar' as const,
          label: 'My Royalty Share',
          data: myRoyaltyShare,
          backgroundColor: computedStyle.getPropertyValue('--accent-green'),
          stack: 'GREEN',
        },
        {
          type: 'bar' as const,
          label: 'Overall Secondary Sales Volume',
          data: overallSecondarySalesVolume,
          backgroundColor: computedStyle.getPropertyValue('--accent-dark-green'),
          stack: 'GREEN',
        },
      ],
    };
  }, [currency, stats?.charts]);

  const getContent = () => {
    const { isError, refetch } = collectiblesQuery;

    if (isError) {
      return <ErrorMessageQueryLoading isVisibleImage={false} onRetry={() => refetch()} />;
    }
    if (isEmpty(collectibles)) {
      return <EmptyList message={t('dashboard.emptyList')} />;
    }
    return (
      <ItemList
        items={collectibles}
        Component={CollectibleItem}
        className={css.list}
        onItemClick={handleCollectibleClick}
      />
    );
  };

  return (
    <PageArea>
      <div className={css.transactionGraph}>
        <PageHeader title="Reporting Dashboard" className={css.rightContentHeading}>
          <Tooltip content={t('dashboard.tooltip')}>
            <HelpIcon />
          </Tooltip>
        </PageHeader>

        <div id={overallSalesVolumeStatisticId}>
          <PriceLineChart
            data={chartData}
            unit={getPeriodUnit(period)}
            tooltipFormat={getAggregatedTimeFormat(period)}
            currency={currency}
            header={
              <div className={css.totalHeader}>
                <PeriodSelector value={period} setValue={setPeriod} />
                <div className={css.totalRevenue}>
                  {t('dashboard.totalRevenue')}:{' '}
                  <span className={css.totalPrice}>
                    {stats?.charts[currency].totalRevenue || 0} {currency}
                  </span>
                </div>
                <FilterDropdown value={currency} onChange={setCurrency} options={currencyOptions} />
              </div>
            }
          />
        </div>
      </div>

      <article>
        <PageHeader title="New Non-Fungible Tokens">
          <ButtonOutline onClick={() => history.push('/home/collection/list')}>View All</ButtonOutline>
        </PageHeader>
        <div className={css.digitalCollectibles}>{getContent()}</div>
      </article>
    </PageArea>
  );
};

export default Dashboard;
