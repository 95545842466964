import * as yup from 'yup';

export default yup.object().shape(
  {
    name: yup
      .string()
      .required('You must provide a name')
      .min(1, 'Minimum length is 1 character')
      .max(100, 'Maximum length is 100 characters'),
    lastname: yup
      .string()
      .required('You must provide a last name')
      .min(1, 'Minimum length is 1 character')
      .max(100, 'Maximum length is 100 characters'),
    business: yup
      .string()
      .required('You must provide a business name')
      .min(1, 'Minimum length is 1 character')
      .max(100, 'Maximum length is 100 characters'),
    email: yup.string().required('You must provide the email').email('You must provide a valid format'),
    phone: yup.string().required('You must provide the phone number'),
    industry: yup.string().required('You must provide the industry'),
    role: yup.string().required('You must provide the user role'),
  },
  [],
);
