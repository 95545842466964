import * as yup from 'yup';

export default yup.object().shape(
  {
    checked: yup
      .boolean()
      .isTrue('You must attest to the terms of this license agreement')
      .required('You must attest to the terms of this license agreement'),
  },
  [],
);
