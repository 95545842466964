// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ClaimRoyaltyList_wrapper__nXuL\\+ {
  display: flex;
}
.ClaimRoyaltyList_wrapper__nXuL\\+ > div:first-of-type {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.ClaimRoyaltyList_wrapper__nXuL\\+ > div > figure {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  border-radius: 6px;
}

.ClaimRoyaltyList_wrapper__nXuL\\+ > div > h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  min-width: 300px;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ClaimRoyaltyList_content__i5iiB {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/collection/ClaimRoyaltyList/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;EAChB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB","sourcesContent":[".wrapper {\n  display: flex;\n}\n.wrapper > div:first-of-type {\n  display: flex;\n  align-items: center;\n  margin-bottom: 20px;\n}\n.wrapper > div > figure {\n  width: 100px;\n  height: 100px;\n  min-width: 100px;\n  min-height: 100px;\n  border-radius: 6px;\n}\n\n.wrapper > div > h4 {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 20px;\n  min-width: 300px;\n  max-width: 300px;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  margin: 20px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ClaimRoyaltyList_wrapper__nXuL+`,
	"content": `ClaimRoyaltyList_content__i5iiB`
};
export default ___CSS_LOADER_EXPORT___;
