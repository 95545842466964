// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dropdown_wrapper__7sf0o {
  position: relative;
}

.Dropdown_toggle__tDnKk {
  width: 100%;
}

.Dropdown_toggle__tDnKk > svg {
  transition: 0.25s all ease;
}

.Dropdown_opened__YJMSn {
  transform: rotate(180deg);
}

.Dropdown_overpane__JhFk7 {
  position: absolute;
  top: calc(100% + 8px);

  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/button/Dropdown/index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;;EAErB,WAAW;AACb","sourcesContent":[".wrapper {\n  position: relative;\n}\n\n.toggle {\n  width: 100%;\n}\n\n.toggle > svg {\n  transition: 0.25s all ease;\n}\n\n.opened {\n  transform: rotate(180deg);\n}\n\n.overpane {\n  position: absolute;\n  top: calc(100% + 8px);\n\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Dropdown_wrapper__7sf0o`,
	"toggle": `Dropdown_toggle__tDnKk`,
	"opened": `Dropdown_opened__YJMSn`,
	"overpane": `Dropdown_overpane__JhFk7`
};
export default ___CSS_LOADER_EXPORT___;
