import { TransactionStatus } from 'beasy-fe-commons';
//import logger from 'logger';
import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import { AlertProps } from 'components/Alert';
import { Attribute, CombinedStepValues, StepIndex } from 'components/collectible/steps/types';
import { LoadingScreenProps } from 'components/loading/Screen';

import { MinimalTransactionInfo, PopulateTransactionReq } from 'protobuf/lib/transactionMessage';

import { createCollectible, RawAssetData } from 'services/collectible';
import history from 'services/history';
import { populateTransaction } from 'services/transaction';
import { Activity, log } from 'services/activityReport';
import { useSelector } from 'react-redux';
import { AppState } from 'state';
import { useWalletConnectClient } from 'context/ClientContext';

export interface TransactionType {
  data: number;
}

export interface CreateCollectibleController {
  uploadProgressMessage: string;
  getTransaction: (combinedValues: CombinedStepValues) => Promise<MinimalTransactionInfo | undefined>;
  handleTransaction: (transaction: PopulateTransactionReq, collectibleName: string, data: TransactionType) => Promise<void>;
  loadingScreenProps: LoadingScreenProps | undefined;
  uploadProgress: number;
  alertProps: AlertProps | undefined;
}

const dateToMilis = (stringDate: string): string => {
  var parts = stringDate.split('/');
  // January - 0, February - 1, etc.
  var mydate = new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
  return mydate.getTime().toString();
}

const setAttributesType = (attributes: Attribute[]): Attribute[] => {
  let modifiedArray = attributes;
  for (let i = 0; i < attributes.length; i++) {
    switch (attributes[i].display_type) {
      case 'Text': {
        modifiedArray[i].display_type = 'string';
        break;
      }
      case 'Percentage': {
        modifiedArray[i].display_type = 'boost_percentage';
        break;
      }
      case 'Number': {
        modifiedArray[i].display_type = 'number';
        break;
      }
      case 'Date': {
        modifiedArray[i].display_type = 'date';
        modifiedArray[i].value = dateToMilis(modifiedArray[i].value)
        break;
      }
    }
  };
  return modifiedArray;
}

const useCreateCollectible = (): CreateCollectibleController => {
  const queryClient = useQueryClient();
  const [loadingScreenProps, setLoadingScreenProps] = useState<LoadingScreenProps | undefined>();
  const [alertProps, setAlertProps] = useState<AlertProps | undefined>();
  const walletConnectV2 = useSelector((state: AppState) => state.session);
  const { sendTransaction } = useWalletConnectClient();
  const noMaticCollectionError = "insufficient funds for gas * price + value";
  const showErrorAlert = useCallback((customMessage?: string, cryptoRequired?: string) => {
    setLoadingScreenProps(undefined);
    setAlertProps({
      message:
        customMessage === undefined
          ? 'We have encountered an error performing this transaction. Please try again.'
          : customMessage,
      cryptoRequired: cryptoRequired,
      isSuccess: false,
      onClose: () => setAlertProps(undefined),
    });
  }, []);

  const [uploadProgress, setUploadProgress] = useState(0);

  const getTransaction = useCallback(
    async (combinedValues: CombinedStepValues) => {
      try {
        setLoadingScreenProps({
          message: 'Calculating the transaction cost',
          isNotification: false,
        });
        setUploadProgress(0);
        const details = combinedValues[StepIndex.DETAILS]!;
        const attributesSetValues = combinedValues[StepIndex.ATTRIBUTES]!;
        const termsSetValues = combinedValues[StepIndex.TERMS]!;
        const { contributors } = combinedValues[StepIndex.CONTRIBUTORS]!;

        const attributes: Attribute[] = attributesSetValues.attributes.map(obj => ({ ...obj }));

        const body: RawAssetData = {
          collection: details.collection,
          name: details.name,
          description: details.description,
          media: [],
          license: termsSetValues.licenseAgreement,
          ownership: termsSetValues.ownershipAgreement,
          price: 0, // Number(details.price),
          quantity: Number(details.quantity),
          creator: walletConnectV2.address!,
          contributors: contributors.map(i => ({ ...i, revenueShare: String(i.revenueShare) })), // todo in formik
          // royaltyHolders: royaltyHolders.map(i => ({ ...i, royalty: String(i.royalty) })), // todo in formik
          network: walletConnectV2.chainId!,
          attributes: setAttributesType(attributes)
        };

        if (details.imgUrl) {
          body.media.push(details.imgUrl);
        }

        if (details.videoUrl) {
          body.media.push(details.videoUrl);
        }

        if (details.audioUrl) {
          body.media.push(details.audioUrl);
        }

        const response = await createCollectible(body, event => {
          const { loaded, total } = event;
          const progress = 0.05 + (0.95 * loaded) / total;
          setUploadProgress(progress);
        });

        if (response.status && response.data && walletConnectV2.address) {
          setLoadingScreenProps(undefined);
          return response.data;
        } else {
          //logger.error({ response });
          showErrorAlert();
        }
      } catch (error) {
        if (String(error).includes('EstimateGasError')) {

          if (String(error).includes(noMaticCollectionError)) {
            const errors = String(error).split('want ');
            const weyRequired = errors[1].split('(');
            const cryptoRequired = Number(Number(weyRequired[0]) / 1000000000000000000).toFixed(20);

            let cryptoFormatted = cryptoRequired.toString();
            while (cryptoFormatted.endsWith('0')) {
              cryptoFormatted = cryptoFormatted.substring(0, cryptoFormatted.length - 2);
            }

            showErrorAlert('You have insufficient funds.\n ' + cryptoFormatted + ' MATIC required.');
            return;
          }

          showErrorAlert(
            'Something went wrong\nPlease try again'
          );
          return;
        }
        //logger.error({ error });
        showErrorAlert();
      }
    },
    [showErrorAlert],
  );

  const handleTransaction = useCallback(
    async (populate: PopulateTransactionReq, collectibleName: string, data: TransactionType) => {
      try {
        setLoadingScreenProps({
          message: 'Preparing the transaction',
          isNotification: true,
        });
        const { primary, additional } = await populateTransaction(populate);
        let additionalExtra = { ...additional, customData: data };
        setLoadingScreenProps({
          message: 'Please confirm the NFT creation in your BEASY Wallet',
          isNotification: true,
        });

        const txForWallet = { ...primary, ...additionalExtra };

        //const [status, hash] = await walletConnectService.sendTransaction(txForWallet);
        const [status,hash] = await sendTransaction(txForWallet)
        setLoadingScreenProps(undefined);
        switch (status) {
          case TransactionStatus.Success:
            log(txForWallet.from!, Activity.NEW_COLLECTIBLE, process.env.REACT_APP_DOMAIN);
            setAlertProps({
              isSuccess: true,
              message: 'NFT sucessfully created!',
              onClose: () => {
                history.push('/home/dashboard');
                setAlertProps(undefined);
              },
            });
            await queryClient.refetchQueries(['getCollectibles']);
            break;
          case TransactionStatus.Pending:
            setAlertProps({
              isSuccess: true,
              message:
                'The transaction is taking a bit long. We will complete it in the background, and you will see it shortly.',
              hash,
              onClose: () => {
                history.push('/home/dashboard');
                setAlertProps(undefined);
              },
            });
            await queryClient.refetchQueries(['getCollectibles']);
            break;
          case TransactionStatus.Rejected:
            setAlertProps({
              isSuccess: false,
              message: 'You have rejected the transaction',
              onClose: () => setAlertProps(undefined),
            });
            break;
          default:
            //logger.error({ status });
            showErrorAlert();
        }
      } catch (error) {
        //logger.error({ error });
        showErrorAlert();
      }
    },
    [queryClient, showErrorAlert],
  );

  const uploadProgressMessage = useMemo(() => {
    return uploadProgress === 1
      ? 'Almost done...'
      : `Uploaded: ${uploadProgress != null ? Math.round(100 * uploadProgress) : null}%`;
  }, [uploadProgress]);

  return useMemo(
    () => ({
      getTransaction,
      handleTransaction,
      loadingScreenProps,
      alertProps,
      uploadProgress,
      uploadProgressMessage,
    }),
    [alertProps, getTransaction, handleTransaction, loadingScreenProps, uploadProgress, uploadProgressMessage],
  );
};

export default useCreateCollectible;
