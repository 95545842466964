import { ButtonOutline } from 'components/button';

import { ErrorMessage } from './ErrorMessage';
import css from './ErrorMessageWithRetryButton.module.css';

interface Props {
  message: string;
  isButtonDisabled: boolean;
  // Optional:
  buttonType?: 'button' | 'submit';
  onRetry?: () => void;
}

export const ErrorMessageWithRetryButton = ({ message, buttonType = 'button', isButtonDisabled, onRetry }: Props) => {
  return (
    <ErrorMessage>
      <div className={css.message}>{message}</div>
      <ButtonOutline disabled={isButtonDisabled} type={buttonType} onClick={onRetry}>
        Try Again
      </ButtonOutline>
    </ErrorMessage>
  );
};
