// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Review_container__4GiGL {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.Review_section__ONb-E {
  margin-top: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/components/collectible/steps/Review/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  margin-right: 20px;\n}\n\n.section {\n  margin-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Review_container__4GiGL`,
	"section": `Review_section__ONb-E`
};
export default ___CSS_LOADER_EXPORT___;
