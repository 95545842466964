// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ClearableSelect_wrapper__2THOL > div {
  display: flex;
  position: relative;
  border-radius: inherit;
}

.ClearableSelect_clearable__Rk9JX {
  flex: 1 1;
}

.ClearableSelect_wrapper__2THOL > div > button {
  align-items: center;
  background-color: transparent;
  border: none;
  margin-left: 5px;
  color: var(--text-color-accent);
}

.ClearableSelect_wrapper__2THOL > div > button:disabled {
  display: none;
}

.ClearableSelect_fileList__sBBae {
  position: relative;
  z-index: 1;
  width: 100%;
  max-height: 100px;
  overflow-y: scroll;
  color: #14161c;
  background-color: #eee;
  padding: 0;
  list-style: none;
}

.ClearableSelect_list__HaD1m {
  position: relative;
  z-index: 1;
  width: 100%;
  max-height: 100px;
  overflow-y: scroll;
  color: #14161c;
  background-color: #eee;
  padding: 0;
  list-style: none;
}

.ClearableSelect_listAbsolute__fcfiU {
  position: absolute;
  z-index: 1;
  width: 350px;
  max-height: 100px;
  overflow-y: scroll;
  color: #14161c;
  background-color: #eee;
  padding: 0;
  list-style: none;
}

.ClearableSelect_wrapper__2THOL > ul > li {
  padding: 5px 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ClearableSelect_wrapper__2THOL > ul > li.highlighted {
  background-color: #bde4ff;
}
`, "",{"version":3,"sources":["webpack://./src/components/input/ClearableSelect/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,mBAAmB;EACnB,6BAA6B;EAC7B,YAAY;EACZ,gBAAgB;EAChB,+BAA+B;AACjC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,sBAAsB;EACtB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,sBAAsB;EACtB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,sBAAsB;EACtB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".wrapper > div {\n  display: flex;\n  position: relative;\n  border-radius: inherit;\n}\n\n.clearable {\n  flex: 1;\n}\n\n.wrapper > div > button {\n  align-items: center;\n  background-color: transparent;\n  border: none;\n  margin-left: 5px;\n  color: var(--text-color-accent);\n}\n\n.wrapper > div > button:disabled {\n  display: none;\n}\n\n.fileList {\n  position: relative;\n  z-index: 1;\n  width: 100%;\n  max-height: 100px;\n  overflow-y: scroll;\n  color: #14161c;\n  background-color: #eee;\n  padding: 0;\n  list-style: none;\n}\n\n.list {\n  position: relative;\n  z-index: 1;\n  width: 100%;\n  max-height: 100px;\n  overflow-y: scroll;\n  color: #14161c;\n  background-color: #eee;\n  padding: 0;\n  list-style: none;\n}\n\n.listAbsolute {\n  position: absolute;\n  z-index: 1;\n  width: 350px;\n  max-height: 100px;\n  overflow-y: scroll;\n  color: #14161c;\n  background-color: #eee;\n  padding: 0;\n  list-style: none;\n}\n\n.wrapper > ul > li {\n  padding: 5px 10px;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.wrapper > ul > li:global(.highlighted) {\n  background-color: #bde4ff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ClearableSelect_wrapper__2THOL`,
	"clearable": `ClearableSelect_clearable__Rk9JX`,
	"fileList": `ClearableSelect_fileList__sBBae`,
	"list": `ClearableSelect_list__HaD1m`,
	"listAbsolute": `ClearableSelect_listAbsolute__fcfiU`
};
export default ___CSS_LOADER_EXPORT___;
