import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  errorMessage: string | null;
}

interface AuthSlice {
  auth: AuthState;
}

const initialState: AuthState = {
  errorMessage: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    clearErrorMessage: state => {
      state.errorMessage = null;
    },
  },
});

export const { setErrorMessage, clearErrorMessage } = authSlice.actions;

export const authSelector = (state: AuthSlice) => state.auth;

export const errorMessageSelector = createSelector(authSelector, state => state.errorMessage);

export default authSlice.reducer;
