import { environment } from '../environment';
import { axios } from './axios';
import { UserInfo } from './userInfoService';

export interface ApplicationInfo {
  name: string;
  url: string;
  description: string;
  icons: string[];
}

interface ApplicationAuthParams {
  redirectUrl: string;
  applicationToken: string;
  userInfo: UserInfo;
}

interface ApplicationAuthResult {
  jwt: string;
}

export const getApplicationInfo = async (applicationToken: string): Promise<ApplicationInfo | null> => {
  const res = await axios.post(`${environment.apiUrl}auth/applicationInfo`, { applicationToken });

  if (res?.data) {
    return res.data as ApplicationInfo;
  }

  return null;
};

const authApplication = async (data: ApplicationAuthParams): Promise<ApplicationAuthResult | null> => {
  const res = await axios.post(`${environment.apiUrl}auth/application`, data);

  if (res?.data) {
    return res.data as ApplicationAuthResult;
  }

  return null;
};

const params = new URLSearchParams(window.location.search);
export const redirectUrl = params.get('redirectUrl');
export const applicationToken = params.get('applicationToken');

export const handleCrossAuth = async (userInfo: UserInfo) => {
  if (!redirectUrl || !applicationToken) {
    return;
  }

  const apiAuth = await authApplication({
    redirectUrl,
    applicationToken,
    userInfo,
  });

  if (apiAuth && apiAuth.jwt) {
    const url = new URL(redirectUrl);
    url.searchParams.append('token', apiAuth.jwt);
    window.location.href = url.toString();
  } else {
    console.error('Cannot authenticate application');
  }
};
