
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SessionTypes } from '@walletconnect/types';

export interface WalletConnectV2State {
    session: SessionTypes.Struct | undefined;
    address: string | undefined;
    chainId: string | undefined;
    balance: string | undefined;
    jwt: string | undefined;
    isCreator: boolean;
    isAccepted: boolean;
}

const initialState: WalletConnectV2State = {
    session: undefined,
    address: undefined,
    chainId: undefined,
    balance: undefined,
    jwt: undefined,
    isCreator: false,
    isAccepted: false,
};


const walletConnectV2Slice = createSlice({
    name: 'walletConnectV2',
    initialState,
    reducers: {
        setWalletConnectV2: (state, action: PayloadAction<WalletConnectV2State>) => {
            return action.payload;
        },
        clearWalletConnectV2: () => initialState,
    },
});

export const { setWalletConnectV2, clearWalletConnectV2 } = walletConnectV2Slice.actions;

export default walletConnectV2Slice.reducer;