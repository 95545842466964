import { grpc } from '@improbable-eng/grpc-web';
import { captureException } from '@sentry/react';

import { GrpcWebImpl, ChargeMasterServiceClientImpl } from 'protobuf/lib/chargeMaster';

import { environment } from 'environment';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const chargeMasterServiceClient = new ChargeMasterServiceClientImpl(rpc);

export const registerUser = async (user: string, role: string, accountID: string) => {
  try {
    const result = await chargeMasterServiceClient.RegisterUser({
      user,
      tierID: 1,
      role,
      accountID,
    });
    return result;
  } catch (error) {
    captureException(error);
    throw error;
  }
};
