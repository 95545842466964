import { useTranslation } from 'react-i18next';

import { WarningIcon } from 'assets/icons';

import { ButtonPrimary, ButtonSecondary } from 'components/button';
import { DialogArea, DialogClose, DialogFooter, DialogHeader, DialogOverlay } from 'components/dialog';

import css from './index.module.css';

interface Props {
  onYes: () => void;
  onNo: () => void;
}

const DeleteNftModal = ({ onYes, onNo }: Props) => {
  const { t } = useTranslation();
  return (
    <DialogOverlay>
      <DialogArea>
        <DialogHeader>
          <div className={css.header}>
            <WarningIcon className={css.icon} />
            <h3>{t('deleteNftModal.title')}</h3>
          </div>
          <DialogClose onClick={onNo} />
        </DialogHeader>
        <p className={css.label}>{t('deleteNftModal.confirmation')}</p>
        <DialogFooter>
          <ButtonSecondary onClick={onNo}>{t('deleteNftModal.no')}</ButtonSecondary>
          <ButtonPrimary onClick={onYes}>{t('deleteNftModal.yes')}</ButtonPrimary>
        </DialogFooter>
      </DialogArea>
    </DialogOverlay>
  );
};

export default DeleteNftModal;
