import { grpc } from '@improbable-eng/grpc-web';
import { captureException } from '@sentry/react';
import { EthereumAddress, IpfsFile } from 'beasy-fe-commons';
import { v4 } from 'uuid';
import web3 from 'web3';

import {
  History,
  CollectibleServiceClientImpl,
  CreateCollectibleTransactionReq,
  GetCollectiblesReq,
} from 'protobuf/lib/collectibleService';
import { GrpcWebImpl } from 'protobuf/lib/collectibleService';
import { MinimalTransactionInfo } from 'protobuf/lib/transactionMessage';

import { environment } from 'environment';

//import logger from '../logger';
import { settings } from '../settings';
import { authenticated } from './grpc';
import { CollectibleDetails, UploadData, ipfsUploadCollectible } from './ipfs';
import generateThumbnail from './thumbnail';
import { Attribute } from 'components/collectible/steps/types';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const collectibleServiceClient = new CollectibleServiceClientImpl(rpc);

export const getCollectibles = async (req: GetCollectiblesReq) => {
  try {
    const message = await collectibleServiceClient.GetCollectibles(req);
    return message.collectibles;
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const getSharedCollectiblesWithCreator = async (address: EthereumAddress) => {
  try {
    const message = await authenticated(meta =>
      collectibleServiceClient.GetSharedCollectiblesWithCreator(
        {
          address,
        },
        meta,
      ),
    );
    return message.collectibles;
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const getCollectibleById = async (collectibleId: string) => {
  try {
    const message = await collectibleServiceClient.GetCollectibleById({ collectibleId });
    return message.collectible;
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const getAssetHistory = async (tokenId: string): Promise<History[]> => {
  try {
    //logger.info('Start', { name: 'getAssetHistory' });

    /* logger.http('gRPC CollectibleService.GetAssetHistory request', {
      name: 'getAssetHistory',
      payload: { tokenId },
    });
    */
    const message = await collectibleServiceClient.GetAssetHistory({ tokenId }, undefined);

    /*
    logger.http('gRPC CollectibleService.GetAssetHistory response', {
      name: 'getAssetHistory',
      payload: message,
    });
    */  

    const { history } = message;

    //logger.info('Success', { name: 'getAssetHistory' });

    return history.sort((a, b) => b.timestamp - a.timestamp);
  } catch (error) {
    //logger.error('Failure', { name: 'getAssetHistory', payload: error });
    captureException(error);
    throw error;
  }
};

export type RawAssetData = Omit<CreateCollectibleTransactionReq, 'collectibleURL' | 'collectibleId'> & {
  media: File[];
  license?: File | IpfsFile;
  ownership?: File | IpfsFile;
  attributes: Attribute[];
};

interface CreateCollectibleSuccessResponse {
  status: true;
  // Optional
  data?: MinimalTransactionInfo;
}

interface CreateCollectibleFailureResponse {
  status: false;
  // Optional
  error?: Error;
}

type CreateCollectibleResponse = CreateCollectibleSuccessResponse | CreateCollectibleFailureResponse;

export const createCollectible = async (
  data: RawAssetData,
  onUploadProgress: (event: ProgressEvent) => void,
): Promise<CreateCollectibleResponse> => {
  try {
    //logger.info('Start', { name: createCollectible.name });

    const {
      collection,
      name,
      description,
      price,
      quantity,
      creator,
      media,
      license,
      ownership,
      contributors,
      network,
      attributes
    } = data;

    const collectibleId = generateCollectibleId();

    const details: CollectibleDetails = {
      name,
      description,
      quantity,
      id: collectibleId,
    };

    const thumbnail = await generateThumbnail(media[0], settings.thumbnails);

    const uploadData: UploadData = {
      thumbnail: thumbnail ?? undefined,
      media,
      license,
      ownership,
    };

    //logger.http('POST ipfs/upload request', { name: createCollectible.name, payload: uploadData });

    const cid = await ipfsUploadCollectible(details, uploadData, onUploadProgress, attributes);

    //logger.http('POST ipfs/upload response', { name: createCollectible.name, payload: { cid } });

    const createCollectibleRequest: CreateCollectibleTransactionReq = {
      collection,
      collectibleURL: cid,
      collectibleId,
      name,
      description,
      price,
      quantity,
      creator,
      contributors,
      network,
    };

    /*logger.http('gRPC CollectibleService.CreateCollectible request', {
      name: createCollectible.name,
      payload: createCollectibleRequest,
    });
    */
    const message = await collectibleServiceClient.CreateCollectibleTransaction(createCollectibleRequest);
    /*
    logger.http('gRPC CollectibleService.CreateCollectible response', {
      name: createCollectible.name,
      payload: message,
    });
    */
    if (message && message.transaction) {
     //logger.info('Success', { name: createCollectible.name });
      return {
        status: true,
        data: message.transaction,
      };
    }

   //logger.error('Could not create Digital Asset', { name: createCollectible.name, message });
    return {
      status: false,
    };
  } catch (error) {
    //logger.error('Could not create Digital Asset', { name: createCollectible.name, payload: error });
    captureException(error);
    return {
      status: false,
      // error,
    };
  }
};

const generateCollectibleId = () => {
  const uuid = v4();
  return '0x' + (BigInt(web3.utils.keccak256(uuid)) & ~BigInt(0xffffffff)).toString(16);
};

export const deleteCollectibleById = async (collectibleId: string) => {
  try {
    await collectibleServiceClient.DeleteCollectible({ collectibleId });
  } catch (error) {
    captureException(error);
    throw error;
  }
};
