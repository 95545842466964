import { grpc } from '@improbable-eng/grpc-web';
import { captureException } from '@sentry/react';

import { GrpcWebImpl, RoyaltyServiceClientImpl } from 'protobuf/lib/royalty';

import { environment } from 'environment';

import { store } from 'state';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const royaltyServiceClient = new RoyaltyServiceClientImpl(rpc);

export const getUserCollectionBalance = async () => {
  try {
    const state = store.getState();
    const walletConnectV2 = state.session;
    return await royaltyServiceClient.GetUserCollectionsBalance({ address: walletConnectV2.address });
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const withdrawMatic = async (collectionId: string) => {
  try {
    return await royaltyServiceClient.Widthdraw({ collectionId });
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const withdrawWeth = async (collectionId: string) => {
  try {
    return await royaltyServiceClient.WidthdrawToken({ collectionId });
  } catch (error) {
    captureException(error);
    throw error;
  }
};
