import { grpc } from '@improbable-eng/grpc-web';
import { captureException } from '@sentry/react';

import {
  GrpcWebImpl,
  FrontstoreServiceClientImpl,
  GetFrontstoreTypesRes,
  GetFrontstoresByEthAddressRes,
  Frontstore,
} from 'protobuf/lib/frontstore';

import { environment } from 'environment';

import { authenticated } from './grpc';
import { store } from 'state';

interface FrontstoreDto {
  fid: string;
  fpwd: string;
  fhost: string;
  type?: string;
}

export enum FrontstoreQueryKey {
  getFrontstoreTypes = 'getFrontstoreTypes',
  getFrontstores = 'getFrontstores',
  createFrontstore = 'createFrontstore',
  updateFrontstore = 'updateFrontstore',
  deleteFrontstore = 'deleteFrontstore',
}

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const frontstoreServiceClient = new FrontstoreServiceClientImpl(rpc);

export class FrontstoreAlreadyExistsError extends Error {
  constructor(readonly fid: string) {
    super(`Frontstore with fid ${fid} already exists`);
  }
}

export const getFrontstoreTypes = async (): Promise<GetFrontstoreTypesRes> => {
  try {
    return await frontstoreServiceClient.GetFrontstoreTypes({});
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const getFrontstores = async (): Promise<GetFrontstoresByEthAddressRes> => {
  try {
    const state = store.getState();
    const walletConnectV2 = state.session;
    return await frontstoreServiceClient.GetFrontstoresByEthAddress({
      ethAddress: walletConnectV2.address!,
    });
  } catch (error) {
    captureException(error);
    throw error;
  }
};

export const createFrontstore = async ({ fid, fpwd, fhost, type }: FrontstoreDto): Promise<Frontstore> => {
  try {
    const state = store.getState();
    const walletConnectV2 = state.session;
    return await authenticated(meta => {
      return frontstoreServiceClient.CreateFrontstore(
        { ethAddress: walletConnectV2.address!, fid, fpwd, fhost, type },
        meta,
      );
    });
  } catch (error: any) {
    if (error.code === grpc.Code.AlreadyExists) {
      throw new FrontstoreAlreadyExistsError(fid);
    }
    captureException(error);
    throw error;
  }
};

export const updateFrontstore = async (
  frontstoreId: number,
  { fid, fpwd, fhost }: FrontstoreDto,
  type: string,
): Promise<Frontstore> => {
  try {
    return await authenticated(meta => {
      return frontstoreServiceClient.UpdateFrontstore({ frontstoreId, data: { fid, type, fpwd, fhost } }, meta);
    });
  } catch (error: any) {
    if (error.code === grpc.Code.AlreadyExists) {
      throw new FrontstoreAlreadyExistsError(fid);
    }
    captureException(error);
    throw error;
  }
};

export const deleteFrontstore = async (frontstoreId: number): Promise<void> => {
  try {
    await authenticated(meta => {
      return frontstoreServiceClient.DeleteFrontstore({ frontstoreId }, meta);
    });
  } catch (error) {
    captureException(error);
    throw error;
  }
};
