import { IpfsFile } from 'beasy-fe-commons';
import { useEffect, useState } from 'react';

import { AudioIcon, PdfIcon } from 'assets/icons';

import { useCollection } from 'hooks/collection';

import { Spacer } from 'components/Spacer';

import { DetailsStepValue } from '../../../types';
import css from './index.module.css';

interface Props {
  details: DetailsStepValue;
  licenseAgreement?: File | IpfsFile;
  ownershipAgreement?: File | IpfsFile;
}

const Details = ({
  details: { collection, name, quantity, imgUrl, videoUrl, description },
  licenseAgreement,
  ownershipAgreement,
}: Props) => {
  const coll = useCollection(collection);
  const [dataURL, setDataURL] = useState<string>();
  useEffect(() => {
    const file = imgUrl || videoUrl;

    if (file && !dataURL) {
      const r = new FileReader();

      r.addEventListener(
        'load',
        () => {
          setDataURL(r.result as string);
        },
        false,
      );

      r.readAsDataURL(file);
    }
  }, [dataURL, imgUrl, videoUrl]);

  return (
    <div className={css.container}>
      <div className={css.media}>
        {imgUrl ? (
          <img className={css.imgPreview} src={dataURL} alt="" />
        ) : videoUrl ? (
          <video className={css.videoPreview} src={dataURL} controls />
        ) : (
          <AudioIcon size={160} />
        )}
      </div>
      <div className={css.info}>
        <div className={css.name}>
          <h2>{name}</h2>
          <p>{quantity} pcs</p>
        </div>
        <div>
          <h4>{coll?.name}</h4>
        </div>

        <div className={css.description}>
          <p>{description}</p>
        </div>
        <Spacer height="20px" />
        <div className={css.terms}>
          <div>
            <p>Ownership Agreement</p>
            <Spacer height="5px" />
            <div className={css.document}>
              {ownershipAgreement && <PdfIcon />}
              <p>{ownershipAgreement?.name || 'No file selected'}</p>
            </div>
          </div>
          <div>
            <p>License Agreement</p>
            <Spacer height="5px" />
            <div className={css.document}>
              {licenseAgreement && <PdfIcon />}
              <p>{licenseAgreement?.name || 'No file selected'}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
